/* eslint-disable import/prefer-default-export */

import { API_ENDPOINT } from '../../constants';
import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';

export const getCreditDuesData = async (
  clientId = '',
  agencyId = '',
  startDate = '',
  endDate = '',
  limit = 10,
  page = 1,
  sortKey = 'employee_id',
  sortType = 'ASC',
  creditPercentage = ''
) => {
  try {
    let queryString = '';

    if (clientId) {
      queryString += `client_id=${clientId}&`;
    }
    if (agencyId) {
      queryString += `agency_id=${agencyId}&`;
    }
    if (startDate) {
      queryString += `start_date=${startDate}&`;
    }
    if (endDate) {
      queryString += `end_date=${endDate}&`;
    }
    if (creditPercentage) {
      queryString += `where_rate_is=${creditPercentage}&`;
    }
    queryString += `page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}`;

    const result = await axiosInstance.get(`${API_ENDPOINT.CREDIT_DUES}?${queryString}`);

    if (result && result.ok) {
      return prepareSuccessReponse(result);
    }
    return prepareErrorResponse(result);
  } catch (error) {
    return prepareErrorResponse(error);
  }
};
