import { Flex } from '@chakra-ui/react';
import styled from 'styled-components';

const StyledMessagingContainer = styled.div``;

export const StyledMessageCardHeaderContainer = styled(Flex)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  wi
`;

export const StyledDetailLabel = styled.div`
  font-weight: 700;
`;

export const StyledDetailValue = styled.div``;

export default StyledMessagingContainer;
