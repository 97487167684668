/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable no-empty */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-lonely-if */
/* eslint-disable one-var */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */

import {
  Button,
  Flex,
  Spacer,
  useEventListenerMap,
  useToast,
  Box,
  Center,
  Text,
  OrderedList,
  ListItem,
  Spinner,
  Image,
  Tooltip
} from '@chakra-ui/react';
import DatePicker, { DateObject } from 'react-multi-date-picker';

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ModalComponent from '../../components/Modal';
import constants, {
  ADD_BOOKING_VALIDATIONS,
  PATH,
  TOAST_SETTINGS,
  USER_TYPE,
  WEEK_DAYS,
  PAGE_TITLE,
  DATE_TO_SHOW,
  CUSTOM_TOAST_DURATION,
  CUSTOM_TOAST_DURATION_NULL,
  DaySelectionList
} from '../../constants';
import {
  allowNaturalNumber,
  allowOnlyNumber,
  allowTwoDecimalNumbers,
  deepClone,
  validateForm
} from '../../utils/helper';
import { getModulePermissions } from '../../utils/hooks';
import Loader from '../../components/Loader';
import Card from '../../components/Card';
import ClientSideDatatable from '../../components/ClientSideDatatable';

import {
  StyledAgency,
  StyledAgencyHeader,
  StyledShiftBookingContainer
} from './ShiftBooking.styled';
import AddBooking from '../../components/Forms/AddBooking';
import StyledInputWrapper from '../../components/Forms/Forms.styled';
import AgencyDropdown from '../../components/Forms/AgencyDropdown';
import InputComponent from '../../components/Input';
import {
  AddBookingData,
  cancelBooking,
  deleteBooking,
  downloadCreateBookingSampleSheet,
  downloadUploadedFullfillSheet,
  getBookings,
  getBookingsDetail,
  updateBooking,
  updateCreateBookingCsvData,
  updateRequestedHeadsInBooking,
  uploadCreateBookingCsvData
} from '../../redux/action/booking';
import ServerSideDatatable from '../../components/ServerSideDatatable';
import { getAgency } from '../../redux/action/agency.action';
import DownloadIcon from '../../icons/DownloadIcon';
import { StyledUploadFileButton } from '../Attendance/Attendance.styled';
import DeleteIcon from '../../assets/images/delete-icon.png';
import AlertDialogComponent from '../../components/AlertModalComponent';
import { getClientById } from '../../redux/action/client.action';

const INITIAL_BOOKING = {
  start_date: '',
  end_date: '',
  department_id: null,
  shift_id: null,
  shift_name: '',
  department_name: '',
  required_workers_heads: {},
  required_workers_total: 0,
  required_supervisors_heads: {},
  // required_supervisors_total: {},
  requested_workers_total: 0,
  required_supervisors_total: 0,
  worker_validation_data: {},
  supervisor_validation_data: {},
  repeat: 0
};

const LISTING_COLUMNS = [
  {
    label: 'Client',
    field: 'client_name',
    sort: true
  },
  {
    label: 'Site',
    field: 'site_name',
    sort: true
  },
  {
    label: 'Department',
    field: 'department_name',
    sort: true
  },
  {
    label: 'Region',
    field: 'region_name',
    sort: true
  },
  {
    label: 'Shift',
    field: 'shift_name',
    sort: true
  },
  {
    label: 'Requested',
    field: 'total',
    sort: true
  },
  {
    label: 'Start Date',
    field: 'start_date',
    sort: true,
    customRow: (cell, row) => {
      let startDate = null;
      try {
        startDate = moment(row.start_date);
      } catch (error) {}
      /* eslint no-underscore-dangle: ["error", { "allow": ["_i"] }] */
      return <span>{DATE_TO_SHOW(startDate._i)}</span>;
    }
  },
  {
    label: 'End Date',
    field: 'end_date',
    sort: true,
    customRow: (cell, row) => {
      let endDate = null;
      try {
        endDate = moment(row.end_date);
      } catch (error) {}
      /* eslint no-underscore-dangle: ["error", { "allow": ["_i"] }] */
      return <span>{DATE_TO_SHOW(endDate._i)}</span>;
    }
  },
  {
    label: 'Status',
    field: 'status',
    sort: true,
    customRow: (cell, row) => {
      // eslint-disable-next-line no-nested-ternary
      const status = row.status === '0' ? 'Open' : row.status === '1' ? 'Fulfilled' : 'Amended';
      return <span>{status}</span>;
    }
  }
];

const ShiftBooking = function () {
  useEffect(() => {
    document.title = PAGE_TITLE.SHIFT_BOOKING;
  }, []);

  const toast = useToast();
  const { weekday_start } = useSelector((state) => state.agency);
  const permission = getModulePermissions(PATH.SHIFT_BOOKING.CODE);
  const { user_details: userDetails } = useSelector((state) => state.authentication);
  const { selected_client_id } = useSelector((state) => state.agency);
  const [isPageLoader, setIsPageLoader] = useState(false);

  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const [isCsvUploadModalOpen, setisCsvUploadModalOpen] = useState(false);
  const [isFullfillBookingCsvUploadModalOpen, setisFullfillBookingCsvUploadModalOpen] =
    useState(false);
  const [isAgencyAssignModalOpen, setIsAgencyAssignOpenModalOpen] = useState(false);

  const [validations, setValidations] = useState(deepClone(ADD_BOOKING_VALIDATIONS));
  const [bookingData, setBookingData] = useState({ ...INITIAL_BOOKING });
  const [selectedAgencies, setSelectedAgencies] = useState({});
  const [remainAssignWorkers, setRemainAssignWorkers] = useState(0);
  const [remainAssignSupervisors, setRemainAssignSupervisors] = useState(0);
  const [isValidDate, setIsValidDate] = useState(true);

  const [refresh, setRefresh] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [column, setColumn] = useState(LISTING_COLUMNS);
  const [isBookingDetailsModalOpen, setIsBookingDetailsModalOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isBookingEdit, setIsBookingEdit] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [reload, setReload] = useState(false);
  const [requiredCountOfWorkers, setRequiredCountOfWorkers] = useState({});
  const [requiredCountOfSupervisors, setRequiredCountOfSupervisors] = useState({});
  const [uploadedBookingFileName, setUploadedBookingFileName] = useState(null);
  const [selectedBookingFile, setSelectedBookingFile] = useState(null);
  const [confirmBtnClicked, setconfirmBtnClicked] = useState(false);
  const calendarRef = useRef();
  const [dateRange, setDateRange] = useState();
  const [openShiftBookingDeleteModal, setopenShiftBookingDeleteModal] = useState(false);
  const [bookingId, setbookingId] = useState();
  const fullfill_data_length = constants.FULLFILL_BOOKING_POPUP_DATA.length;
  const [selectweekDay, setselectweekDay] = useState();
  const [bookingFormat, setBookingFormat] = useState();
  const dayIndex = DaySelectionList.indexOf(weekday_start);
  const diffInDaysObj = useRef({});
  // STEP : Retrieve bookings
  const getBookingListing = async (...params) => {
    let queryParams = '';
    if (userDetails.user_type_id === USER_TYPE.SITE_ADMIN) {
      if (userDetails.site_id) {
        queryParams = `?site_id=${userDetails.site_id}`;
      } else {
        return;
      }
    } else if (userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN) {
      if (selected_client_id || userDetails.client_id) {
        queryParams = `?client_id=${selected_client_id || userDetails.client_id}`;
      } else {
        return;
      }
    } else if (userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN) {
      if ((selected_client_id || userDetails.client_id) && userDetails.agency_id) {
        queryParams = `?client_id=${selected_client_id || userDetails.client_id}&agency_id=${
          userDetails.agency_id
        }`;
      } else {
        return;
      }
    } else if (userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN) {
      if (userDetails.client_id && userDetails.agency_id && userDetails.site_id) {
        queryParams = `?client_id=${selected_client_id || userDetails.client_id}&agency_id=${
          userDetails.agency_id
        }&site_id=${userDetails.site_id}`;
      } else {
        return;
      }
    } else if (userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN) {
      if (userDetails.client_id && userDetails.agency_id && userDetails.region_id) {
        queryParams = `?client_id=${selected_client_id || userDetails.client_id}&agency_id=${
          userDetails.agency_id
        }&region_id=${userDetails.region_id}`;
      } else {
        return;
      }
    }

    const result = await getBookings(queryParams, ...params);
    setIsPageLoader(false);
    if (result && result.ok) {
      return result;
    }
    toast({
      ...TOAST_SETTINGS.SETTINGS,
      status: TOAST_SETTINGS.ERROR,
      title: result.error
    });
    return { data: [] };
  };

  // STEP: onBookingModalClosed
  const onBookingModalClosed = () => {
    setBookingData({ ...INITIAL_BOOKING });
    setSelectedAgencies({});
  };

  // STEP : On Booking Cancel
  const bookingModalCancelBtnClickHandler = () => {
    setValidations(deepClone(ADD_BOOKING_VALIDATIONS));
    setIsValidDate(true);
    setIsBookingModalOpen(false);
    onBookingModalClosed();
  };

  const csvUploadModalCancelBtnClickHandler = () => {
    setisCsvUploadModalOpen(false);
    setUploadedBookingFileName(null);
    setSelectedBookingFile(null);
    setDateRange();
  };

  const confirmBtnClickHandler = async () => {
    if (!selectedBookingFile) {
      toast({
        ...TOAST_SETTINGS.ERROR,
        status: TOAST_SETTINGS.ERROR,
        title: 'Please first select a file.'
      });
      return;
    }
    if (isFullfillBookingCsvUploadModalOpen && !dateRange) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: 'Please first select a Week Range.'
      });
      return;
    }
    setconfirmBtnClicked(true);

    if (isFullfillBookingCsvUploadModalOpen) {
      const startDate = moment(
        `${dateRange[0].year}/${dateRange[0].month}/${dateRange[0].day}`,
        'YYYY-MM-DD'
      );
      const endDate = moment(
        `${dateRange[1].year}/${dateRange[1].month}/${dateRange[1].day}`,
        'YYYY-MM-DD'
      );
      const result = await updateCreateBookingCsvData(
        userDetails.client_id || selected_client_id,
        userDetails.agency_id,
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD'),
        { bookings: selectedBookingFile }
      );
      if (result) {
        setconfirmBtnClicked(false);
      }

      if (result && result.ok) {
        setReload(!reload);
        csvUploadModalCancelBtnClickHandler();
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.SUCCESS,
          title: result.message
        });
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION_NULL.duration,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
    } else {
      const result = await uploadCreateBookingCsvData({ bookings: selectedBookingFile });
      if (result) {
        setconfirmBtnClicked(false);
      }

      if (result && result.ok) {
        setReload(!reload);
        csvUploadModalCancelBtnClickHandler();
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.SUCCESS,
          title: result.message
        });
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION_NULL.duration,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
    }
  };

  const onCloseDeleteModal = () => {
    setopenShiftBookingDeleteModal(false);
  };

  const confirmDeleteShiftBooking = async () => {
    const result = await deleteBooking(bookingId);
    if (result && result.ok) {
      setopenShiftBookingDeleteModal(false);
      setReload(!reload);
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const downloadCsvData = async () => {
    if (isFullfillBookingCsvUploadModalOpen) {
      const startDate = moment(
        `${dateRange[0].year}/${dateRange[0].month}/${dateRange[0].day}`,
        'YYYY-MM-DD'
      );
      const endDate = moment(
        `${dateRange[1].year}/${dateRange[1].month}/${dateRange[1].day}`,
        'YYYY-MM-DD'
      );
      const result = await downloadUploadedFullfillSheet(
        userDetails.client_id || selected_client_id,
        userDetails.agency_id,
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD')
      );

      if (result && result.ok) {
        let line = `id,client_name,site,department,region,shift,requested,start_date,end_date,supervisor_flag,total_fulfillment,sun,mon,tue,wed,thu,fri,sat${'\r\n'}`;
        result.data.bookings.forEach((row) => {
          line += `${row.id},${row.client_name},${row.site},${row.department},${row.region},${
            row.shift
          },${row.requested},${row.start_date},${row.end_date},${row.supervisor_flag},${
            row.total_fulfillment
          },${row.sun},${row.mon},${row.tue},${row.wed},${row.thu},${row.fri},${row.sat}${'\r\n'}`;
        });
        const csvContent = `data:text/csv;charset=utf-8,${line}`;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute(
          'download',
          `Open-Bookings_${moment(startDate).format('DD/MM/YYYY')}_to_${endDate.format(
            'DD/MM/YYYY'
          )}.csv`
        );
        document.body.appendChild(link); // Required for FF
        link.click();
        document.body.removeChild(link);
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
    } else {
      const result = await downloadCreateBookingSampleSheet();
      if (result && result.ok) {
        let line = `agency_id,agency_name,department,shift,start_date,end_date,repeat,supervisor_flag,total_heads,sun,mon,tue,wed,thu,fri,sat${'\r\n'}`;
        result.data.csv_data.forEach((row) => {
          line += `${row.agency_id},${row.agency_name},${row.department},${row.shift},${
            row.start_date
          },${row.end_date},${row.repeat},${row.supervisor_flag},${row.total_heads},${row.sun},${
            row.mon
          },${row.tue},${row.wed},${row.thu},${row.fri},${row.sat}${'\r\n'}`;
        });
        const csvContent = `data:text/csv;charset=utf-8,${line}`;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `Bulk-Bookings-Sample-Sheet`);
        document.body.appendChild(link); // Required for FF
        link.click();
        document.body.removeChild(link);
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
    }
  };
  // STEP: Booking Next Btn On Click.
  const bookingModalNextBtnClickHandler = () => {
    let isWorker = false;
    let isSupervisor = false;
    // eslint-disable-next-line no-plusplus
    for (let index = 1; index < 8; index++) {
      if (parseFloat(bookingData.required_workers_heads[index]) > 0) {
        isWorker = true;
      }
      if (parseFloat(bookingData.required_supervisors_heads[index]) > 0) {
        isSupervisor = true;
      }
    }
    if (isWorker || isSupervisor) {
      const dateIsValid = bookingData.start_date.length > 0 && bookingData.end_date.length > 0;
      let formValidationObject, formIsValid;
      let workerValidationObject, workerDataIsValid;
      let supervisorValidationObject, supervisorDataIsValid;

      if (isWorker) {
        [formValidationObject, formIsValid] = validateForm({ ...bookingData }, validations);
        [workerValidationObject, workerDataIsValid] = validateForm(
          { ...bookingData.required_workers_heads },
          bookingData.worker_validation_data
        );
      }

      if (isSupervisor) {
        [formValidationObject, formIsValid] = validateForm({ ...bookingData }, validations);
        [supervisorValidationObject, supervisorDataIsValid] = validateForm(
          { ...bookingData.required_supervisors_heads },
          bookingData.supervisor_validation_data
        );
      }

      setIsValidDate(dateIsValid);
      setValidations({ ...formValidationObject });
      setBookingData({
        ...bookingData,
        worker_validation_data: isWorker
          ? workerValidationObject
          : bookingData.worker_validation_data,
        supervisor_validation_data: isSupervisor
          ? supervisorValidationObject
          : bookingData.supervisor_validation_data
      });

      if (isWorker) {
        setRequiredCountOfWorkers(bookingData.required_workers_heads);
      }

      if (isSupervisor) {
        setRequiredCountOfSupervisors(bookingData.required_supervisors_heads);
      }

      setSelectedAgencies({});

      const dataIsValid =
        (isWorker ? workerDataIsValid : true) && (isSupervisor ? supervisorDataIsValid : true);

      if (dateIsValid && formIsValid && dataIsValid) {
        setIsBookingModalOpen(false);
        setIsAgencyAssignOpenModalOpen(true);
      } else {
        setRefresh(!refresh);
      }
    } else {
      const toastMessage = 'You cannot create a booking with no workers or supervisors in a week.';
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: toastMessage
      });
    }
  };
  const getClientByIdFunction = async () => {
    const result = await getClientById(
      selected_client_id !== null ? selected_client_id : userDetails.client_id
    );

    if (result && result.ok) {
      const weekDayIndex = DaySelectionList.indexOf(result.data.client_details.weekday_start);
      setselectweekDay(weekDayIndex);
      const bookingValue = result?.data?.client_details?.booking_format;
      setBookingFormat(bookingValue);
    }
  };

  useEffect(() => {
    getClientByIdFunction();
  }, []);

  const handelDateChange = (event) => {
    const client_index = typeof selectweekDay === 'number' ? selectweekDay : dayIndex;
    const current_index = event[0].weekDay.index;
    let momentStartDate, formattedStartDate, momentEndDate, formattedEndDate;
    if (client_index > current_index) {
      const forward_index = client_index - current_index - 1;
      const index = 7 - (client_index - current_index);
      momentStartDate = moment(event[0].toDate(), 'YYYY-MM-DD').subtract(index, 'days');
      formattedStartDate = momentStartDate.format('YYYY-MM-DD');
      momentEndDate = moment(event[0].toDate(), 'YYYY-MM-DD').add(forward_index, 'days');
      formattedEndDate = momentEndDate.format('YYYY-MM-DD');
      setDateRange([
        new DateObject(momentStartDate.format('YYYY-MM-DD')),
        new DateObject(momentEndDate.format('YYYY-MM-DD'))
      ]);
    } else {
      const index = current_index - client_index;
      const forward_index = 7 - index - 1;
      momentStartDate = moment(event[0].toDate(), 'YYYY-MM-DD').subtract(index, 'days');
      formattedStartDate = momentStartDate.format('YYYY-MM-DD');
      momentEndDate = moment(event[0].toDate(), 'YYYY-MM-DD').add(forward_index, 'days');
      formattedEndDate = momentEndDate.format('YYYY-MM-DD');
      setDateRange([
        new DateObject(momentStartDate.format('YYYY-MM-DD')),
        new DateObject(momentEndDate.format('YYYY-MM-DD'))
      ]);
    }

    // if (event.length === 2) {
    //   const start = moment(`${event[0].year}/${event[0].month}/${event[0].day}`, 'YYYY-MM-DD');
    //   const end = moment(`${event[1].year}/${event[1].month}/${event[1].day}`, 'YYYY-MM-DD');
    //   setDateRange([
    //     new DateObject(start.format('YYYY-MM-DD')),
    //     new DateObject(end.format('YYYY-MM-DD'))
    //   ]);
    // }
    calendarRef.current.closeCalendar();
  };

  const handelBookingFileChange = (event) => {
    setUploadedBookingFileName(event.target.files[0].name);
    setSelectedBookingFile(event.target.files[0]);
  };

  // STEP: onAgencyModalClosed
  // const onAgencyModalClosed = () => {
  //   onAgencyModalClosed();
  // };

  // STEP : Back button on click
  const agencyAssignModalBackBtnClickHandler = () => {
    setIsAgencyAssignOpenModalOpen(false);
    setIsBookingModalOpen(true);
  };

  const onClosedAgencySelectionModal = () => {
    bookingModalCancelBtnClickHandler();
  };

  // STEP : On Select Agency close btn on click
  const agencyAssignModalCancelBtnClickHandler = () => {
    setIsAgencyAssignOpenModalOpen(false);
    onClosedAgencySelectionModal();
    setRequiredCountOfWorkers({});
    setRequiredCountOfSupervisors({});
  };

  // STEP : validateWorkerFulFieldHeads
  const validateWorkerFulFieldHeads = (booking, day) => {
    let isValid = true;
    if (
      parseFloat(booking.fulfilled_workers_heads[day] || 0) >
      parseFloat(booking.requested_workers_heads[day] || 0)
    ) {
      isValid = false;
    }

    return {
      errorMessage: '*Daily request exceeded.',
      isvalid: isValid
    };
  };

  const validateSupervisorFulFieldHeads = (booking, day) => {
    let isValid = true;
    if (
      parseFloat(booking.fulfilled_supervisors_heads[day] || 0) >
      parseFloat(booking.requested_supervisors_heads[day] || 0)
    ) {
      isValid = false;
    }

    return {
      errorMessage: '*Daily request exceeded.',
      isvalid: isValid
    };
  };

  const checkRequestedAgencyInputDisable = (data, index) => {
    // eslint-disable-next-line eqeqeq
    return data.required_workers_heads[index] == 0;
  };
  const checkRequestedAgencySupervisorInputDisable = (data, index) => {
    // eslint-disable-next-line eqeqeq
    return data.required_supervisors_heads[index] == 0;
  };

  const validateSelectedAgencyWorkerInput = (agency, index, dayIndex) => {
    return agency[index].workerValidationObj[dayIndex];
  };
  const validateSelectedAgencySupervisorInput = (agency, index, dayIndex) => {
    return agency[index].supervisorValidationObj[dayIndex];
  };

  const onSelectAgency = (e, count) => {
    const agencies = { ...selectedAgencies };
    const workerKeys = Object.keys(bookingData.required_workers_heads);
    const supervisorKeys = Object.keys(bookingData.required_supervisors_heads);
    const workerDays = {};
    const supervisorDays = {};
    const workerValidationObj = {};
    const supervisorValidationObj = {};
    const disableWorkerValues = {};
    const disableSupervisorValues = {};

    if (count === 1) {
      if (Object.keys(selectedAgencies).includes(e.agency_id)) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: constants.SHIFT_BOOKING.ALL_READY_SELECTED
        });
        return false;
      }
    } else {
      if (Object.keys(selectedAgencies).includes(e.value)) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: constants.SHIFT_BOOKING.ALL_READY_SELECTED
        });
        return false;
      }
    }

    // Process worker data
    workerKeys.forEach((key) => {
      if (count === 1) {
        workerDays[key] = bookingData.required_workers_heads[key];
      } else {
        workerDays[key] = '';
      }
      if (bookingData.required_workers_heads[key] != 0) {
        workerValidationObj[key] = {
          required: true,
          errorMessage: '*Please Enter Valid Number',
          isvalid: true
        };
      } else {
        workerValidationObj[key] = {
          required: false,
          isvalid: true,
          errorMessage: true
        };
      }
      disableWorkerValues[key] = bookingData.required_workers_heads[key] == 0;
    });

    // Process supervisor data
    supervisorKeys.forEach((key) => {
      if (count === 1) {
        supervisorDays[key] = bookingData.required_supervisors_heads[key];
      } else {
        supervisorDays[key] = '';
      }
      if (bookingData.required_supervisors_heads[key] != 0) {
        supervisorValidationObj[key] = {
          required: true,
          errorMessage: '*Please Enter Valid Number',
          isvalid: true
        };
      } else {
        supervisorValidationObj[key] = {
          required: false,
          isvalid: true,
          errorMessage: true
        };
      }
      disableSupervisorValues[key] = bookingData.required_supervisors_heads[key] == 0;
    });

    const agency = {
      name: count === 1 ? e.agency_name : e.label,
      id: count === 1 ? e.agency_id : e.value,
      requested_workers_heads: workerDays,
      requested_supervisors_heads: supervisorDays,
      totalWorkers: count === 1 ? bookingData.required_workers_total : 0,
      totalSupervisors: count === 1 ? bookingData.required_supervisors_total : 0,
      workerValidationObj: { ...workerValidationObj },
      supervisorValidationObj: { ...supervisorValidationObj },
      disableWorkerValues: { ...disableWorkerValues },
      disableSupervisorValues: { ...disableSupervisorValues },
      isFormValid: true
    };
    if (count === 1) {
      agencies[e.agency_id] = { ...agency };
    } else {
      agencies[e.value] = { ...agency };
    }
    setSelectedAgencies({ ...agencies });
  };

  // STEP : Handel requested inputs events.
  const setNumberOfRequestedWorkerForUpdateAgency = (event, bookingIndex, day) => {
    if (event === '' || event == '0' || parseInt(event) < 10000) {
      const data = [...selectedBooking];
      const requestedData = { ...data[bookingIndex].requested_workers_heads };
      requestedData[day] = allowOnlyNumber(event);
      let count = 0;
      Object.keys(requestedData).forEach((d) => {
        // eslint-disable-next-line radix
        if (parseFloat(requestedData[d])) {
          // eslint-disable-next-line radix
          count += parseFloat(requestedData[d]);
        }
      });
      data[bookingIndex] = {
        ...data[bookingIndex],
        requested_workers_heads: requestedData,
        requested_workers_total: count,
        remainAssignWorkers: data[bookingIndex].requested_workers_heads - count
      };

      setSelectedBooking(data);
      setRefresh(!refresh);
    }
  };

  const setNumberOfRequestedSupervisorForUpdateAgency = (event, bookingIndex, day) => {
    if (event === '' || event == '0' || parseInt(event) < 10000) {
      const data = [...selectedBooking];
      const requestedData = { ...data[bookingIndex].requested_supervisors_heads };
      requestedData[day] = allowOnlyNumber(event);
      let count = 0;
      Object.keys(requestedData).forEach((d) => {
        // eslint-disable-next-line radix
        if (parseFloat(requestedData[d])) {
          // eslint-disable-next-line radix
          count += parseFloat(requestedData[d]);
        }
      });
      data[bookingIndex] = {
        ...data[bookingIndex],
        requested_supervisors_heads: requestedData,
        requested_supervisors_total: count,
        remainAssignSupervisors: data[bookingIndex].requested_supervisors_heads - count
      };

      setSelectedBooking(data);
      setRefresh(!refresh);
    }
  };

  //  For Supervisor
  const setNumberOfSupervisorForUpdateAgency = (event, bookingIndex, day) => {
    if (event === '' || event == 0 || parseInt(event) < 10000) {
      const data = [...selectedBooking];
      const fullFillData = { ...data[bookingIndex].fulfilled_supervisors_heads };
      fullFillData[day] =
        data?.[0]?.booking_format !== 'HEADS'
          ? allowTwoDecimalNumbers(event)
          : allowNaturalNumber(event);
      let count = 0;
      Object.keys(fullFillData).forEach((d) => {
        if (parseFloat(fullFillData[d])) {
          count += parseFloat(fullFillData[d]);
        }
      });
      data[bookingIndex] = {
        ...data[bookingIndex],
        fulfilled_supervisors_heads: fullFillData,
        fulfilled_supervisors_total: count,
        remainAssignSupervisors: data[bookingIndex].requested_supervisors_total - count
      };

      setSelectedBooking(data);
      setRefresh(!refresh);
    } else {
      setRefresh(!refresh);
    }
  };

  // STEP : Handel fulfill inputs events.
  const setNumberOfWorkerForUpdateAgency = (event, bookingIndex, day) => {
    if (event === '' || event == 0 || parseInt(event) < 10000) {
      const data = [...selectedBooking];
      const fullFillData = { ...data[bookingIndex].fulfilled_workers_heads };
      // fullFillData[day] =  allowTwoDecimalNumbers(event);
      fullFillData[day] =
        data?.[0]?.booking_format !== 'HEADS'
          ? allowTwoDecimalNumbers(event)
          : allowNaturalNumber(event);
      let count = 0;
      Object.keys(fullFillData).forEach((d) => {
        // eslint-disable-next-line radix
        if (parseFloat(fullFillData[d])) {
          // eslint-disable-next-line radix
          count += parseFloat(fullFillData[d]);
        }
      });
      data[bookingIndex] = {
        ...data[bookingIndex],
        fulfilled_workers_heads: fullFillData,
        fulfilled_workers_total: count,
        remainAssignWorkers: data[bookingIndex].requested_workers_heads - count
      };

      setSelectedBooking(data);
      setRefresh(!refresh);
    } else {
      setRefresh(!refresh);
    }
  };

  // STEP : Close detail modal
  const closeBookingDetailsModal = () => {
    setIsBookingEdit(false);
    setIsBookingDetailsModalOpen(false);
    setSelectedBooking(null);
    setRequiredCountOfWorkers({});
    setRequiredCountOfSupervisors({});
  };

  const checkBtnDisability = (booking) => {
    let isBtnDisabled = false;
    if (booking && booking.length > 0) {
      booking.forEach((bookingItem) => {
        Object.keys(bookingItem?.fulfilled_workers_heads || {}).forEach((day) => {
          if (
            parseFloat(bookingItem?.fulfilled_workers_heads[day] || 0) >
              parseFloat(bookingItem?.requested_workers_heads[day] || 0) ||
            parseFloat(bookingItem?.fulfilled_supervisors_heads[day] || 0) >
              parseFloat(bookingItem?.requested_supervisors_heads[day] || 0) ||
            !bookingItem?.allowFullfillment
          ) {
            isBtnDisabled = true;
          }
        });
      });
    }
    return isBtnDisabled;
  };

  // STEP : Update fulfill data.
  const updateSelectedBookingDetail = async (booking, userTypeId) => {
    if (
      userTypeId === USER_TYPE.AGENCY_ADMIN ||
      userTypeId === USER_TYPE.AGENCY_SITE_ADMIN ||
      userTypeId === USER_TYPE.AGENCY_REGION_ADMIN
    ) {
      const id = booking[0].booking_id;
      let fulfilledWorkersCount = 0;
      let fulfilledSupervisorsCount = 0;

      Object.keys(booking[0].fulfilled_workers_heads).forEach((key) => {
        if (
          booking[0].requested_workers_heads[key] === '' ||
          booking[0].requested_workers_heads[key] === '0' ||
          booking[0].requested_workers_heads[key] === 0
        ) {
          // eslint-disable-next-line no-param-reassign
          booking[0].fulfilled_workers_heads[key] = '0';
        }

        if (booking[0].fulfilled_workers_heads[key] !== '') fulfilledWorkersCount += 1;
      });

      Object.keys(booking[0].fulfilled_supervisors_heads).forEach((key) => {
        if (
          booking[0].requested_supervisors_heads[key] === '' ||
          booking[0].requested_supervisors_heads[key] === '0' ||
          booking[0].requested_supervisors_heads[key] === 0
        ) {
          // eslint-disable-next-line no-param-reassign
          booking[0].fulfilled_supervisors_heads[key] = '0';
        }

        if (booking[0].fulfilled_supervisors_heads[key] !== '') fulfilledSupervisorsCount += 1;
      });

      if (fulfilledWorkersCount < 7 || fulfilledSupervisorsCount < 7) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: 'All the days are required to be fulfilled for both workers and supervisors.'
        });
        return false;
      }

      const payload = {
        fulfilled_workers_heads: { ...booking[0].fulfilled_workers_heads },
        fulfilled_supervisors_heads: { ...booking[0].fulfilled_supervisors_heads },
        fulfilled_workers_total: booking[0].fulfilled_workers_total,
        fulfilled_supervisors_total: booking[0].fulfilled_supervisors_total,
        total: booking[0].fulfilled_workers_total + booking[0].fulfilled_supervisors_total
      };
      if (!(id && payload)) {
        return false;
      }
      const result = await updateBooking(id, payload);
      if (result && result.ok) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.SUCCESS,
          title: result.message
        });
        closeBookingDetailsModal();
        setReload(!reload);
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
    } else if (userTypeId === USER_TYPE.SITE_ADMIN) {
      const bookingDetails = [];
      let bookingTotalWorkers = 0;
      let bookingTotalSupervisors = 0;
      let isValid = true;
      booking.forEach((item) => {
        if (isValid && item.requested_workers_heads === 0) {
          isValid = false;
        }
        bookingDetails.push({
          booking_association_id: item.id,
          requested_workers_heads: item.requested_workers_heads,
          requested_supervisors_heads: item.requested_supervisors_heads,
          requested_workers_total: item.requested_workers_total,
          requested_supervisors_total: item.requested_supervisors_total,
          requested_total: item.requested_supervisors_total + item.requested_workers_total
        });
        bookingTotalWorkers += item.requested_workers_total;
        bookingTotalSupervisors += item.requested_supervisors_total;
      });

      if (!isValid) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: 'You cannot update a booking with no workers for any agency.'
        });
        return false;
      }

      const payload = {
        //  booking_total_workers: bookingTotalWorkers,
        //  booking_total_supervisors: bookingTotalSupervisors,
        booking_total: bookingTotalWorkers + bookingTotalSupervisors,
        booking_details: bookingDetails
      };
      const result = await updateRequestedHeadsInBooking(payload);
      if (result && result.ok) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.SUCCESS,
          title: result.message
        });
        closeBookingDetailsModal();
        setReload(!reload);
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
    }
  };

  const checkCountOfWorkers = (e, agencyId, day) => {
    if (e?.target?.value == '') {
      return;
    }

    const totalCountOfWorkers = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0 };

    if (Object.keys(selectedAgencies).length > 0) {
      Object.keys(selectedAgencies).forEach((agency) => {
        if (selectedAgencies[agency].id != agencyId) {
          Object.keys(selectedAgencies[agency].requested_workers_heads).forEach((eachDay) => {
            if (selectedAgencies[agency].requested_workers_heads[eachDay] !== '') {
              totalCountOfWorkers[eachDay] += parseFloat(
                selectedAgencies[agency].requested_workers_heads[eachDay]
              );
            }
          });
        }
      });

      if (
        parseFloat(totalCountOfWorkers[day]) + parseFloat(e?.target.value) >
        parseFloat(requiredCountOfWorkers[day])
      ) {
        selectedAgencies[agencyId].workerValidationObj[day] = {
          isvalid: false,
          errorMessage: `*Daily request exceeded by ${
            parseFloat(totalCountOfWorkers[day] + parseFloat(e?.target.value)) -
            requiredCountOfWorkers[day]
          }`,
          required: true
        };
        setRefresh(!refresh);
      }
    }
  };

  const checkCountOfSupervisors = (e, agencyId, day) => {
    if (e?.target?.value == '') {
      return;
    }

    const totalCountOfSupervisors = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0 };

    if (Object.keys(selectedAgencies).length > 0) {
      Object.keys(selectedAgencies).forEach((agency) => {
        if (selectedAgencies[agency].id != agencyId) {
          Object.keys(selectedAgencies[agency].requested_supervisors_heads).forEach((eachDay) => {
            if (selectedAgencies[agency].requested_supervisors_heads[eachDay] !== '') {
              totalCountOfSupervisors[eachDay] += parseFloat(
                selectedAgencies[agency].requested_supervisors_heads[eachDay]
              );
            }
          });
        }
      });

      if (
        parseFloat(totalCountOfSupervisors[day]) + parseFloat(e?.target.value) >
        parseFloat(requiredCountOfSupervisors[day])
      ) {
        selectedAgencies[agencyId].supervisorValidationObj[day] = {
          isvalid: false,
          errorMessage: `*Daily supervisor request exceeded by ${
            parseFloat(totalCountOfSupervisors[day] + parseFloat(e?.target.value)) -
            requiredCountOfSupervisors[day]
          }`,
          required: true
        };
        setRefresh(!refresh);
      }
    }
  };

  const setNumberOfWorkerForEachAgency = (event, agencyId, day) => {
    if (event === '' || event == '0' || parseInt(event) < 10000) {
      const agency = { ...selectedAgencies[agencyId] };
      const heads = { ...agency.requested_workers_heads };
      // heads[day] = allowTwoDecimalNumbers(event);
      heads[day] =
        bookingFormat !== 'HEADS' ? allowTwoDecimalNumbers(event) : allowNaturalNumber(event);
      let count = 0;
      Object.keys(heads).forEach((d) => {
        // eslint-disable-next-line radix
        if (parseFloat(heads[d])) {
          // eslint-disable-next-line radix
          count += parseFloat(heads[d]);
        }
      });
      agency.totalWorkers = count;
      agency.requested_workers_heads = heads;
      agency.workerValidationObj[day].isvalid = true;

      const newData = { ...selectedAgencies };
      newData[agencyId] = agency;
      setSelectedAgencies(newData);
      // setRefresh(!refresh);
    } else {
      setRefresh(!refresh);
    }
  };

  const setNumberOfSupervisorForEachAgency = (event, agencyId, day) => {
    if (event === '' || event == '0' || parseInt(event) < 10000) {
      const agency = { ...selectedAgencies[agencyId] };
      const heads = { ...agency.requested_supervisors_heads };
      // heads[day] = allowTwoDecimalNumbers(event);
      heads[day] =
        bookingFormat !== 'HEADS' ? allowTwoDecimalNumbers(event) : allowNaturalNumber(event);
      let count = 0;
      Object.keys(heads).forEach((d) => {
        // eslint-disable-next-line radix
        if (parseFloat(heads[d])) {
          // eslint-disable-next-line radix
          count += parseFloat(heads[d]);
        }
      });
      agency.totalSupervisors = count;
      agency.requested_supervisors_heads = heads;
      agency.supervisorValidationObj[day].isvalid = true;

      const newData = { ...selectedAgencies };
      newData[agencyId] = agency;
      setSelectedAgencies(newData);
      // setRefresh(!refresh);
    } else {
      setRefresh(!refresh);
    }
  };

  const removeSelectedAgency = (id) => {
    const agencies = { ...selectedAgencies };
    try {
      delete agencies[id];
    } catch (error) {}
    setSelectedAgencies({ ...agencies });
  };

  // STEP: Referance
  const getAgencyAssignModalBodyTemplate = () => {
    return (
      <>
        <Flex
          style={{
            position: 'sticky',
            top: '0px',
            zIndex: 12,
            textAlign: 'center',
            padding: '4px',
            width: '513px',
            borderRadius: '7px',
            display: 'block',
            margin: 'auto',
            fontWeight: '600',
            fontSize: '17px',
            backgroundColor:
              remainAssignWorkers > 0 ? 'rgb(108 199 108 / 97%)' : 'rgb(255 110 110 / 97%)'
          }}>
          Number of workers remaining to be assigned : {remainAssignWorkers.toFixed(2)}
        </Flex>
        <br />
        <Flex
          style={{
            position: 'sticky',
            top: '35px',
            zIndex: 12,
            textAlign: 'center',
            padding: '4px',
            width: '513px',
            borderRadius: '7px',
            display: 'block',
            margin: 'auto',
            fontWeight: '600',
            fontSize: '17px',
            backgroundColor:
              remainAssignSupervisors > 0 ? 'rgb(108 199 108 / 97%)' : 'rgb(255 110 110 / 97%)'
          }}>
          Number of supervisors remaining to be assigned : {remainAssignSupervisors?.toFixed(2)}
        </Flex>
        <Flex mt={4}>
          <StyledInputWrapper>
            <AgencyDropdown setAgency={onSelectAgency} />
          </StyledInputWrapper>
        </Flex>
        {selectedAgencies &&
          Object.keys(selectedAgencies).length > 0 &&
          Object.keys(selectedAgencies).map((agencyKey) => (
            <StyledAgency key={agencyKey}>
              <StyledAgencyHeader>
                {selectedAgencies[agencyKey].name}
                <Spacer />
                {`Assigned workers : ${selectedAgencies[agencyKey].totalWorkers.toFixed(2)}`}
              </StyledAgencyHeader>
              <Flex>
                {selectedAgencies[agencyKey].requested_workers_heads &&
                  Object.keys(selectedAgencies[agencyKey].requested_workers_heads).length > 0 &&
                  Object.keys(selectedAgencies[agencyKey].requested_workers_heads).map(
                    (dayIndex) => (
                      <StyledInputWrapper
                        key={dayIndex}
                        style={{ marginLeft: dayIndex === 0 ? 0 : '8px' }}>
                        <InputComponent
                          lable={WEEK_DAYS[dayIndex - 1]}
                          placeholder={WEEK_DAYS[dayIndex - 1]}
                          onChange={(e) => {
                            setNumberOfWorkerForEachAgency(e, agencyKey, dayIndex);
                          }}
                          onKeyDown={(e) => {
                            if (
                              bookingFormat === 'HEADS' &&
                              ['e', 'E', '+', '-', '.'].includes(e.key)
                            ) {
                              e.stopPropagation();
                              e.preventDefault();
                            } else if (
                              bookingFormat === 'HOURS' &&
                              ['e', 'E', '+', '-'].includes(e.key)
                            ) {
                              e.stopPropagation();
                              e.preventDefault();
                            }
                          }}
                          onBlur={(e) => {
                            checkCountOfWorkers(e, agencyKey, dayIndex);
                          }}
                          value={selectedAgencies[agencyKey].requested_workers_heads[dayIndex]}
                          validationObj={validateSelectedAgencyWorkerInput(
                            selectedAgencies,
                            agencyKey,
                            dayIndex
                          )}
                          type="number"
                          refresh={refresh}
                          style={{ padding: '0px 8px', textAlign: 'center' }}
                          labelFontSize="xs"
                          disabled={checkRequestedAgencyInputDisable(bookingData, dayIndex)}
                        />
                      </StyledInputWrapper>
                    )
                  )}
              </Flex>
              <StyledAgencyHeader>
                {selectedAgencies[agencyKey].name}
                <Spacer />
                {`Assigned supervisors : ${selectedAgencies[agencyKey].totalSupervisors.toFixed(
                  2
                )}`}
              </StyledAgencyHeader>
              <Flex>
                {selectedAgencies[agencyKey].requested_supervisors_heads &&
                  Object.keys(selectedAgencies[agencyKey].requested_supervisors_heads).length > 0 &&
                  Object.keys(selectedAgencies[agencyKey].requested_supervisors_heads).map(
                    (dayIndex) => (
                      <StyledInputWrapper
                        key={dayIndex}
                        style={{ marginLeft: dayIndex === 0 ? 0 : '8px' }}>
                        <InputComponent
                          lable={WEEK_DAYS[dayIndex - 1]}
                          placeholder={WEEK_DAYS[dayIndex - 1]}
                          onChange={(e) => {
                            setNumberOfSupervisorForEachAgency(e, agencyKey, dayIndex);
                          }}
                          onKeyDown={(e) => {
                            if (
                              bookingFormat === 'HEADS' &&
                              ['e', 'E', '+', '-', '.'].includes(e.key)
                            ) {
                              e.stopPropagation();
                              e.preventDefault();
                            } else if (
                              bookingFormat === 'HOURS' &&
                              ['e', 'E', '+', '-'].includes(e.key)
                            ) {
                              e.stopPropagation();
                              e.preventDefault();
                            }
                          }}
                          onBlur={(e) => {
                            checkCountOfSupervisors(e, agencyKey, dayIndex);
                          }}
                          value={selectedAgencies[agencyKey].requested_supervisors_heads[dayIndex]}
                          validationObj={validateSelectedAgencySupervisorInput(
                            selectedAgencies,
                            agencyKey,
                            dayIndex
                          )}
                          type="number"
                          refresh={refresh}
                          style={{ padding: '0px 8px', textAlign: 'center' }}
                          labelFontSize="xs"
                          disabled={checkRequestedAgencySupervisorInputDisable(
                            bookingData,
                            dayIndex
                          )}
                        />
                      </StyledInputWrapper>
                    )
                  )}
              </Flex>
              <Flex justify="flex-end">
                <Button
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  size="xs"
                  onClick={() => removeSelectedAgency(agencyKey)}>
                  Remove
                </Button>
              </Flex>
            </StyledAgency>
          ))}
      </>
    );
  };

  // STEP : validateEachAgencyForm
  const validateEachAgencyForm = (agency) => {
    const [agencyValidationObject, isValid] = validateForm(
      { ...agency.requested_workers_heads },
      agency.workerValidationObj
    );
    const [agencySupervisorValidationObject, isSupervisorvalid] = validateForm(
      { ...agency.requested_supervisors_heads },
      agency.supervisorValidationObj
    );
    return {
      ...agency,
      workerValidationObj: agencyValidationObject,
      supervisorValidationObj: agencySupervisorValidationObject,
      isFormValid: isValid && isSupervisorvalid
    };
  };

  // STEP : Add new booking
  const createBookingBtnClickHandler = async (currentAgency) => {
    const keys = Object.keys(currentAgency);

    const agencies = { ...currentAgency };
    keys.forEach((key) => {
      agencies[key] = validateEachAgencyForm(currentAgency[key]);
    });

    setSelectedAgencies({ ...agencies });
    let isValidAllForms = true;
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      if (!agencies[key].isFormValid) {
        isValidAllForms = false;
        break;
      }
      if (agencies[key].totalWorkers + agencies[key].totalSupervisors === 0) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: 'You cannot create a booking with no workers and no supervisors for any agency.'
        });
        setRefresh(!refresh);
        return false;
      }
    }

    if (isValidAllForms) {
      const agencyData = [];
      const AgencyIds = Object.keys(selectedAgencies);
      let remainingWorkers = bookingData.required_workers_total;
      let remainingSupervisors = bookingData.required_supervisors_total;

      AgencyIds.forEach((key) => {
        const agency = selectedAgencies[key];
        remainingWorkers -= agency.totalWorkers;
        remainingSupervisors -= agency.totalSupervisors;
        agencyData.push({
          agency_id: agency.id,
          requested_workers_heads: agency.requested_workers_heads,
          requested_workers_total: agency.totalWorkers,
          requested_supervisors_heads: agency.requested_supervisors_heads,
          requested_supervisors_total: agency.totalSupervisors,
          total: agency.totalWorkers + agency.totalSupervisors
        });
      });

      if (remainingWorkers > 0 || remainingSupervisors > 0) {
        let errorMessage = '';
        if (remainingWorkers > 0 && remainingSupervisors > 0) {
          errorMessage =
            'Please assign the remaining workers and supervisors to another agency to complete the booking.';
        } else if (remainingWorkers > 0) {
          errorMessage =
            'Please assign the remaining workers to another agency to complete the booking.';
        } else if (remainingSupervisors > 0) {
          errorMessage =
            'Please assign the remaining supervisors to another agency to complete the booking.';
        }
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: errorMessage
        });
        setRefresh(!refresh);
        return false;
      }

      const payload = { ...bookingData };
      payload.agency_requested = agencyData;
      payload.shift_type_id = payload.shift_id;
      payload.site_id = userDetails.site_id;
      payload.requested_total =
        bookingData.required_supervisors_total + bookingData.required_workers_total;
      delete payload.requested_workers_total;
      delete payload.department_name;
      delete payload.shift_name;
      delete payload.worker_validation_data;
      delete payload.supervisor_validation_data;
      delete payload.shift_id;

      const result = await AddBookingData(payload);
      if (result && result.ok) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.SUCCESS,
          title: result.message
        });
        agencyAssignModalCancelBtnClickHandler();
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
      setReload(!reload);
    } else {
      setRefresh(!refresh);
    }
  };

  const getAgencyAssignModalTemplate = () => {
    return (
      <ModalComponent
        isOpen={isAgencyAssignModalOpen}
        onClose={() => agencyAssignModalCancelBtnClickHandler()}
        modalTitle={`${constants.SHIFT_BOOKING.AGENCY_MODAL_HEADER_TEXT} (${DATE_TO_SHOW(
          bookingData.start_date
        )} to ${DATE_TO_SHOW(bookingData.end_date)})`}
        modalContent={getAgencyAssignModalBodyTemplate()}
        negative={
          <Button
            bg="main.semiPrimary"
            color="main.white"
            _hover={{ bg: 'main.primary' }}
            _active={{
              bg: 'main.semiPrimary',
              borderColor: 'main.primary'
            }}
            onClick={() => agencyAssignModalBackBtnClickHandler()}
            mr={3}>
            {constants.SHIFT_BOOKING.BACK_BUTTON_TEXT}
          </Button>
        }
        positive={
          <Button
            bg="main.semiPrimary"
            color="main.white"
            _hover={{ bg: 'main.primary' }}
            _active={{
              bg: 'main.semiPrimary',
              borderColor: 'main.primary'
            }}
            onClick={() => createBookingBtnClickHandler(selectedAgencies)}
            disabled={!(remainAssignWorkers >= 0 && remainAssignSupervisors >= 0)}>
            {constants.SHIFT_BOOKING.SAVE_BUTTON_TEXT}
          </Button>
        }
      />
    );
  };

  const onBookingModalOpen = () => {
    setBookingData({ ...INITIAL_BOOKING });
    setIsBookingModalOpen(true);
  };

  const onCsvUploadModalOpen = () => {
    setisCsvUploadModalOpen(true);
  };

  const onFullfillBookingCsvUploaModalOpen = () => {
    setisCsvUploadModalOpen(true);
    setisFullfillBookingCsvUploadModalOpen(true);
  };

  const getAddBookingModalTemplate = () => {
    return (
      permission &&
      permission.includes('create') &&
      userDetails.user_type_id === USER_TYPE.SITE_ADMIN && (
        <>
          <Box display="flex" justifyContent="space-between">
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={onBookingModalOpen}>
              {constants.SHIFT_BOOKING.CREATE_BUTTON_TEXT}
            </Button>
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={onCsvUploadModalOpen}>
              {constants.SHIFT_BOOKING.BOOK_VIA_CSV_UPLOAD_BUTTON}
            </Button>
          </Box>
          <ModalComponent
            isOpen={isBookingModalOpen}
            onClose={() => bookingModalCancelBtnClickHandler()}
            modalTitle={constants.SHIFT_BOOKING.HEADER_TEXT}
            modalContent={
              <AddBooking
                data={bookingData}
                setData={setBookingData}
                isValidDate={isValidDate}
                validations={validations}
                refresh={refresh}
              />
            }
            negative={
              <Button
                onClick={() => bookingModalCancelBtnClickHandler()}
                bg="main.secondary"
                mr={3}
                _hover={{ bg: 'main.secondary' }}
                color="main.black">
                {constants.SHIFT_BOOKING.CANCEL_BUTTON_TEXT}
              </Button>
            }
            positive={
              <Button
                onClick={() => bookingModalNextBtnClickHandler()}
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}>
                {constants.SHIFT_BOOKING.NEXT_BUTTON_TEXT}
              </Button>
            }
          />
          {isAgencyAssignModalOpen ? getAgencyAssignModalTemplate() : null}
          {/* {getAssignWorkersToAgencyModalTemplate()} */}
        </>
      )
    );
  };

  // STEP : Cancel Booking.
  const cancelSelectedBooking = async (rowData) => {
    const result = await cancelBooking(rowData.id);
    if (result && result.ok) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  // STEP : Retrieve Selected Booking information
  const retrieveBookingDetails = async (clientId, id) => {
    let queryParams = '';
    if (userDetails.user_type_id === USER_TYPE.SITE_ADMIN) {
      queryParams = `/${id}/?site_id=${userDetails.site_id}`;
    } else if (userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN) {
      queryParams = `/${id}/?client_id=${clientId}`;
    } else if (userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN) {
      queryParams = `/${id}/?client_id=${clientId}&agency_id=${userDetails.agency_id}`;
    } else if (userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN) {
      queryParams = `/${id}/?client_id=${clientId}&agency_id=${userDetails.agency_id}&region_id=${userDetails.region_id}`;
    } else if (userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN) {
      queryParams = `/${id}/?client_id=${clientId}&agency_id=${userDetails.agency_id}&site_id=${userDetails.site_id}`;
    }

    const result = await getBookingsDetail(queryParams);
    if (result && result.ok) {
      const data = result.data.booking_details.map((booking) => {
        const requestedWorkersHeads = JSON.parse(booking.requested_workers_heads);
        const requestedSupervisorsHeads = JSON.parse(booking.requested_supervisors_heads);
        const fulfilledWorkersHeads = booking.fulfilled_workers_heads
          ? JSON.parse(booking.fulfilled_workers_heads)
          : { 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '' };
        const fulfilledSupervisorsHeads = booking.fulfilled_supervisors_heads
          ? JSON.parse(booking.fulfilled_supervisors_heads)
          : { 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '' };

        return {
          ...booking,
          requested_workers_heads: requestedWorkersHeads,
          requested_supervisors_heads: requestedSupervisorsHeads,
          fulfilled_workers_heads: fulfilledWorkersHeads,
          fulfilled_supervisors_heads: fulfilledSupervisorsHeads,
          fulfilled_workers_total: booking.fulfilled_workers_total
            ? booking.fulfilled_workers_total
            : 0,
          fulfilled_supervisors_total: booking.fulfilled_supervisors_total
            ? booking.fulfilled_supervisors_total
            : 0,
          remainAssignWorkers:
            booking.requested_workers_heads - (booking.fulfilled_workers_total || 0),
          allowFullfillment: result.data.allow_fulfilment
        };
      });
      setSelectedBooking(data);
    }
  };
  const storeDiffInDays = (diffInDaysObj, modifiedStartOfDay) => {
    const dayIndex = modifiedStartOfDay.day() + 1;
    diffInDaysObj.current[dayIndex] = modifiedStartOfDay.diff(moment().startOf('day'), 'days');
  };

  // STEP : Open detail modal
  const openBookingDetailsModal = (rowData, isEdit) => {
    const current = moment().startOf('day');
    const startDate = moment(rowData.start_date);
    const endDate = moment(rowData.end_date);

    if (current.isBetween(startDate, endDate, null, '[]')) {
      const bookingStartDate = moment(rowData.start_date);

      for (let dayIndex = 1; dayIndex <= 7; dayIndex += 1) {
        const modifiedStartOfDay = bookingStartDate.clone().add(dayIndex - 1, 'days');
        // Store the difference in days
        storeDiffInDays(diffInDaysObj, modifiedStartOfDay);
      }
    }
    setBookingData({ ...rowData });
    setIsBookingEdit(isEdit);
    setIsBookingDetailsModalOpen(true);
    retrieveBookingDetails(rowData.client_id, rowData.id);
  };

  const getViewEditActionButton = (rowData) => {
    let label = null;
    let isEdit = false;
    if (
      permission &&
      permission.includes('edit') &&
      (userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
        userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
        userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN) &&
      // eslint-disable-next-line eqeqeq
      rowData.status == 0
    ) {
      label = constants.SHIFT_BOOKING.FULFILL_BUTTON_TEXT;
      isEdit = true;
    } else {
      label = constants.SHIFT_BOOKING.DETAILS_BUTTON_TEXT;
      isEdit = false;
    }

    return (
      <>
        <Button
          bg="main.semiPrimary"
          color="main.white"
          _hover={{ bg: 'main.primary' }}
          _active={{
            bg: 'main.semiPrimary',
            borderColor: 'main.primary'
          }}
          ml={2}
          mr={2}
          onClick={() => openBookingDetailsModal(rowData, isEdit)}>
          {label}
        </Button>

        {userDetails.user_type_id === USER_TYPE.SITE_ADMIN &&
          (rowData.status === '0' || rowData.status === '2') && (
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={() => {
                setopenShiftBookingDeleteModal(true);
                setbookingId(rowData.id);
              }}>
              <Image src={DeleteIcon} height={6} />
            </Button>
          )}
      </>
    );
  };

  const isBookingDetailFieldDisable = (booking, dayIndex, userType, fieldType) => {
    let isDisabled = true;
    const current = moment().startOf('day');
    const startDate = moment(booking.start_date);
    const endDate = moment(booking.end_date);
    if (userType === USER_TYPE.SITE_ADMIN && fieldType === 'requested_workers_heads') {
      // Check for Site Admin
      isDisabled = false;
      if (current.isBefore(startDate)) {
        return false;
        // eslint-disable-next-line no-else-return
      } else if (current.isBetween(startDate, endDate, null, '[]')) {
        if (diffInDaysObj.current[dayIndex] < 0) {
          return true;
        }
        return false;
        // eslint-disable-next-line no-else-return
      } else if (current.isAfter(endDate)) {
        return true;
      }
    } else if (
      (userType === USER_TYPE.AGENCY_ADMIN ||
        userType === USER_TYPE.AGENCY_SITE_ADMIN ||
        userType === USER_TYPE.AGENCY_REGION_ADMIN) &&
      fieldType === 'fulfilled_workers_heads'
    ) {
      // Check for Agency Admin
      isDisabled = false;

      // eslint-disable-next-line eqeqeq
      isDisabled = booking.requested_workers_heads[dayIndex] == 0;
      if (
        booking.requested_workers_heads[dayIndex] === '0' ||
        booking.requested_workers_heads[dayIndex] === ''
      ) {
        return true;
      }
      const dayCount = dayIndex - 1;
      const todayDate = moment(booking.start_date).add(dayCount, 'days');
      if (current.isSame(todayDate) || todayDate.isBefore(current)) {
        // TODO : Un comment once validation needed
        isDisabled = !booking.allowFullfillment;
      }
    }

    return isDisabled;
  };

  const isBookingDetailFieldDisableSupervisor = (booking, dayIndex, userType, fieldType) => {
    let isDisabled = true;
    const current = moment().startOf('day');
    const startDate = moment(booking.start_date);
    const endDate = moment(booking.end_date);
    if (userType === USER_TYPE.SITE_ADMIN && fieldType === 'requested_supervisors_heads') {
      // Check for Site Admin
      isDisabled = false;
      if (current.isBefore(startDate)) {
        return false;
        // eslint-disable-next-line no-else-return
      } else if (current.isBetween(startDate, endDate, null, '[]')) {
        if (diffInDaysObj.current[dayIndex] < 0) {
          return true;
        }
        return false;
        // eslint-disable-next-line no-else-return
      } else if (current.isAfter(endDate)) {
        return true;
      }
    } else if (
      (userType === USER_TYPE.AGENCY_ADMIN ||
        userType === USER_TYPE.AGENCY_SITE_ADMIN ||
        userType === USER_TYPE.AGENCY_REGION_ADMIN) &&
      fieldType === 'fulfilled_supervisors_heads'
    ) {
      // Check for Agency Admin
      isDisabled = false;

      // eslint-disable-next-line eqeqeq
      isDisabled = booking.requested_supervisors_heads[dayIndex] == 0;
      if (
        booking.requested_supervisors_heads[dayIndex] === '0' ||
        booking.requested_supervisors_heads[dayIndex] === ''
      ) {
        return true;
      }
      const dayCount = dayIndex - 1;
      const todayDate = moment(booking.start_date).add(dayCount, 'days');
      if (current.isSame(todayDate) || todayDate.isBefore(current)) {
        // TODO : Un comment once validation needed
        isDisabled = !booking.allowFullfillment;
      }
    }

    return isDisabled;
  };

  const getBookingDetailModalTemplate = () => {
    return (
      <>
        {permission &&
          (userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
            userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
            userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN) && (
            <Box display="flex" justifyContent="flex-end">
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={onFullfillBookingCsvUploaModalOpen}>
                {constants.SHIFT_BOOKING.UPLOAD_BULK_FULFILLMENT_TEXT}
              </Button>
            </Box>
          )}
        <ModalComponent
          isOpen={isCsvUploadModalOpen}
          onClose={() => csvUploadModalCancelBtnClickHandler()}
          modalTitle={
            isFullfillBookingCsvUploadModalOpen
              ? constants.SHIFT_BOOKING_CSV_UPLOAD.FULLFILL_POPUP_HEADER_TEXT
              : constants.SHIFT_BOOKING_CSV_UPLOAD.HEADER_TEXT
          }
          modalContent={
            <>
              <Flex flexDirection="column" mb="10px">
                <Text fontSize="xl" fontWeight="600">
                  {constants.SHIFT_BOOKING_POPUP_TITLE}
                </Text>
                {isFullfillBookingCsvUploadModalOpen ? (
                  <OrderedList>
                    {constants.FULLFILL_BOOKING_POPUP_DATA.map((item, i) =>
                      fullfill_data_length - 1 !== i ? (
                        <ListItem key={item}>
                          <div dangerouslySetInnerHTML={{ __html: item }} />
                        </ListItem>
                      ) : (
                        <Flex flexDirection="column" mt="10px">
                          <Text fontSize="xl" fontWeight="600">
                            {constants.FULLFILL_BOOKING_POPUP_NOTE}
                          </Text>
                          <Text key={item}>
                            <div dangerouslySetInnerHTML={{ __html: item }} />
                          </Text>
                        </Flex>
                      )
                    )}
                  </OrderedList>
                ) : (
                  <OrderedList>
                    {constants.CREATE_BOOKING_POPUP_DATA.map((item, i) => (
                      <>
                        <ListItem key={item}>
                          <div dangerouslySetInnerHTML={{ __html: item }} />
                        </ListItem>
                      </>
                    ))}
                  </OrderedList>
                )}
              </Flex>
              <Flex
                justifyContent={isFullfillBookingCsvUploadModalOpen ? 'space-between' : 'flex-end'}>
                {isFullfillBookingCsvUploadModalOpen && (
                  <Box width="210px" mr={4}>
                    <p className="input-label">Select Date Range</p>
                    <DatePicker
                      format="DD/MM/YYYY"
                      inputClass="date-picker"
                      range
                      // weekPicker
                      placeholder="Select Week Range"
                      onChange={handelDateChange}
                      value={dateRange}
                      ref={calendarRef}
                      editable={false}
                      weekStartDayIndex={
                        typeof selectweekDay === 'number' ? selectweekDay : dayIndex
                      }
                      // minDate={new Date()}
                      className="booking-datepicker">
                      <Button
                        bg="main.semiPrimary"
                        color="main.white"
                        _hover={{ bg: 'main.primary' }}
                        _active={{
                          bg: 'main.semiPrimary',
                          borderColor: 'main.primary'
                        }}
                        onClick={() => calendarRef.current.closeCalendar()}
                        size="xs"
                        mb={4}>
                        {constants.SHIFT_BOOKING.CANCEL_BUTTON_TEXT}
                      </Button>
                    </DatePicker>
                    <p
                      className="input-error-msg"
                      style={{ visibility: isValidDate ? 'hidden' : 'visible' }}>
                      *Please Select Date
                    </p>
                  </Box>
                )}
                <Box display="flex" alignItems="center">
                  <Button
                    title={
                      isFullfillBookingCsvUploadModalOpen
                        ? 'Download Fulfillment Sheet'
                        : 'Download Bulk Booking Sample Sheet'
                    }
                    bg="main.semiPrimary"
                    color="main.white"
                    _hover={{ bg: 'main.primary' }}
                    _active={{
                      bg: 'main.semiPrimary',
                      borderColor: 'main.primary'
                    }}
                    disabled={isFullfillBookingCsvUploadModalOpen && !dateRange}
                    mr="8px"
                    onClick={() => downloadCsvData()}>
                    <DownloadIcon />
                  </Button>
                  <Box>
                    <StyledUploadFileButton
                      bg="main.primary"
                      for="clock-report"
                      disabled={confirmBtnClicked}>
                      {constants.SHIFT_BOOKING_CSV_UPLOAD.ADD_CSV_BUTTON_TEXT}
                    </StyledUploadFileButton>
                    <input
                      type="file"
                      id="clock-report"
                      style={{ display: 'none' }}
                      onChange={(event) => {
                        handelBookingFileChange(event);
                      }}
                      onClick={(e) => {
                        e.target.value = null;
                      }}
                      disabled={confirmBtnClicked}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                  </Box>
                </Box>
              </Flex>
              {uploadedBookingFileName ? <Box>{uploadedBookingFileName}</Box> : null}
            </>
          }
          positive={
            <>
              {confirmBtnClicked && (
                <>
                  <Text mr="10px">We are uploading the file, so please stay tuned</Text>
                  <Spinner size="sm" mr="10px" color="main.primary" />
                </>
              )}
              <Button
                onClick={() => confirmBtnClickHandler()}
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}>
                {constants.SHIFT_BOOKING_CSV_UPLOAD.CONFIRM_BUTTON_TEXT}
              </Button>
            </>
          }
        />
        <ModalComponent
          isOpen={isBookingDetailsModalOpen}
          onClose={closeBookingDetailsModal}
          modalTitle={`${constants.SHIFT_BOOKING.BOOKING_DETAILS_HEARER_TEXT} (${DATE_TO_SHOW(
            bookingData.start_date
          )} to ${DATE_TO_SHOW(bookingData.end_date)})`}
          modalContent={
            <>
              {selectedBooking &&
                selectedBooking.length > 0 &&
                selectedBooking.map((booking, bookingIndex) => {
                  return (
                    <StyledAgency key={booking.id}>
                      <StyledAgencyHeader>{booking.agency_name}</StyledAgencyHeader>

                      <div mt={4} mb={2}>
                        Requested Workers: {booking.requested_workers_total.toFixed(2)}
                      </div>
                      <Flex>
                        {booking.requested_workers_heads &&
                          Object.keys(booking.requested_workers_heads) &&
                          Object.keys(booking.requested_workers_heads).length > 0 &&
                          Object.keys(booking.requested_workers_heads).map((dayIndex) => (
                            <StyledInputWrapper
                              key={dayIndex}
                              style={{ marginLeft: dayIndex === 0 ? 0 : '8px' }}>
                              <InputComponent
                                lable={WEEK_DAYS[dayIndex - 1]}
                                placeholder={WEEK_DAYS[dayIndex - 1]}
                                value={booking.requested_workers_heads[dayIndex]}
                                validationObj={{ isvalid: true }}
                                labelFontSize="xs"
                                disabled={isBookingDetailFieldDisable(
                                  { ...booking },
                                  dayIndex,
                                  userDetails.user_type_id,
                                  'requested_workers_heads'
                                )}
                                style={{ padding: '0px 8px', textAlign: 'center' }}
                                onChange={(e) =>
                                  setNumberOfRequestedWorkerForUpdateAgency(
                                    e,
                                    bookingIndex,
                                    dayIndex
                                  )
                                }
                                refresh={refresh}
                              />
                            </StyledInputWrapper>
                          ))}
                      </Flex>
                      <div mt={4} mb={2}>
                        Fulfill Workers: {booking.fulfilled_workers_total?.toFixed(2) ?? 0}
                      </div>
                      <Flex>
                        {booking.fulfilled_workers_heads &&
                          Object.keys(booking.fulfilled_workers_heads).length > 0 &&
                          Object.keys(booking.fulfilled_workers_heads).map((dayIndex) => (
                            <StyledInputWrapper
                              key={dayIndex}
                              style={{ marginLeft: dayIndex === 0 ? 0 : '8px' }}>
                              <InputComponent
                                lable={WEEK_DAYS[dayIndex - 1]}
                                placeholder={WEEK_DAYS[dayIndex - 1]}
                                value={booking.fulfilled_workers_heads[dayIndex]}
                                validationObj={validateWorkerFulFieldHeads(booking, dayIndex)}
                                disabled={isBookingDetailFieldDisable(
                                  { ...booking },
                                  dayIndex,
                                  userDetails.user_type_id,
                                  'fulfilled_workers_heads'
                                )}
                                onChange={(e) =>
                                  setNumberOfWorkerForUpdateAgency(e, bookingIndex, dayIndex)
                                }
                                onKeyDown={(e) => {
                                  if (
                                    booking?.booking_format === 'HEADS' &&
                                    ['e', 'E', '+', '-', '.'].includes(e.key)
                                  ) {
                                    e.stopPropagation();
                                    e.preventDefault();
                                  } else if (
                                    booking?.booking_format === 'HOURS' &&
                                    ['e', 'E', '+', '-'].includes(e.key)
                                  ) {
                                    e.stopPropagation();
                                    e.preventDefault();
                                  }
                                }}
                                type="number"
                                style={{ padding: '0px 8px', textAlign: 'center' }}
                                labelFontSize="xs"
                                refresh={refresh}
                              />
                            </StyledInputWrapper>
                          ))}
                      </Flex>

                      <div mt={4} mb={2}>
                        Requested Supervisors: {booking.requested_supervisors_total.toFixed(2)}
                      </div>
                      <Flex>
                        {booking.requested_supervisors_heads &&
                          Object.keys(booking.requested_supervisors_heads).map((dayIndex) => (
                            <StyledInputWrapper
                              key={dayIndex}
                              style={{ marginLeft: dayIndex === 0 ? 0 : '8px' }}>
                              <InputComponent
                                lable={WEEK_DAYS[dayIndex - 1]}
                                placeholder={WEEK_DAYS[dayIndex - 1]}
                                value={booking.requested_supervisors_heads[dayIndex]}
                                validationObj={{ isvalid: true }}
                                labelFontSize="xs"
                                disabled={isBookingDetailFieldDisableSupervisor(
                                  { ...booking },
                                  dayIndex,
                                  userDetails.user_type_id,
                                  'requested_supervisors_heads'
                                )}
                                style={{ padding: '0px 8px', textAlign: 'center' }}
                                onChange={(e) =>
                                  setNumberOfRequestedSupervisorForUpdateAgency(
                                    e,
                                    bookingIndex,
                                    dayIndex
                                  )
                                }
                                refresh={refresh}
                              />
                            </StyledInputWrapper>
                          ))}
                      </Flex>

                      <div mt={4} mb={2}>
                        Fulfill Supervisors: {booking.fulfilled_supervisors_total?.toFixed(2) ?? 0}
                      </div>
                      <Flex>
                        {booking.fulfilled_supervisors_heads &&
                          Object.keys(booking.fulfilled_supervisors_heads).map((dayIndex) => (
                            <StyledInputWrapper
                              key={dayIndex}
                              style={{ marginLeft: dayIndex === 0 ? 0 : '8px' }}>
                              <InputComponent
                                lable={WEEK_DAYS[dayIndex - 1]}
                                placeholder={WEEK_DAYS[dayIndex - 1]}
                                value={booking.fulfilled_supervisors_heads[dayIndex]}
                                validationObj={validateSupervisorFulFieldHeads(booking, dayIndex)}
                                // eslint-disable-next-line eqeqeq
                                disabled={isBookingDetailFieldDisableSupervisor(
                                  { ...booking },
                                  dayIndex,
                                  userDetails.user_type_id,
                                  'fulfilled_supervisors_heads'
                                )}
                                onChange={(e) =>
                                  setNumberOfSupervisorForUpdateAgency(e, bookingIndex, dayIndex)
                                }
                                onKeyDown={(e) => {
                                  if (
                                    booking?.booking_format === 'HEADS' &&
                                    ['e', 'E', '+', '-', '.'].includes(e.key)
                                  ) {
                                    e.stopPropagation();
                                    e.preventDefault();
                                  } else if (
                                    booking?.booking_format === 'HOURS' &&
                                    ['e', 'E', '+', '-'].includes(e.key)
                                  ) {
                                    e.stopPropagation();
                                    e.preventDefault();
                                  }
                                }}
                                type="number"
                                style={{ padding: '0px 8px', textAlign: 'center' }}
                                labelFontSize="xs"
                                refresh={refresh}
                              />
                            </StyledInputWrapper>
                          ))}
                      </Flex>
                    </StyledAgency>
                  );
                })}{' '}
            </>
          }
          negative={
            userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
            userDetails.user_type_id === USER_TYPE.SITE_ADMIN ||
            userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
            userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ? (
              <Button
                onClick={closeBookingDetailsModal}
                bg="main.secondary"
                mr={3}
                _hover={{ bg: 'main.secondary' }}
                color="main.black">
                {constants.SHIFT_BOOKING.CANCEL_BUTTON_TEXT}
              </Button>
            ) : null
          }
          positive={
            userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
            userDetails.user_type_id === USER_TYPE.SITE_ADMIN ||
            userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
            userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ? (
              <Tooltip
                isDisabled={selectedBooking?.[0]?.allowFullfillment}
                label={constants.FULFILL_BOOKING_DISABLED_NOTE}
                shouldWrapChildren>
                <Button
                  onClick={() =>
                    updateSelectedBookingDetail(selectedBooking, userDetails.user_type_id)
                  }
                  disabled={checkBtnDisability(selectedBooking)}
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}>
                  {constants.SHIFT_BOOKING.EDIT_UPDATE_BUTTON_TEXT}
                </Button>
              </Tooltip>
            ) : null
          }
        />
      </>
    );
  };

  useEffect(() => {
    let totalWorkersCount = bookingData.required_workers_total;
    let totalSupervisorsCount = bookingData.required_supervisors_total;

    // Update remaining workers count
    // eslint-disable-next-line no-unused-expressions
    selectedAgencies &&
      Object.keys(selectedAgencies).length > 0 &&
      Object.keys(selectedAgencies).forEach((agencyId) => {
        // eslint-disable-next-line radix
        if (parseFloat(selectedAgencies[agencyId].totalWorkers)) {
          // eslint-disable-next-line radix
          totalWorkersCount -= parseFloat(selectedAgencies[agencyId].totalWorkers);
        }
      });

    // Update remaining supervisors count
    // eslint-disable-next-line no-unused-expressions
    selectedAgencies &&
      Object.keys(selectedAgencies).length > 0 &&
      Object.keys(selectedAgencies).forEach((agencyId) => {
        if (parseFloat(selectedAgencies[agencyId].totalSupervisors)) {
          totalSupervisorsCount -= parseFloat(selectedAgencies[agencyId].totalSupervisors);
        }
      });
    setRemainAssignWorkers(totalWorkersCount);
    setRemainAssignSupervisors(totalSupervisorsCount);
  }, [bookingData, selectedAgencies]);
  useEffect(async () => {
    if (isAgencyAssignModalOpen) {
      const result = await getAgency(1000, 1, 'name', 'ASC');
      if (result && result.ok) {
        if (result.data.count === 1) {
          onSelectAgency(result.data.agency_list[0], result.data.count);
        }
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
    }
  }, [isAgencyAssignModalOpen]);

  useEffect(() => {
    if (userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN) setReload(!reload);
  }, [selected_client_id]);

  useEffect(() => {
    setColumn([
      ...column,
      {
        label: 'Actions',
        field: 'id',
        sort: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        customRow: (cell, row) => {
          // eslint-disable-next-line no-plusplus
          return <div style={{ display: 'flex' }}>{getViewEditActionButton(row)}</div>;
        }
      }
    ]);
  }, [permission]);

  return (
    <>
      <StyledShiftBookingContainer>
        <AlertDialogComponent
          isOpen={openShiftBookingDeleteModal}
          onClose={onCloseDeleteModal}
          title={constants.SHIFT_BOOKING.DELETE_BUTTON_TEXT}
          body={<Box>Are you sure you want delete Shift Booking ?</Box>}
          negative={
            <Button
              bg="main.secondary"
              mr={3}
              _hover={{ bg: 'main.secondary' }}
              color="main.black"
              onClick={onCloseDeleteModal}>
              {constants.SHIFT_BOOKING.CANCEL_BUTTON_TEXT}
            </Button>
          }
          positive={
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={confirmDeleteShiftBooking}>
              {constants.SHIFT_BOOKING.CONFIRM_BUTTON_TEXT}
            </Button>
          }
        />
        {isPageLoader ? <Loader /> : null}
        <Card>
          {getAddBookingModalTemplate()}
          {getBookingDetailModalTemplate()}
          {/* <ClientSideDatatable
          pageSize={10}
          initialSortKey="id"
          column={column}
          row={bookingListing}
          pagination
          pages={[2, 5, 10, 15]}
        /> */}
          <ServerSideDatatable
            pageSize={10}
            initialSortKey={null}
            column={column}
            onPaginate={getBookingListing}
            afterPaginateData={() => setIsPageLoader(false)}
            pagination
            pages={[10, 15]}
            dataKey="bookings"
            refresh={reload}
          />
        </Card>
      </StyledShiftBookingContainer>{' '}
    </>
  );
};

ShiftBooking.propTypes = {};

export default ShiftBooking;
