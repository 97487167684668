// CreditDues.js
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useEffect, useState } from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import '../../components/Forms/formStyles.css';
import { USER_TYPE, PAGE_TITLE } from '../../constants';
import Card from '../../components/Card';
import ServerSideDatatable from '../../components/ServerSideDatatable';
import AgencyDropdown from '../../components/Forms/AgencyDropdown';
import { getSites } from '../../redux/action/site.action';
import { getCreditDuesData } from '../../redux/action/creditDues';
import CreditPercentageDropdown from '../../components/CreditPercentageDropdown/CreditPercentageDropdown'; // Import the new component

const INITIAL_COLUMNS_TEMPLATE = () => [
  { label: 'EmployeeID', field: 'employee_id', sort: true },
  { label: 'Agency', field: 'agency_name', sort: true },
  { label: 'Site', field: 'site_name', sort: true },
  { label: 'Assignment Date', field: 'assignment_date', sort: true },
  { label: 'Performance Target Achieved', field: 'performance_target_achieved', sort: true },
  { label: 'Target Number', field: 'performance_threshold', sort: true },
  {
    label: 'Week Number Achieved Performance',
    field: 'achievement_week',
    sort: true
  },
  {
    label: 'Actual Training Cost',
    field: 'actual_training_cost',
    sort: true,
    customRow: (cell) => {
      if (cell !== null && cell !== undefined) {
        return `£${cell}`;
      }
      return `-`;
    }
  },
  {
    label: 'Credit % Applied',
    field: 'applied_credit_rate',
    sort: true,
    customRow: (cell) => {
      if (cell !== null && cell !== undefined) {
        return `${Math.round(cell)}`;
      }
      return `-`;
    }
  },
  {
    label: 'Total Credit Due',
    field: 'total_credit_due',
    sort: true,
    customRow: (cell) => {
      if (cell !== null && cell !== undefined) {
        return `£${cell}`;
      }
      return `-`;
    }
  }
];

const CreditDues = function () {
  const [reload, setReload] = useState(false);
  /* eslint-disable-next-line no-unused-vars */
  const [isPageLoader, setIsPageLoader] = useState(false);
  const [agency, setAgency] = useState({});
  const [moveToFirstPage, setMoveToFirstPage] = useState(false);
  const [sitesList, setSitesList] = useState([]);
  const [creditPercentage, setCreditPercentage] = useState('');
  const { user_details } = useSelector((state) => state.authentication);
  const [countData, setCountData] = useState();
  const { selected_client_id, selected_site_id, selected_start_date, selected_end_date } =
    useSelector((state) => state.agency);

  const getSitesFunction = async () => {
    const sites = await getSites(user_details.client_id);
    if (sites && sites.ok) {
      setSitesList(sites.data.sites);
    }
  };

  useEffect(() => {
    document.title = PAGE_TITLE.TRAINING;
  }, []);

  if (user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN) {
    useEffect(() => {
      getSitesFunction();
    }, []);
  }

  useEffect(() => {
    setSitesList(
      sitesList.filter((value) => parseInt(value.id) === parseInt(user_details.site_id))
    );
  }, [sitesList.length]);

  const retrievePerformanceData = async (size, pageNumber, sortKey, sorting) => {
    const result = await getCreditDuesData(
      selected_client_id || user_details.client_id,
      agency.value || user_details.agency_id,
      selected_start_date,
      selected_end_date,
      size,
      pageNumber,
      sortKey,
      sorting,
      creditPercentage.value
    );
    if (result.ok && result.data && result.data.credit_dues) {
      setCountData(result.data.total);
      return {
        data: {
          performance_data: result.data.credit_dues,
          count: result.data.total
        }
      };
    }
    return {
      data: {
        performance_data: [],
        count: 0
      }
    };
  };

  const setAgencyData = (e) => {
    setAgency(e);
    setMoveToFirstPage(true);
    setReload(!reload);
  };

  useEffect(() => {
    setMoveToFirstPage(true);
    setReload(!reload);
  }, [
    selected_client_id,
    selected_site_id,
    selected_start_date,
    selected_end_date,
    creditPercentage
  ]);

  const exportPerformanceData = async () => {
    const { data } = await retrievePerformanceData(countData, 1, 'employee_id', 'ASC', null);

    const performanceList = data.performance_data;
    let csvContent =
      'Employee ID,Agency,Site,Assignment Date,Performance Target Achieved,Actual Training Cost,Credit % Applied,Total Credit Due\r\n';
    performanceList.forEach((row) => {
      csvContent += `${row.employee_id},${row.agency_name ? row.agency_name : ''},${
        row.site_name
      },${row.assignment_date},${row.performance_target_achieved},${row.actual_training_cost},${
        row.applied_credit_rate
      },${row.total_credit_due}\r\n`;
    });

    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute(
      'download',
      `Training Credits_${selected_start_date}_to_${selected_end_date}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const resetCreditPercentage = () => {
    setCreditPercentage('');
    setReload(!reload);
  };

  return (
    <Card>
      <Flex>
        {(user_details.user_type_id === USER_TYPE.CLIENT_ADMIN ||
          user_details.user_type_id === USER_TYPE.SITE_ADMIN ||
          user_details.user_type_id === USER_TYPE.REGION_ADMIN) && (
          <Box width="220px" mr="15px">
            <AgencyDropdown agency={agency} refresh={reload} setAgency={setAgencyData} />
          </Box>
        )}
        <Flex>
          <Box width="150px" mr="15px">
            <CreditPercentageDropdown
              creditPercentage={creditPercentage}
              setCreditPercentage={setCreditPercentage}
            />
          </Box>

          <Button
            _hover={{ bg: 'main.primary' }}
            marginTop="25px"
            colorScheme="blue"
            onClick={resetCreditPercentage}>
            Reset Credit %
          </Button>
        </Flex>
      </Flex>
      <Button colorScheme="blue" disabled={countData <= 0} onClick={exportPerformanceData} mt={4}>
        Export
      </Button>
      <ServerSideDatatable
        size={10}
        initialSortKey="employee_id"
        column={INITIAL_COLUMNS_TEMPLATE()}
        onPaginate={retrievePerformanceData}
        afterPaginateData={() => setIsPageLoader(false)}
        pagination
        pages={[5, 10, 15]}
        dataKey="performance_data"
        refresh={reload}
        moveToFirstPage={moveToFirstPage}
      />
    </Card>
  );
};

export default CreditDues;
