/* eslint-disable no-nested-ternary */
/* eslint-disable import/named */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Flex, Text } from '@chakra-ui/react';
import Card from '../../components/Card';
import SiteDropdown from '../../components/Forms/SiteDropdown';
import CompanyDropdown from '../../components/Forms/CompanyDropdown';
import { PAGE_TITLE, RATING_STAR_COLOR, SPEND_HOUR_COLORS, USER_TYPE } from '../../constants';
import HeaderCard from '../../components/HeaderCard';
// import LineChart from '../../components/Charts/LineChart';
import AreaChart from '../../components/Charts/AreaChart';
import {
  getAgencyRatingData,
  getClientRatingData,
  getHeadsData,
  getHoursData,
  getLeaversData,
  getSiteRatingData,
  getSpendData
} from '../../redux/action/trendAnalysis.action';
import NoData from '../../components/NoData';
import YearDropdown from '../../components/Forms/YearDropdown';
import WorkerTypeDropdown from '../../components/Forms/WorkerTypeDropdown';
import AgencyDropdown from '../../components/Forms/AgencyDropdown';

const TrendAnalysis = function () {
  useEffect(() => {
    document.title = PAGE_TITLE.TRENDS_ANALYSIS;
  }, []);

  // const { selected_client_id } = useSelector((state) => state.agency);
  const { userData, user_details } = useSelector((state) => state.authentication);
  const [agency, setAgency] = useState({});
  const [client, setClient] = useState({});
  const [site, setSite] = useState({});
  const [spendData, setSpendData] = useState(null);
  const [hoursData, setHoursData] = useState(null);
  const [headsData, setHeadsData] = useState(null);
  const [leaversData, setLeaversData] = useState(null);
  const [siteRatingData, setSiteRatingData] = useState(null);
  const [agencyRatingData, setAgencyRatingData] = useState(null);
  const [clientRatingData, setClientRatingData] = useState(null);
  const currentYear = new Date().getFullYear();
  const [selectedDate, setSelectedDate] = useState({
    label: `${currentYear}-${currentYear + 1}`,
    startDate: `${currentYear}-04-01`,
    endDate: `${currentYear + 1}-03-31`
  });
  const [workerType, setWorkerType] = useState({ label: 'Temporary Worker', value: 'TEMPORARY' });
  const [firstUpdate, setFirstUpdate] = useState(true);

  const getSpendDataFunction = async (
    clientId,
    siteId,
    agencyId,
    regionId,
    workerTypeId,
    startDate,
    endDate
  ) => {
    const spend = await getSpendData(
      clientId || userData.client_id,
      siteId || userData.site_id,
      agencyId || userData.agency_id,
      regionId,
      workerTypeId,
      startDate,
      endDate
    );
    if (spend && spend.ok) {
      setSpendData(spend.data);
    }
  };

  const getHoursDataFunction = async (
    clientId,
    siteId,
    agencyId,
    regionId,
    workerTypeId,
    startDate,
    endDate
  ) => {
    const hours = await getHoursData(
      clientId || userData.client_id,
      siteId || userData.site_id,
      agencyId || userData.agency_id,
      regionId,
      workerTypeId,
      startDate,
      endDate
    );
    if (hours && hours.ok) {
      setHoursData(hours.data);
    }
  };

  const getHeadsDataFunction = async (
    clientId,
    siteId,
    agencyId,
    regionId,
    workerTypeId,
    startDate,
    endDate
  ) => {
    const heads = await getHeadsData(
      clientId || userData.client_id,
      siteId || userData.site_id,
      agencyId || userData.agency_id,
      regionId,
      workerTypeId,
      startDate,
      endDate
    );
    if (heads && heads.ok) {
      setHeadsData(heads.data);
    }
  };

  const getLeaversDataFunction = async (
    clientId,
    siteId,
    agencyId,
    regionId,
    workerTypeId,
    startDate,
    endDate
  ) => {
    const leavers = await getLeaversData(
      clientId || userData.client_id,
      siteId || userData.site_id,
      agencyId || userData.agency_id,
      regionId,
      workerTypeId,
      startDate,
      endDate
    );
    if (leavers && leavers.ok) {
      setLeaversData(leavers.data);
    }
  };

  const getSiteRatingFunction = async (
    clientId,
    siteId,
    agencyId,
    regionId,
    workerTypeId,
    startDate,
    endDate
  ) => {
    const rating = await getSiteRatingData(
      clientId || userData.client_id,
      siteId || userData.site_id,
      agencyId || userData.agency_id,
      regionId,
      workerTypeId,
      startDate,
      endDate
    );
    if (rating && rating.ok) {
      setSiteRatingData(rating.data);
    }
  };

  const getAgencyDataFunction = async (
    clientId,
    siteId,
    agencyId,
    regionId,
    workerTypeId,
    startDate,
    endDate
  ) => {
    const rating = await getAgencyRatingData(
      clientId || userData.client_id,
      siteId || userData.site_id,
      agencyId || userData.agency_id,
      regionId,
      workerTypeId,
      startDate,
      endDate
    );
    if (rating && rating.ok) {
      setAgencyRatingData(rating.data);
    }
  };

  const getClientDataFunction = async (
    clientId,
    siteId,
    agencyId,
    regionId,
    workerTypeId,
    startDate,
    endDate
  ) => {
    const rating = await getClientRatingData(
      clientId || userData.client_id,
      siteId || userData.site_id,
      agencyId || userData.agency_id,
      regionId,
      workerTypeId,
      startDate,
      endDate
    );
    if (rating && rating.ok) {
      setClientRatingData(rating.data);
    }
  };

  const getChartData = (clientId, siteId, agencyId, regionId, workerTypeId, startDate, endDate) => {
    Promise.all([
      getSpendDataFunction(clientId, siteId, agencyId, regionId, workerTypeId, startDate, endDate),
      getHoursDataFunction(clientId, siteId, agencyId, regionId, workerTypeId, startDate, endDate),
      getHeadsDataFunction(clientId, siteId, agencyId, regionId, workerTypeId, startDate, endDate),
      getLeaversDataFunction(
        clientId,
        siteId,
        agencyId,
        regionId,
        workerTypeId,
        startDate,
        endDate
      ),
      getSiteRatingFunction(clientId, siteId, agencyId, regionId, workerTypeId, startDate, endDate),
      getAgencyDataFunction(clientId, siteId, agencyId, regionId, workerTypeId, startDate, endDate),
      getClientDataFunction(clientId, siteId, agencyId, regionId, workerTypeId, startDate, endDate)
    ]);
  };

  const resetData = () => {
    setSpendData(null);
    setHoursData(null);
    setHeadsData(null);
    setLeaversData(null);
    setSiteRatingData(null);
    setAgencyRatingData(null);
    setClientRatingData(null);
  };

  const setAgencyData = (e) => {
    setAgency(e);
    setSite({});
    resetData();
    getChartData(
      client.value,
      user_details.user_type_id === USER_TYPE.SITE_ADMIN ? user_details.site_id : '',
      workerType.value !== 'TEMPORARY' ? '' : e.value,
      user_details.region_id,
      workerType.value,
      selectedDate.startDate,
      selectedDate.endDate
    );
  };
  const setClientData = (clientData) => {
    setClient(clientData);
    setSite({});
    resetData();
    getChartData(
      clientData.value,
      '',
      workerType.value !== 'TEMPORARY' ? '' : agency.value,
      user_details.region_id,
      workerType.value,
      selectedDate.startDate,
      selectedDate.endDate
    );
  };
  const setSiteData = (e) => {
    setSite(e);
    resetData();
    getChartData(
      client.value || user_details.client_id,
      e.value,
      workerType.value !== 'TEMPORARY' ? '' : agency.value,
      user_details.region_id,
      workerType.value,
      selectedDate.startDate,
      selectedDate.endDate
    );
  };

  useEffect(() => {
    setFirstUpdate(false);
    getChartData(
      user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
        user_details.user_type_id === USER_TYPE.SITE_ADMIN
        ? user_details.client_id
        : client.value,
      user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
        user_details.user_type_id === USER_TYPE.SITE_ADMIN
        ? user_details.site_id
        : site.value,
      workerType.value !== 'TEMPORARY'
        ? ''
        : user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
          user_details.user_type_id === USER_TYPE.SITE_ADMIN
        ? user_details.agency_id
        : agency.value,
      user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
        user_details.user_type_id === USER_TYPE.REGION_ADMIN
        ? user_details.region_id
        : '',
      workerType.value,
      selectedDate.startDate,
      selectedDate.endDate
    );
  }, []);

  useEffect(() => {
    if (!firstUpdate) {
      setSite({});
      setAgency({});
      resetData();
      getChartData(
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
          user_details.user_type_id === USER_TYPE.SITE_ADMIN
          ? user_details.client_id
          : client.value,
        user_details.user_type_id === USER_TYPE.SITE_ADMIN ? user_details.site_id : '',
        '',
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
          user_details.user_type_id === USER_TYPE.REGION_ADMIN
          ? user_details.region_id
          : '',
        workerType.value,
        selectedDate.startDate,
        selectedDate.endDate
      );
    }
  }, [workerType.value]);

  useEffect(() => {
    if (!firstUpdate) {
      resetData();
      getChartData(
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
          user_details.user_type_id === USER_TYPE.SITE_ADMIN
          ? user_details.client_id
          : client.value,
        user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
          user_details.user_type_id === USER_TYPE.SITE_ADMIN
          ? user_details.site_id
          : site.value,
        workerType.value !== 'TEMPORARY'
          ? ''
          : user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
            user_details.user_type_id === USER_TYPE.SITE_ADMIN
          ? user_details.agency_id
          : agency.value,
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
          user_details.user_type_id === USER_TYPE.REGION_ADMIN
          ? user_details.region_id
          : '',
        workerType.value,
        selectedDate.startDate,
        selectedDate.endDate
      );
    }
  }, [selectedDate]);

  return (
    <>
      <Card margin="5px">
        <Flex p="8px" justifyContent="space-between">
          <Flex>
            {((userData.client_id && user_details.user_type_id === USER_TYPE.CLIENT_ADMIN) ||
              (userData.client_id && user_details.user_type_id === USER_TYPE.MESSAGE_ADMIN)) && (
              <Flex>
                <Box w="250px" mr="15px" zIndex="12">
                  <AgencyDropdown
                    disabled={workerType.value !== 'TEMPORARY'}
                    setDefaultValue
                    agency={agency}
                    setAgency={setAgencyData}
                    placeholder={workerType.value !== 'TEMPORARY' ? ' ' : 'All Agencies'}
                  />
                </Box>
                <Box w="250px" mr="15px" zIndex="12">
                  <SiteDropdown
                    label="Site"
                    site={site}
                    setSite={setSiteData}
                    disabled={false}
                    multiple={false}
                    clientId={client.value}
                  />
                </Box>
              </Flex>
            )}
            {user_details.user_type_id === USER_TYPE.AGENCY_ADMIN && (
              <Flex>
                <Box w="250px" mr="15px" zIndex="12">
                  <CompanyDropdown
                    setDefaultValue
                    company={client}
                    setCompany={setClientData}
                    disabled={false}
                  />
                </Box>
                <Box w="250px" mr="15px" zIndex="12">
                  <SiteDropdown
                    label="Site"
                    site={site}
                    setSite={setSiteData}
                    disabled={false}
                    multiple={false}
                    clientId={client.value}
                  />
                </Box>
              </Flex>
            )}
            {userData.client_id && user_details.user_type_id === USER_TYPE.REGION_ADMIN && (
              <Flex>
                <Box w="250px" mr="15px" zIndex="12">
                  <AgencyDropdown
                    disabled={workerType.value !== 'TEMPORARY'}
                    setDefaultValue
                    agency={agency}
                    setAgency={setAgencyData}
                    placeholder={workerType.value !== 'TEMPORARY' ? ' ' : 'All Agencies'}
                  />
                </Box>
                <Box w="250px" mr="15px" zIndex="12">
                  <SiteDropdown
                    label="Site"
                    site={site}
                    setSite={setSiteData}
                    disabled={false}
                    multiple={false}
                  />
                </Box>
              </Flex>
            )}
            {userData.client_id && user_details.user_type_id === USER_TYPE.SITE_ADMIN && (
              <Box w="250px" mr="15px" zIndex="12">
                <AgencyDropdown
                  disabled={workerType.value !== 'TEMPORARY'}
                  setDefaultValue
                  agency={agency}
                  setAgency={setAgencyData}
                  placeholder={workerType.value !== 'TEMPORARY' ? ' ' : 'All Agencies'}
                />
              </Box>
            )}
            {user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN && (
              <Box w="250px" mr="15px" zIndex="12">
                <SiteDropdown
                  label="Site"
                  site={site}
                  setSite={setSiteData}
                  disabled={false}
                  multiple={false}
                />
              </Box>
            )}
          </Flex>
          <Flex>
            {((userData.client_id && user_details.user_type_id === USER_TYPE.SITE_ADMIN) ||
              (userData.client_id && user_details.user_type_id === USER_TYPE.REGION_ADMIN) ||
              (userData.client_id && user_details.user_type_id === USER_TYPE.CLIENT_ADMIN) ||
              (userData.client_id && user_details.user_type_id === USER_TYPE.MESSAGE_ADMIN)) && (
              <Box w="210px" mr="15px">
                <WorkerTypeDropdown
                  twoOptions
                  workerType={workerType}
                  setWorkerTypeDropdown={setWorkerType}
                  setDefaultValue
                  disabled={false}
                  multiple={false}
                />
              </Box>
            )}
            <Box w="210px" ml="auto" zIndex="12">
              <YearDropdown setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
            </Box>
          </Flex>
        </Flex>
      </Card>
      <Flex>
        <HeaderCard
          minWidth="390px"
          width="100%"
          m="5px"
          boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
          h="320px">
          <Text textAlign="center" fontSize="xl" fontWeight="bold">
            Site Rating
          </Text>
          <Box m="auto" w="100%">
            {siteRatingData && siteRatingData.result ? (
              <AreaChart
                colors={[RATING_STAR_COLOR]}
                height="250px"
                series={[
                  {
                    name: 'Rating',
                    data: siteRatingData.result.avg_score
                  }
                ]}
                categories={siteRatingData.result.labels}
              />
            ) : (
              <NoData />
            )}
          </Box>
        </HeaderCard>
        <HeaderCard
          minWidth="390px"
          width="100%"
          m="5px"
          boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
          h="320px">
          <Text textAlign="center" fontSize="xl" fontWeight="bold">
            Agency Rating
          </Text>
          <Box m="auto" w="100%">
            {agencyRatingData && agencyRatingData.result ? (
              <AreaChart
                colors={[RATING_STAR_COLOR]}
                height="250px"
                series={[
                  {
                    name: 'Rating',
                    data: agencyRatingData.result.avg_score
                  }
                ]}
                categories={agencyRatingData.result.labels}
              />
            ) : (
              <NoData />
            )}
          </Box>
        </HeaderCard>
        <HeaderCard
          minWidth="390px"
          width="100%"
          m="5px"
          boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
          h="320px">
          <Text textAlign="center" fontSize="xl" fontWeight="bold">
            Client Rating
          </Text>
          <Box m="auto" w="100%">
            {clientRatingData && clientRatingData.result ? (
              <AreaChart
                colors={[RATING_STAR_COLOR]}
                height="250px"
                series={[
                  {
                    name: 'Rating',
                    data: clientRatingData.result.avg_score
                  }
                ]}
                categories={clientRatingData.result.labels}
              />
            ) : (
              <NoData />
            )}
          </Box>
        </HeaderCard>
      </Flex>
      {workerType.value !== 'PERMANENT' && (
        <Flex>
          <HeaderCard
            minWidth="590px"
            width="100%"
            m="5px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
            h="320px">
            <Text textAlign="center" fontSize="xl" fontWeight="bold">
              Spend
            </Text>

            <Box m="auto" w="100%">
              {spendData && spendData.result ? (
                <AreaChart
                  colors={SPEND_HOUR_COLORS}
                  height="250px"
                  series={[
                    {
                      name: 'Standard',
                      data: spendData.result.standard
                    },
                    {
                      name: 'Overtime',
                      data: spendData.result.overtime
                    }
                  ]}
                  categories={spendData.result.labels}
                />
              ) : (
                <NoData />
              )}
            </Box>
          </HeaderCard>
          <HeaderCard
            minWidth="590px"
            width="100%"
            m="5px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
            h="320px">
            <Text textAlign="center" fontSize="xl" fontWeight="bold">
              Hours
            </Text>
            <Box m="auto" w="100%">
              {hoursData && hoursData.result ? (
                <AreaChart
                  colors={SPEND_HOUR_COLORS}
                  height="250px"
                  series={[
                    {
                      name: 'Standard',
                      data: hoursData.result.standard
                    },
                    {
                      name: 'Overtime',
                      data: hoursData.result.overtime
                    }
                  ]}
                  categories={hoursData.result.labels}
                />
              ) : (
                <NoData />
              )}
            </Box>
          </HeaderCard>
        </Flex>
      )}
      {workerType.value !== 'PERMANENT' && (
        <Flex>
          <HeaderCard
            minWidth="590px"
            width="100%"
            m="5px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
            h="320px">
            <Text textAlign="center" fontSize="xl" fontWeight="bold">
              Heads
            </Text>
            <Box m="auto" w="100%">
              {headsData && headsData.result ? (
                <AreaChart
                  height="250px"
                  series={[
                    {
                      name: 'Total Heads',
                      data: headsData.result.total_workers
                    }
                  ]}
                  categories={headsData.result.labels}
                />
              ) : (
                <NoData />
              )}
            </Box>
          </HeaderCard>
          <HeaderCard
            minWidth="590px"
            width="100%"
            m="5px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
            h="320px">
            <Text textAlign="center" fontSize="xl" fontWeight="bold">
              Leavers
            </Text>
            <Box m="auto" w="100%">
              {leaversData && leaversData.result ? (
                <AreaChart
                  height="250px"
                  series={[
                    {
                      name: 'Leavers',
                      data: leaversData.result.total_hours
                    }
                  ]}
                  categories={leaversData.result.labels}
                />
              ) : (
                <NoData />
              )}
            </Box>
          </HeaderCard>
        </Flex>
      )}
    </>
  );
};

export default TrendAnalysis;
