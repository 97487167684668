/* eslint-disable default-param-last */
import { API_ENDPOINT } from '../../constants';

import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';
import { toFormData } from '../../utils/helper';

export const getAttendances = async (limit = 1, page = 1, sortKey, sortType) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.ATTENDANCE}?page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const getAttendanceDetails = async (limit = 1, page = 1, sortKey, sortType, id) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.ATTENDANCE}/${id}?page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const uploadTAndAData = async (payload) => {
  const result = await axiosInstance.post(
    API_ENDPOINT.UPOLAD_T_AND_A_DATA,
    await toFormData(payload)
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const downloadTAndASample = async () => {
  const result = await axiosInstance.get(`${API_ENDPOINT.BULK_T_A_DOWNLOAD_SAMPLE}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const getAttendanceProcessData = async (limit = 1, page = 1, sortKey, sortType, id) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.ATTENDANCE_PROCESS_DATA}/${id}?page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const getPayrollData = async (
  clientId,
  agencyId,
  siteId,
  regionId,
  startDate,
  endDate,
  limit = 1,
  page = 1,
  sortKey,
  sortType
) => {
  let queryString = `client_id=${clientId}`;
  if (agencyId) {
    queryString += `&agency_id=${agencyId}`;
  }
  if (siteId) {
    queryString += `&site_id=${siteId}`;
  }
  if (regionId) {
    queryString += `&region_id=${regionId}`;
  }
  if (startDate) {
    queryString += `&start_date=${startDate}`;
  }
  if (endDate) {
    queryString += `&end_date=${endDate}`;
  }
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_PAYROLL}?${queryString}&page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const uploadPayrollData = async (payload) => {
  const result = await axiosInstance.post(
    API_ENDPOINT.ATTENDANCE_PROCESS_DATA,
    await toFormData(payload)
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const uploadAttendanceData = async (payload) => {
  const result = await axiosInstance.post(
    API_ENDPOINT.UPOLAD_T_AND_A_DATA,
    await toFormData(payload)
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const uploadTapData = async (payload) => {
  const result = await axiosInstance.post(
    `${API_ENDPOINT.GET_TOTAL_AGENCY_PAY}${API_ENDPOINT.UPLOAD_TAP_DATA}`,
    await toFormData(payload)
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const downloadPayrollData = async (payrollId) => {
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_PAYROLL_CSV_DATA}/${payrollId}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const downloadTapSample = async () => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_TOTAL_AGENCY_PAY}${API_ENDPOINT.GET_SAMPLE_SHEET}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const downloadPayrollSample = async () => {
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_PAYROLL_DOWNLOAD_SAMPLE}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const downloadTapClockReport = async (
  clientId,
  siteId,
  agencyId,
  startDate,
  endDate,
  week
) => {
  let queryString = `client_id=${clientId}`;
  if (siteId) {
    queryString += `&site_id=${siteId}`;
  }
  if (agencyId) {
    queryString += `&agency_id=${agencyId}`;
  }
  if (startDate) {
    queryString += `&start_date=${startDate}`;
  }
  if (endDate) {
    queryString += `&end_date=${endDate}`;
  }
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_TOTAL_AGENCY_PAY}${API_ENDPOINT.TAP_SHEET_DOWNLOAD}?${queryString}&week=${week}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const deleteTapDataSheet = async (payload) => {
  const result = await axiosInstance.delete(
    `${API_ENDPOINT.GET_TOTAL_AGENCY_PAY}${API_ENDPOINT.DELETE_TAP_SHEET}`,
    {
      data: payload
    }
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const downloadWorkerClockReport = async (reportId) => {
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_WORKER_CLOCK_REPORT}/${reportId}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const deletePayrollData = async (payload) => {
  const result = await axiosInstance.delete(API_ENDPOINT.ATTENDANCE_PROCESS_DATA, {
    data: payload
  });
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};
