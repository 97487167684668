/* eslint-disable react/function-component-definition */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import moment from 'moment';
import { Box, Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { WEEK_RANGE_ACTIONS } from '../../constants';
import './formStyles.css';

const WeekRangePicker = ({ onDateRangeChange, weekStartDay, disabled = false }) => {
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState();
  const dayIndex = weekStartDay || 0; // Default to Sunday if not provided
  const { startDate, endDate } = useSelector((state) => state.weekRange);
  const { selected_client_id } = useSelector((state) => state.agency);

  // Helper function to get current week range based on weekStartDay
  const getCurrentWeekRange = () => {
    const today = moment().endOf('day');
    const currentDayIndex = today.day();
    const diff = currentDayIndex - dayIndex;

    // Calculate the start of the current week based on weekStartDay
    const weekStart = today
      .clone()
      .subtract(diff >= 0 ? diff : diff + 7, 'days')
      .startOf('day');

    // If today is before the calculated week end, adjust to previous week
    const weekEnd = weekStart.clone().add(6, 'days').endOf('day');
    if (today.isBefore(weekEnd)) {
      weekStart.subtract(7, 'days');
      weekEnd.subtract(7, 'days');
    }

    return [
      new DateObject(weekStart.format('YYYY-MM-DD')),
      new DateObject(weekEnd.format('YYYY-MM-DD'))
    ];
  };

  // Effect to handle weekStartDay changes and client changes
  useEffect(() => {
    const initialRange = getCurrentWeekRange();
    setDateRange(initialRange);

    // Update Redux with new range
    const start = moment(initialRange[0].format('YYYY-MM-DD')).startOf('day');
    const end = moment(initialRange[1].format('YYYY-MM-DD')).endOf('day');

    dispatch({
      type: WEEK_RANGE_ACTIONS.SET_WEEK_RANGE,
      payload: {
        startDate: start.format('YYYY-MM-DD'),
        endDate: end.format('YYYY-MM-DD')
      }
    });
  }, [selected_client_id, weekStartDay, dayIndex]); // Add weekStartDay and dayIndex as dependencies

  useEffect(() => {
    if (dateRange) {
      const start = moment(dateRange[0].format('YYYY-MM-DD')).startOf('day');
      const end = moment(dateRange[1].format('YYYY-MM-DD')).endOf('day');

      // Only update Redux if the dates are different
      if (start.format('YYYY-MM-DD') !== startDate || end.format('YYYY-MM-DD') !== endDate) {
        dispatch({
          type: WEEK_RANGE_ACTIONS.SET_WEEK_RANGE,
          payload: {
            startDate: start.format('YYYY-MM-DD'),
            endDate: end.format('YYYY-MM-DD')
          }
        });
      }

      // Notify parent component
      if (onDateRangeChange) {
        onDateRangeChange({
          startDate: start,
          endDate: end,
          dateRange
        });
      }
    }
  }, [dateRange]); // Only run when dateRange changes

  const handleDateChange = (event) => {
    if (!event || !event[0]) return;

    const selectedDate = moment(event[0].toDate());
    const today = moment().endOf('day');

    // If selected date is in future week, return
    if (selectedDate.isAfter(today)) {
      return;
    }

    const client_index = dayIndex;
    const current_index = event[0].weekDay.index;

    let weekStart;
    let weekEnd;

    if (client_index > current_index) {
      const forward_index = client_index - current_index - 1;
      const index = 7 - (client_index - current_index);

      weekStart = moment(event[0].toDate()).subtract(index, 'days').startOf('day');
      weekEnd = moment(event[0].toDate()).add(forward_index, 'days').endOf('day');
    } else {
      const index = current_index - client_index;
      const forward_index = 7 - index - 1;
      weekStart = moment(event[0].toDate()).subtract(index, 'days').startOf('day');
      weekEnd = moment(event[0].toDate()).add(forward_index, 'days').endOf('day');
    }

    // If week end is in future, adjust to previous week
    if (weekEnd.isAfter(today)) {
      weekStart.subtract(7, 'days');
      weekEnd.subtract(7, 'days');
    }

    setDateRange([
      new DateObject(weekStart.format('YYYY-MM-DD')),
      new DateObject(weekEnd.format('YYYY-MM-DD'))
    ]);
  };

  return (
    <Flex alignItems="flex-end">
      <Box width="14rem" mr={4}>
        <p className="input-label">Select Week Range</p>
        <DatePicker
          format="DD/MM/YYYY"
          inputClass="date-picker"
          range
          placeholder="Select Week Range"
          maxDate={new Date()}
          editable={false}
          onChange={handleDateChange}
          value={dateRange}
          className="booking-datepicker"
          weekStartDayIndex={dayIndex}
          disabled={disabled}
        />
      </Box>
    </Flex>
  );
};

export default WeekRangePicker;
