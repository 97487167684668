/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable import/named */
/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPerformanceDashboardType } from '../../redux/actions/performanceDashboard.action';
import Dropdown from '../Dropdown';

const DashboardTypeDropdown = ({
  label = 'Dashboard Type',
  placeholder = 'Select Dashboard Type',
  disabled = false,
  onChangeCallback
}) => {
  const dispatch = useDispatch();
  const { selected_performance_dashboard_type } = useSelector((state) => state.agency);

  const dashboardTypes = useMemo(
    () => [
      { label: 'Performance Dashboard', value: 'Performance Dashboard' },
      { label: 'Shift Report', value: 'Shift Report' },
      { label: 'Site View', value: 'Site View' }
    ],
    []
  );

  const handleChange = (selectedOption) => {
    // Ensure the selected type is one of the valid options
    const validType = selectedOption?.value;

    if (validType) {
      dispatch(selectPerformanceDashboardType(validType));

      if (onChangeCallback) {
        onChangeCallback(validType);
      }
    }
  };

  // Find the full option object for the current selected value
  const selectedOption = useMemo(
    () => dashboardTypes.find((type) => type.value === selected_performance_dashboard_type) || null,
    [selected_performance_dashboard_type, dashboardTypes]
  );

  return (
    <Dropdown
      label={label}
      placeholder={placeholder}
      options={dashboardTypes}
      onSelect={handleChange}
      value={selectedOption}
      disabled={disabled}
    />
  );
};

export default DashboardTypeDropdown;
