/* eslint-disable default-param-last */

import moment from 'moment';
import { WEEK_RANGE_ACTIONS } from '../../constants';

const initialState = {
  startDate: null,
  endDate: null
};

const weekRangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case WEEK_RANGE_ACTIONS.SET_WEEK_RANGE: {
      // Prevent setting future weeks
      const today = moment().endOf('day');
      const newEndDate = moment(action.payload.endDate);

      if (newEndDate.isAfter(today)) {
        return state;
      }

      return {
        ...state,
        startDate: moment(action.payload.startDate).format('YYYY-MM-DD'),
        endDate: moment(action.payload.endDate).format('YYYY-MM-DD')
      };
    }
    case WEEK_RANGE_ACTIONS.CLEAR_WEEK_RANGE:
      return initialState;
    default:
      return state;
  }
};

export default weekRangeReducer;
