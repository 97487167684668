/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable prettier/prettier */
import React from 'react';

function DemographicsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      version="1"
      viewBox="0 0 840 840">
      <path
        fill='currentColor'
        d="M2705 7630c-152-27-282-123-345-255-147-309 75-665 415-665 349 0 570 365 411 677-86 171-294 275-481 243zM5487 7629c-178-27-340-181-378-360-7-30-9-90-6-137 14-222 197-405 420-419 151-10 262 31 365 133 185 185 183 476-6 656-111 106-247 150-395 127zM2140 6384c-206-44-378-208-432-410-10-38-13-235-13-889v-840l22-41c43-80 116-124 208-124s165 44 208 124c22 41 22 44 27 714 5 652 6 674 24 688 30 21 82 18 106-8 20-22 20-27 20-1403V2814l23-44c42-84 43-85 432-88 337-3 340-3 384 20 25 13 54 36 65 51 21 28 21 35 26 1427 5 1357 6 1399 24 1419 24 27 76 28 107 2l24-19 5-679 5-678 32-45c86-125 267-135 365-21 20 23 41 61 47 84 9 31 11 264 9 877l-3 835-26 67c-74 189-228 321-424 363-100 21-1167 21-1265-1zM5070 6394c-186-31-335-141-424-314-22-41-218-575-469-1275-83-232-100-290-101-340-2-199 224-306 374-178 22 19 49 52 60 73 11 22 115 306 231 632 139 391 218 599 231 611 40 34 109 13 122-38 5-18-61-212-245-722-139-384-256-715-262-736-13-52 13-118 61-155l35-27h878c875 0 878 0 906 21 43 32 73 82 73 124 0 23-96 304-255 749-140 391-255 720-255 730s9 30 21 45c23 29 72 36 103 13 10-8 103-253 237-632 156-439 228-630 248-655 45-57 105-85 181-85 135 1 230 97 230 233 0 42-43 171-243 727-256 710-308 849-339 908-41 77-157 196-223 230-123 62-115 61-655 63-272 1-506 0-520-2zM4985 3733c3-21 30-238 60-483 31-245 60-457 66-471 15-38 60-78 100-89 19-6 176-10 358-10 308 0 327 1 367 21 79 38 78 35 144 561 33 260 60 481 60 491 0 16-34 17-580 17h-580l5-37zM1730 3192c-566-471-998-1117-1214-1812-66-216-71-265-32-325 54-83 190-85 247-5 10 14 41 99 68 190 106 353 241 642 446 950 142 214 251 347 442 540l163 164v193c0 106-3 193-7 193-5 0-55-39-113-88zM6490 3082v-199l81-74c226-203 494-547 664-849 121-215 248-522 315-760 39-137 50-162 90-190 98-70 240 0 240 118 0 83-111 426-210 652-94 214-183 374-326 590-210 316-435 566-739 824-126 107-115 118-115-112z"
        transform="matrix(.1 0 0 -.1 0 840)"></path>
      <path
        fill='currentColor'
        d="M1569 2501c-71-23-109-76-109-153 0-150 201-205 280-76 25 42 26 119 3 160-33 54-115 87-174 69zM6675 2495c-162-58-116-295 57-295 149 0 203 192 78 281-36 26-88 31-135 14zM1950 2298c-90-46-114-159-49-233 38-42 79-59 133-53 143 17 185 193 66 279-42 31-98 33-150 7zM6250 2297c-108-57-108-214 0-270 44-23 120-18 158 10 41 29 75 104 67 146-11 55-42 96-88 117-55 26-85 25-137-3zM2379 2138c-101-58-103-189-5-262 20-15 43-21 81-21 116 0 187 113 136 217-39 81-136 111-212 66zM5806 2137c-73-42-99-141-53-207 37-55 72-75 132-75 58 0 91 18 129 68 28 38 28 125 0 164-54 73-134 92-208 50zM2844 2022c-72-46-98-117-68-190 44-110 170-134 253-48 62 64 51 176-22 228-43 30-122 35-163 10zM5350 2022c-53-29-80-75-80-136 0-125 127-193 237-126 87 53 88 188 1 253-38 29-114 33-158 9zM3345 1946c-37-16-83-68-91-102-17-69 19-149 80-178 72-34 149-12 200 57 29 39 29 125 0 163-48 64-126 89-189 60zM4886 1950c-60-19-106-86-106-152 0-46 38-107 80-129 125-67 271 63 217 193-29 69-119 111-191 88zM3808 1871c-40-36-42-42-46-104-4-65-4-66 35-108 36-38 46-43 95-47 61-5 97 9 138 53 22 23 25 36 25 97 0 69 0 70-42 109-40 36-46 39-103 39s-64-3-102-39zM4347 1892c-87-58-97-169-22-242 30-29 43-34 92-37 46-3 65 0 91 16 96 61 96 202 0 261-43 26-123 27-161 2zM5960 1727c-146-77-82-298 83-284 83 7 139 68 139 152 0 61-28 105-82 133-56 28-86 28-140-1zM2230 1711c-108-57-108-211 0-268 52-28 82-29 137-3 55 25 83 70 83 135 0 120-116 192-220 136zM6126 1300c-101-32-136-163-66-244 39-43 79-60 134-54 143 17 185 193 66 279-30 22-95 31-134 19zM2084 1271c-77-47-100-122-62-198 28-57 61-83 116-90 49-7 117 23 146 64 28 39 32 119 8 163-39 71-142 101-208 61z"
        transform="matrix(.1 0 0 -.1 0 840)"></path>
    </svg>
  );
}

export default DemographicsIcon;
