/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CloseButton, Flex, useToast } from '@chakra-ui/react';
import ClientSideDatatable from '../../components/ClientSideDatatable';
import Loader from '../../components/Loader';
import constants, {
  ADD_SHIFT_VALIDATION,
  PATH,
  TOAST_SETTINGS,
  USER_TYPE,
  PAGE_TITLE,
  CUSTOM_TOAST_DURATION
} from '../../constants';
import { addShift, getShifts, updateShift } from '../../redux/action/shift.action';
import {
  getActivityTopCardData,
  getWorkforceTopCardLOSData,
  getWorkforceTopCardShiftUtiData,
  getWorkforceTopCardPoolUtiData,
  getLeaverTopCardLOS,
  getLeaverTopCardCounts,
  getActivityBottomCardShiftUti,
  getActivityBottomCardHeadCount,
  getActivityBottomCardSpend,
  getActivityBottomCardAverageHours,
  getWorkforceShiftUti,
  getWorkforceLOS,
  getLeaverNSR,
  getLeaverCount,
  getLeaverLOS,
  getLeaverTopCardPoolUti,
  getSiteRatingData,
  getAgencyRatingData,
  getClientRatingData,
  getHeaderData,
  getLeaverAnalysisData
} from '../../redux/action/shiftDashboard.action';
import { getModulePermissions } from '../../utils/hooks';
import ModalComponent from '../../components/Modal';
import {
  deepClone,
  isDashboardBottomCardVisible,
  restrictSomeSpecialCharacter,
  validateForm
} from '../../utils/helper';
import InputComponent from '../../components/Input';
import StyledInputWrapper from '../../components/Forms/Forms.styled';
import StyledSitesContainer from '../Sites/Sites.styled';
import Card from '../../components/Card';
import DashboardTopCard from '../../components/DashboardTopCard';
import DashboardBottomCard from '../../components/DashboardBottomCard';
import DashboardRatingCard from '../../components/DashboardRatingCard';
import Dropdown from '../../components/Dropdown';
import { getRegions } from '../../redux/action/region.action';
import { getSites } from '../../redux/action/site.action';
import { StyledDiv, StyledItem } from './Shift.styled';
import ShiftDropdownHeader from '../../components/Forms/ShiftDropdownHeader';
import { selectPerformanceShift } from '../../redux/actions/performanceDashboard.action';
import { getDepartments } from '../../redux/action/department.action';

const Shift = function () {
  useEffect(() => {
    document.title = PAGE_TITLE.SHIFT_TYPE;
  }, []);

  const toast = useToast();
  const { userData, user_details } = useSelector((state) => state.authentication);
  const {
    selected_client_id,
    selected_performance_region_id,
    selected_performance_site_id,
    selected_start_date,
    selected_end_date,
    is_restricted,
    selected_performance_shift_id,
    selected_performance_shift_name
  } = useSelector((state) => state.agency);
  const permission = getModulePermissions(PATH.SHIFT.CODE);
  const [validations, setValidations] = useState(deepClone(ADD_SHIFT_VALIDATION));
  const [isPageLoader, setIsPageLoader] = useState(false);
  const [shifts, setShifts] = useState([]);
  const [reload, setReload] = useState(false);
  const [inModalOpan, setModalOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [shiftName, setShiftName] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [id, setId] = useState(null);
  const [regionData, setRegionData] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState({});
  const [siteData, setSiteData] = useState([]);
  const [selectedSite, setSelectedSite] = useState({});
  const [shouldShiftsRefresh, setShouldShiftsRefresh] = useState(false);
  const [selectedRegionSiteList, setSelectedRegionSiteList] = useState([]);

  const [association, setAssociations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState({});
  const isFirstRender = useRef(true);

  const dispatch = useDispatch();

  const getClientId = () => {
    switch (user_details.user_type_id) {
      case USER_TYPE.CLIENT_ADMIN:
        return userData.client_id;
      case USER_TYPE.AGENCY_ADMIN:
      case USER_TYPE.AGENCY_REGION_ADMIN:
      case USER_TYPE.AGENCY_SITE_ADMIN:
        return selected_client_id || userData.client_id;
      default:
        return selected_client_id || userData.client_id;
    }
  };

  const getRegionId = () => {
    switch (user_details.user_type_id) {
      case USER_TYPE.SUPER_ADMIN:
        return selected_performance_region_id;
      case USER_TYPE.CLIENT_ADMIN:
        return selected_performance_region_id;
      case USER_TYPE.AGENCY_ADMIN:
        return selected_performance_region_id;
      case USER_TYPE.AGENCY_REGION_ADMIN:
        return user_details.region_id;
      case USER_TYPE.AGENCY_SITE_ADMIN:
        return selected_performance_region_id || userData.region_id;
      case USER_TYPE.SITE_ADMIN:
        return null;
      default:
        return selected_performance_region_id || userData.region_id;
    }
  };

  const getSiteId = () => {
    switch (user_details.user_type_id) {
      case USER_TYPE.SITE_ADMIN:
      case USER_TYPE.AGENCY_SITE_ADMIN:
        return user_details.site_id;
      default:
        return selected_performance_site_id || userData.site_id || null;
    }
  };

  const getShiftData = async () => {
    if (getClientId()) {
      setIsPageLoader(true);
      const result = await getShifts(getClientId(), getSiteId(), getRegionId());

      setIsPageLoader(false);
      if (result && result.ok) {
        setShifts(result.data.shifts);
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
    }
  };

  useEffect(() => {
    getShiftData();
  }, [
    selected_client_id,
    selected_start_date,
    selected_end_date,
    is_restricted,
    selected_performance_shift_id,
    selected_performance_region_id,
    selected_performance_site_id
  ]);

  useEffect(async () => {
    if (user_details.user_type_id !== USER_TYPE.AGENCY_ADMIN) getShiftData();
    if (permission && permission.includes('create')) {
      const regionsData = await getRegions(selected_client_id || userData.client_id);
      if (regionsData && regionsData.ok) {
        setRegionData(regionsData.data?.region_details);
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: regionsData.error
        });
      }
    }
  }, []);

  useEffect(() => {
    const fetchSiteData = async () => {
      try {
        if (selectedRegion && selectedRegion.value) {
          const sites = await getSites(
            selected_client_id || userData.client_id,
            selectedRegion.value
          );

          setSiteData(sites?.data?.sites);
        }
      } catch (error) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: error
        });
      }
    };

    fetchSiteData();
  }, [selectedRegion]);

  useEffect(() => {
    if (selectedRegion.value && selectedSite.value) {
      setSelectedRegionSiteList((prevList) => {
        const newList = [...prevList];
        const existingEntryIndex = newList.findIndex(
          (item) =>
            item[selectedRegion.value] &&
            item[selectedRegion.value].regionId === selectedRegion.value
        );

        if (existingEntryIndex !== -1) {
          // Entry already exists for the selected region ID and region name
          const existingEntry = newList[existingEntryIndex];
          const existingSites = existingEntry[selectedRegion.value].sites;
          const existingSiteIds = existingSites.map((site) => site.siteId);

          // Check if the selected site ID already exists
          if (!existingSiteIds.includes(selectedSite.value)) {
            // Check if the selected site label already exists (optional)
            const existingSiteLabels = existingSites.map((site) => site[selectedSite.value]);
            if (!existingSiteLabels.includes(selectedSite.label)) {
              // Add the new site to the existing entry
              existingEntry[selectedRegion.value].sites.push({
                [selectedSite.value]: selectedSite.label
              });
              // Sort the sites array based on siteId for consistency
              existingEntry[selectedRegion.value].sites.sort(
                (a, b) => parseInt(a.siteId, 10) - parseInt(b.siteId, 10)
              );
              newList[existingEntryIndex] = existingEntry;
            }
          }
        } else {
          // Entry doesn't exist, create a new one
          newList.push({
            [selectedRegion.value]: {
              region: selectedRegion.label,
              regionId: selectedRegion.value,
              sites: [
                {
                  [selectedSite.value]: selectedSite.label
                }
              ]
            }
          });
        }

        return newList;
      });

      setSelectedSite({});
    }
  }, [selectedRegion, selectedSite, selectedRegionSiteList]);

  const addNewAssociation = (newAssociation) => {
    setAssociations((prevAssociations) => {
      const existingAssociations = [...prevAssociations];

      // Check if the new association already exists
      const existingIndex = existingAssociations.findIndex(
        (assoc) =>
          assoc.siteId === newAssociation.siteId && assoc.regionId === newAssociation.regionId
      );

      if (existingIndex === -1) {
        // Add the new association
        existingAssociations.push(newAssociation);
      } else {
        // Update the existing association (if needed)
        existingAssociations[existingIndex] = newAssociation;
      }

      return existingAssociations;
    });
  };

  useEffect(() => {
    if (selectedRegion.value && selectedSite.value) {
      const newAssociation = {
        siteId: selectedSite.value,
        siteName: selectedSite.label,
        regionId: selectedRegion.value,
        regionName: selectedRegion.label
      };
      addNewAssociation(newAssociation);
    }
  }, [selectedRegion, selectedSite]);

  const [column, setColumn] = useState([
    {
      label: 'Name',
      field: 'name',
      sort: false
    }
  ]);

  const onModalClose = () => {
    setShiftName('');
    setModalOpen(false);
    setEdit(false);
    setValidations(deepClone(ADD_SHIFT_VALIDATION));
    setSelectedRegion('');
    setSelectedSite('');
    setSelectedRegionSiteList('');
    setSiteData([]);
  };

  const editData = (rowId, row) => {
    setEdit(true);
    setShiftName(row.name);
    setAssociations(row.associations);
    setModalOpen(true);
    setId(rowId);
  };

  useEffect(() => {
    if (permission && permission.includes('edit')) {
      setColumn([
        ...column,
        {
          label: 'Action',
          field: 'id',
          sort: false,
          customRow: (cell, row) => {
            return (
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={() => editData(cell, row)}>
                {constants.SHIFT.EDIT_BUTTON_TEXT}
              </Button>
            );
          }
        }
      ]);
    }
  }, [permission]);

  const saveShift = async () => {
    let shiftData = shiftName;
    shiftData = shiftData.trim();

    const [validationObject, isFormValid] = validateForm({ name: shiftData }, validations);
    setValidations(validationObject);
    if (isFormValid) {
      let result = null;
      if (edit) {
        const modifiedData = association.map((item) => {
          const { siteId, regionId, siteName, regionName, ...rest } = item;
          return {
            ...rest,
            site_id: siteId,
            region_id: regionId
          };
        });
        result = await updateShift(id, { name: shiftData, associations: modifiedData });

        if (result && result.ok) {
          if (id === selected_performance_shift_id) {
            await setShouldShiftsRefresh((prev) => !prev);
            dispatch(selectPerformanceShift(selected_performance_shift_id, shiftData));
          }
          getShiftData();
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            duration: CUSTOM_TOAST_DURATION.duration,
            status: TOAST_SETTINGS.SUCCESS,
            title: result.message
          });
          setEdit(false);
          onModalClose();
        } else {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            status: TOAST_SETTINGS.ERROR,
            title: result.error
          });
        }
      } else {
        let addAssociation = [];
        if (selectedRegionSiteList) {
          addAssociation = selectedRegionSiteList.flatMap((data) => {
            // Initialize an array to store associations
            const regionAssociations = [];

            // Iterate through each region and its sites
            Object.values(data).forEach((regionInfo) => {
              const { regionId, region, sites } = regionInfo;

              // Iterate through each site in the region
              sites.forEach((site) => {
                const siteId = Object.keys(site)[0];
                const siteName = Object.values(site)[0];

                // Create a unique association object and add it to the array
                regionAssociations.push({
                  site_id: siteId,
                  region_id: regionId,
                  region_name: region,
                  site_name: siteName
                });
              });
            });

            return regionAssociations;
          });
        }
        result = await addShift({ name: shiftData, associations: addAssociation });
        if (result && result.ok) {
          getShiftData();
          setId(result.data?.site_id);
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            duration: CUSTOM_TOAST_DURATION.duration,
            status: TOAST_SETTINGS.SUCCESS,
            title: result.message
          });
          onModalClose();
          setEdit(false);
        } else {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            status: TOAST_SETTINGS.ERROR,
            title: result.error
          });
        }
      }
      setReload(!reload);
    } else {
      setRefresh(!refresh);
      setReload(!reload);
    }
  };

  const [isCurrentWeek, setIsCurrentWeek] = useState(true);
  useEffect(() => {
    setIsCurrentWeek(() => isDashboardBottomCardVisible(selected_start_date, selected_end_date));
  }, [selected_start_date, selected_end_date]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [headerData, setHeaderData] = useState(null);
  const [ratingData, setratingData] = useState(null);
  const [siteRatingData, setSiteRatingData] = useState(null);
  const [agencyRatingData, setAgencyRatingData] = useState(null);
  const [clientRatingData, setClientRatingData] = useState(null);
  const [leaversData, setLeaversData] = useState(null);

  const [activityTopCardData, setActivityTopCardData] = useState(null);
  const [workforceTopCardLOS, setWorkforceTopCardLOS] = useState(null);
  const [workforceTopCardShiftUti, setWorkforceTopCardShiftUti] = useState(null);
  const [workforceTopCardPoolUti, setWorkforceTopCardPoolUti] = useState(null);
  const [leaverTopCardLos, setLeaverTopCardLos] = useState(null);
  const [leaverTopCardCounts, setLeaverTopCardCounts] = useState(null);
  const [leaversTopCardPoolUti, setLeaversTopCardPoolUti] = useState(null);

  const [activityBottomCardShiftUtil, setActivityBottomCardShiftUtil] = useState(null);
  const [activityBottomCardHeadCount, setActivityBottomCardHeadCount] = useState(null);
  const [activityBottomCardSpend, setActivityBottomCardSpend] = useState(null);
  const [activityBottomCardAverageHours, setActivityBottomCardAverageHours] = useState(null);

  const [workforceShiftUti, setWorkforceShiftUti] = useState(null);
  const [workforceLOS, setWorkforceLOS] = useState(null);

  const [leaverNSR, setLeaverNSR] = useState(null);
  const [leaverCount, setLeaverCount] = useState(null);
  const [leaverLOS, setLeaverLOS] = useState(null);
  const [regionIdForDashboard, setRegionIdForDashboard] = useState(null);

  const resetData = () => {
    setAgencyRatingData(null);
    setClientRatingData(null);
    setSiteRatingData(null);
    setActivityTopCardData(null);
    setWorkforceTopCardLOS(null);
    setWorkforceTopCardShiftUti(null);
    setWorkforceTopCardPoolUti(null);
    setLeaverTopCardLos(null);
    setLeaverTopCardCounts(null);
    setActivityBottomCardShiftUtil(null);
    setActivityBottomCardHeadCount(null);
    setActivityBottomCardSpend(null);
    setActivityBottomCardAverageHours(null);
    setWorkforceShiftUti(null);
    setWorkforceLOS(null);
    setLeaverNSR(null);
    setLeaverCount(null);
    setLeaverLOS(null);
  };

  const getSiteRatingDataFunction = async (regionId, departmentId) => {
    const rating = await getSiteRatingData(
      userData.client_id || selected_client_id,
      regionId,
      departmentId
    );
    if (rating && rating.ok) {
      setSiteRatingData(rating.data);
    }
  };

  const getAgencyRatingDataFunction = async () => {
    const rating = await getAgencyRatingData(
      userData.client_id || selected_client_id,
      user_details.agency_id
    );
    if (rating && rating.ok) {
      setAgencyRatingData(rating.data);
    }
  };

  const getClientRatingDataFunction = async () => {
    const rating = await getClientRatingData(userData.client_id || selected_client_id);
    if (rating && rating.ok) {
      setClientRatingData(rating.data);
    }
  };

  const getLeaverAnalysisDataFunction = async (regionId, startDate, endDate, departmentId) => {
    const leaversAnalysis = await getLeaverAnalysisData(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? userData.agency_id : null,
      departmentId
    );
    if (leaversAnalysis && leaversAnalysis.ok) {
      setLeaversData(leaversAnalysis.data);
    }
  };

  const getHeaderDataFunction = async (regionId, startDate, endDate, departmentId) => {
    const header = await getHeaderData(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? userData.agency_id : null,
      departmentId
    );
    if (header && header.ok) {
      setHeaderData(header.data);
    }
  };

  const getActivityTopCardDataFunction = async (regionId, startDate, endDate, departmentId) => {
    const activityTopCard = await getActivityTopCardData(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
        ? userData.agency_id
        : null,
      departmentId
    );
    if (activityTopCard && activityTopCard.ok) {
      setActivityTopCardData(activityTopCard.data);
    }
  };

  const getWorkforceTopCardLOSDataFunction = async (regionId, startDate, endDate, departmentId) => {
    const WorkforceTopCard = await getWorkforceTopCardLOSData(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
        ? userData.agency_id
        : null,
      departmentId
    );
    if (WorkforceTopCard && WorkforceTopCard.ok) {
      setWorkforceTopCardLOS(WorkforceTopCard.data);
    }
  };

  const getWorkforceTopCardShiftUtiDataFunction = async (
    regionId,
    startDate,
    endDate,
    departmentId
  ) => {
    const WorkforceTopCard = await getWorkforceTopCardShiftUtiData(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
        ? userData.agency_id
        : null,
      departmentId
    );
    if (WorkforceTopCard && WorkforceTopCard.ok) {
      setWorkforceTopCardShiftUti(WorkforceTopCard.data);
    }
  };

  const getWorkforceTopCardPoolUtiDataFunction = async (
    regionId,
    startDate,
    endDate,
    departmentId
  ) => {
    const WorkforceTopCard = await getWorkforceTopCardPoolUtiData(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
        ? userData.agency_id
        : null,
      departmentId
    );
    if (WorkforceTopCard && WorkforceTopCard.ok) {
      setWorkforceTopCardPoolUti(WorkforceTopCard.data);
    }
  };

  const getLeaverTopCardLOSFunction = async (regionId, startDate, endDate, departmentId) => {
    const leaverTopCardData = await getLeaverTopCardLOS(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
        ? userData.agency_id
        : null,
      departmentId
    );
    if (leaverTopCardData && leaverTopCardData.ok) {
      setLeaverTopCardLos(leaverTopCardData.data);
    }
  };

  const getLeaverTopCardCountsFunction = async (regionId, startDate, endDate, departmentId) => {
    const leaverTopCardData = await getLeaverTopCardCounts(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
        ? userData.agency_id
        : null,
      departmentId
    );
    if (leaverTopCardData && leaverTopCardData.ok) {
      setLeaverTopCardCounts(leaverTopCardData.data);
    }
  };

  const getLeaverTopCardPoolUtiFunction = async (regionId, startDate, endDate, departmentId) => {
    const leaverTopCardData = await getLeaverTopCardPoolUti(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
        ? userData.agency_id
        : null,
      departmentId
    );
    if (leaverTopCardData && leaverTopCardData.ok) {
      setLeaversTopCardPoolUti(leaverTopCardData.data);
    }
  };

  const getActivityBottomCardShiftUtiFunction = async (
    regionId,
    startDate,
    endDate,
    departmentId
  ) => {
    const bottomDeckData = await getActivityBottomCardShiftUti(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      departmentId
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardShiftUtil(bottomDeckData?.data);
    }
  };

  const getActivityBottomCardHeadCountFunction = async (
    regionId,
    startDate,
    endDate,
    departmentId
  ) => {
    const bottomDeckData = await getActivityBottomCardHeadCount(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      departmentId
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardHeadCount(bottomDeckData?.data);
    }
  };

  const getActivityBottomCardSpendFunction = async (regionId, startDate, endDate, departmentId) => {
    const bottomDeckData = await getActivityBottomCardSpend(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      departmentId
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardSpend(bottomDeckData?.data);
    }
  };

  const getActivityBottomCardAverageHoursFunction = async (
    regionId,
    startDate,
    endDate,
    departmentId
  ) => {
    const bottomDeckData = await getActivityBottomCardAverageHours(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      departmentId
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardAverageHours(bottomDeckData?.data);
    }
  };

  const getWorkforceShiftUtiFunction = async (regionId, startDate, endDate, departmentId) => {
    const bottomDeckData = await getWorkforceShiftUti(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      departmentId
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setWorkforceShiftUti(bottomDeckData?.data);
    }
  };

  const getWorkforceLOSFunction = async (regionId, startDate, endDate, departmentId) => {
    const bottomDeckData = await getWorkforceLOS(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      departmentId
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setWorkforceLOS(bottomDeckData?.data);
    }
  };

  const getLeaverNSRFunction = async (regionId, startDate, endDate, departmentId) => {
    const bottomDeckData = await getLeaverNSR(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      departmentId
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setLeaverNSR(bottomDeckData?.data);
    }
  };

  const getLeaverCountFunction = async (regionId, startDate, endDate, departmentId) => {
    const bottomDeckData = await getLeaverCount(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      departmentId
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setLeaverCount(bottomDeckData?.data);
    }
  };

  const getLeaverLOSFunction = async (regionId, startDate, endDate, departmentId) => {
    const bottomDeckData = await getLeaverLOS(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      departmentId
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setLeaverLOS(bottomDeckData?.data);
    }
  };

  const getChartData = (siteId, startDate, endDate, departmentId) => {
    if (
      (selected_client_id || userData.client_id || user_details.client_id) &&
      isDashboardBottomCardVisible(selected_start_date, selected_end_date)
    ) {
      if (is_restricted && user_details.user_type_id === USER_TYPE.AGENCY_ADMIN) {
        Promise.all([
          getHeaderDataFunction(siteId, startDate, endDate, departmentId),
          getSiteRatingDataFunction(siteId, departmentId),
          // getAgencyRatingDataFunction(),
          // getClientRatingDataFunction(),
          getLeaverAnalysisDataFunction(siteId, startDate, endDate, departmentId)
        ]);
      } else {
        Promise.all([
          getHeaderDataFunction(siteId, startDate, endDate, departmentId),
          getSiteRatingDataFunction(siteId, departmentId),
          // getAgencyRatingDataFunction(),
          // getClientRatingDataFunction(),
          getLeaverAnalysisDataFunction(siteId, startDate, endDate, departmentId),
          getActivityTopCardDataFunction(siteId, startDate, endDate, departmentId),
          getWorkforceTopCardLOSDataFunction(siteId, startDate, endDate, departmentId),
          getWorkforceTopCardShiftUtiDataFunction(siteId, startDate, endDate, departmentId),
          getWorkforceTopCardPoolUtiDataFunction(siteId, startDate, endDate, departmentId),
          getLeaverTopCardLOSFunction(siteId, startDate, endDate, departmentId),
          getLeaverTopCardCountsFunction(siteId, startDate, endDate, departmentId),
          getActivityBottomCardShiftUtiFunction(siteId, startDate, endDate, departmentId),
          getActivityBottomCardSpendFunction(siteId, startDate, endDate, departmentId),
          getActivityBottomCardHeadCountFunction(siteId, startDate, endDate, departmentId),
          getActivityBottomCardAverageHoursFunction(siteId, startDate, endDate, departmentId),
          getWorkforceShiftUtiFunction(siteId, startDate, endDate, departmentId),
          getWorkforceLOSFunction(siteId, startDate, endDate, departmentId),
          getLeaverNSRFunction(siteId, startDate, endDate, departmentId),
          getLeaverCountFunction(siteId, startDate, endDate, departmentId),
          getLeaverLOSFunction(siteId, startDate, endDate, departmentId)
        ]);
      }
    } else if (selected_client_id || userData.client_id) {
      Promise.all([
        getSiteRatingDataFunction(siteId, departmentId),
        // getAgencyRatingDataFunction(),
        // getClientRatingDataFunction(),
        getActivityTopCardDataFunction(siteId, startDate, endDate, departmentId),
        getWorkforceTopCardLOSDataFunction(siteId, startDate, endDate, departmentId),
        getWorkforceTopCardShiftUtiDataFunction(siteId, startDate, endDate, departmentId),
        getWorkforceTopCardPoolUtiDataFunction(siteId, startDate, endDate, departmentId),
        getLeaverTopCardLOSFunction(siteId, startDate, endDate, departmentId),
        getLeaverTopCardPoolUtiFunction(siteId, startDate, endDate, departmentId)
      ]);
    }
  };

  const deleteEntry = (regionId, region, site) => {
    setSelectedRegionSiteList((prevList) => {
      const newList = [...prevList];
      const index = newList.findIndex((item) => Object.keys(item)[0] === regionId);

      if (index !== -1) {
        const entry = newList[index][regionId];
        entry.sites = entry.sites.filter((s) => s !== site);

        if (entry.sites.length === 0) {
          newList.splice(index, 1);
        } else {
          newList[index][regionId] = entry;
        }
        return newList;
      }
      return prevList;
    });
  };

  const deleteEditEntry = (regionId, siteId) => {
    setAssociations((prevAssociations) => {
      return prevAssociations.filter((assoc) => {
        if (assoc.regionId === regionId && assoc.siteId === siteId) {
          // Remove the entry if regionId and siteId match
          return false;
        }
        return true;
      });
    });
  };
  useEffect(() => {
    if (departments.length > 0) {
      isFirstRender.current = true;
      setSelectedDepartment({ label: departments[0].name, value: departments[0].id });
    }
  }, [departments]);

  useEffect(() => {
    if (Object.keys(selectedDepartment).length === 0) return;

    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (selected_performance_shift_id && Object.keys(selectedDepartment).length > 0) {
      getChartData(
        selected_performance_shift_id,
        selected_start_date,
        selected_end_date,
        selectedDepartment.value ?? null
      );
    }
  }, [selectedDepartment]);

  const getDepartmentList = async () => {
    const clientId = getClientId();
    const siteId = getSiteId();

    const result = await getDepartments(clientId, siteId);
    if (result.data.ok && result.ok && result.data.count !== 0) {
      setDepartments([{ id: '', name: 'All Departments' }, ...result.data.department_list]);
    }
  };

  useEffect(() => {
    getDepartmentList();
    if (selected_performance_shift_id) {
      resetData();
      setRegionIdForDashboard(selected_performance_shift_id);
      getChartData(selected_performance_shift_id, selected_start_date, selected_end_date, null);
    } else {
      resetData();
    }
  }, [selected_performance_shift_id, selected_start_date, selected_end_date]);

  useEffect(() => {
    getAgencyRatingDataFunction();
    getClientRatingDataFunction();
  }, []);

  return (
    <>
      <Box mb={5}>
        <Card>
          {user_details.user_type_id !== USER_TYPE.SUPER_ADMIN && (
            <Flex>
              <Box width="12rem" mx="15px">
                <ShiftDropdownHeader refresh={shouldShiftsRefresh} />
              </Box>
              <Box width="12rem" mx="15px">
                <Dropdown
                  label="Department"
                  onSelect={(e) => {
                    setSelectedDepartment(e);
                  }}
                  options={departments.map((department) => {
                    return { label: department.name, value: department.id };
                  })}
                  value={selectedDepartment}
                  placeholder="Select"
                />
              </Box>
            </Flex>
          )}
        </Card>

        {is_restricted && user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? (
          <DashboardRatingCard
            siteRatingData={siteRatingData}
            agencyRatingData={agencyRatingData}
            clientRatingData={clientRatingData}
            headerData={headerData}
            ratingData={ratingData}
            leaversData={leaversData}
            regionId={regionIdForDashboard}
          />
        ) : (
          <>
            <Box>
              <DashboardTopCard
                activityTopCardData={activityTopCardData}
                workforceTopCardPoolUti={workforceTopCardPoolUti}
                workforceTopCardShiftUti={workforceTopCardShiftUti}
                workforceTopCardLOS={workforceTopCardLOS}
                leaverTopCardLos={leaverTopCardLos}
                leaversTopCardPoolUti={leaversTopCardPoolUti}
                leaverTopCardCounts={leaverTopCardCounts}
                isDataAvailable={!!(shifts && shifts.length > 0)}
              />
            </Box>

            {isCurrentWeek && (
              <Box mt={10}>
                <DashboardBottomCard
                  activityBottomCardShiftUtil={activityBottomCardShiftUtil}
                  activityBottomCardSpend={activityBottomCardSpend}
                  activityBottomCardAverageHours={activityBottomCardAverageHours}
                  workforceLOS={workforceLOS}
                  leaverLOS={leaverLOS}
                  leaverCount={leaverCount}
                  leaverNSR={leaverNSR}
                  activityBottomCardHeadCount={activityBottomCardHeadCount}
                  workforceShiftUti={workforceShiftUti}
                  isDataAvailable={!!(shifts && shifts.length > 0)}
                />
              </Box>
            )}
          </>
        )}
      </Box>
      {is_restricted && user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? (
        <Box></Box>
      ) : (
        <StyledSitesContainer>
          {isPageLoader ? <Loader /> : null}
          <Card>
            {permission && permission.includes('create') && (
              <>
                <Button
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  onClick={() => setModalOpen(true)}>
                  Add Shift
                </Button>
                <ModalComponent
                  isOpen={inModalOpan}
                  onClose={() => onModalClose()}
                  modalTitle={edit ? constants.SHIFT.EDIT_HEADER_TEXT : constants.SHIFT.HEADER_TEXT}
                  modalContent={
                    <Box p="10px">
                      <Flex>
                        <StyledInputWrapper>
                          <InputComponent
                            isRequired
                            lable={constants.NAME}
                            placeholder={constants.NAME}
                            onChange={(e) => setShiftName(restrictSomeSpecialCharacter(e))}
                            value={shiftName}
                            validationObj={validations.name}
                            refresh={refresh}
                          />
                          <StyledInputWrapper>
                            <Dropdown
                              label="Region"
                              onSelect={(e) => {
                                setSelectedRegion(e);
                              }}
                              options={regionData.map((reg) => {
                                return { label: reg.name, value: reg.id };
                              })}
                              value={selectedRegion}
                              placeholder=""
                            />
                          </StyledInputWrapper>
                          <StyledInputWrapper>
                            <Dropdown
                              label="Site"
                              onSelect={(e) => {
                                setSelectedSite(e);
                              }}
                              options={siteData.map((site) => {
                                return { label: site.name, value: site.id };
                              })}
                              value={selectedSite}
                              placeholder=""
                            />
                          </StyledInputWrapper>

                          {!edit && (
                            <StyledDiv>
                              {selectedRegionSiteList.length > 0 &&
                                selectedRegionSiteList.map((item) => {
                                  const [regionId] = Object.keys(item);
                                  const { region, sites } = item[regionId];

                                  return (
                                    <div
                                      key={regionId}
                                      style={{ display: 'flex', flexWrap: 'wrap' }}>
                                      {sites.map((site) => (
                                        <StyledItem
                                          key={`${regionId}-${Object.keys(site)[0]}`}
                                          style={{ fontSize: '12px' }}>
                                          {region} || {Object.values(site)[0]}
                                          <CloseButton
                                            onClick={() => {
                                              deleteEntry(regionId, region, site);
                                            }}
                                            ml={2}
                                            size="sm"
                                          />
                                        </StyledItem>
                                      ))}
                                    </div>
                                  );
                                })}
                            </StyledDiv>
                          )}

                          {edit && (
                            <StyledDiv>
                              {association &&
                                association?.map((data, index) => {
                                  return (
                                    <div
                                      key={data.regionId}
                                      style={{ display: 'flex', flexWrap: 'wrap' }}>
                                      <StyledItem style={{ fontSize: '12px' }}>
                                        {`${data.regionName} || ${data.siteName}`}
                                        <CloseButton
                                          onClick={() => {
                                            deleteEditEntry(data.regionId, data.siteId);
                                          }}
                                          ml={2}
                                          size="sm"
                                        />
                                      </StyledItem>
                                    </div>
                                  );
                                })}
                            </StyledDiv>
                          )}
                        </StyledInputWrapper>
                      </Flex>
                    </Box>
                  }
                  negative={
                    <Button
                      onClick={() => onModalClose()}
                      bg="main.secondary"
                      mr={3}
                      _hover={{ bg: 'main.secondary' }}
                      color="main.black">
                      {constants.SHIFT.CANCEL_BUTTON_TEXT}
                    </Button>
                  }
                  positive={
                    <Button
                      onClick={() => saveShift()}
                      bg="main.semiPrimary"
                      color="main.white"
                      _hover={{ bg: 'main.primary' }}
                      _active={{
                        bg: 'main.semiPrimary',
                        borderColor: 'main.primary'
                      }}>
                      {edit
                        ? constants.SHIFT.EDIT_UPDATE_BUTTON_TEXT
                        : constants.SHIFT.SAVE_BUTTON_TEXT}
                    </Button>
                  }
                />
              </>
            )}

            <ClientSideDatatable
              pageSize={10}
              initialSortKey="id"
              column={column}
              row={shifts}
              pagination
              pages={[2, 5, 10, 15]}
              refresh={reload}
            />
          </Card>
        </StyledSitesContainer>
      )}
    </>
  );
};

Shift.propTypes = {};

export default Shift;
