/* eslint-disable no-underscore-dangle */
/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Center, Flex, Text, Tooltip, useMediaQuery } from '@chakra-ui/react';
import moment from 'moment';
import DashboardRatingCard from '../../components/DashboardRatingCard';
import Card from '../../components/Card';
import { PAGE_TITLE, USER_TYPE } from '../../constants';
import {
  getHeaderData,
  getLeaverAnalysisData,
  getRatingData,
  getActivityTopCardData,
  // getWorkforceTopCardDemographicsData,
  getWorkforceTopCardLOSData,
  getWorkforceTopCardShiftUtiData,
  getWorkforceTopCardPoolUtiData,
  getLeaverTopCardLOS,
  getLeaverTopCardCounts,
  // getLeaverTopCardShiftUti,
  getActivityBottomCardShiftUti,
  // getActivityBottomCardLostShift,
  getActivityBottomCardHeadCount,
  getActivityBottomCardSpend,
  getActivityBottomCardAverageHours,
  // getWorkforceDemo,
  getWorkforceShiftUti,
  getWorkforceLOS,
  getLeaverNSR,
  getLeaverCount,
  // getLeaverShiftUtl,
  getLeaverLOS,
  getLeaverTopCardPoolUti,
  getSiteRatingData,
  getAgencyRatingData,
  getClientRatingData
  // getSiteRatingDataDetails,
  // getAgencyRatingDataDetails
} from '../../redux/action/agenchDashboard.action';
import { colors } from '../../theme';
import DashboardTopCard from '../../components/DashboardTopCard';
import DashboardBottomCard from '../../components/DashboardBottomCard';
import { currancyFormatter, isDashboardBottomCardVisible } from '../../utils/helper';

const AgencyDashboard = function () {
  useEffect(() => {
    document.title = PAGE_TITLE.DASHBOARD;
  }, []);

  const { selected_client_id, selected_start_date, selected_end_date, is_restricted } = useSelector(
    (state) => state.agency
  );
  const [ratingData, setRatingData] = useState(null);
  const [siteRatingData, setSiteRatingData] = useState(null);
  // const [siteRatingDataDetails, setSiteRatingDataDetails] = useState(null);
  const [agencyRatingData, setAgencyRatingData] = useState(null);
  // const [agencyRatingDataDetails, setAgencyRatingDataDetails] = useState(null);
  const [clientRatingData, setClientRatingData] = useState(null);
  const [headerData, setHeaderData] = useState(null);
  const [leaversData, setLeaversData] = useState(null);
  const [activityTopCardData, setActivityTopCardData] = useState(null);
  // const [workforceTopCardDemographics, setWorkforceTopCardDemographics] = useState(null);
  const [workforceTopCardLOS, setWorkforceTopCardLOS] = useState(null);
  const [workforceTopCardShiftUti, setWorkforceTopCardShiftUti] = useState(null);
  const [workforceTopCardPoolUti, setWorkforceTopCardPoolUti] = useState(null);
  const [leaverTopCardLos, setLeaverTopCardLos] = useState(null);
  const [leaverTopCardCounts, setLeaverTopCardCounts] = useState(null);
  const [leaversTopCardPoolUti, setLeaversTopCardPoolUti] = useState(null);

  const [activityBottomCardShiftUtil, setActivityBottomCardShiftUtil] = useState(null);
  // const [activityBottomCardLostShift, setActivityBottomCardLostShift] = useState(null);
  const [activityBottomCardHeadCount, setActivityBottomCardHeadCount] = useState(null);
  const [activityBottomCardSpend, setActivityBottomCardSpend] = useState(null);
  const [activityBottomCardAverageHours, setActivityBottomCardAverageHours] = useState(null);

  // const [workforceDemo, setWorkforceDemo] = useState(null);
  const [workforceShiftUti, setWorkforceShiftUti] = useState(null);
  const [workforceLOS, setWorkforceLOS] = useState(null);

  const [leaverNSR, setLeaverNSR] = useState(null);
  const [leaverCount, setLeaverCount] = useState(null);
  // const [leaverShiftUtl, setLeaverShiftUtl] = useState(null);
  const [leaverLOS, setLeaverLOS] = useState(null);
  const { user_details: userDetails } = useSelector((state) => state.authentication);
  // const [isSmallerThan1440] = useMediaQuery('(max-width: 1440px)');

  useEffect(async () => {
    if ((selected_client_id, selected_start_date, selected_end_date))
      getChartData(selected_start_date, selected_end_date);
  }, []);

  useEffect(async () => {
    if ((selected_client_id, selected_start_date, selected_end_date)) {
      resetData();
      getChartData(selected_start_date, selected_end_date);
    }
  }, [selected_client_id]);

  const [isCurrentWeek, setIsCurrentWeek] = useState(true);
  useEffect(async () => {
    setIsCurrentWeek(() => isDashboardBottomCardVisible(selected_start_date, selected_end_date));
    resetData();
    getChartData(selected_start_date, selected_end_date);
  }, [selected_start_date, selected_end_date]);

  const resetData = () => {
    setAgencyRatingData(null);
    setClientRatingData(null);
    setSiteRatingData(null);
    setActivityTopCardData(null);
    setWorkforceTopCardLOS(null);
    setWorkforceTopCardShiftUti(null);
    setWorkforceTopCardPoolUti(null);
    setLeaverTopCardLos(null);
    setLeaversTopCardPoolUti(null);
    setLeaverTopCardCounts(null);
    setActivityBottomCardShiftUtil(null);
    setActivityBottomCardHeadCount(null);
    setActivityBottomCardSpend(null);
    setActivityBottomCardAverageHours(null);
    setWorkforceShiftUti(null);
    setWorkforceLOS(null);
    setLeaverNSR(null);
    setLeaverCount(null);
    setLeaverLOS(null);
  };

  const getChartData = (startDate, endDate) => {
    if (
      selected_client_id &&
      isDashboardBottomCardVisible(selected_start_date, selected_end_date)
    ) {
      if (is_restricted) {
        Promise.all([
          getHeaderDataFunction(startDate, endDate),
          getSiteRatingDataFunction(startDate, endDate),
          getAgencyRatingDataFunction(startDate, endDate),
          getClientRatingDataFunction(startDate, endDate),
          getLeaverAnalysisDataFunction(startDate, endDate)
        ]);
      } else {
        Promise.all([
          // getRatingDataFunction(startDate, endDate),
          getSiteRatingDataFunction(startDate, endDate),
          // getSiteRatingDataDetailsFunction(startDate, endDate),
          getAgencyRatingDataFunction(startDate, endDate),
          // getAgencyRatingDataDetailsFunction(startDate, endDate),
          getClientRatingDataFunction(startDate, endDate),
          getHeaderDataFunction(startDate, endDate),
          getLeaverAnalysisDataFunction(startDate, endDate),
          getActivityTopCardDataFunction(startDate, endDate),
          getWorkforceTopCardLOSDataFunction(startDate, endDate),
          getWorkforceTopCardShiftUtiDataFunction(startDate, endDate),
          getWorkforceTopCardPoolUtiDataFunction(startDate, endDate),
          getLeaverTopCardLOSFunction(startDate, endDate),
          getLeaverTopCardCountsFunction(startDate, endDate),
          getActivityBottomCardShiftUtiFunction(startDate, endDate),
          getActivityBottomCardHeadCountFunction(startDate, endDate),
          getActivityBottomCardSpendFunction(startDate, endDate),
          getActivityBottomCardAverageHoursFunction(startDate, endDate),
          getWorkforceShiftUtiFunction(startDate, endDate),
          getWorkforceLOSFunction(startDate, endDate),
          getLeaverNSRFunction(startDate, endDate),
          getLeaverCountFunction(startDate, endDate),
          getLeaverLOSFunction(startDate, endDate)
          // getLeaverTopCardPoolUtiFunction(startDate, endDate)
        ]);
      }
    } else if (selected_client_id) {
      Promise.all([
        // getRatingDataFunction(startDate, endDate),
        getSiteRatingDataFunction(startDate, endDate),
        // getSiteRatingDataDetailsFunction(startDate, endDate),
        getAgencyRatingDataFunction(startDate, endDate),
        // getAgencyRatingDataDetailsFunction(startDate, endDate),
        getClientRatingDataFunction(startDate, endDate),
        getHeaderDataFunction(startDate, endDate),
        getLeaverAnalysisDataFunction(startDate, endDate),
        getActivityTopCardDataFunction(startDate, endDate),
        getWorkforceTopCardLOSDataFunction(startDate, endDate),
        getWorkforceTopCardShiftUtiDataFunction(startDate, endDate),
        getWorkforceTopCardPoolUtiDataFunction(startDate, endDate),
        getLeaverTopCardLOSFunction(startDate, endDate),
        getLeaverTopCardCountsFunction(startDate, endDate)
        // getLeaverTopCardPoolUtiFunction(startDate, endDate)
      ]);
    }
  };

  const getRatingDataFunction = async () => {
    const rating = await getRatingData(selected_client_id, selected_start_date, selected_end_date);
    if (rating && rating.ok) {
      setRatingData(rating.data.result);
    }
  };

  const getSiteRatingDataFunction = async () => {
    const rating = await getSiteRatingData(selected_client_id);
    if (rating && rating.ok) {
      setSiteRatingData(rating.data);
    }
  };

  const getAgencyRatingDataFunction = async () => {
    const rating = await getAgencyRatingData(selected_client_id, userDetails.agency_id);
    if (rating && rating.ok) {
      setAgencyRatingData(rating.data);
    }
  };

  const getClientRatingDataFunction = async () => {
    const rating = await getClientRatingData(selected_client_id);
    if (rating && rating.ok) {
      setClientRatingData(rating.data);
    }
  };

  const getHeaderDataFunction = async () => {
    const header = await getHeaderData(
      selected_client_id,
      userDetails.agency_id,
      selected_start_date,
      selected_end_date
    );
    if (header && header.ok) {
      setHeaderData(header.data);
    }
  };

  const getLeaverAnalysisDataFunction = async () => {
    const leaverAnalysis = await getLeaverAnalysisData(
      selected_client_id,
      userDetails.agency_id,
      selected_start_date,
      selected_end_date
    );
    if (leaverAnalysis && leaverAnalysis.ok) {
      setLeaversData(leaverAnalysis.data);
    }
  };

  const getActivityTopCardDataFunction = async () => {
    const activityTopCard = await getActivityTopCardData(
      selected_client_id,
      userDetails.agency_id,
      selected_start_date,
      selected_end_date
    );
    if (activityTopCard && activityTopCard.ok) {
      setActivityTopCardData(activityTopCard.data);
    }
  };

  const getWorkforceTopCardLOSDataFunction = async () => {
    const WorkforceTopCard = await getWorkforceTopCardLOSData(
      selected_client_id,
      userDetails.agency_id,
      selected_start_date,
      selected_end_date
    );
    if (WorkforceTopCard && WorkforceTopCard.ok) {
      setWorkforceTopCardLOS(WorkforceTopCard.data);
    }
  };

  const getWorkforceTopCardShiftUtiDataFunction = async () => {
    const WorkforceTopCard = await getWorkforceTopCardShiftUtiData(
      selected_client_id,
      userDetails.agency_id,
      selected_start_date,
      selected_end_date
    );
    if (WorkforceTopCard && WorkforceTopCard.ok) {
      setWorkforceTopCardShiftUti(WorkforceTopCard.data);
    }
  };

  const getWorkforceTopCardPoolUtiDataFunction = async () => {
    const WorkforceTopCard = await getWorkforceTopCardPoolUtiData(
      selected_client_id,
      userDetails.agency_id,
      selected_start_date,
      selected_end_date
    );
    if (WorkforceTopCard && WorkforceTopCard.ok) {
      setWorkforceTopCardPoolUti(WorkforceTopCard.data);
    }
  };

  const getLeaverTopCardLOSFunction = async () => {
    const leaverTopCardData = await getLeaverTopCardLOS(
      selected_client_id,
      userDetails.agency_id,
      selected_start_date,
      selected_end_date
    );
    if (leaverTopCardData && leaverTopCardData.ok) {
      setLeaverTopCardLos(leaverTopCardData.data);
    }
  };

  const getLeaverTopCardCountsFunction = async () => {
    const leaverTopCardData = await getLeaverTopCardCounts(
      selected_client_id,
      userDetails.agency_id,
      selected_start_date,
      selected_end_date
    );
    if (leaverTopCardData && leaverTopCardData.ok) {
      setLeaverTopCardCounts(leaverTopCardData.data);
    }
  };

  const getLeaverTopCardPoolUtiFunction = async () => {
    const leaverTopCardData = await getLeaverTopCardPoolUti(
      selected_client_id,
      userDetails.agency_id,
      selected_start_date,
      selected_end_date
    );
    if (leaverTopCardData && leaverTopCardData.ok) {
      setLeaversTopCardPoolUti(leaverTopCardData.data);
    }
  };

  const getActivityBottomCardShiftUtiFunction = async () => {
    const bottomDeckData = await getActivityBottomCardShiftUti(
      selected_client_id,
      selected_start_date,
      selected_end_date
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardShiftUtil(bottomDeckData?.data);
    }
  };

  // const getActivityBottomCardLostShiftFunction = async () => {
  //   const bottomDeckData = await getActivityBottomCardLostShift(
  //     selected_client_id,
  //     selected_start_date,
  //     selected_end_date
  //   );
  //   if (bottomDeckData && bottomDeckData.ok) {
  //     setActivityBottomCardLostShift(bottomDeckData?.data?.result?.rows);
  //   }
  // };

  const getActivityBottomCardHeadCountFunction = async () => {
    const bottomDeckData = await getActivityBottomCardHeadCount(
      selected_client_id,
      selected_start_date,
      selected_end_date
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardHeadCount(bottomDeckData?.data);
    }
  };

  const getActivityBottomCardSpendFunction = async () => {
    const bottomDeckData = await getActivityBottomCardSpend(
      selected_client_id,
      selected_start_date,
      selected_end_date
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardSpend(bottomDeckData?.data);
    }
  };

  const getActivityBottomCardAverageHoursFunction = async () => {
    const bottomDeckData = await getActivityBottomCardAverageHours(
      selected_client_id,
      selected_start_date,
      selected_end_date
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardAverageHours(bottomDeckData?.data);
    }
  };

  // const getWorkforceDemoFunction = async () => {
  //   const bottomDeckData = await getWorkforceDemo(
  //     selected_client_id,
  //     selected_start_date,
  //     selected_end_date
  //   );
  //   if (bottomDeckData && bottomDeckData.ok) {
  //     setWorkforceDemo(bottomDeckData?.data?.result?.rows);
  //   }
  // };

  const getWorkforceShiftUtiFunction = async () => {
    const bottomDeckData = await getWorkforceShiftUti(
      selected_client_id,
      selected_start_date,
      selected_end_date
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setWorkforceShiftUti(bottomDeckData?.data);
    }
  };

  const getWorkforceLOSFunction = async () => {
    const bottomDeckData = await getWorkforceLOS(
      selected_client_id,
      selected_start_date,
      selected_end_date
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setWorkforceLOS(bottomDeckData?.data);
    }
  };

  const getLeaverNSRFunction = async () => {
    const bottomDeckData = await getLeaverNSR(
      selected_client_id,
      selected_start_date,
      selected_end_date
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setLeaverNSR(bottomDeckData?.data);
    }
  };

  const getLeaverCountFunction = async () => {
    const bottomDeckData = await getLeaverCount(
      selected_client_id,
      selected_start_date,
      selected_end_date
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setLeaverCount(bottomDeckData?.data);
    }
  };

  const getLeaverLOSFunction = async () => {
    const bottomDeckData = await getLeaverLOS(
      selected_client_id,
      selected_start_date,
      selected_end_date
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setLeaverLOS(bottomDeckData?.data);
    }
  };

  const from = moment(headerData?.result?.clearvue_savings?.last_week).format('DD/MM/YYYY');
  const obj = moment(from, 'DD/MM/YYYY').add(6, 'days')._d;
  const to = moment(obj).format('DD/MM/YYYY');

  return (
    <>
      <Box>
        <Card margin="5px" minHeight="58px">
          {/* <Flex justifyContent="space-around" fontSize={25}>
          <>
            <Text>
              Savings last week:{' '}
              <Tooltip
                borderRadius="7px"
                label={from && from !== '' ? `From: ${from} To: ${to}` : ''}>
                <Text as="span" color={colors.main.primary} fontWeight="semibold">
                  {currancyFormatter(headerData?.result?.clearvue_savings?.value || 0)}
                </Text>
              </Tooltip>
            </Text>
            <Text>
              Cumulative savings:{' '}
              <Text as="span" color={colors.main.primary} fontWeight="semibold">
                {currancyFormatter(headerData?.result?.cumulative_clearvue_savings || 0)}
              </Text>
            </Text>
          </>
        </Flex> */}
        </Card>

        <DashboardRatingCard
          siteRatingData={siteRatingData}
          agencyRatingData={agencyRatingData}
          clientRatingData={clientRatingData}
          ratingData={ratingData}
          headerData={headerData}
          leaversData={leaversData}
        />
        {is_restricted && userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ? (
          <Box>
            <Box h="600px" display="flex" justifyContent="center">
              <Center fontSize={35}>Client Has Restricted View</Center>
            </Box>
          </Box>
        ) : (
          <>
            <Box>
              <DashboardTopCard
                activityTopCardData={activityTopCardData}
                workforceTopCardPoolUti={workforceTopCardPoolUti}
                workforceTopCardShiftUti={workforceTopCardShiftUti}
                workforceTopCardLOS={workforceTopCardLOS}
                leaverTopCardLos={leaverTopCardLos}
                leaversTopCardPoolUti={leaversTopCardPoolUti}
                leaverTopCardCounts={leaverTopCardCounts}
                isDataAvailable={selected_client_id || false}
              />
            </Box>
            {isCurrentWeek && (
              <Box>
                <DashboardBottomCard
                  activityBottomCardShiftUtil={activityBottomCardShiftUtil}
                  activityBottomCardSpend={activityBottomCardSpend}
                  activityBottomCardAverageHours={activityBottomCardAverageHours}
                  workforceLOS={workforceLOS}
                  leaverLOS={leaverLOS}
                  leaverCount={leaverCount}
                  leaverNSR={leaverNSR}
                  activityBottomCardHeadCount={activityBottomCardHeadCount}
                  workforceShiftUti={workforceShiftUti}
                  isDataAvailable={selected_client_id || false}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default AgencyDashboard;
