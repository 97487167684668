/* eslint-disable default-param-last */
import { API_ENDPOINT, USER_TYPE, REDUX_SELECTOR } from '../../constants';

import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';

const getClients = async (limit = 1, page = 1, sortKey, sortType) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_CLIENT}?page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getClientById = async (id) => {
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_CLIENT}/${id}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const addClient = async (payload) => {
  const result = await axiosInstance.post(API_ENDPOINT.ADD_CLIENT, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const updateClient = async (id, payload) => {
  const result = await axiosInstance.put(`${API_ENDPOINT.ADD_CLIENT}/${id}`, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getClientUsers = async () => {
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_CLIENT_USERS}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const addClientUser = async (payload) => {
  const result = await axiosInstance.post(API_ENDPOINT.ADD_CLIENT_USERS, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const updateClientUser = async (id, payload) => {
  const result = await axiosInstance.put(`${API_ENDPOINT.UPDATE_CLIENT_USERS}/${id}`, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getClientAdminUser = async (id) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_AGENCY_USER}?user_type=${USER_TYPE.CLIENT_ADMIN}&id=${id}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getClientData = (id) => async (dispatch) => {
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_CLIENT}/${id}`);
  if (result && result.ok) {
    dispatch({
      type: REDUX_SELECTOR.PROFILE_DETAIL,
      payload: result
    });
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export {
  addClient,
  getClients,
  updateClient,
  getClientUsers,
  addClientUser,
  updateClientUser,
  getClientAdminUser,
  getClientData,
  getClientById
};
