import { API_ENDPOINT } from '../../constants';
import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';

export const getPerformanceShiftsBlocks = async (
  clientId,
  startDate,
  endDate,
  agencyId,
  regionId,
  siteId,
  shiftId,
  departmentId
) => {
  const params = new URLSearchParams();
  if (clientId) params.append('client_id', clientId);
  if (startDate) params.append('start_date', startDate);
  if (endDate) params.append('end_date', endDate);
  if (agencyId) params.append('agency_id', agencyId);
  if (regionId) params.append('region_id', regionId);
  if (siteId) params.append('site_id', siteId);
  if (shiftId) params.append('shift_id', shiftId);
  if (departmentId) params.append('department_id', departmentId);

  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_PERFORMANCE_SHIFTS_BLOCKS}?${params.toString()}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const getNewStarterPerformance = async (
  clientId,
  agencyId,
  regionId,
  siteId,
  shiftId,
  departmentId,
  startDate,
  endDate
) => {
  const params = new URLSearchParams();
  if (clientId) params.append('client_id', clientId);
  if (agencyId) params.append('agency_id', agencyId);
  if (regionId) params.append('region_id', regionId);
  if (siteId) params.append('site_id', siteId);
  if (shiftId) params.append('shift_id', shiftId);
  if (departmentId) params.append('department_id', departmentId);
  if (startDate) params.append('start_date', startDate);
  if (endDate) params.append('end_date', endDate);

  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_PERFORMANCE_NEW_STARTERS_GRAPH}?${params.toString()}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const getPerformanceByTenure = async (
  clientId,
  agencyId,
  regionId,
  siteId,
  shiftId,
  departmentId,
  startDate,
  endDate
) => {
  const params = new URLSearchParams();
  if (clientId) params.append('client_id', clientId);
  if (agencyId) params.append('agency_id', agencyId);
  if (regionId) params.append('region_id', regionId);
  if (siteId) params.append('site_id', siteId);
  if (shiftId) params.append('shift_id', shiftId);
  if (departmentId) params.append('department_id', departmentId);
  if (startDate) params.append('start_date', startDate);
  if (endDate) params.append('end_date', endDate);

  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_PERFORMANCE_BY_TENURE}?${params.toString()}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const getShiftBookingsGraph = async (
  clientId,
  startDate,
  endDate,
  agencyId,
  regionId,
  siteId,
  shiftId,
  departmentId
) => {
  const params = new URLSearchParams();
  if (clientId) params.append('client_id', clientId);
  if (startDate) params.append('start_date', startDate);
  if (endDate) params.append('end_date', endDate);
  if (agencyId) params.append('agency_id', agencyId);
  if (regionId) params.append('region_id', regionId);
  if (siteId) params.append('site_id', siteId);
  if (shiftId) params.append('shift_id', shiftId);
  if (departmentId) params.append('department_id', departmentId);

  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_SHIFT_BOOKINGS_GRAPH}?${params.toString()}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const getSiteViewData = async (clientId, startDate, endDate, regionId) => {
  const params = new URLSearchParams();

  params.append('client_id', clientId);
  params.append('start_date', startDate);
  params.append('end_date', endDate);
  if (regionId) params.append('region_id', regionId);

  const resp = await axiosInstance.get(`${API_ENDPOINT.GET_SITE_STATS}?${params.toString()}`);

  if (resp && resp.ok) return prepareSuccessReponse(resp);

  return prepareErrorResponse(resp);
};
