/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/function-component-definition */
import React, { useState } from 'react';
import EyeDisabledIcon from '../../icons/EyeDisabledIcon';
import EyeIcon from '../../icons/EyeIcon';
import InputComponent from '../Input';

const PasswordField = (props) => {
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  return (
    <>
      <InputComponent
        {...props}
        style={{ position: 'relative' }}
        type={isPasswordShow ? 'text' : 'password'}
      />
      <span
        style={{ zIndex: 1 }}
        className="password-icon-container"
        onClick={() => setIsPasswordShow(!isPasswordShow)}>
        {isPasswordShow ? <EyeIcon /> : <EyeDisabledIcon />}
      </span>
    </>
  );
};

export default PasswordField;
