/* eslint-disable react/self-closing-comp */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Button, Flex, SimpleGrid, Spacer, useMediaQuery } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import Loader from '../../components/Loader';
import {
  StyledContainer,
  StyledHeaderTitleCard,
  StyledHeaderValue,
  StyledTableContainer,
  StyledTableData,
  StyledTableHeader,
  StyledTableRow,
  StyledTableTitle
} from './AdminDashboard.styled';
import HeaderCard from '../../components/HeaderCard';
import {
  getAgencyData,
  getAnalytics,
  getClientsData,
  getSectorData
} from '../../redux/action/adminDashboard.action';
import { PAGE_TITLE } from '../../constants';
import Card from '../../components/Card';
import { currancyFormatter } from '../../utils/helper';

const AdminDashboard = function () {
  useEffect(() => {
    document.title = PAGE_TITLE.DASHBOARD;
  }, []);
  const [isPageLoader, setPageLoader] = useState(false);
  const [analytics, setAnalytics] = useState({
    client_count: 0,
    agency_count: 0,
    worker_count: 0,
    cumulative_savings: 0,
    total_spends: 0
  });
  const [clientsData, setClientsData] = useState([]);
  const [agencyData, setAgencyData] = useState([]);
  const [sectorData, setSectorData] = useState([]);
  const [isSmallerThan1440] = useMediaQuery('(max-width: 1440px)');

  const redirectToMore = () => {
    // console.log('sectionName', sectionName);
  };

  useEffect(async () => {
    setPageLoader(true);
    const dashboardData = await Promise.all([
      getAnalytics(),
      getAgencyData(5, 1, 'total_spend', 'DESC'),
      getClientsData(5, 1, 'total_spend', 'DESC'),
      getSectorData(5, 1, 'total_spend', 'DESC')
    ]);
    setAnalytics(dashboardData[0].data.records);
    setAgencyData(dashboardData[1].data);
    setClientsData(dashboardData[2].data);
    setSectorData(dashboardData[3].data);
    setPageLoader(false);
  }, []);

  return (
    <>
      <StyledContainer>
        {isPageLoader ? <Loader /> : null}
        <SimpleGrid width={isSmallerThan1440 && '1190px'} minChildWidth="220px" spacing="10px">
          <HeaderCard textAlign="center" fontWeight="semibold" p={4}>
            <StyledHeaderTitleCard>Total Clients</StyledHeaderTitleCard>
            <StyledHeaderValue fontSize="4xl">{analytics.client_count}</StyledHeaderValue>
          </HeaderCard>
          <HeaderCard textAlign="center" fontWeight="semibold" p={4}>
            <StyledHeaderTitleCard>Total Agencies</StyledHeaderTitleCard>
            <StyledHeaderValue fontSize="4xl">{analytics.agency_count}</StyledHeaderValue>
          </HeaderCard>
          <HeaderCard textAlign="center" fontWeight="semibold" p={4}>
            <StyledHeaderTitleCard>Total Active Workers</StyledHeaderTitleCard>
            <StyledHeaderValue fontSize="4xl">{analytics.worker_count}</StyledHeaderValue>
          </HeaderCard>
          <HeaderCard textAlign="center" fontWeight="semibold" p={4}>
            <StyledHeaderTitleCard>ClearVue Credit</StyledHeaderTitleCard>
            <StyledHeaderValue fontSize="4xl">
              {currancyFormatter(analytics.cumulative_savings)}
            </StyledHeaderValue>
          </HeaderCard>
          <HeaderCard textAlign="center" fontWeight="semibold" p={4}>
            <StyledHeaderTitleCard>Cumulative Spend</StyledHeaderTitleCard>
            <StyledHeaderValue fontSize="4xl">
              {currancyFormatter(analytics.total_spends)}
            </StyledHeaderValue>
          </HeaderCard>
        </SimpleGrid>

        <Card style={{ padding: '16px 32px', marginTop: '16px' }}>
          <StyledTableTitle>Clients</StyledTableTitle>
          <StyledTableContainer>
            <thead>
              <StyledTableRow>
                <StyledTableHeader>Name</StyledTableHeader>
                <StyledTableHeader>Agency Used</StyledTableHeader>
                <StyledTableHeader>Total Active Workers With Client</StyledTableHeader>
                <StyledTableHeader>ClearVue Credit</StyledTableHeader>
                <StyledTableHeader>Total Spend</StyledTableHeader>
              </StyledTableRow>
            </thead>

            <tbody>
              {clientsData && clientsData.records && clientsData.records.length > 0 ? (
                clientsData.records.map((client) => {
                  return (
                    <StyledTableRow key={client.id}>
                      <StyledTableData>{client.name}</StyledTableData>
                      <StyledTableData>{client.used}</StyledTableData>
                      <StyledTableData>{client.total_active_workers}</StyledTableData>
                      <StyledTableData>
                        {currancyFormatter(client.cumulative_clearvue_saving)}
                      </StyledTableData>
                      <StyledTableData>{currancyFormatter(client.total_spend)}</StyledTableData>
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow>
                  <StyledTableData colSpan={5} style={{ textAlign: 'center' }}>
                    No Data Found!
                  </StyledTableData>
                </StyledTableRow>
              )}
            </tbody>
          </StyledTableContainer>
          <Flex>
            <Spacer />
            {clientsData && clientsData.count && clientsData.count > 5 && (
              <NavLink color="main.primary" to="/view-more/clients" fontSize="sm">
                <Button
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  onClick={() => redirectToMore('client')}>
                  View More
                </Button>
              </NavLink>
            )}
          </Flex>
        </Card>

        <Card style={{ padding: '16px 32px', marginTop: '16px' }}>
          <StyledTableTitle>Agencies</StyledTableTitle>
          <StyledTableContainer>
            <thead>
              <StyledTableRow>
                <StyledTableHeader>Name</StyledTableHeader>
                <StyledTableHeader>Client Deployments</StyledTableHeader>
                <StyledTableHeader>Total Active Workers</StyledTableHeader>
                <StyledTableHeader>ClearVue Credit</StyledTableHeader>
                <StyledTableHeader>Total Spend</StyledTableHeader>
              </StyledTableRow>
            </thead>

            <tbody>
              {agencyData && agencyData.records && agencyData.records.length > 0 ? (
                agencyData.records.map((agency) => {
                  return (
                    <StyledTableRow key={agency.id}>
                      <StyledTableData>{agency.name}</StyledTableData>
                      <StyledTableData>{agency.used}</StyledTableData>
                      <StyledTableData>{agency.total_active_workers}</StyledTableData>
                      <StyledTableData>
                        {currancyFormatter(agency.cumulative_clearvue_saving)}
                      </StyledTableData>
                      <StyledTableData>{currancyFormatter(agency.total_spend)}</StyledTableData>
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow>
                  <StyledTableData colSpan={5} style={{ textAlign: 'center' }}>
                    No Data Found!
                  </StyledTableData>
                </StyledTableRow>
              )}
            </tbody>
          </StyledTableContainer>
          <Flex>
            <Spacer />
            {agencyData && agencyData.count && agencyData.count > 5 && (
              <NavLink color="main.primary" to="/view-more/agencies" fontSize="sm">
                <Button
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  onClick={() => redirectToMore('agency')}>
                  View More
                </Button>
              </NavLink>
            )}
          </Flex>
        </Card>

        <Card style={{ padding: '16px 32px', marginTop: '16px' }}>
          <StyledTableTitle>Sectors</StyledTableTitle>
          <StyledTableContainer>
            <thead>
              <StyledTableRow>
                <StyledTableHeader>Name</StyledTableHeader>
                <StyledTableHeader>Sector Used</StyledTableHeader>
                <StyledTableHeader>Total Active Workers With Client</StyledTableHeader>
                <StyledTableHeader>ClearVue Credit</StyledTableHeader>
                <StyledTableHeader>Total Spend</StyledTableHeader>
              </StyledTableRow>
            </thead>
            <tbody>
              {sectorData && sectorData.records && sectorData.records.length > 0 ? (
                sectorData.records.map((sector) => {
                  return (
                    <StyledTableRow key={sector.id}>
                      <StyledTableData>{sector.name}</StyledTableData>
                      <StyledTableData>{sector.used}</StyledTableData>
                      <StyledTableData>{sector.total_active_workers}</StyledTableData>
                      <StyledTableData>
                        {currancyFormatter(sector.cumulative_clearvue_saving)}
                      </StyledTableData>
                      <StyledTableData>{currancyFormatter(sector.total_spend)}</StyledTableData>
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow>
                  <StyledTableData colSpan={5} style={{ textAlign: 'center' }}>
                    No Data Found!
                  </StyledTableData>
                </StyledTableRow>
              )}
            </tbody>
          </StyledTableContainer>
          <Flex>
            <Spacer />
            {sectorData && sectorData.count && sectorData.count > 5 && (
              <NavLink color="main.primary" to="/view-more/sectors" fontSize="sm">
                <Button
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  onClick={() => redirectToMore('sector')}>
                  View More
                </Button>
              </NavLink>
            )}
          </Flex>
        </Card>
      </StyledContainer>
      <div />
    </>
  );
};

export default AdminDashboard;
