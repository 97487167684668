import { Flex } from '@chakra-ui/react';
import styled from 'styled-components';
import { colors } from '../../theme';

const StyledMessagingViewContainer = styled(Flex)`
  flex-direction: column;
`;

export const StyledMessagingBodyContainer = styled(Flex)`
  justify-content: space-between;
  margin-top: 1.5rem;
  width: 1187px;
`;

export const StyledMessagingFormContainer = styled(Flex)`
  position: relative;
  width: 45%;
  min-width: 380px;
`;
export const StyledMobileContainer = styled(Flex)`
  // width: 40%;
  position: sticky;
  right: 15px;
  top: 0;
  height: 500px;
  margin-right: -35px;
`;
export const StyleMobile = styled.div`
  position: relative;
  width: 295px;
  height: 460px;
  border: 16px black solid;
  // border-top-width: 50px;
  // border-bottom-width: 50px;
  border-radius: 36px;
  margin: auto;
  z-index: 2;
  box-shadow: 0 0.5em 2em 0.2em rgb(0 0 0 / 33%), 0 0 0 0.5px #000 inset;
  overflow: hidden;
  &::before {
    content: '';
    display: block;
    width: 100px;
    height: 5px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background: black;
    border-radius: 10px;
    border: 18px solid black;
  }

  // &::after {
  //   content: '';
  //   display: block;
  //   width: 32px;
  //   height: 32px;
  //   position: absolute;
  //   left: 50%;
  //   bottom: -56px;
  //   -webkit-transform: translate(-50%, -50%);
  //   -ms-transform: translate(-50%, -50%);
  //   transform: translate(-50%, -50%);
  //   background: black;
  //   border-radius: 8px;
  //   border: 6px solid white;
  // }
`;

export const StyledMobileHeader = styled(Flex)`
  color: white;
  padding: 8px;
  background-color: ${colors.main.mobileBackground};
  margin-top: -1px;
  height: 80px;
  padding-top: 18px;
`;
export const StyledMobileHeaderTitle = styled(Flex)`
  margin-left: 70px;
`;
export const StyledMobileScreen = styled.div`
  height: 376px;
  background: white;
  overflow-y: auto;
  margin-top: -24px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  margin-top: -28px;
  overflow: hidden;
`;
export const StyledMobileFooter = styled(Flex)`
  color: white;
  padding: 8px;
  align-items: center;
  background-color: ${colors.main.primary};
  justify-content: space-around;
`;

export const StyleMessageContainer = styled.div`
  font-size: 0.8rem;
  color: ${colors.main.primary};
  height: 100%;
  padding: 16px 16px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #bec4c4;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const StyleMessageUserLogo = styled.span`
  background-color: ${colors.main.mobileBackground};
  color: ${colors.main.white};
  width: 40px;
  height: 40px;
  margin-right: 8px;
  font-size: 2rem;
  border-radius: 50%;
  text-align: center;
  line-height: 2rem;
`;

export default StyledMessagingViewContainer;
