/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable import/named */
/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */

import React, { useState, useEffect, useRef } from 'react';
import { SimpleGrid, Box, Text, Button, Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import AssemblyPerformance from './AssemblyPerformance';
import Loader from '../../../components/Loader';
import DashboardCard from '../../../components/DashboardCard';
import Card from '../../../components/Card';
import { getShiftBookingsGraph } from '../../../redux/action/performanceGraph.action';
import { USER_TYPE } from '../../../constants';

const ShiftPerformanceList = () => {
  const debounceTimeout = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [performanceData, setPerformanceData] = useState([]);

  const { userData, user_details } = useSelector((state) => state.authentication);
  const {
    selected_site_id,
    selected_client_id,
    selected_performance_region_id,
    selected_performance_site_id,
    selected_performance_shift_id,
    selected_performance_department_id,
    selected_performance_agency_id
  } = useSelector((state) => state.agency);
  const { startDate, endDate } = useSelector((state) => state.weekRange);

  const getClientId = () => {
    switch (user_details.user_type_id) {
      case USER_TYPE.CLIENT_ADMIN:
        return userData.client_id;
      case USER_TYPE.AGENCY_ADMIN:
      case USER_TYPE.AGENCY_REGION_ADMIN:
      case USER_TYPE.AGENCY_SITE_ADMIN:
        return selected_client_id || userData.client_id;
      default:
        return selected_client_id || userData.client_id;
    }
  };

  const getSiteId = () => {
    switch (user_details.user_type_id) {
      case USER_TYPE.AGENCY_ADMIN:
        return selected_performance_site_id;
      case USER_TYPE.AGENCY_SITE_ADMIN:
        return user_details.site_id;
      case USER_TYPE.AGENCY_REGION_ADMIN:
        return selected_performance_site_id;
      case USER_TYPE.CLIENT_ADMIN:
        return selected_performance_site_id;
      case USER_TYPE.SITE_ADMIN:
        return user_details.site_id;
      default:
        return selected_performance_site_id || userData.site_id || selected_site_id || '';
    }
  };

  const getRegionId = () => {
    switch (user_details.user_type_id) {
      case USER_TYPE.SUPER_ADMIN:
      case USER_TYPE.CLIENT_ADMIN:
      case USER_TYPE.AGENCY_ADMIN:
        return selected_performance_region_id;
      case USER_TYPE.AGENCY_REGION_ADMIN:
        return user_details.region_id;
      case USER_TYPE.AGENCY_SITE_ADMIN:
      case USER_TYPE.SITE_ADMIN:
        return '';
      default:
        return selected_performance_region_id || userData.region_id;
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const result = await getShiftBookingsGraph(
        getClientId(),
        startDate,
        endDate,
        selected_performance_agency_id,
        getRegionId(),
        getSiteId(),
        selected_performance_shift_id,
        selected_performance_department_id
      );

      if (result && result.ok) {
        const data = result.data.result || [];
        setPerformanceData(data);
      } else {
        throw new Error(result.error || 'Failed to fetch assembly performance data');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      fetchData();
    }, 700);
  }, [
    selected_performance_agency_id,
    selected_performance_region_id,
    selected_performance_site_id,
    selected_performance_shift_id,
    selected_performance_department_id,
    startDate,
    endDate
  ]);

  const downloadCSV = () => {
    const headers = [
      'start_date',
      'end_date',
      'site',
      'shift',
      'department',
      'original_request',
      'final_request',
      'fulfillment_total',
      'fulfillment_sun',
      'fulfillment_mon',
      'fulfillment_tues',
      'fulfillment_wed',
      'fulfillment_thu',
      'fulfillment_fri',
      'fulfillment_sat'
    ];

    const rows = performanceData.map((booking) => [
      startDate,
      endDate,
      booking.site_name,
      booking.shift_name,
      booking.department_name,
      booking.total_requested_workers,
      booking.total_amended_workers,
      booking.total_fulfilled_workers,
      ...booking.fulfilled // This contains the daily fulfillment values [Sun to Sat]
    ]);

    const csvContent = [headers.join(','), ...rows.map((row) => row.join(','))].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `shift_booking_report_${startDate}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (error) {
    return (
      <DashboardCard m="5px">
        <Text textAlign="center" color="red.500">
          {error}
        </Text>
      </DashboardCard>
    );
  }

  let content;
  if (isLoading) {
    content = (
      <DashboardCard m="5px">
        <Loader />
      </DashboardCard>
    );
  } else if (!performanceData || performanceData.length === 0) {
    content = (
      <DashboardCard m="5px">
        <Text textAlign="center" color="gray.500">
          No data available
        </Text>
      </DashboardCard>
    );
  } else {
    content = (
      <SimpleGrid columns={{ base: 1, lg: 2, xl: 3 }} spacing={4}>
        {performanceData.map((assembly) => (
          <Box key={assembly.booking_id}>
            <AssemblyPerformance
              title={`${assembly.department_name} - ${assembly.shift_name}`}
              // subtitle={assembly.shift_name}
              data={{
                requested: assembly.requested || [],
                amended: assembly.amended || [],
                fulfilled: assembly.fulfilled || [],
                percentages: {
                  requested: assembly.fulfilment_percentage_requested || 0,
                  amended: assembly.fulfilment_percentage_amended || 0
                },
                totals: {
                  requested: assembly.total_requested_workers || 0,
                  amended: assembly.total_amended_workers || 0,
                  fulfilled: assembly.total_fulfilled_workers || 0
                }
              }}
            />
          </Box>
        ))}
      </SimpleGrid>
    );
  }

  return (
    <Box>
      <Box mb={5}>
        <Card>
          <Flex justify="flex-end">
            <Button disabled={isLoading} onClick={downloadCSV} colorScheme="blue">
              Export
            </Button>
          </Flex>
        </Card>
      </Box>
      {content}
    </Box>
  );
};

export default ShiftPerformanceList;
