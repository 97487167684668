import React from 'react';

const RateCardIcon = function () {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 60 60"
      xmlSpace="preserve"
      width="18"
      height="18">
      <g>
        <path
          fill="currentColor"
          d="M11,57.439C11,58.852,12.148,60,13.561,60h32.879C47.852,60,49,58.852,49,57.439V52H11V57.439z"
        />
        <path
          fill="currentColor"
          d="M29,25.189c-0.494,0.176-0.944,0.464-1.292,0.875c-0.256,0.303-0.374,0.694-0.331,1.104
		c0.046,0.439,0.272,0.848,0.622,1.12L29,29.067V25.189z"
        />
        <path
          fill="currentColor"
          d="M32.623,35.053c-0.046-0.438-0.272-0.847-0.622-1.118L31,33.156v3.878c0.494-0.176,0.944-0.464,1.292-0.875
		C32.548,35.855,32.666,35.463,32.623,35.053z"
        />
        <path
          fill="currentColor"
          d="M46.439,4H31v4.101c2.279,0.465,4,2.484,4,4.899c0,2.757-2.243,5-5,5s-5-2.243-5-5c0-2.414,1.721-4.434,4-4.899V4H13.561
		C12.148,4,11,5.148,11,6.561V50h38V6.561C49,5.148,47.852,4,46.439,4z M26,33.223c0.552,0,1,0.447,1,1c0,1.302,0.839,2.402,2,2.816
		V31.6l-2.229-1.734c-0.777-0.604-1.282-1.513-1.383-2.491c-0.1-0.956,0.183-1.881,0.794-2.603c0.733-0.867,1.728-1.446,2.818-1.668
		V22c0-0.553,0.448-1,1-1s1,0.447,1,1v1.101c2.279,0.465,4,2.484,4,4.899c0,0.553-0.448,1-1,1s-1-0.447-1-1
		c0-1.302-0.839-2.402-2-2.816v5.438l2.229,1.734c0.777,0.604,1.281,1.511,1.383,2.488c0.1,0.957-0.182,1.882-0.793,2.605
		c-0.734,0.867-1.728,1.446-2.819,1.668V40c0,0.553-0.448,1-1,1s-1-0.447-1-1v-0.878c-2.279-0.465-4-2.484-4-4.899
		C25,33.67,25.448,33.223,26,33.223z M38,48H22c-0.552,0-1-0.447-1-1s0.448-1,1-1h16c0.552,0,1,0.447,1,1S38.552,48,38,48z M38,45
		H22c-0.552,0-1-0.447-1-1s0.448-1,1-1h16c0.552,0,1,0.447,1,1S38.552,45,38,45z"
        />
        <path fill="currentColor" d="M31,1c0-0.552-0.448-1-1-1s-1,0.448-1,1v3h2V1z" />
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
};

export default RateCardIcon;
