/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Text, useToast } from '@chakra-ui/react';
import { TOAST_SETTINGS, USER_TYPE } from '../../constants';
import { getDepartments } from '../../redux/action/department.action';
import Dropdown from '../Dropdown';

const RoleDepartmentDropdown = function ({
  department,
  setDepartment,
  validationObj,
  refresh,
  multiple,
  site,
  disabled = false,
  clientId = null
}) {
  const toast = useToast();
  const { userData, user_details } = useSelector((state) => state.authentication);
  const [departments, setDepartments] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj?.isvalid || true);
  const { selected_client_id, selected_site_id } = useSelector((state) => state.agency);
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(async () => {
    if (site.value) {
      const result = await getDepartments(
        clientId || selected_client_id || userData.client_id || user_details.client_id || null,
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
          ? ''
          : site.value || selected_site_id || userData.site_id || null,
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN && user_details.region_id
      );

      if (result && result.ok) {
        if (result.data.department_list.length !== 0) {
          setDepartments(result.data.department_list);
        } else {
          // Set department state to empty if department list is empty
          // setDepartment({ label: '', value: '' });
          setDepartments([]);
        }
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
    }
  }, [
    clientId,
    site,
    selected_client_id,
    selected_site_id,
    userData.client_id,
    userData.site_id,
    user_details.user_type_id,
    user_details.region_id
  ]);

  useEffect(() => {
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage);
      setValid(validationObj.isvalid);
    }
  }, [refresh]);

  const setDepartmentData = (e) => {
    setErrorMsg(null);
    setValid(true);
    setDepartment(e);
  };

  useEffect(() => {
    if (!isInitialRender) {
      setDepartment({ label: '', value: '' });
    } else {
      setIsInitialRender(false);
    }
  }, [site]);

  return (
    <>
      <Dropdown
        label={`Department${validationObj?.required ? '*' : ''}`}
        onSelect={setDepartmentData}
        options={departments.map((dept) => {
          return { label: dept.name, value: dept.id };
        })}
        value={department}
        placeholder=""
        isError={!isValid}
        multiple={multiple}
        disabled={disabled}
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error !'}
      </Text>
    </>
  );
};

export default RoleDepartmentDropdown;
