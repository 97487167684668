/* eslint-disable default-param-last */
/* eslint-disable radix */
import { API_ENDPOINT } from '../../constants';

import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';
import { toFormData } from '../../utils/helper';

const getWorkers = async (
  workerType,
  clientId,
  agencyId,
  siteId,
  regionId,
  limit = 10,
  page = 1,
  sortKey,
  sortType
) => {
  let query = '?';
  query += workerType ? `type=${workerType}&` : '';
  query += clientId ? `client_id=${parseInt(clientId)}` : '';
  query += clientId && agencyId ? '&' : '';
  query += agencyId ? `agency_id=${parseInt(agencyId)}` : '';
  query += siteId ? `&site_id=${parseInt(siteId)}` : '';
  query += regionId ? `&region_id=${parseInt(regionId)}` : '';
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_workers}${query}&page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getSearchWorkers = async (
  workerType,
  clientId,
  agencyId,
  siteId,
  regionId,
  payload,
  limit = 10,
  page = 1,
  sortKey,
  sortType
) => {
  let query = '?';
  query += workerType ? `type=${workerType}&` : '';
  query += clientId ? `client_id=${parseInt(clientId)}` : '';
  query += clientId && agencyId ? '&' : '';
  query += agencyId ? `agency_id=${parseInt(agencyId)}` : '';
  query += siteId ? `&site_id=${parseInt(siteId)}` : '';
  query += regionId ? `&region_id=${parseInt(regionId)}` : '';
  const result = await axiosInstance.post(
    `${API_ENDPOINT.SEARCH_workers}${query}&page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}`,
    payload
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getWorkerDetails = async (id) => {
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_workers}/${id}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const addWorker = async (payload) => {
  const result = await axiosInstance.post(API_ENDPOINT.ADD_WORKER, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const addWorkerDocs = async (payload) => {
  const result = await axiosInstance.post(API_ENDPOINT.ADD_WORKER_DOCS, await toFormData(payload));
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const updateWorker = async (id, payload) => {
  const result = await axiosInstance.put(`${API_ENDPOINT.UPDATE_WORKER}/${id}`, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const uploadBulkWorker = async (clientId, agencyId, siteId, workerType, payload) => {
  const result = await axiosInstance.post(
    `${API_ENDPOINT.BULK_WORKER_UPLOAD}?client_id=${clientId}${
      agencyId ? `&agency_id=${agencyId}` : ''
    }${siteId ? `&site_id=${siteId}` : ''}${workerType ? `&type=${workerType}` : ''}`,
    await toFormData(payload)
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const updateBulkWorkers = async (payload) => {
  const result = await axiosInstance.put(`${API_ENDPOINT.BULK_WORKER_UPLOAD}`, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const enableFTPLookup = async (clientId) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.FTP_MANUAL_UPLOAD_TNA_TRIGGER}${clientId}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const enableWorkerFTPLookup = async (clientId) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.FTP_MANUAL_UPLOAD_WORKER_TRIGGER}${clientId}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const downloadWorkerSample = async () => {
  const result = await axiosInstance.get(`${API_ENDPOINT.BULK_WORKER_DOWNLOAD_SAMPLE}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const downloadFilteredDataOfWorker = async (
  workerType,
  clientId,
  agencyId,
  siteId,
  regionId,
  payload,
  limit,
  page = 1,
  sortKey,
  sortType
) => {
  let query = '?';
  query += workerType ? `type=${workerType}&` : '';
  query += clientId ? `client_id=${parseInt(clientId)}` : '';
  query += clientId && agencyId ? '&' : '';
  query += agencyId ? `agency_id=${parseInt(agencyId)}` : '';
  query += siteId ? `&site_id=${parseInt(siteId)}` : '';
  query += regionId ? `&region_id=${parseInt(regionId)}` : '';
  const result = await axiosInstance.post(
    `${API_ENDPOINT.SEARCH_workers}${query}&page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}`,
    payload
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getWorkersListing = async (clientId, agencyId, siteId, workerType) => {
  let query = '?';
  query += clientId ? `client_id=${parseInt(clientId)}` : '';
  query += clientId && agencyId ? '&' : '';
  query += agencyId ? `agency_id=${parseInt(agencyId)}` : '';
  query += siteId ? `&site_id=${parseInt(siteId)}` : '';
  query += workerType ? `&type=${workerType}` : '';
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_WORKER_LISTING}${query}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getWorkersNationality = async (clientId, agencyId, siteId) => {
  let query = '?';
  query += clientId ? `client_id=${parseInt(clientId)}` : '';
  query += agencyId ? `&agency_id=${parseInt(agencyId)}` : '';
  query += siteId ? `&site_id=${parseInt(siteId)}` : '';
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_WORKER_NATIONALITY}${query}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export {
  addWorker,
  getWorkers,
  getWorkerDetails,
  uploadBulkWorker,
  downloadWorkerSample,
  downloadFilteredDataOfWorker,
  updateBulkWorkers,
  addWorkerDocs,
  updateWorker,
  getWorkersListing,
  getWorkersNationality,
  getSearchWorkers,
  enableFTPLookup,
  enableWorkerFTPLookup
};
