/* eslint-disable default-param-last */
import axiosInstance from '../../config/axios.config';
import { API_ENDPOINT } from '../../constants';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';

const getPayrollListing = async (limit = 1, page = 1, sortKey, sortType) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_PAYROLL_LISTING}?limit=${limit}&page=${page}&sort_by=${sortKey}&sort_type=${sortType}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export default getPayrollListing;
