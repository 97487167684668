import { Flex } from '@chakra-ui/react';
import styled from 'styled-components';

export const StyledViewMoreContainer = styled(Flex)``;

export const StyledViewMoreHeaderContainer = styled(Flex)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledDetailLabel = styled.div`
  font-weight: 700;
`;

export const StyledDetailValue = styled.div``;
