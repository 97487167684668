import React from 'react';

const RegionIcon = function () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 25 23">
      <path
        fill="currentColor"
        d="M24.306 19.444h-.695V4.167H1.39v15.277H.695a.694.694 0 00-.694.695v1.389c0 .384.31.694.694.694H7.64c.384 0 .694-.31.694-.694v-7.64a4.167 4.167 0 118.334 0V21.529c0 .384.311.694.695.694h6.944c.383 0 .694-.31.694-.694v-1.39a.694.694 0 00-.694-.694zm0-19.444H.694A.694.694 0 000 .694v1.39c0 .383.31.694.694.694h23.612c.383 0 .694-.311.694-.695V.694A.694.694 0 0024.306 0z"
      />
    </svg>
  );
};

export default RegionIcon;
