import { Flex } from '@chakra-ui/react';
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const LoaderContainer = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;
