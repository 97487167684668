/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';
import Dropdown from '../Dropdown';
import { TYPES } from '../../constants';

const TypeDropdown = function ({
  setMessageType,
  messageType,
  validationObj,
  refresh,
  multiple,
  label,
  disabled,
  labelFontSize
}) {
  const [messageTypes, setMessageTypes] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj?.isvalid || true);

  useEffect(async () => {
    setMessageTypes(TYPES);
  }, []);

  useEffect(() => {
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage);
      setValid(validationObj.isvalid);
    }
  }, [refresh]);

  const setMessageTypeData = (e) => {
    setErrorMsg(null);
    setValid(true);
    setMessageType(e);
  };

  return (
    <>
      <Dropdown
        label={`${label}${validationObj?.required ? '*' : ''}`}
        onSelect={setMessageTypeData}
        options={messageTypes.map((item) => {
          return { label: item.name, value: item.value };
        })}
        value={messageType}
        placeholder=""
        isError={!isValid}
        disabled={disabled}
        multiple={multiple}
        labelFontSize={labelFontSize}
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error !'}
      </Text>
    </>
  );
};

export default TypeDropdown;
