/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable import/named */
/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '@chakra-ui/react';
import { USER_TYPE } from '../../constants';
import { getShifts } from '../../redux/action/shift.action';
import Dropdown from '../Dropdown';
import { selectPerformanceShift } from '../../redux/actions/performanceDashboard.action';

const ShiftDropdownHeader = function ({
  validationObj = { isvalid: true },
  refresh = false,
  multiple = false,
  disabled = false,
  isHeader = false
}) {
  const dispatch = useDispatch();
  const [shifts, setShifts] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj?.isvalid || true);

  const { userData, user_details } = useSelector((state) => state.authentication);
  const {
    selected_client_id,
    selected_performance_site_id,
    selected_performance_shift_id,
    selected_performance_shift_name,
    selected_performance_region_id
  } = useSelector((state) => state.agency);

  useEffect(() => {
    let mounted = true;

    const fetchShiftData = async () => {
      // Helper function to determine client ID based on user type
      const getRegionId = () => {
        switch (user_details.user_type_id) {
          case USER_TYPE.SUPER_ADMIN:
            return selected_performance_region_id;
          case USER_TYPE.CLIENT_ADMIN:
            return selected_performance_region_id;
          case USER_TYPE.AGENCY_ADMIN:
            return selected_performance_region_id;
          case USER_TYPE.AGENCY_REGION_ADMIN:
            return user_details.region_id;
          case USER_TYPE.AGENCY_SITE_ADMIN:
            return selected_performance_region_id;
          case USER_TYPE.SITE_ADMIN:
            return null;
          default:
            return selected_performance_region_id || userData.region_id;
        }
      };

      const getClientId = () => {
        switch (user_details.user_type_id) {
          case USER_TYPE.CLIENT_ADMIN:
            return userData.client_id;
          case USER_TYPE.AGENCY_ADMIN:
          case USER_TYPE.AGENCY_REGION_ADMIN:
          case USER_TYPE.AGENCY_SITE_ADMIN:
            return selected_client_id || userData.client_id;
          default:
            return selected_client_id || userData.client_id;
        }
      };
      const getSiteId = () => {
        switch (user_details.user_type_id) {
          case USER_TYPE.SITE_ADMIN:
          case USER_TYPE.AGENCY_SITE_ADMIN:
            return user_details.site_id;
          default:
            return selected_performance_site_id || userData.site_id || null;
        }
      };

      const clientId = getClientId();
      if (clientId) {
        const result = await getShifts(clientId, getSiteId(), getRegionId());

        if (mounted && result && result.ok) {
          setShifts(result.data.shifts);

          if (!selected_performance_shift_id) {
            if (isHeader && result.data.shifts.length > 0) {
              dispatch(selectPerformanceShift('', 'All Shifts'));
            } else if (result.data.shifts.length > 0) {
              const firstShift = result.data.shifts[0];
              dispatch(selectPerformanceShift(firstShift.id, firstShift.name));
            }
          }
        } else if (mounted) {
          setShifts([]);
          dispatch(selectPerformanceShift(null, null));
        }
      }
    };

    fetchShiftData();

    return () => {
      mounted = false;
    };
  }, [
    selected_client_id,
    userData.client_id,
    selected_performance_site_id,
    selected_performance_region_id,
    user_details.user_type_id,
    user_details.site_id,
    user_details.region_id,
    refresh,
    isHeader,
    selected_performance_shift_id,
    dispatch
  ]);

  useEffect(() => {
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage);
      setValid(validationObj.isvalid);
    }
  }, [refresh, validationObj]);

  const handleShiftSelect = (selectedOption) => {
    setErrorMsg(null);
    setValid(true);
    dispatch(selectPerformanceShift(selectedOption.value, selectedOption.label));
  };

  const getOptions = () => {
    const options = shifts.map((shift) => ({
      label: shift.name,
      value: shift.id
    }));

    if (isHeader && options.length > 0) {
      return [{ value: '', label: 'All Shifts' }, ...options];
    }
    return options;
  };

  const getCurrentValue = () => {
    if (selected_performance_shift_name) {
      return {
        label: selected_performance_shift_name,
        value: selected_performance_shift_id
      };
    }
    if (selected_performance_shift_id === '' && isHeader) {
      return { label: 'All Shifts', value: '' };
    }
    return {};
  };

  return (
    <>
      <Dropdown
        label={`Shift${validationObj?.required ? '*' : ''}`}
        onSelect={handleShiftSelect}
        options={getOptions()}
        value={getCurrentValue()}
        placeholder="Select"
        multiple={multiple}
        isError={!isValid}
        disabled={disabled}
        isSearchable
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error!'}
      </Text>
    </>
  );
};

export default ShiftDropdownHeader;
