/* eslint-disable react/prop-types */
import { useState } from 'react';
import Dropdown from '../Dropdown';

const YearDropdown = function ({ setSelectedDate, selectedDate }) {
  const Years = [];
  const currentYear = new Date().getFullYear();
  let prev = 2021;
  // eslint-disable-next-line no-plusplus
  for (let i = prev + 1; i <= currentYear + 1; i++) {
    Years.push(`${prev}-${i}`);
    prev = i;
  }
  const [selectedYear, setSelectedYear] = useState(selectedDate);

  return (
    <Dropdown
      label="Select Year"
      placeholder="Select"
      value={selectedYear}
      options={Years.map((item) => {
        return {
          label: item,
          startDate: `${item.split('-')[0]}-04-01`,
          endDate: `${item.split('-')[1]}-03-31`
        };
      })}
      onSelect={(e) => {
        setSelectedYear(e);
        setSelectedDate(e);
      }}
      disabled={false}
    />
  );
};

export default YearDropdown;
