/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import Dropdown from '../Dropdown';

// eslint-disable-next-line react/prop-types
const WorkerTypeDropdown = function ({
  workerType,
  setWorkerTypeDropdown,
  disabled,
  multiple,
  setDefaultValue,
  twoOptions
}) {
  let options = [];
  if (twoOptions) {
    options = [
      { label: 'Temporary Worker', value: 'TEMPORARY' },
      { label: 'Permanent Worker', value: 'PERMANENT' }
    ];
  } else {
    options = [
      { label: 'All Workers', value: '' },
      { label: 'Permanent Worker', value: 'PERMANENT' },
      { label: 'Temporary Worker', value: 'TEMPORARY' }
    ];
  }

  useEffect(() => {
    if (setDefaultValue) {
      setWorkerTypeDropdown(options[0]);
    }
  }, []);

  return (
    <Dropdown
      label="Worker Type"
      placeholder="Select Worker Type"
      options={options.map((item) => {
        return { label: item.label, value: item.value };
      })}
      value={workerType}
      disabled={disabled}
      multiple={multiple}
      onSelect={(e) => {
        setWorkerTypeDropdown(e);
      }}
    />
  );
};

export default WorkerTypeDropdown;
