import { Box } from '@chakra-ui/react';
import styled from 'styled-components';

const StyledRightPanel = styled(Box)`
  width: 97%;
  border-radius: 50px 0 0 50px;
  padding: 10px 30px 30px;
  overflow: auto;
`;

export default StyledRightPanel;
