/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

import { Box, Button, Center, Flex, Tbody } from '@chakra-ui/react';

import Dropdown from '../Dropdown';
import {
  StyledContainer,
  StyledHeadTr,
  StyledTable,
  StyledTd,
  StyledTh,
  StyledThead,
  StyledTr
} from './ClientSideDatatable.styled';
import ArrowUp from '../../icons/ArrowUp';
import ArrowDown from '../../icons/ArrowDown';
import { colors } from '../../theme';

const ClientSideDatatable = function ({
  pageSize,
  initialSortKey,
  row,
  column,
  pagination,
  pages,
  refresh
}) {
  const [dropDownValue, setDropdownValue] = useState(pageSize || 10);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortKey, setSortKey] = useState(initialSortKey);
  const [data, setData] = useState(row);
  const [isDesc, setIsDesc] = useState(false);

  useEffect(() => {
    setData(row);
  }, [refresh, row]);

  const renderTable = () => {
    const rowData = [];
    const length =
      (pageNumber - 1) * dropDownValue + dropDownValue > row.length
        ? row.length
        : (pageNumber - 1) * dropDownValue + dropDownValue;
    if (row.length > 0 && length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = (pageNumber - 1) * dropDownValue; i < length; i++) {
        rowData.push(
          <StyledTr key={i}>
            {column.map((d, tdIndex) => {
              return (
                <StyledTd key={`td-${tdIndex}`} length={column.length}>
                  {d.customRow ? d.customRow(row[i][d.field], row[i]) : row[i][d.field]}
                </StyledTd>
              );
            })}
          </StyledTr>
        );
      }
    } else {
      rowData.push(
        <StyledTr>
          <StyledTd colSpan={column.length}>
            <Center>No Data Found!</Center>
          </StyledTd>
        </StyledTr>
      );
    }
    return rowData;
  };

  const sortData = (key) => {
    let sortedData;
    setPageNumber(1);
    if (sortKey === key) {
      if (isDesc) {
        setIsDesc(false);
        sortedData = data.sort((firstItem, secondItem) =>
          typeof firstItem[key] === 'number'
            ? firstItem[key] - secondItem[key]
            : firstItem[key].toLowerCase() < secondItem[key].toLowerCase()
            ? -1
            : 1
        );
      } else {
        setIsDesc(true);
        sortedData = data.sort((firstItem, secondItem) =>
          typeof firstItem[key] === 'number'
            ? secondItem[key] - firstItem[key]
            : secondItem[key].toLowerCase() < firstItem[key].toLowerCase()
            ? -1
            : 1
        );
      }
    } else {
      setIsDesc(false);
      sortedData = data.sort((firstItem, secondItem) =>
        typeof firstItem[key] === 'number'
          ? firstItem[key] - secondItem[key]
          : firstItem[key].toLowerCase() < secondItem[key].toLowerCase()
          ? -1
          : 1
      );
    }
    setSortKey(key);
    setData(sortedData);
  };

  return (
    <StyledContainer>
      <Box width="100%" overflowX="auto">
        <StyledTable variant="simple" style={{ backgroundColor: 'white' }}>
          <StyledThead>
            <StyledHeadTr>
              {column.map((header, index) => {
                return header.sort ? (
                  <StyledTh
                    className={sortKey === header.field ? 'active' : ''}
                    key={`th-${index}`}
                    onClick={() => sortData(header.field)}>
                    {header.label}
                    {sortKey === header.field ? (
                      isDesc ? (
                        <ArrowDown
                          style={{
                            display: 'inline-block',
                            color: colors.main.semiPrimary,
                            marginLeft: '3px'
                          }}
                        />
                      ) : (
                        <ArrowUp
                          style={{
                            display: 'inline-block',
                            color: colors.main.semiPrimary,
                            marginLeft: '3px'
                          }}
                        />
                      )
                    ) : (
                      <ArrowUp
                        style={{
                          display: 'inline-block',
                          color: colors.main.semiPrimary,
                          marginLeft: '3px'
                        }}
                      />
                    )}
                  </StyledTh>
                ) : (
                  <StyledTh key={`th-${index}`}>{header.label}</StyledTh>
                );
              })}
            </StyledHeadTr>
          </StyledThead>
          <Tbody>{row && data && renderTable()}</Tbody>
        </StyledTable>
      </Box>
      {pagination && data && data.length > 0 ? (
        <Flex justifyContent="flex-end" p="10px 0">
          {false && (
            <Box mr="10px">
              <Dropdown
                label=""
                onSelect={(e) => {
                  setDropdownValue(e.value);
                  setPageNumber(1);
                }}
                options={pages.map((page) => {
                  return { label: page, value: page };
                })}
                value={{ label: dropDownValue, value: dropDownValue }}
              />
            </Box>
          )}
          <Box mr="10px" p="10px 0">
            Showing {(pageNumber - 1) * dropDownValue + 1} to{' '}
            {pageNumber * dropDownValue < data.length ? pageNumber * dropDownValue : data.length} of{' '}
            {data.length} entries
          </Box>
          <Box>
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={() => setPageNumber(pageNumber - 1)}
              disabled={pageNumber <= 1}
              mr="1px"
              borderRadius="10px 0 0 10px">
              {' < '}
            </Button>
            {/* {<PageNumber />} */}
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={() => setPageNumber(pageNumber + 1)}
              borderRadius="0 10px 10px 0"
              disabled={pageNumber >= Math.ceil(data.length / dropDownValue)}>
              {' > '}
            </Button>
          </Box>
        </Flex>
      ) : (
        ''
      )}
    </StyledContainer>
  );
};

export default ClientSideDatatable;
