import { API_ENDPOINT } from '../../constants';

import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';

const getRatingData = async (clientId, departmentId, startDate, endDate) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_RATING_DATA}?client_id=${clientId}&department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getSiteRatingData = async (clientId, departmentId, shiftId) => {
  let params = `client_id=${clientId}&department_id=${departmentId}`;
  if (shiftId) params += `&shift_id=${shiftId}`;
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_SITE_RATING_DATA}?${params}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getAgencyRatingData = async (clientId, agencyId) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_AGENCY_RATING_DATA}?client_id=${clientId}${
      agencyId ? `&agency_id=${agencyId}` : ''
    }`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getClientRatingData = async (clientId) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_CLIENT_RATING_DATA}?client_id=${clientId}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getHeaderData = async (clientId, departmentId, startDate, endDate, agencyId, shiftId) => {
  let params = `client_id=${clientId}&department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`;
  if (agencyId) params += `&agency_id=${agencyId}`;
  if (shiftId) params += `&shift_id=${shiftId}`;
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_HEADER_DATA}?${params}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getLeaverAnalysisData = async (
  clientId,
  departmentId,
  startDate,
  endDate,
  agencyId,
  shiftId
) => {
  let params = `client_id=${clientId}&department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`;
  if (agencyId) params += `&agency_id=${agencyId}`;
  if (shiftId) params += `&shift_id=${shiftId}`;
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_LEAVER_ANALYSIS_DATA}?${params}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getActivityTopCardData = async (
  clientId,
  departmentId,
  startDate,
  endDate,
  agencyId,
  shiftId
) => {
  let params = `client_id=${clientId}&department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`;
  if (agencyId) params += `&agency_id=${agencyId}`;
  if (shiftId) params += `&shift_id=${shiftId}`;
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_ACTIVITY_TP_CARD_DATA}?${params}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

// const getWorkforceTopCardDemographicsData = async (clientId, departmentId) => {
//   const result = await axiosInstance.get(
//     `${API_ENDPOINT.GET_WORKFORCE_TOP_CARD_DEMOGRAPHICS}?client_id=${clientId}&department_id=${departmentId}`
//   );
//   if (result && result.ok) {
//     return prepareSuccessReponse(result);
//   }
//   return prepareErrorResponse(result);
// };

const getWorkforceTopCardLOSData = async (
  clientId,
  departmentId,
  startDate,
  endDate,
  agencyId,
  shiftId
) => {
  let params = `client_id=${clientId}&department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`;
  if (agencyId) params += `&agency_id=${agencyId}`;
  if (shiftId) params += `&shift_id=${shiftId}`;
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_WORKFORCE_TOP_CARD_LOS}?${params}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getWorkforceTopCardShiftUtiData = async (
  clientId,
  departmentId,
  startDate,
  endDate,
  agencyId,
  shiftId
) => {
  let params = `client_id=${clientId}&department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`;
  if (agencyId) params += `&agency_id=${agencyId}`;
  if (shiftId) params += `&shift_id=${shiftId}`;
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_WORKFORCE_TOP_CARD_SHIFT_UTI}?${params}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getWorkforceTopCardPoolUtiData = async (
  clientId,
  departmentId,
  startDate,
  endDate,
  agencyId,
  shiftId
) => {
  let params = `client_id=${clientId}&department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`;
  if (agencyId) params += `&agency_id=${agencyId}`;
  if (shiftId) params += `&shift_id=${shiftId}`;

  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_WORKFORCE_TOP_CARD_POOL_UTI}?${params}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getLeaverTopCardLOS = async (
  clientId,
  departmentId,
  startDate,
  endDate,
  agencyId,
  shiftId
) => {
  let params = `client_id=${clientId}&department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`;
  if (agencyId) params += `&agency_id=${agencyId}`;
  if (shiftId) params += `&shift_id=${shiftId}`;

  const result = await axiosInstance.get(`${API_ENDPOINT.GET_LEAVER_TOP_CARD_LOS}?${params}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getLeaverTopCardCounts = async (
  clientId,
  departmentId,
  startDate,
  endDate,
  agencyId,
  shiftId
) => {
  let params = `client_id=${clientId}&department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`;
  if (agencyId) params += `&agency_id=${agencyId}`;
  if (shiftId) params += `&shift_id=${shiftId}`;
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_LEAVER_TOP_CARD_COUNTS}?${params}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getLeaverTopCardPoolUti = async (clientId, departmentId, startDate, endDate, agencyId) => {
  const result = await axiosInstance.get(
    `${
      API_ENDPOINT.GET_LEAVER_TOP_CARD_POOL_UTI
    }?client_id=${clientId}&department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}${
      agencyId ? `&agency_id=${agencyId}` : ''
    }`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getActivityBottomCardShiftUti = async (
  clientId,
  departmentId,
  startDate,
  endDate,
  shiftId
) => {
  let params = `client_id=${clientId}&department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`;
  if (shiftId) params += `&shift_id=${shiftId}`;
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_ACTIVITY_BOTTOM_CARD_SHIFT_UTI}?${params}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

// const getActivityBottomCardLostShift = async (clientId, departmentId, startDate, endDate) => {
//   const result = await axiosInstance.get(
//     `${API_ENDPOINT.GET_ACTIVITY_BOTTOM_CARD_LOST_SHIFT}?client_id=${clientId}&department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`
//   );
//   if (result && result.ok) {
//     return prepareSuccessReponse(result);
//   }
//   return prepareErrorResponse(result);
// };

const getActivityBottomCardHeadCount = async (
  clientId,
  departmentId,
  startDate,
  endDate,
  shiftId
) => {
  let params = `client_id=${clientId}&department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`;
  if (shiftId) params += `&shift_id=${shiftId}`;
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_ACTIVITY_BOTTOM_CARD_HEAD_COUNT}?${params}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getActivityBottomCardSpend = async (clientId, departmentId, startDate, endDate, shiftId) => {
  let params = `client_id=${clientId}&department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`;
  if (shiftId) params += `&shift_id=${shiftId}`;
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_ACTIVITY_BOTTOM_CARD_SPEND}?${params}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getActivityBottomCardAverageHours = async (
  clientId,
  departmentId,
  startDate,
  endDate,
  shiftId
) => {
  let params = `client_id=${clientId}&department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`;
  if (shiftId) params += `&shift_id=${shiftId}`;
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_ACTIVITY_BOTTOM_CARD_AVERAGE_HOURS}?${params}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

// const getWorkforceDemo = async (clientId, departmentId) => {
//   const result = await axiosInstance.get(
//     `${API_ENDPOINT.GET_WORKFORCE_BOTTOM_CARD_DEMO}?client_id=${clientId}&department_id=${departmentId}`
//   );
//   if (result && result.ok) {
//     return prepareSuccessReponse(result);
//   }
//   return prepareErrorResponse(result);
// };

const getWorkforceShiftUti = async (clientId, departmentId, startDate, endDate, shiftId) => {
  let params = `client_id=${clientId}&department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`;
  if (shiftId) params += `&shift_id=${shiftId}`;
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_WORKFORCE_BOTTOM_CARD_SHIFT_UTL}?${params}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getWorkforceLOS = async (clientId, departmentId, startDate, endDate) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_WORKFORCE_BOTTOM_CARD_LOS}?client_id=${clientId}&department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getLeaverNSR = async (clientId, departmentId, startDate, endDate, shiftId) => {
  let params = `client_id=${clientId}&department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`;
  if (shiftId) params += `&shift_id=${shiftId}`;
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_LEAVER_BOTTOM_CARD_NSR}?${params}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getLeaverCount = async (clientId, departmentId, startDate, endDate, shiftId) => {
  let params = `client_id=${clientId}&department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`;
  if (shiftId) params += `&shift_id=${shiftId}`;
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_LEAVER_BOTTOM_CARD_COUNT}?${params}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

// const getLeaverShiftUtl = async (clientId, departmentId, startDate, endDate) => {
//   const result = await axiosInstance.get(
//     `${API_ENDPOINT.GET_LEAVER_BOTTOM_CARD_SHIFT_UTL}?client_id=${clientId}&department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`
//   );
//   if (result && result.ok) {
//     return prepareSuccessReponse(result);
//   }
//   return prepareErrorResponse(result);
// };

const getLeaverLOS = async (clientId, departmentId, startDate, endDate, shiftId) => {
  let params = `client_id=${clientId}&department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`;
  if (shiftId) params += `&shift_id=${shiftId}`;
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_LEAVER_BOTTOM_CARD_LOS}?${params}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export {
  getRatingData,
  getHeaderData,
  getLeaverAnalysisData,
  getActivityTopCardData,
  // getWorkforceTopCardDemographicsData,
  getWorkforceTopCardLOSData,
  getWorkforceTopCardShiftUtiData,
  getWorkforceTopCardPoolUtiData,
  getLeaverTopCardLOS,
  getLeaverTopCardCounts,
  // getLeaverTopCardShiftUti,
  getActivityBottomCardShiftUti,
  // getActivityBottomCardLostShift,
  getActivityBottomCardHeadCount,
  getActivityBottomCardSpend,
  getActivityBottomCardAverageHours,
  // getWorkforceDemo,
  getWorkforceShiftUti,
  getWorkforceLOS,
  getLeaverNSR,
  getLeaverCount,
  // getLeaverShiftUtl,
  getLeaverLOS,
  getLeaverTopCardPoolUti,
  getSiteRatingData,
  getAgencyRatingData,
  getClientRatingData
};
