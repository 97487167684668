/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable object-shorthand */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-else-return */
import { Box, SimpleGrid, Text, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SiteViewCard from './SiteViewCard';
import { HEADER_COLORS, USER_TYPE } from '../../../constants';
import { getSiteViewData } from '../../../redux/action/performanceGraph.action';
import Loader from '../../../components/Loader';

const SiteViewPerformance = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [siteViewData, setSiteViewData] = useState([]);
  const { userData, user_details } = useSelector((state) => state.authentication);
  const { selected_client_id, selected_performance_region_id } = useSelector(
    (state) => state.agency
  );
  const { startDate, endDate } = useSelector((state) => state.weekRange);

  const getClientId = () => {
    if (!isLoading) {
      switch (user_details.user_type_id) {
        case USER_TYPE.CLIENT_ADMIN:
          return userData.client_id;
        case USER_TYPE.AGENCY_ADMIN:
        case USER_TYPE.AGENCY_REGION_ADMIN:
        case USER_TYPE.AGENCY_SITE_ADMIN:
          return selected_client_id || userData.client_id;
        default:
          return selected_client_id || userData.client_id;
      }
    }
    return null;
  };

  const getRegionId = () => {
    if (!isLoading) {
      switch (user_details.user_type_id) {
        case USER_TYPE.SUPER_ADMIN:
        case USER_TYPE.CLIENT_ADMIN:
        case USER_TYPE.AGENCY_ADMIN:
          return selected_performance_region_id;
        case USER_TYPE.AGENCY_REGION_ADMIN:
          return user_details.region_id;
        case USER_TYPE.AGENCY_SITE_ADMIN:
        case USER_TYPE.SITE_ADMIN:
          return '';
        default:
          return selected_performance_region_id || userData.region_id;
      }
    }
    return null;
  };

  useEffect(async () => {
    if (getClientId()) {
      setSiteViewData([]);
      setIsLoading(true);
      const apiRes = await getSiteViewData(getClientId(), startDate, endDate, getRegionId());
      // console.log('Site Data:::: ', apiRes);
      if (apiRes.ok && apiRes.data.ok) {
        setSiteViewData(apiRes.data.result);
      }
      setIsLoading(false);
    }
  }, [startDate, selected_performance_region_id]);

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Loader />
      </Flex>
    );
  }
  if (siteViewData?.length === 0) {
    return (
      <Flex mt="20" justifyContent="center" alignItems="center">
        <Text textAlign="center" color="gray.500">
          No data available
        </Text>
      </Flex>
    );
  }
  return (
    <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} spacing={4} p={4} mt={12}>
      {siteViewData?.map((item) => (
        <Box
          key={item.s_id}
          border="solid"
          borderColor={HEADER_COLORS.ACTIVITY_COLOR}
          borderRadius="lg"
          p={1}>
          <SiteViewCard key={item.s_id} title={item.s_name} data={item.data} />
        </Box>
      ))}
    </SimpleGrid>
  );
};
export default SiteViewPerformance;
