/* eslint-disable react/prop-types */
import React from 'react';

import StyledRightPanel from './RightPanel.styled';

const RightPanel = function ({ children }) {
  return <StyledRightPanel bg="main.blueBackground">{children}</StyledRightPanel>;
};

export default RightPanel;
