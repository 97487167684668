/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { Box, Flex, Switch } from '@chakra-ui/react';
import constants from '../../constants';
import InputComponent from '../Input';
import StyledInputWrapper, { StyledInfoLabel } from './Forms.styled';
import SectorDropdown from './SectorDropdown';
import { allowAlphaNumeric, allowOnlyAlphabate } from '../../utils/helper';
import NationalityDropdown from './NationalityDropdown';
import DaySelectionDropdown from './DaySelectionDropdown';
import CountryDropDownData from '../../utils/countryData.json';
import BookingFormatDropdown from './BookingFormatDropdown';
import Dropdown from '../Dropdown';
import { StyledAgency } from '../../pages/ShiftBooking/ShiftBooking.styled';
import cronToText from '../../utils/cronToText';

const AddClient = function ({
  countryValue,
  dayValue,
  setCountryData,
  setDayData,
  setBookingData,
  bookingValue,
  data,
  setData,
  refresh,
  validations,
  sectorValue,
  isClientEdit,
  isHeaderForm
}) {
  const [sector, setSector] = useState(sectorValue);
  const [filePath, setFilePath] = useState(data.remote_directory || '');
  const [emailGroup, setEmailGroup] = useState(data.notification_email || '');

  // New state for Worker FTP and schedule
  const [workerFtp, setWorkerFtpEnabled] = useState(
    data.worker_ftp !== undefined && data.worker_ftp !== ''
      ? { value: data.worker_ftp, label: data.worker_ftp ? 'True' : 'False' }
      : { value: false, label: 'False' }
  );
  const [workerCronSchedule, setWorkerCronSchedule] = useState(data.worker_cron_expression || '');

  // New state for T&A FTP and schedule
  const [tnaFtp, setTnaFtp] = useState(
    data.tna_ftp !== undefined && data.tna_ftp !== ''
      ? { value: data.tna_ftp, label: data.tna_ftp ? 'True' : 'False' }
      : { value: false, label: 'False' }
  );
  const [tAndACronSchedule, setTAndACronSchedule] = useState(data.tna_cron_expression || '');

  const [workerPerformance, setWorkerPerformance] = useState(
    data.workerPerformance !== undefined && data.workerPerformance !== ''
      ? { value: data.workerPerformance, label: data.workerPerformance ? 'True' : 'False' }
      : { value: false, label: 'False' }
  );
  const [workerTraining, setWorkerTraining] = useState(
    data.workerTraining !== undefined && data.workerTraining !== ''
      ? { value: data.workerTraining, label: data.workerTraining ? 'True' : 'False' }
      : { value: false, label: 'False' }
  );

  const [rateCard, setRateCard] = useState(
    data.rateCardLookup !== undefined && data.rateCardLookup !== ''
      ? { value: data.rateCardLookup, label: data.rateCardLookup ? 'True' : 'False' }
      : { value: false, label: 'False' }
  );

  const initSwitch = data?.worker_invite_email;

  const setSectorData = (e) => {
    setSector(e);
    setData((prevData) => ({ ...prevData, sectorId: e.value }));
  };

  useEffect(() => {
    setSector(sectorValue);
  }, [sectorValue]);

  const onToggleChanged = (e) => {
    const isChecked = e.target.checked;
    setData((prevData) => ({ ...prevData, worker_invite_email: isChecked }));
  };

  const setWorkerPerformanceValue = (selectedOption) => {
    setWorkerPerformance(selectedOption);
    setData((prevData) => ({
      ...prevData,
      workerPerformance: selectedOption ? selectedOption.value : false
    }));
  };
  const setWorkerTrainingValue = (selectedOption) => {
    setWorkerTraining(selectedOption);
    setData((prevData) => ({
      ...prevData,
      workerTraining: selectedOption ? selectedOption.value : false
    }));
  };

  const handleWorkerCronScheduleChange = (e) => {
    // Maximum allowed length to prevent exceeding "59 23 31 12 6"
    const MAX_LENGTH = 13;

    // If input exceeds max length, don't update
    if (e.length > MAX_LENGTH) {
      return;
    }

    // Split the input into parts
    const parts = e.split(' ').filter((part) => part !== '');

    // Define maximum values for each part
    const maxValues = [59, 23, 31, 12, 6];

    // Check if any part exceeds its maximum value
    let isValid = true;
    parts.forEach((part, index) => {
      if (part !== '*' && !part.includes('/') && !part.includes('-') && !part.includes(',')) {
        const num = parseInt(part, 10);
        if (!Number.isNaN(num) && num > maxValues[index]) {
          isValid = false;
        }
      }
    });

    // If valid or incomplete, update the state
    if (isValid) {
      setWorkerCronSchedule(e);
      setData((prevData) => ({ ...prevData, worker_cron_expression: e }));
    }
  };

  const handleTAndACronScheduleChange = (e) => {
    // Maximum allowed length to prevent exceeding "59 23 31 12 6"
    const MAX_LENGTH = 13;

    // If input exceeds max length, don't update
    if (e.length > MAX_LENGTH) {
      return;
    }

    // Split the input into parts
    const parts = e.split(' ').filter((part) => part !== '');

    // Define maximum values for each part
    const maxValues = [59, 23, 31, 12, 6];

    // Check if any part exceeds its maximum value
    let isValid = true;
    parts.forEach((part, index) => {
      if (part !== '*' && !part.includes('/') && !part.includes('-') && !part.includes(',')) {
        const num = parseInt(part, 10);
        if (!Number.isNaN(num) && num > maxValues[index]) {
          isValid = false;
        }
      }
    });

    // If valid or incomplete, update the state
    if (isValid) {
      setTAndACronSchedule(e);
      setData((prevData) => ({ ...prevData, tna_cron_expression: e }));
    }
  };

  const handleFilePathChange = (e) => {
    // Remove any emoji characters using regex
    const sanitizedPath = e.replace(
      /[\u{1F300}-\u{1F9FF}]|[\u{1F600}-\u{1F64F}]|[\u{1F680}-\u{1F6FF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{1F900}-\u{1F9FF}]|[\u{1F1E0}-\u{1F1FF}]/gu,
      ''
    );
    setFilePath(sanitizedPath);
    setData((prevData) => ({ ...prevData, remote_directory: sanitizedPath }));
  };

  const handleEmailGroupChange = (e) => {
    // Remove any emoji characters using regex
    const sanitizedEmail = e.replace(
      /[\u{1F300}-\u{1F9FF}]|[\u{1F600}-\u{1F64F}]|[\u{1F680}-\u{1F6FF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{1F900}-\u{1F9FF}]|[\u{1F1E0}-\u{1F1FF}]/gu,
      ''
    );
    setEmailGroup(sanitizedEmail);
    setData((prevData) => ({ ...prevData, notification_email: sanitizedEmail }));
  };

  const validateCronSchedule = (cronExpression) => {
    // Return false if input is empty or not a string
    if (!cronExpression || typeof cronExpression !== 'string') {
      return false;
    }

    // Trim whitespace and normalize spaces
    const normalizedCron = cronExpression.trim().replace(/\s+/g, ' ');

    // Split into parts
    const parts = normalizedCron.split(' ');
    if (parts.length !== 5) {
      return false;
    }

    const [minute, hour, dayOfMonth, month, dayOfWeek] = parts;

    // Validation ranges
    const ranges = {
      minute: { min: 0, max: 59 },
      hour: { min: 0, max: 23 },
      dayOfMonth: { min: 1, max: 31 },
      month: { min: 1, max: 12 },
      dayOfWeek: { min: 0, max: 6 }
    };

    // Helper function to validate a single cron part
    const isValidPart = (part, rangeValues) => {
      // Allow asterisk
      if (part === '*') return true;

      // Allow single number within range
      if (/^\d+$/.test(part)) {
        const num = parseInt(part, 10);
        return num >= rangeValues.min && num <= rangeValues.max;
      }

      // Allow comma-separated list
      if (part.includes(',')) {
        return part.split(',').every((num) => {
          if (/^\d+$/.test(num)) {
            const value = parseInt(num, 10);
            return value >= rangeValues.min && value <= rangeValues.max;
          }
          return false;
        });
      }

      // Allow range with hyphen
      if (part.includes('-')) {
        const [start, end] = part.split('-').map((num) => parseInt(num, 10));
        return (
          Number.isInteger(start) &&
          Number.isInteger(end) &&
          start >= rangeValues.min &&
          end <= rangeValues.max &&
          start <= end
        );
      }

      // Allow step values
      if (part.includes('/')) {
        const [rangeStr, step] = part.split('/');
        const stepNum = parseInt(step, 10);

        if (!Number.isInteger(stepNum) || stepNum < 1) return false;

        if (rangeStr === '*') return true;

        if (rangeStr.includes('-')) {
          const [start, end] = rangeStr.split('-').map((num) => parseInt(num, 10));
          return Number.isInteger(start) && Number.isInteger(end) && start <= end;
        }

        return false;
      }

      return false;
    };

    // Validate each part
    return (
      isValidPart(minute, ranges.minute) &&
      isValidPart(hour, ranges.hour) &&
      isValidPart(dayOfMonth, ranges.dayOfMonth) &&
      isValidPart(month, ranges.month) &&
      isValidPart(dayOfWeek, ranges.dayOfWeek)
    );
  };

  const setRateCardValue = (selectedOption) => {
    setRateCard(selectedOption);
    setData((prevData) => ({
      ...prevData,
      rateCardLookup: selectedOption ? selectedOption.value : false
    }));
  };

  // New state for FTP credentials
  const [ftpHost, setFtpHost] = useState(data.ftp_host || '');
  const [ftpUsername, setFtpUsername] = useState(data.ftp_username || '');
  const [ftpPassword, setFtpPassword] = useState(data.ftp_password || '');

  // Handlers for new FTP inputs
  const handleFtpHostChange = (e) => {
    setFtpHost(e);
    setData((prevData) => ({ ...prevData, ftp_host: e }));
  };

  const handleFtpUsernameChange = (e) => {
    setFtpUsername(e);
    setData((prevData) => ({ ...prevData, ftp_username: e }));
  };

  const setTAndAFtp = (selectedOption) => {
    setTnaFtp(selectedOption);
    setData((prevData) => ({
      ...prevData,
      tna_ftp: selectedOption ? selectedOption.value : false
    }));
  };

  const handleFtpPasswordChange = (e) => {
    setFtpPassword(e);
    setData((prevData) => ({ ...prevData, ftp_password: e }));
  };

  const setWorkerFtp = (selectedOption) => {
    setWorkerFtpEnabled(selectedOption);
    setData((prevData) => ({
      ...prevData,
      worker_ftp: selectedOption ? selectedOption.value : false
    }));
  };

  return (
    <Box p="10px">
      <Flex>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.NAME}
            placeholder={constants.NAME}
            onChange={(e) => setData({ ...data, name: allowAlphaNumeric(e) })}
            value={data.name}
            validationObj={validations.name}
            refresh={refresh}
            maxLength="50"
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.ADDRESS1}
            placeholder={constants.ADDRESS1}
            onChange={(e) => setData({ ...data, address_line_1: e })}
            value={data.address_line_1}
            validationObj={validations.address_line_1}
            refresh={refresh}
            maxLength="50"
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.ADDRESS2}
            placeholder={constants.ADDRESS2}
            onChange={(e) => setData({ ...data, address_line_2: e })}
            value={data.address_line_2}
            validationObj={validations.address_line_2}
            refresh={refresh}
            maxLength="50"
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.ADDRESS3}
            placeholder={constants.ADDRESS3}
            onChange={(e) => setData({ ...data, address_line_3: e })}
            value={data.address_line_3}
            validationObj={validations.address_line_3}
            refresh={refresh}
            maxLength="50"
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.POST_CODE}
            placeholder={constants.POST_CODE}
            onChange={(e) => setData({ ...data, postCode: e })}
            value={data.postCode}
            validationObj={validations.postCode}
            refresh={refresh}
            maxLength="15"
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.CITY}
            placeholder={constants.CITY}
            onChange={(e) => setData({ ...data, city: allowOnlyAlphabate(e) })}
            value={data.city}
            validationObj={validations.city}
            refresh={refresh}
            maxLength="20"
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <NationalityDropdown
            label="Country"
            country={countryValue}
            setCountry={setCountryData}
            validationObj={validations.country}
            refresh={refresh}
            dropdownData={CountryDropDownData}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <DaySelectionDropdown
            disabled={isClientEdit}
            dayValue={dayValue}
            setDayData={setDayData}
            validationObj={validations.weekday_start}
            refresh={refresh}
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <BookingFormatDropdown
            disabled={isClientEdit}
            bookingValue={bookingValue}
            setBookingData={setBookingData}
            validationObj={validations.booking_format}
            refresh={refresh}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <Dropdown
            label="Worker Performance"
            options={[
              { value: true, label: 'True' },
              { value: false, label: 'False' }
            ]}
            onSelect={setWorkerPerformanceValue}
            value={workerPerformance}
            placeholder="Select"
            disabled={isHeaderForm}
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper>
          <SectorDropdown
            sector={sector}
            validationObj={validations.sectorId}
            refresh={refresh}
            setSector={setSectorData}
          />
        </StyledInputWrapper>

        <StyledInputWrapper
          style={{
            paddingLeft: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
          <StyledInfoLabel style={{ color: '#426b9d', fontWeight: 400 }}>
            Allow Worker Invite
          </StyledInfoLabel>

          <Switch
            id="user-switch-block"
            float="left"
            isChecked={data.worker_invite_email}
            isDisabled={(isClientEdit && initSwitch) || isHeaderForm}
            onChange={(e) => onToggleChanged(e)}
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <Dropdown
            label="Worker Training"
            options={[
              { value: true, label: 'True' },
              { value: false, label: 'False' }
            ]}
            onSelect={setWorkerTrainingValue}
            value={workerTraining}
            placeholder="Select"
            disabled={isHeaderForm}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <Dropdown
            label="Rate Card Lookup"
            options={[
              { value: true, label: 'True' },
              { value: false, label: 'False' }
            ]}
            onSelect={setRateCardValue}
            value={rateCard}
            placeholder="Rate Card Lookup"
            disabled={isHeaderForm}
          />
        </StyledInputWrapper>
      </Flex>
      {!isHeaderForm && (
        <StyledAgency>
          <Flex>
            <StyledInputWrapper style={{ marginRight: '8px' }}>
              <InputComponent
                lable="FTP Host"
                placeholder="FTP Host"
                onChange={handleFtpHostChange}
                value={ftpHost}
                validationObj={validations.ftp_host}
                refresh={refresh}
                maxLength="100"
                disabled={!workerFtp.value && !tnaFtp.value}
              />
            </StyledInputWrapper>
            <StyledInputWrapper>
              <InputComponent
                lable="FTP Username"
                placeholder="FTP Username"
                onChange={handleFtpUsernameChange}
                value={ftpUsername}
                validationObj={validations.ftp_username}
                refresh={refresh}
                maxLength="100"
                disabled={!workerFtp.value && !tnaFtp.value}
              />
            </StyledInputWrapper>
          </Flex>
          <Flex>
            <StyledInputWrapper style={{ marginRight: '8px' }}>
              <InputComponent
                lable="FTP Password"
                placeholder="FTP Password"
                onChange={handleFtpPasswordChange}
                value={ftpPassword}
                validationObj={validations.ftp_password}
                refresh={refresh}
                maxLength="100"
                disabled={!workerFtp.value && !tnaFtp.value}
              />
            </StyledInputWrapper>
          </Flex>
          <Flex>
            <StyledInputWrapper style={{ marginRight: '8px' }}>
              <Dropdown
                label="Worker FTP Lookup"
                options={[
                  { value: true, label: 'True' },
                  { value: false, label: 'False' }
                ]}
                onSelect={setWorkerFtp}
                validationObj={validations.worker_ftp}
                value={workerFtp}
                placeholder="Select"
              />
            </StyledInputWrapper>
            <StyledInputWrapper>
              <InputComponent
                lable="Worker Job Schedule"
                disabled={!workerFtp.value}
                placeholder="(e.g., '0 12 * * 1')"
                onChange={handleWorkerCronScheduleChange}
                value={workerCronSchedule}
                validationObj={validations.worker_cron_expression}
                refresh={refresh}
                maxLength="50"
              />
              {/* Display cronToText output */}
              {workerFtp.value && workerCronSchedule && (
                <StyledInfoLabel
                  style={{
                    color: validateCronSchedule(workerCronSchedule) ? '#426b9d' : '#FF6666',
                    fontWeight: 400
                  }}>
                  {cronToText(workerCronSchedule)}
                </StyledInfoLabel>
              )}
            </StyledInputWrapper>
          </Flex>
          <Flex>
            <StyledInputWrapper style={{ marginRight: '8px' }}>
              <Dropdown
                label="T&A FTP Lookup"
                options={[
                  { value: true, label: 'True' },
                  { value: false, label: 'False' }
                ]}
                onSelect={setTAndAFtp}
                validationObj={validations.tna_ftp}
                value={tnaFtp}
                placeholder="Select"
              />
            </StyledInputWrapper>
            <StyledInputWrapper>
              <InputComponent
                lable="T&A Job Schedule"
                disabled={!tnaFtp.value}
                placeholder="(e.g., '0 12 * * 1')"
                onChange={handleTAndACronScheduleChange}
                value={tAndACronSchedule}
                validationObj={validations.tna_cron_expression}
                refresh={refresh}
                maxLength="50"
              />
              {/* Display cronToText output */}
              {tnaFtp.value && tAndACronSchedule && (
                <StyledInfoLabel
                  style={{
                    color: validateCronSchedule(tAndACronSchedule) ? '#426b9d' : '#FF6666',
                    fontWeight: 400
                  }}>
                  {cronToText(tAndACronSchedule)}
                </StyledInfoLabel>
              )}
            </StyledInputWrapper>
          </Flex>
          <Flex>
            <StyledInputWrapper style={{ marginRight: '8px' }}>
              <InputComponent
                lable="File Path for Upload"
                placeholder="(e.g., '/home/downloads')"
                onChange={handleFilePathChange}
                value={filePath}
                validationObj={validations.remote_directory}
                refresh={refresh}
                maxLength="100"
                disabled={!workerFtp.value && !tnaFtp.value}
              />
            </StyledInputWrapper>
            <StyledInputWrapper>
              <InputComponent
                lable="Email for Status Updates"
                placeholder="Email for Status Updates"
                onChange={handleEmailGroupChange}
                value={emailGroup}
                validationObj={validations.notification_email}
                refresh={refresh}
                maxLength="100"
                disabled={!workerFtp.value && !tnaFtp.value}
              />
            </StyledInputWrapper>
          </Flex>
        </StyledAgency>
      )}
    </Box>
  );
};

export default AddClient;
