import { extendTheme } from '@chakra-ui/react';

export const colors = {
  main: {
    primary: '#124684',
    semiPrimary: '#426b9d',
    lightPrimary: '#426b9d1f',
    secondary: '#DCDCDC',
    black: '#000000',
    white: '#ffffff',
    gray: '#A0AEC0',
    error: '#F56565',
    blue: '#322fed',
    grayBackground: '#f6f6f6',
    blueBackground: '#E9F4FF',
    positiveGreen: '#64C047',
    negativeRed: '#F17A68',
    mobileBackground: '#0072fe'
  }
};
// do not remove this
export const breakpoints = {
  sm: '40em', // 640px
  md: '52em', // 832px
  lg: '64em', // 1024px
  xl: '98em', // 1440px
  '2xl': '113em' // 1680px
};

const theme = extendTheme({ colors, breakpoints });

export default theme;
