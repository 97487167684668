/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import { Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Dropdown from '../Dropdown';
import { BookingFormat } from '../../constants';

const BookingFormatDropdown = function ({
  bookingValue,
  validationObj,
  setBookingData,
  refresh,
  disabled
}) {
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setIsValid] = useState(validationObj?.isvalid || true);

  useEffect(() => {
    if (validationObj && !validationObj?.isvalid) {
      setErrorMsg(validationObj?.errorMessage);
      setIsValid(validationObj?.isvalid);
    }
  }, [refresh]);

  const setDropdownData = (e) => {
    setErrorMsg(null);
    setBookingData(e);
    setIsValid(true);
  };

  return (
    <>
      <Dropdown
        label="Booking Format*"
        placeholder="Select"
        options={BookingFormat.map((item) => ({
          label: item,
          value: item
        }))}
        onSelect={(e) => {
          setDropdownData(e);
        }}
        value={bookingValue}
        isError={!isValid}
        disabled={disabled}
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error!'}
      </Text>
    </>
  );
};

export default BookingFormatDropdown;
