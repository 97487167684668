import { Flex } from '@chakra-ui/react';
import { PropTypes } from 'prop-types';
import InputComponent from '../Input';
import StyledInputWrapper from '../Forms/Forms.styled';
import { WEEK_DAYS } from '../../constants';

const RequirementPerDayForm = function ({
  workersForEachDay,
  setNumberOfWorker,
  bookingFormat,
  validationObject,
  refresh,
  checkIsPreviousDay
}) {
  return (
    <Flex>
      {Object.keys(workersForEachDay).map((dayIndex) => (
        // eslint-disable-next-line eqeqeq
        <StyledInputWrapper key={dayIndex} style={{ marginLeft: dayIndex == 1 ? 0 : '8px' }}>
          <InputComponent
            lable={WEEK_DAYS[dayIndex - 1]}
            placeholder={WEEK_DAYS[dayIndex - 1]}
            onChange={(e) => setNumberOfWorker(e, dayIndex, bookingFormat)}
            onKeyDown={(e) => {
              if (bookingFormat === 'HEADS' && ['e', 'E', '+', '-', '.'].includes(e.key)) {
                e.stopPropagation();
                e.preventDefault();
              } else if (bookingFormat === 'HOURS' && ['e', 'E', '+', '-'].includes(e.key)) {
                e.stopPropagation();
                e.preventDefault();
              }
            }}
            value={workersForEachDay[dayIndex]}
            refresh={refresh}
            type="number"
            disabled={checkIsPreviousDay(dayIndex)}
            labelFontSize="xs"
            style={{ padding: '0px 8px', textAlign: 'center' }}
            validationObj={{ isvalid: true, ...validationObject[dayIndex] }}
          />
        </StyledInputWrapper>
      ))}
    </Flex>
  );
};
RequirementPerDayForm.propTypes = {
  workersForEachDay: PropTypes.objectOf(PropTypes.string).isRequired,
  setNumberOfWorker: PropTypes.func.isRequired,
  bookingFormat: PropTypes.oneOf(['HEADS', 'HOURS']).isRequired,
  validationObject: PropTypes.objectOf(
    PropTypes.shape({
      required: PropTypes.bool,
      errorMessage: PropTypes.string,
      isvalid: PropTypes.bool
    })
  ).isRequired,
  refresh: PropTypes.bool,
  checkIsPreviousDay: PropTypes.func.isRequired
};
RequirementPerDayForm.defaultProps = {
  refresh: false
};
export default RequirementPerDayForm;
