/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */

import React, { useEffect, useState } from 'react';
import { Text, useToast } from '@chakra-ui/react';
import { TOAST_SETTINGS } from '../../constants';
import { getClients } from '../../redux/action/client.action';
import Dropdown from '../Dropdown';

const CompanyDropdown = function ({
  company,
  setCompany,
  validationObj,
  refresh,
  disabled = false,
  setDefaultValue
}) {
  const toast = useToast();
  const [companies, setCompanies] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj?.isvalid || true);

  useEffect(async () => {
    const result = await getClients(1000, 1, 'name', 'ASC');
    if (result && result.ok) {
      setCompanies(result.data.client_details);
      if (setDefaultValue && result.data.client_details.length > 0) {
        setCompany({
          label: result.data.client_details[0].client_name,
          value: result.data.client_details[0].client_id,
          is_restricted: result.data.client_details[0].is_restricted,
          comment_restricted: result.data.client_details[0].comment_restricted,
          weekday_start: result.data.client_details[0].weekday_start,
          total_assignment_pay: result.data.client_details[0].total_assignment_pay
        });
      }
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  }, []);
  useEffect(() => {
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage);
      setValid(validationObj.isvalid);
    }
  }, [refresh]);
  const setCompanyData = (e) => {
    const client_data = companies.find((o) => {
      if (o.client_name === e.label) {
        return o;
      }
    });
    setErrorMsg(null);
    setValid(true);
    setCompany({
      ...e,
      is_restricted: client_data.is_restricted,
      comment_restricted: client_data.comment_restricted,
      weekday_start: client_data.weekday_start,
      total_assignment_pay: client_data.total_assignment_pay
    });
  };
  return (
    <>
      <Dropdown
        label={`Client${validationObj?.required ? '*' : ''}`}
        onSelect={setCompanyData}
        options={companies.map((comp) => {
          return { label: comp.client_name, value: comp.client_id };
        })}
        value={company}
        placeholder=""
        isError={!isValid}
        disabled={disabled}
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error !'}
      </Text>
    </>
  );
};

export default CompanyDropdown;
