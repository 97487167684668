/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import { StyledDropDown, StyledDropDownContent } from './DropdownMenu.styled';

const DropDownMenu = function ({ LabelComponent, DropDownItems }) {
  return (
    <StyledDropDown>
      {LabelComponent}
      <StyledDropDownContent>{DropDownItems}</StyledDropDownContent>
    </StyledDropDown>
  );
};

DropDownMenu.propTypes = {
  LabelComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  DropDownItems: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
};

export default DropDownMenu;
