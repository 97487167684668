/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Text, useToast } from '@chakra-ui/react';
// eslint-disable-next-line import/named
import { TOAST_SETTINGS } from '../../constants';
import Dropdown from '../Dropdown';
import {
  getRoleTypeDropDownData,
  getRoleTypeDropDownDataForMessaging
} from '../../redux/action/role.action';

const JobDropdown = function ({
  setRoleType,
  roleType,
  validationObj,
  refresh,
  multiple,
  siteId,
  type,
  disabled = false
}) {
  const toast = useToast();
  const [jobs, setJobs] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj?.isvalid || true);

  useEffect(async () => {
    if (siteId) {
      const result =
        type === 'messaging'
          ? await getRoleTypeDropDownDataForMessaging(siteId)
          : await getRoleTypeDropDownData(siteId);
      if (result && result.ok) {
        setJobs(result.data.job_list);
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
    }
  }, [siteId]);

  useEffect(() => {
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage);
      setValid(validationObj.isvalid);
    }
  }, [refresh]);

  const setSectorData = (e) => {
    setErrorMsg(null);
    setValid(true);
    setRoleType(e);
  };

  return (
    <>
      <Dropdown
        label={`Role${validationObj?.required ? '*' : ''}`}
        onSelect={(e) => {
          setSectorData(e);
        }}
        options={jobs.map((jobData) => {
          return { label: jobData.name, value: jobData.id };
        })}
        value={roleType}
        placeholder=""
        multiple={multiple}
        isError={!isValid}
        disabled={disabled}
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error !'}
      </Text>
    </>
  );
};

export default JobDropdown;
