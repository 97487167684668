/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledRateCardContainer = styled.div``;

export const StyledRow = styled.div`
  display: flex;
`;

export const StyledInfoBlock = styled.div`
  width: 50%;
`;

export const StyledInfoLabel = styled.div`
  font-weight: 600;
`;

export const StyledInfoValue = styled.div`
  font-size: 12px;
`;
