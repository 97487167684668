/* eslint-disable no-self-compare */
/* eslint-disable consistent-return */
import React from 'react';
import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { USER_TYPE } from '../../constants';
import AdminDashboard from '../AdminDashboard';
import AgencyDashboard from '../AgencyDashboard';
import ClientDashboard from '../ClientDashboard';
import RegionDashboard from '../RegionDashboard';
import SiteDashboard from '../SiteDashboard';

const Dashboard = function () {
  const { user_details: userDetails } = useSelector((state) => state.authentication);

  const renderDashboard = () => {
    switch (userDetails.user_type_id) {
      case USER_TYPE.SUPER_ADMIN:
        return <AdminDashboard />;
      case USER_TYPE.CLIENT_ADMIN:
        return <ClientDashboard />;
      case USER_TYPE.AGENCY_ADMIN:
        return <AgencyDashboard />;
      case USER_TYPE.REGION_ADMIN:
        return <RegionDashboard />;
      case USER_TYPE.SITE_ADMIN:
        return <SiteDashboard />;
      case USER_TYPE.AGENCY_REGION_ADMIN:
        return <RegionDashboard />;
      case USER_TYPE.AGENCY_SITE_ADMIN:
        return <SiteDashboard />;
      default:
        return <>Dashboard</>;
    }
  };

  return <Box>{renderDashboard()}</Box>;
};

Dashboard.propTypes = {};

export default Dashboard;
