/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import InputComponent from '../Input';
import StyledInputWrapper from './Forms.styled';
import SiteDropdown from './SiteDropdown';
import constants from '../../constants';
import RoleTypeDropdown from './RoleTypeDropDown';
import RoleShiftDropdown from './RoleShiftDropdown';
import RoleDepartmentDropdown from './RoleDepartmentDropdown';
// import RateCardDropdown from './RateCardDropDown';
import { allowOnlyAToZ, allowTwoDecimalNumbers } from '../../utils/helper';

const AddRole = function ({
  data,
  setData,
  validations,
  refresh,
  siteValue,
  roleTypeValue,
  departmentValue,
  shiftValue
  // rateCardValue
}) {
  const [site, setSite] = useState(siteValue);
  const [roleType, setRoleType] = useState(roleTypeValue);
  const [department, setDepartment] = useState(departmentValue);
  const [shift, setShift] = useState(shiftValue);
  // const [rateCard, setRateCard] = useState(rateCardValue);

  const setSiteData = (e) => {
    setSite(e);
    setData({ ...data, siteId: e.value, shiftId: '', departmentId: [] });
  };

  const setRoleTypeData = (e) => {
    setRoleType(e);
    setData({ ...data, type: e.value });
  };

  const setDepartmentData = (e) => {
    setDepartment(e);
    if (e.value) {
      setData({ ...data, departmentId: [e.value] });
    } else {
      setData({ ...data, departmentId: [] });
    }
  };

  const setShiftData = (e) => {
    setShift(e);
    setData((previous) => {
      return { ...previous, shiftId: e.value };
    });
  };

  return (
    <Box p="10px">
      <Flex>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.ROLE_NAME}
            placeholder={constants.ROLE_NAME}
            onChange={(e) => setData({ ...data, name: allowOnlyAToZ(e) })}
            value={data.name}
            validationObj={validations.name}
            refresh={refresh}
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper>
          <SiteDropdown
            site={site}
            setSite={setSiteData}
            disabled={false}
            multiple={false}
            validationObj={validations.siteId}
            refresh={refresh}
            label="Sites"
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <RoleTypeDropdown
            roleType={roleType}
            setRoleType={setRoleTypeData}
            disabled={false}
            multiple={false}
            validationObj={validations.type}
            refresh={refresh}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <RoleShiftDropdown
            site={site}
            shift={shift}
            setShift={setShiftData}
            multiple={false}
            validationObj={validations.shiftId}
            refresh={refresh}
          />
        </StyledInputWrapper>
      </Flex>

      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.HOUR_PER_WEEK}
            placeholder={constants.HOUR_PER_WEEK}
            value={data.hoursPerWeek}
            validationObj={validations.hoursPerWeek}
            refresh={refresh}
            type="number"
            onChange={(e) => setData({ ...data, hoursPerWeek: allowTwoDecimalNumbers(e) })}
          />
        </StyledInputWrapper>
        {/* <StyledInputWrapper>
          <RateCardDropdown
            rateCard={rateCard}
            setRateCard={setRateCardData}
            multiple={false}
            validationObj={validations.rateCardId}
            refresh={refresh}
          />
        </StyledInputWrapper> */}
      </Flex>

      <Flex>
        <StyledInputWrapper>
          <RoleDepartmentDropdown
            site={site}
            department={department}
            validationObj={validations.departmentId}
            refresh={refresh}
            setDepartment={setDepartmentData}
          />
        </StyledInputWrapper>
      </Flex>
    </Box>
  );
};

export default AddRole;
