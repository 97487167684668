/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Button, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import constants, {
  ADD_DYNAMIC_RATE_CARD_VALIDATIONS,
  ADD_RATE_CARD_VALIDATIONS,
  CURRENCY_DATA,
  CUSTOM_TOAST_DURATION,
  PAGE_TITLE,
  PATH,
  TOAST_SETTINGS
} from '../../constants';
import { getRateCards, addRateCard, updateRateCard } from '../../redux/action/rateCard.action';
import { getModulePermissions } from '../../utils/hooks';
import {
  StyledInfoBlock,
  StyledInfoLabel,
  StyledInfoValue,
  StyledRateCardContainer,
  StyledRow
} from './RateCard.styled';
import Card from '../../components/Card';
import Loader from '../../components/Loader';
import ModalComponent from '../../components/Modal';
import AddRateCard from '../../components/Forms/AddRateCard';
import { deepClone, validateForm } from '../../utils/helper';
import ServerSideDatatable from '../../components/ServerSideDatatable';
import AddDynamicRateCard from '../../components/Forms/AddDynamicRateCard';

const INITIAL_STATIC_RATE_CARD = {
  name: '',
  currency: '',
  pay_per_hour: '',
  insurance_rate: '',
  holiday_pay_rate: '',
  apprenticeship_rate: '',
  pension_rate: '',
  full_time_hours: '',
  overtime_pay: ''
};

const INITIAL_DYNAMIC_RATE_CARD = {
  name: '',
  currency: '',
  pay_per_hour: '10',
  insurance_rate: '4',
  holiday_pay_rate: '8',
  apprenticeship_rate: '2.5',
  pension_rate: '20.8',
  full_time_hours: '20.8',
  overtime_pay: '15.5'
};

const INITIAL_RATE_CARD_COLUMNS = [
  { label: 'Name', field: 'name', sort: false },
  { label: 'Full Time Hours Value', field: 'full_time_hours', sort: false },
  { label: 'Pay Per Hour', field: 'pay_per_hour', sort: false },
  { label: 'Insurance Rate', field: 'insurance_rate', sort: false },
  { label: 'Apprenticeship Rate', field: 'apprenticeship_rate', sort: false },
  { label: 'Pension Rate', field: 'pension_rate', sort: false },
  { label: 'Currency', field: 'currency', sort: false }
];

const RateCard = function () {
  useEffect(() => {
    document.title = PAGE_TITLE.RATE_CARD;
  }, []);

  const { user_details: userDetails } = useSelector((state) => state.authentication);
  const permission = getModulePermissions(PATH.ROLE.CODE);
  const toast = useToast();
  const [isRateCardModalOpen, setIsRateCardModalOpen] = useState(false);
  const [reload, setReload] = useState([]);
  const [isPageLoader, setIsPageLoader] = useState(false);
  const [validations, setValidations] = useState(deepClone(ADD_RATE_CARD_VALIDATIONS));
  const [dynamicValidations, setDynamicValidations] = useState(
    deepClone(ADD_DYNAMIC_RATE_CARD_VALIDATIONS)
  );
  const [refresh, setRefresh] = useState(false);
  const [isStaticRateCard, setIsStaticRateCard] = useState(true);
  const [staticRateCardData, setStaticRateCardData] = useState({ ...INITIAL_STATIC_RATE_CARD });
  const [dynamicRateCardData, setDynamicRateCardData] = useState({ ...INITIAL_DYNAMIC_RATE_CARD });
  const [rateCardListingColumns, setRateCardListingColumns] = useState([
    ...INITIAL_RATE_CARD_COLUMNS
  ]);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [selectedRateCard, setSelectedRateCard] = useState(null);

  const resetRateCardData = () => {
    setStaticRateCardData({ ...INITIAL_STATIC_RATE_CARD });
    setDynamicRateCardData({ ...INITIAL_DYNAMIC_RATE_CARD });
    setIsStaticRateCard(true);
  };

  const closeRateCardModal = () => {
    setIsRateCardModalOpen(false);
    setIsEdit(false);
    setSelectedCurrency({});
    resetRateCardData();
    setValidations(deepClone(ADD_RATE_CARD_VALIDATIONS));
    setDynamicValidations(deepClone(ADD_DYNAMIC_RATE_CARD_VALIDATIONS));
  };

  const closeRateCardModalBtnHandler = () => {
    closeRateCardModal();
  };

  const saveRateCard = async () => {
    if (isStaticRateCard) {
      const [validationObject, isFormValid] = validateForm(staticRateCardData, validations);
      setValidations(validationObject);
      if (isFormValid) {
        setIsStaticRateCard(false);
      } else {
        setRefresh(!refresh);
      }
    } else {
      const [validationObject, isFormValid] = validateForm(dynamicRateCardData, dynamicValidations);
      setValidations(validationObject);
      if (isFormValid) {
        const payload = {
          name: staticRateCardData.name,
          currency: staticRateCardData.currency,
          pay_per_hour: staticRateCardData.pay_per_hour,
          insurance_rate: staticRateCardData.insurance_rate,
          holiday_pay_rate: staticRateCardData.holiday_pay_rate,
          apprenticeship_rate: staticRateCardData.apprenticeship_rate,
          pension_rate: staticRateCardData.pension_rate,
          overtime_pay: staticRateCardData.overtime_pay,
          full_time_hours: staticRateCardData.full_time_hours,
          pay_per_hour_dynamic: dynamicRateCardData.pay_per_hour,
          insurance_rate_dynamic: dynamicRateCardData.insurance_rate,
          holiday_pay_rate_dynamic: dynamicRateCardData.holiday_pay_rate,
          apprenticeship_rate_dynamic: dynamicRateCardData.apprenticeship_rate,
          pension_rate_dynamic: dynamicRateCardData.pension_rate,
          full_time_hours_dynamic: dynamicRateCardData.full_time_hours,
          overtime_pay_dynamic: dynamicRateCardData.overtime_pay,
          client_id: userDetails.client_id.toString()
        };

        // Check if isEdit
        if (isEdit) {
          const { id } = staticRateCardData;
          delete payload.client_id;
          const result = await updateRateCard(id, payload);
          if (result && result.ok) {
            setReload(!reload);
            toast({
              ...TOAST_SETTINGS.SETTINGS,
              duration: CUSTOM_TOAST_DURATION.duration,
              status: TOAST_SETTINGS.SUCCESS,
              title: result.message
            });
            closeRateCardModalBtnHandler();
          } else {
            toast({
              ...TOAST_SETTINGS.SETTINGS,
              status: TOAST_SETTINGS.ERROR,
              title: result.error
            });
          }
        } else {
          const result = await addRateCard(payload);
          if (result && result.ok) {
            setReload(!reload);
            toast({
              ...TOAST_SETTINGS.SETTINGS,
              duration: CUSTOM_TOAST_DURATION.duration,
              status: TOAST_SETTINGS.SUCCESS,
              title: result.message
            });
            closeRateCardModalBtnHandler();
          } else {
            toast({
              ...TOAST_SETTINGS.SETTINGS,
              status: TOAST_SETTINGS.ERROR,
              title: result.error
            });
          }
        }
      } else {
        setRefresh(!refresh);
      }
    }
  };

  const addRateCardClickHandler = () => {
    saveRateCard();
  };

  const cancelRateCardBtnClickHandler = () => {
    setValidations(deepClone(ADD_RATE_CARD_VALIDATIONS));
    setDynamicValidations(deepClone(ADD_DYNAMIC_RATE_CARD_VALIDATIONS));
    setRefresh(!refresh);
    if (isStaticRateCard) {
      closeRateCardModal();
    } else {
      setIsStaticRateCard(true);
    }
  };

  const editRateCardData = (id, data) => {
    setIsEdit(true);
    const rateCard = { ...data };

    // eslint-disable-next-line no-restricted-syntax
    for (const key in rateCard) {
      if (Object.hasOwnProperty.call(rateCard, key)) {
        if (typeof rateCard[key] === 'number') {
          rateCard[key] = rateCard[key].toString();
        }
      }
    }

    setStaticRateCardData({
      name: rateCard.name,
      currency: rateCard.currency,
      pay_per_hour: rateCard.pay_per_hour,
      insurance_rate: rateCard.insurance_rate,
      holiday_pay_rate: rateCard.holiday_pay_rate,
      apprenticeship_rate: rateCard.apprenticeship_rate,
      pension_rate: rateCard.pension_rate,
      full_time_hours: rateCard.full_time_hours,
      overtime_pay: rateCard.overtime_pay,
      id: rateCard.id
    });

    setDynamicRateCardData({
      pay_per_hour: rateCard.pay_per_hour_dynamic,
      insurance_rate: rateCard.insurance_rate_dynamic,
      holiday_pay_rate: rateCard.holiday_pay_rate_dynamic,
      apprenticeship_rate: rateCard.apprenticeship_rate_dynamic,
      pension_rate: rateCard.pension_rate_dynamic,
      full_time_hours: rateCard.full_time_hours_dynamic,
      overtime_pay: rateCard.overtime_pay_dynamic,
      id: rateCard.id
    });

    setIsStaticRateCard(true);
    setIsRateCardModalOpen(true);
  };

  useEffect(() => {
    setRateCardListingColumns([
      ...rateCardListingColumns,
      {
        label: 'Information',
        field: 'id',
        sort: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        customRow: (cell, row) => {
          // eslint-disable-next-line no-plusplus
          return (
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={() => setSelectedRateCard(row)}>
              {constants.RATE_CARD.MORE_INFORMATION}
            </Button>
          );
        }
      }
    ]);

    setIsPageLoader(true);
    setReload(!reload);
  }, []);

  useEffect(() => {
    setRateCardListingColumns([
      ...rateCardListingColumns,
      {
        label: 'Actions',
        field: 'id',
        sort: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        customRow: (cell, row) => {
          // eslint-disable-next-line no-plusplus
          return (
            <div style={{ display: 'flex' }}>
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={() => setSelectedRateCard(row)}>
                {constants.RATE_CARD.DETAIL}
              </Button>
              {permission && permission.includes('edit') ? (
                <Button
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  ml={2}
                  onClick={() => editRateCardData(cell, row)}>
                  {constants.RATE_CARD.EDIT_BUTTON_TEXT}
                </Button>
              ) : null}
            </div>
          );
        }
      }
    ]);
  }, [permission]);

  useEffect(() => {
    setRefresh(!refresh);
  }, [validations]);

  useEffect(() => {
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < CURRENCY_DATA.length; index++) {
      const currency = CURRENCY_DATA[index];
      if (currency.value === staticRateCardData.currency) {
        setSelectedCurrency({ ...currency });
        break;
      }
    }
  }, [staticRateCardData.currency]);

  return (
    <StyledRateCardContainer>
      {isPageLoader ? <Loader /> : null}
      <Card>
        {permission && permission.includes('create') && (
          <Button
            bg="main.semiPrimary"
            color="main.white"
            _hover={{ bg: 'main.primary' }}
            _active={{
              bg: 'main.semiPrimary',
              borderColor: 'main.primary'
            }}
            onClick={() => setIsRateCardModalOpen(true)}>
            {constants.RATE_CARD.CREATE_BUTTON_TEXT}
          </Button>
        )}

        {selectedRateCard ? (
          <ModalComponent
            isOpen={selectedRateCard}
            onClose={() => setSelectedRateCard(null)}
            modalTitle={selectedRateCard.name.toUpperCase()}
            modalContent={
              <div>
                <StyledRow>
                  <StyledInfoBlock>
                    <StyledInfoLabel>Pay per Hour Static</StyledInfoLabel>
                    <StyledInfoValue>{`${selectedRateCard.pay_per_hour} ${selectedRateCard.currency}`}</StyledInfoValue>
                  </StyledInfoBlock>
                  <StyledInfoBlock>
                    <StyledInfoLabel>Pay per Hour Dynamic</StyledInfoLabel>
                    <StyledInfoValue>{`${selectedRateCard.pay_per_hour_dynamic} ${selectedRateCard.currency}`}</StyledInfoValue>
                  </StyledInfoBlock>
                </StyledRow>

                <StyledRow>
                  <StyledInfoBlock>
                    <StyledInfoLabel>National Insurance Rate Static</StyledInfoLabel>
                    <StyledInfoValue>{`${selectedRateCard.insurance_rate} ${selectedRateCard.currency}`}</StyledInfoValue>
                  </StyledInfoBlock>
                  <StyledInfoBlock>
                    <StyledInfoLabel>National Insurance Rate Dynamic</StyledInfoLabel>
                    <StyledInfoValue>{`${selectedRateCard.insurance_rate_dynamic} ${selectedRateCard.currency}`}</StyledInfoValue>
                  </StyledInfoBlock>
                </StyledRow>

                <StyledRow>
                  <StyledInfoBlock>
                    <StyledInfoLabel>Holiday Pay Rate Static</StyledInfoLabel>
                    <StyledInfoValue>{`${selectedRateCard.holiday_pay_rate} ${selectedRateCard.currency}`}</StyledInfoValue>
                  </StyledInfoBlock>
                  <StyledInfoBlock>
                    <StyledInfoLabel>Holiday Pay Rate Dynamic</StyledInfoLabel>
                    <StyledInfoValue>{`${selectedRateCard.holiday_pay_rate_dynamic} ${selectedRateCard.currency}`}</StyledInfoValue>
                  </StyledInfoBlock>
                </StyledRow>

                <StyledRow>
                  <StyledInfoBlock>
                    <StyledInfoLabel>Apprenticeship Levy Rate Static</StyledInfoLabel>
                    <StyledInfoValue>{`${selectedRateCard.apprenticeship_rate} ${selectedRateCard.currency}`}</StyledInfoValue>
                  </StyledInfoBlock>
                  <StyledInfoBlock>
                    <StyledInfoLabel>Apprenticeship Levy Rate Dynamic</StyledInfoLabel>
                    <StyledInfoValue>{`${selectedRateCard.apprenticeship_rate_dynamic} ${selectedRateCard.currency}`}</StyledInfoValue>
                  </StyledInfoBlock>
                </StyledRow>

                <StyledRow>
                  <StyledInfoBlock>
                    <StyledInfoLabel>Pension Rate Static</StyledInfoLabel>
                    <StyledInfoValue>{`${selectedRateCard.pension_rate} ${selectedRateCard.currency}`}</StyledInfoValue>
                  </StyledInfoBlock>
                  <StyledInfoBlock>
                    <StyledInfoLabel>Pension Rate Dynamic</StyledInfoLabel>
                    <StyledInfoValue>{`${selectedRateCard.pension_rate_dynamic} ${selectedRateCard.currency}`}</StyledInfoValue>
                  </StyledInfoBlock>
                </StyledRow>

                <StyledRow>
                  <StyledInfoBlock>
                    <StyledInfoLabel>Full Time Hours Value Static</StyledInfoLabel>
                    <StyledInfoValue>{`${selectedRateCard.full_time_hours} ${selectedRateCard.currency}`}</StyledInfoValue>
                  </StyledInfoBlock>
                  <StyledInfoBlock>
                    <StyledInfoLabel>Full Time Hours Value Dynamic</StyledInfoLabel>
                    <StyledInfoValue>{`${selectedRateCard.full_time_hours_dynamic} ${selectedRateCard.currency}`}</StyledInfoValue>
                  </StyledInfoBlock>
                </StyledRow>

                <StyledRow>
                  <StyledInfoBlock>
                    <StyledInfoLabel>Overtime Pay Static</StyledInfoLabel>
                    <StyledInfoValue>{`${selectedRateCard.overtime_pay} ${selectedRateCard.currency}`}</StyledInfoValue>
                  </StyledInfoBlock>
                  <StyledInfoBlock>
                    <StyledInfoLabel>Overtime Pay Dynamic</StyledInfoLabel>
                    <StyledInfoValue>{`${selectedRateCard.overtime_pay_dynamic} ${selectedRateCard.currency}`}</StyledInfoValue>
                  </StyledInfoBlock>
                </StyledRow>
              </div>
            }
          />
        ) : null}

        <ModalComponent
          isOpen={isRateCardModalOpen}
          onClose={closeRateCardModalBtnHandler}
          modalTitle={
            // eslint-disable-next-line no-nested-ternary
            isEdit
              ? isStaticRateCard
                ? constants.RATE_CARD.EDIT_STATIC_HEADER_TEXT
                : constants.RATE_CARD.EDIT_DYNAMIC_HEADER_TEXT
              : isStaticRateCard
              ? constants.RATE_CARD.STATIC_HEADER_TEXT
              : constants.RATE_CARD.DYNAMIC_HEADER_TEXT
          }
          modalContent={
            isStaticRateCard ? (
              <AddRateCard
                setData={setStaticRateCardData}
                data={staticRateCardData}
                validations={validations}
                refresh={refresh}
                isStatic={isStaticRateCard}
                currencyValue={selectedCurrency}
              />
            ) : (
              <AddDynamicRateCard
                setData={setDynamicRateCardData}
                data={dynamicRateCardData}
                validations={dynamicValidations}
                refresh={refresh}
                isStatic={isStaticRateCard}
                currencyValue={selectedCurrency}
              />
            )
          }
          negative={
            <Button
              bg="main.secondary"
              mr={3}
              _hover={{ bg: 'main.secondary' }}
              color="main.black"
              onClick={cancelRateCardBtnClickHandler}>
              {isStaticRateCard
                ? constants.WORKER.CANCEL_BUTTON_TEXT
                : constants.WORKER.BACK_BUTTON_TEXT}
            </Button>
          }
          positive={
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={addRateCardClickHandler}>
              {isStaticRateCard
                ? constants.WORKER.NEXT_BUTTON_TEXT
                : isEdit
                ? constants.WORKER.EDIT_UPDATE_BUTTON_TEXT
                : constants.WORKER.SAVE_BUTTON_TEXT}
            </Button>
          }
        />

        <ServerSideDatatable
          pageSize={10}
          initialSortKey="name"
          column={rateCardListingColumns}
          onPaginate={getRateCards}
          afterPaginateData={() => setIsPageLoader(false)}
          pagination
          pages={[10, 15, 25, 30, 40, 50]}
          dataKey="rate_card_list"
          refresh={reload}
          id={userDetails.client_id}
        />
        {/* )} */}
      </Card>
    </StyledRateCardContainer>
  );
};

RateCard.propTypes = {};

export default RateCard;
