/* eslint-disable react/prop-types */
import { Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Dropdown from '../Dropdown';

const PayTypeDropdown = function ({
  payTypeValue,
  setPayTypeData,
  refresh,
  validationObj,
  payTypeList
}) {
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setIsValid] = useState(validationObj?.isvalid || true);

  useEffect(() => {
    if (validationObj && !validationObj?.isvalid) {
      setErrorMsg(validationObj?.errorMessage);
      setIsValid(validationObj?.isvalid);
    }
  }, [refresh]);

  const setDropdownData = (e) => {
    setErrorMsg(null);
    setPayTypeData(e);
    setIsValid(true);
  };
  return (
    <>
      <Dropdown
        label="Pay Type*"
        placeholder="Select"
        options={payTypeList.map((item) => {
          return { label: item, value: item };
        })}
        onSelect={(e) => {
          setDropdownData(e);
        }}
        value={payTypeValue}
        isError={!isValid}
        disabled={false}
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error!'}
      </Text>
    </>
  );
};

export default PayTypeDropdown;
