/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Text, useToast } from '@chakra-ui/react';
import Dropdown from '../Dropdown';

import { CURRENCY_DATA } from '../../constants';

const CurrencyDropdown = function ({
  currency,
  setCurrency,
  validationObj,
  refresh,
  disabled = false
}) {
  const toast = useToast();
  const [currencies, setCurrencies] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj?.isvalid || true);

  useEffect(async () => {
    setCurrencies(CURRENCY_DATA);
  }, []);

  useEffect(() => {
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage);
      setValid(validationObj.isvalid);
    }
  }, [refresh]);

  useEffect(() => {
    setCurrency({ label: 'GBP', value: 'GBP' });
  }, []);

  const setCurrencyData = (e) => {
    setErrorMsg(null);
    setValid(true);
    setCurrency(e);
  };

  return (
    <>
      <Dropdown
        label={`Currency${validationObj?.required ? '*' : ''}`}
        onSelect={setCurrencyData}
        options={currencies.map((item) => {
          return { label: item.label, value: item.value };
        })}
        value={currency}
        placeholder=""
        isError={!isValid}
        disabled
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error !'}
      </Text>
    </>
  );
};

export default CurrencyDropdown;
