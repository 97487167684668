/* eslint-disable no-alert */
/* eslint-disable no-return-await */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import { Button } from '@chakra-ui/react';
import { NavLink, useParams } from 'react-router-dom';
import Card from '../../components/Card';
import {
  getClientsData,
  getSectorData,
  getAgencyData
} from '../../redux/action/adminDashboard.action';

import { StyledViewMoreContainer, StyledViewMoreHeaderContainer } from './ViewMore.styled';
import Loader from '../../components/Loader';
import ServerSideDatatable from '../../components/ServerSideDatatable';
import { PAGE_TITLE } from '../../constants';
import { currancyFormatter } from '../../utils/helper';

const CLIENT_INITIAL_COLUMNS = [
  {
    label: 'Name',
    field: 'name',
    sort: true
  },
  {
    label: 'Agency Used',
    field: 'used',
    sort: true
  },
  {
    label: 'Total Active Workers With Client',
    field: 'total_active_workers',
    sort: true
  },
  {
    label: 'Last Week Total Active Workers With Client',
    field: 'last_week_active_workers',
    sort: true
  },
  {
    label: 'ClearVue Credit',
    field: 'cumulative_clearvue_saving',
    sort: true,
    customRow: (cell) => {
      return currancyFormatter(cell);
    }
  },
  {
    label: 'Total Spend',
    field: 'total_spend',
    sort: false,
    customRow: (cell) => {
      return currancyFormatter(cell);
    }
  }
];
const AGENSIES_INITIAL_COLUMNS = [
  {
    label: 'Name',
    field: 'name',
    sort: true
  },
  {
    label: 'Client Deployments',
    field: 'used',
    sort: true
  },
  {
    label: 'Total Active Workers',
    field: 'total_active_workers',
    sort: true
  },
  {
    label: 'ClearVue Credit',
    field: 'cumulative_clearvue_saving',
    sort: true,
    customRow: (cell) => {
      return currancyFormatter(cell);
    }
  },
  {
    label: 'Total Spend',
    field: 'total_spend',
    sort: false,
    customRow: (cell) => {
      return currancyFormatter(cell);
    }
  }
];
const SECTORS_INITIAL_COLUMNS = [
  {
    label: 'Name',
    field: 'name',
    sort: true
  },
  {
    label: 'Sector Used',
    field: 'used',
    sort: true
  },
  {
    label: 'Total Active Workers With Client',
    field: 'total_active_workers',
    sort: true
  },
  {
    label: 'ClearVue Credit',
    field: 'cumulative_clearvue_saving',
    sort: true,
    customRow: (cell) => {
      return currancyFormatter(cell);
    }
  },
  {
    label: 'Total Spend',
    field: 'total_spend',
    sort: false,
    customRow: (cell) => {
      return currancyFormatter(cell);
    }
  }
];

const ViewMore = function () {
  useEffect(() => {
    document.title = PAGE_TITLE.VIEW_MORE;
  }, []);

  const [isPageLoader, setIsPageLoader] = useState(false);
  const { section } = useParams();

  const getSectionTemplate = () => {
    let template = null;
    switch (section) {
      case 'clients':
        template = (
          <ServerSideDatatable
            pageSize={10}
            initialSortKey="name"
            column={[...CLIENT_INITIAL_COLUMNS]}
            onPaginate={getClientsData}
            afterPaginateData={() => setIsPageLoader(false)}
            pagination
            pages={[5, 10, 15]}
            dataKey="records"
          />
        );
        break;
      case 'agencies':
        template = (
          <ServerSideDatatable
            pageSize={10}
            initialSortKey="name"
            column={[...AGENSIES_INITIAL_COLUMNS]}
            onPaginate={getAgencyData}
            afterPaginateData={() => setIsPageLoader(false)}
            pagination
            pages={[5, 10, 15]}
            dataKey="records"
          />
        );
        break;
      case 'sectors':
        template = (
          <ServerSideDatatable
            pageSize={10}
            initialSortKey="name"
            column={[...SECTORS_INITIAL_COLUMNS]}
            onPaginate={getSectorData}
            afterPaginateData={() => setIsPageLoader(false)}
            pagination
            pages={[5, 10, 15]}
            dataKey="records"
          />
        );
        break;
      default:
        break;
    }
    return template;
  };

  return (
    <StyledViewMoreContainer>
      {isPageLoader ? <Loader /> : null}
      <Card className="add-worker-card">
        <StyledViewMoreHeaderContainer>
          <NavLink to="/dashboard">
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}>
              Back To Dashboard
            </Button>
          </NavLink>
        </StyledViewMoreHeaderContainer>
        {getSectionTemplate()}
      </Card>
    </StyledViewMoreContainer>
  );
};

ViewMore.propTypes = {};

export default ViewMore;
