/* eslint-disable react/prop-types */

import { Box, Checkbox, Grid, Flex } from '@chakra-ui/react';
import React from 'react';
import constants from '../../constants';
import { allowAlphaNumeric } from '../../utils/helper';
import InputComponent from '../Input';
import Label from '../Label';
import StyledInputWrapper from './Forms.styled';
import SelectionDropdown from './SelectionDropdown';

const SearchWorker = function ({ data, setData, validations }) {
  return (
    <Box p="10px">
      <StyledInputWrapper style={{ marginRight: '8px' }}>
        <InputComponent
          lable={constants.FIRST_NAME}
          placeholder={constants.FIRST_NAME}
          onChange={(e) => setData({ ...data, first_name: allowAlphaNumeric(e) })}
          value={data.first_name}
          validationObj={validations.first_name}
          // refresh={refresh}
          maxLength="50"
        />
      </StyledInputWrapper>
      <StyledInputWrapper>
        <InputComponent
          lable={constants.LAST_NAME}
          placeholder={constants.LAST_NAME}
          onChange={(e) => setData({ ...data, last_name: allowAlphaNumeric(e) })}
          value={data.last_name}
          validationObj={validations.last_name}
          // refresh={refresh}
          maxLength="50"
        />
      </StyledInputWrapper>
      <Flex>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.EMPLOYEE_ID}
            placeholder={constants.EMPLOYEE_ID}
            onChange={(e) => setData({ ...data, employee_id: e })}
            value={data.employee_id}
            validationObj={validations.employee_id}
            // refresh={refresh}
            maxLength="50"
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.PAYROLL_REF}
            placeholder={constants.PAYROLL_REF}
            onChange={(e) => setData({ ...data, payroll_ref: e })}
            value={data.payroll_ref}
            validationObj={validations.payroll_ref}
            // refresh={refresh}
            maxLength="50"
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.NATIONAL_INSURANCE_NUMBER}
            placeholder={constants.NATIONAL_INSURANCE_NUMBER}
            onChange={(e) => setData({ ...data, national_insurance_number: e })}
            value={data.national_insurance_number}
            validationObj={validations.national_insurance_number}
            // refresh={refresh}
            maxLength="50"
            // disabled={edit && true}
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.EMAIL}
            placeholder={constants.EMAIL}
            onChange={(e) => setData({ ...data, email: e })}
            value={data.email}
            validationObj={validations.email}
            // refresh={refresh}
            maxLength="50"
            // disabled={edit && true}
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <SelectionDropdown
            lable={constants.PENSION_OPT_OUT_STATUS}
            validationObj={validations.pension}
            // refresh={refresh}
            Dropdownvalue={data.pension_opt_out}
            setDropdownvalue={(e) =>
              setData({ ...data, pension_opt_out: { label: e.value, value: e.value } })
            }
          />
        </StyledInputWrapper>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <SelectionDropdown
            lable={constants.APP_DOWNLOADED}
            validationObj={validations.app_downloaded}
            // refresh={refresh}
            Dropdownvalue={data.app_downloaded}
            setDropdownvalue={(e) =>
              setData({ ...data, app_downloaded: { label: e.value, value: e.value } })
            }
          />
        </StyledInputWrapper>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <SelectionDropdown
            validationObj={validations.other_assignment}
            lable={constants.OTHER_ASSIGNMENT}
            // refresh={refresh}
            Dropdownvalue={data.other_assignment}
            setDropdownvalue={(e) =>
              setData({ ...data, other_assignment: { label: e.value, value: e.value } })
            }
          />
        </StyledInputWrapper>
      </Flex>
      <Grid templateColumns="repeat(2, 1fr)">
        <Checkbox
          size="lg"
          isChecked={data.under_twentyone}
          onChange={(e) => setData({ ...data, under_twentyone: e.target.checked })}>
          <Label label="U21 Year" color="main.primary" checkbox />
        </Checkbox>
        <Checkbox
          size="lg"
          isChecked={data.under_twentytwo}
          onChange={(e) => setData({ ...data, under_twentytwo: e.target.checked })}>
          <Label label="U22 Year" color="main.primary" checkbox />
        </Checkbox>
        <Checkbox
          size="lg"
          isChecked={data.within_twelveweeks}
          onChange={(e) => setData({ ...data, within_twelveweeks: e.target.checked })}>
          <Label label="Within 12 Week" color="main.primary" checkbox />
        </Checkbox>
        <Checkbox
          size="lg"
          isChecked={data.returning_worker}
          onChange={(e) => setData({ ...data, returning_worker: e.target.checked })}>
          <Label label="Returning Workers" color="main.primary" checkbox />
        </Checkbox>
        <Checkbox
          size="lg"
          isChecked={data.all_workers}
          onChange={(e) => setData({ ...data, all_workers: e.target.checked })}>
          <Label label="All Workers" color="main.primary" checkbox />
        </Checkbox>
      </Grid>
    </Box>
  );
};

export default SearchWorker;
