/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-curly-brace-presence */
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import moment from 'moment';
import InputComponent from '../Input';
import Label from '../Label';
import StyledInputWrapper from './Forms.styled';
import PayTypeDropdown from './PayTypeDropdown';
import RoleTypeSelectionDropdown from './RoleTypeSelectionDropdown';
import TaxYearDropDown from './TaxYearDropDown';
import AddIcon from '../../assets/images/add.png';
import DeleteIcon from '../../assets/images/delete-icon.png';
import constants, {
  DATE_SAVE_TO_DB,
  DATE_TO_SHOW,
  PayTypeSelectionList,
  RoleTypeSelectionList
} from '../../constants';
import { allowTwoDecimalNumbersInRange } from '../../utils/helper';

const AddWtr = function ({ ruleData, refresh, validations, setruleData, editWtrModel, WtrData }) {
  const [roleType, setRoleType] = useState();
  const [payType, setPayType] = useState();
  const [preWeek, setPreWeek] = useState();
  const [postWeek, setPostWeek] = useState();
  const [LosData, setLosData] = useState([]);
  const [isValidStartDate, setIsValidStartDate] = useState(true);
  const [startDate, setStartDate] = useState();
  const [payTypeList, setpayTypeList] = useState(PayTypeSelectionList);
  const [roleTypeList, setroleTypeList] = useState(RoleTypeSelectionList);
  const startDateRef = useRef();
  const EmptyPayTypeList = [];

  const preparePayTypeList = () => {
    const matchingData = WtrData.filter((item) => item.role_type === ruleData.role_type);
    const matchingPayTypes = matchingData.map((item) => item.pay_type);
    const updatedPayTypeSelectionList = PayTypeSelectionList.filter(
      (payTypeData) => !matchingPayTypes.includes(payTypeData)
    );
    return updatedPayTypeSelectionList;
  };

  const prepareRoleTypeList = () => {
    const updatedRoleTypeSelectionList = roleTypeList.filter((roleTypeData) => {
      const matchingData = WtrData.filter((item) => item.role_type === roleTypeData);
      const matchingPayTypes = matchingData.map((item) => item.pay_type);
      const allPayTypesPresent = payTypeList.every((payTypeData) =>
        matchingPayTypes.includes(payTypeData)
      );
      return !allPayTypesPresent;
    });
    return updatedRoleTypeSelectionList;
  };

  useEffect(() => {
    setIsValidStartDate(validations.start_tax_year.isvalid);
  }, [validations.start_tax_year.isvalid]);

  useEffect(() => {
    if (editWtrModel) {
      setRoleType({
        label: ruleData.role_type,
        value: ruleData.role_type
      });
      setPayType({
        label: ruleData.pay_type,
        value: ruleData.pay_type
      });
      setPostWeek(ruleData.post_twelve_week);
      setPreWeek(ruleData.pre_twelve_week);
      setLosData(ruleData.los ? ruleData.los : []);
      setStartDate(ruleData?.start_tax_year ? DATE_TO_SHOW(ruleData.start_tax_year) : '');
    }
    const updatedRoleTypeList = prepareRoleTypeList();
    setroleTypeList(updatedRoleTypeList);
  }, []);

  useEffect(() => {
    const payTypeSelection = preparePayTypeList();
    setpayTypeList(payTypeSelection);
  }, [roleType]);

  const setRoleTypeData = (e) => {
    setRoleType(e);
    setruleData({ ...ruleData, role_type: e.value });
  };

  const handelDateChange = (event) => {
    let selectedDate = '';
    if (event) {
      try {
        selectedDate = moment(`${event.day}/${event.month}/${event.year}`, 'DD/MM/YYYY').format(
          'DD/MM/YYYY'
        );
      } catch (error) {
        selectedDate = '';
      }
    }

    if (event) {
      setStartDate(event);
      setIsValidStartDate(true);
      startDateRef.current.closeCalendar();
      const startDateToSave = DATE_SAVE_TO_DB(selectedDate);
      setruleData({ ...ruleData, start_tax_year: startDateToSave });
    } else {
      const startDateToSave = DATE_SAVE_TO_DB(selectedDate);
      setruleData({ ...ruleData, start_tax_year: startDateToSave });
    }
  };

  const setLosYearData = (e, index) => {
    setLosData((prevData) => {
      const los = [...prevData];
      los[index] = { ...los[index], year: e };
      setruleData({ ...ruleData, los });
      return los;
    });
  };

  const setLosHolidayData = (e, index) => {
    setLosData((prevData) => {
      const los = [...prevData];
      los[index] = { ...los[index], holiday: { value: e } };
      setruleData({ ...ruleData, los });
      return los;
    });
  };
  const setPayTypeData = (e) => {
    setPayType(e);
    setruleData({ ...ruleData, pay_type: e.value });
  };

  const removeLosdata = (index) => {
    setLosData((prevData) => {
      const los = [...prevData];
      los.splice(index, 1);
      setruleData({ ...ruleData, los });
      return los;
    });
  };

  const setPreWeekData = (e) => {
    setPreWeek(e);
    setruleData({ ...ruleData, pre_twelve_week: e });
  };
  const setPostWeekData = (e) => {
    setPostWeek(e);
    setruleData({ ...ruleData, post_twelve_week: e });
  };

  const openLOSModel = () => {
    const AddNewRole = {
      year: '',
      holiday: ''
    };
    if (LosData.length === 0) {
      setLosData([AddNewRole]);
    } else {
      setLosData([...LosData, AddNewRole]);
    }
  };
  return (
    <Box p="10px">
      <Flex>
        <StyledInputWrapper>
          {' '}
          <InputComponent
            isRequired
            Disable="true"
            lable={constants.NAME}
            placeholder={constants.NAME}
            value={ruleData.rule_name}
            validationObj={validations.rule_name}
            refresh={refresh}
            maxLength="50"
            disabled
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <RoleTypeSelectionDropdown
            roleTypeValue={roleType}
            setRoleTypeData={setRoleTypeData}
            validationObj={validations.role_type}
            refresh={refresh}
            roleTypeList={roleTypeList}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <p
            className={`input-label ${
              !isValidStartDate ? 'input-label-error' : ''
            }`}>{`${constants.TAX_YEAR}*`}</p>
          <DatePicker
            format="DD/MM/YYYY"
            inputClass={`date-picker ${!isValidStartDate ? 'input-error' : ''}`}
            onChange={(event) => handelDateChange(event, 'startDate')}
            value={startDate}
            ref={startDateRef}
            maxDate={new Date()}
            editable={false}
            onClickOutside={() => startDateRef.current.closeCalendar()}>
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={() => startDateRef.current.closeCalendar()}
              size="xs"
              mb={4}>
              {constants.CANCEL}
            </Button>
          </DatePicker>
          <p
            className="input-error-msg"
            style={{ visibility: isValidStartDate ? 'hidden' : 'visible' }}>
            {validations.start_tax_year.errorMessage}
          </p>
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <PayTypeDropdown
            payTypeValue={payType}
            setPayTypeData={setPayTypeData}
            validationObj={validations.pay_type}
            refresh={refresh}
            disabled={false}
            payTypeList={!roleType ? EmptyPayTypeList : payTypeList}
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable="Pre 12 Week"
            placeholder="Pre 12 Week %"
            value={preWeek}
            onChange={(e) => setPreWeekData(allowTwoDecimalNumbersInRange(e))}
            validationObj={validations.pre_twelve_week}
            refresh={refresh}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputComponent
            lable="Post 12 Week"
            placeholder="Post 12 Week %"
            value={postWeek}
            onChange={(e) => setPostWeekData(allowTwoDecimalNumbersInRange(e))}
            validationObj={validations.post_twelve_week}
            refresh={refresh}
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <Image
          mr="10px"
          cursor="pointer"
          src={AddIcon}
          height={6}
          onClick={openLOSModel}
          mb="10px"
        />
        <Label label="LOS" color="main.primary" />
      </Flex>

      {LosData.length !== 0 &&
        LosData?.map((item, index) => {
          return (
            <Flex key={item}>
              <StyledInputWrapper style={{ marginRight: '8px' }}>
                {index === 0 && <Text color={'main.semiPrimary'}>Length Of Service</Text>}
                <TaxYearDropDown
                  label=""
                  taxYearData={LosData[index].year}
                  setTaxYearData={(e) => setLosYearData(e, index)}
                  validationObj={validations.length_of_service}
                  refresh={refresh}
                />
              </StyledInputWrapper>{' '}
              <StyledInputWrapper style={{ marginRight: '8px' }}>
                {index === 0 && <Text color={'main.semiPrimary'}>Holiday days(%)</Text>}
                <InputComponent
                  lable=""
                  placeholder="Holiday days percentage"
                  value={LosData[index].holiday.value}
                  onChange={(e) => setLosHolidayData(allowTwoDecimalNumbersInRange(e), index)}
                  validationObj={validations.holiday_days}
                  refresh={refresh}
                />
              </StyledInputWrapper>{' '}
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                mt={index === 0 ? '24px' : ''}
                onClick={() => removeLosdata(index)}>
                <Image cursor="pointer" src={DeleteIcon} width="40px" height={5} />
              </Button>
            </Flex>
          );
        })}
    </Box>
  );
};

export default AddWtr;
