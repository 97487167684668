/* eslint-disable no-plusplus */
export const setCookie = (key, value) => {
  try {
    document.cookie = `${key}=${value}; expires=${new Date(
      new Date().getTime() + 1000 * 60 * 60 * process.env.REACT_APP_LOGOUT_TIME
    ).toUTCString()}; path=/;`;
    return null;
  } catch (error) {
    return error;
  }
};

export const getCookie = (key) => {
  try {
    const name = `${key}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return undefined;
  } catch (error) {
    return error;
  }
};

export const deleteCookie = (key) => {
  try {
    const allCookies = document.cookie.split(';');
    for (let i = 0; i < allCookies.length; i++) {
      const cookie = allCookies[i];
      if (cookie.split('=')[0].trim() === key) {
        document.cookie = `${allCookies[i]}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      }
    }
    return null;
  } catch (error) {
    return error;
  }
};
