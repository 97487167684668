const PdfDownloadIcon = function () {
  return (
    <svg viewBox="0 0 700 700" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="none"
        stroke="#164684"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="2.613"
        strokeWidth="10"
        d="M133.661 126.169h99.545M233.206 126.169c7.22 0 13.136 5.94 13.136 13.112M246.342 139.281V321.88M246.342 321.88c0 7.184-5.94 13.111-13.136 13.111M233.206 334.991H89.991M89.991 334.991c-7.16 0-13.112-5.916-13.112-13.111M76.879 321.88V178.7l56.782-52.531M136.341 126.169v47.268M136.341 173.437c0 3.852-3.2 7.039-7.039 7.039M129.302 180.476H76.879"
        className="stroke-130b7a"
      />
      <g clipRule="evenodd" fill="#164684" fillRule="evenodd" className="fill-130b7a">
        <path d="M319.217 285.176c4.117 3.43 6.182 8.741 6.182 15.865 0 7.159-2.125 12.411-6.314 15.743-4.202 3.309-10.637 4.999-19.317 4.999h-10.492v17.325h-9.973v-59.1h20.296c8.969.001 15.501 1.739 19.618 5.168zm-7.051 24.413c1.992-2.137 2.981-5.24 2.981-9.333s-1.255-6.99-3.791-8.681c-2.56-1.69-6.507-2.535-11.952-2.535h-10.13v23.688h11.591c5.518 0 9.284-1.063 11.301-3.139zM381.865 287.76c5.699 5.119 8.536 12.315 8.536 21.515 0 9.164-2.765 16.444-8.271 21.805-5.529 5.361-13.98 8.029-25.378 8.029h-19.619v-59.1h20.295c10.601 0 18.751 2.559 24.437 7.751zm-1.52 21.757c0-13.522-7.764-20.308-23.254-20.308h-9.973v40.519h11.071c7.147 0 12.641-1.703 16.456-5.096 3.804-3.429 5.7-8.451 5.7-15.115zM413.22 289.306v16.238h26.223v9.212H413.22v24.352h-9.973v-59.099h39.408l-.085 9.297z" />
      </g>
      <path
        clipRule="evenodd"
        fill="none"
        fillRule="evenodd"
        stroke="#164684"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="2.613"
        strokeWidth="10"
        d="M279.772 245.262h175.934M455.706 245.262c10.674 0 19.294 8.645 19.294 19.293M475 264.555v90.008M475 354.563c0 10.648-8.62 19.269-19.294 19.269M455.706 373.831H44.293M44.293 373.831c-10.648 0-19.293-8.62-19.293-19.269M25 354.563v-90.008M25 264.555c0-10.648 8.645-19.293 19.293-19.293M163.349 186.874v116.799M163.349 303.673l36.968-36.982M163.349 303.673l-40.446-40.47"
        className="stroke-130b7a"
      />
    </svg>
  );
};

export default PdfDownloadIcon;
