/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */

import React, { useState } from 'react';
import { Box, Button, Flex, Image, Spinner, Text, Tooltip, useToast } from '@chakra-ui/react';
import constants, {
  ADD_RC_VALIDATIONS,
  CUSTOM_TOAST_DURATION,
  CUSTOM_TOAST_DURATION_NULL,
  TOAST_SETTINGS
} from '../../constants';
import StyledInputWrapper from './Forms.styled';
import InputComponent from '../Input';
import AgencyDropdown from './AgencyDropdown';
import { StyledDownloadSample, StyledUploadLabel } from '../../pages/Attendance/Attendance.styled';
import { StyledUploadFileButton } from '../PerformanceModal/PerformanceModal.styled';
import DownloadIcon from '../../icons/DownloadIcon';
import DeleteIcon from '../../assets/images/delete-icon.png';
import { deepClone, filterErrorData, validateForm } from '../../utils/helper';
import {
  addRateCard,
  deleteRateCard,
  downloadRateCardSample
} from '../../redux/action/site.action';
import AlertDialogComponent from '../AlertModalComponent';
import { StyledHeadTr, StyledTh } from '../ClientSideDatatable/ClientSideDatatable.styled';

const INITIAL_RATE_CARD_DATA = { name: '', agency_id: '' };

const AddRC = function ({ siteId, rateCards, getRCData, setRateCards }) {
  const [rateCardData, setRateCardData] = useState(INITIAL_RATE_CARD_DATA);
  const [agency, setAgency] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [rulevalidations, setRulevalidations] = useState(deepClone(ADD_RC_VALIDATIONS));
  const [openDeleteAlertModal, setOpenDeleteAlertModal] = useState(false);
  const [deleteRateCardId, setDeleteRateCardId] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);

  const toast = useToast();

  const onSelectAgency = (data) => {
    if (rateCards.find((item) => item.agency_id === data.value) === undefined) {
      setAgency(data);
      setRateCardData((prev) => ({ ...prev, agency_id: data.value }));
    }
  };

  const checkValidation = (e) => {
    const [validationObject, isFormValid] = validateForm(rateCardData, rulevalidations);
    setRulevalidations(validationObject);
    if (!isFormValid) {
      setRefresh(!refresh);
      e.preventDefault();
    }
  };

  const handleFileUpload = async (event) => {
    setFileUploading(true);
    try {
      const file = event.target.files[0];
      const payload = {
        name: rateCardData.name,
        agency_id: rateCardData.agency_id,
        site_id: siteId,
        rate_card: file
      };
      const data = await addRateCard(payload);
      if (data && data.ok) {
        getRCData(siteId);
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.SUCCESS,
          title: data.message
        });
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: data.error
        });
      }
      setRateCardData(INITIAL_RATE_CARD_DATA);
      setAgency({});
    } catch {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION_NULL.duration,
        status: TOAST_SETTINGS.ERROR,
        title: 'An error occurred during the file upload.'
      });
    } finally {
      setFileUploading(false);
    }
  };
  const downloadBtnClickHandler = async () => {
    try {
      const result = await downloadRateCardSample();
      if (result.ok) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.setAttribute('href', result.data.resource_url);
        a.setAttribute('download', 'Rate-Card-Sample');
        a.click();
      }
    } catch (errorApi) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION_NULL.duration,
        status: TOAST_SETTINGS.ERROR,
        title: 'An error occurred during the delete rate card operation.'
      });
    }
  };
  const getDownloadSampleBtn = () => (
    <StyledDownloadSample title="Download sample" onClick={downloadBtnClickHandler}>
      <DownloadIcon />
    </StyledDownloadSample>
  );

  const downloadRateCard = (url) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.setAttribute('href', url);
    a.setAttribute('download', 'Worker-Performance');
    a.click();
  };

  const deleteRateCardApiCall = async () => {
    try {
      if (deleteRateCardId) {
        const response = await deleteRateCard(deleteRateCardId);
        if (response && response.ok) {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            duration: CUSTOM_TOAST_DURATION.duration,
            status: TOAST_SETTINGS.SUCCESS,
            title: response.message
          });
          const newRateCards = rateCards.filter((item) => item.id !== deleteRateCardId);
          setRateCards(newRateCards);
          setOpenDeleteAlertModal(false);
          setDeleteRateCardId(null);
        } else {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            duration: CUSTOM_TOAST_DURATION_NULL.duration,
            status: TOAST_SETTINGS.ERROR,
            title: filterErrorData(response.error)
          });
        }
      }
    } catch (errorApi) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION_NULL.duration,
        status: TOAST_SETTINGS.ERROR,
        title: 'An error occurred during the delete Rate card data operation.'
      });
    }
  };

  return (
    <Box p="10px">
      <Flex>
        <StyledInputWrapper>
          <InputComponent
            isRequired
            lable={constants.NAME}
            placeholder={constants.NAME}
            value={rateCardData.name}
            refresh={refresh}
            validationObj={rulevalidations.name}
            onChange={(e) => setRateCardData((prev) => ({ ...prev, name: e }))}
            maxLength="50"
          />
        </StyledInputWrapper>
      </Flex>
      <Flex justifyContent="space-between" alignItems="center">
        <StyledInputWrapper>
          <AgencyDropdown
            agency={agency}
            setAgency={onSelectAgency}
            placeholder="Select"
            refresh={refresh}
            validationObj={rulevalidations.agency_id}
          />
        </StyledInputWrapper>
        <Box ml={8} mr={4}>
          <StyledUploadFileButton bg="main.semiPrimary" for="rate-card" disabled={fileUploading}>
            Choose File
            {fileUploading && <Spinner size="sm" ml="10px" color="main.primary" />}
          </StyledUploadFileButton>
          <input
            type="file"
            id="rate-card"
            style={{ display: 'none' }}
            onChange={handleFileUpload}
            onClick={(e) => {
              e.target.value = null;
              checkValidation(e);
            }}
            disabled={false}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </Box>
        <Box w="240px">
          <StyledUploadLabel>Sample File {getDownloadSampleBtn()}</StyledUploadLabel>
        </Box>
      </Flex>
      <Box maxH={230} overflowY="auto">
        <StyledHeadTr display="flex" justifyContent="space-between" alignItems="center">
          <StyledTh key="Name">Name</StyledTh>
          <StyledTh key="Agency">Agency</StyledTh>
          <StyledTh key="Agency" mr="4">
            Action
          </StyledTh>
        </StyledHeadTr>
        {rateCards &&
          rateCards.length > 0 &&
          rateCards.map((item) => (
            <Flex
              key={item.agency_id}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={4}
              id={item.id}>
              <Text
                mr={2}
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                width="40%">
                <Tooltip placement="bottom" label={item.name}>
                  {item.name}
                </Tooltip>
              </Text>
              <Text
                mr={2}
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                width="40%">
                <Tooltip placement="bottom" label={item.agency_name}>
                  {item.agency_name}
                </Tooltip>
              </Text>
              <Box display="flex" mr="4">
                <Button
                  title="Download Rate Card Ruling Report."
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  padding="6px"
                  size="sm"
                  mr="8px"
                  onClick={() => {
                    downloadRateCard(item.resource_url);
                  }}>
                  <DownloadIcon />
                </Button>
                <Button
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  padding="6px"
                  size="sm"
                  onClick={() => {
                    setOpenDeleteAlertModal(true);
                    setDeleteRateCardId(item.id);
                  }}>
                  <Image src={DeleteIcon} height={4} />
                </Button>
              </Box>
            </Flex>
          ))}
      </Box>

      <AlertDialogComponent
        isOpen={openDeleteAlertModal}
        onClose={() => {
          setOpenDeleteAlertModal(false);
          setDeleteRateCardId(null);
        }}
        title="Delete Rate Card"
        body={<Box>Are you sure you want to proceed?</Box>}
        negative={
          <Button
            bg="main.secondary"
            mr={3}
            _hover={{ bg: 'main.secondary' }}
            color="main.black"
            onClick={() => {
              setOpenDeleteAlertModal(false);
            }}>
            Cancel
          </Button>
        }
        positive={
          <Button
            bg="main.semiPrimary"
            color="main.white"
            _hover={{ bg: 'main.primary' }}
            _active={{
              bg: 'main.semiPrimary',
              borderColor: 'main.primary'
            }}
            onClick={() => {
              deleteRateCardApiCall();
            }}>
            Confirm
          </Button>
        }
      />
    </Box>
  );
};

export default AddRC;
