/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable dot-notation */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-undef */
/* eslint-disable prefer-const */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable radix */
/* eslint-disable no-lonely-if */

import { Box, Button, Center, Checkbox, Flex, Image, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClientSideDatatable from '../../components/ClientSideDatatable';
import Dropdown from '../../components/Dropdown';
import InputComponent from '../../components/Input';
import ModalComponent from '../../components/Modal';
import constants, {
  ADD_SITE_VALIDATIONS,
  PATH,
  TOAST_SETTINGS,
  USER_TYPE,
  PAGE_TITLE,
  CUSTOM_TOAST_DURATION,
  CurrentYear,
  ADD_RULE_VALIDATIONS,
  RoleTypeList
} from '../../constants';
import { getRegions } from '../../redux/action/region.action';
import { addSite, getSiteRateCards, getSites, updateSite } from '../../redux/action/site.action';
import {
  getActivityTopCardData,
  getWorkforceTopCardLOSData,
  getWorkforceTopCardShiftUtiData,
  getWorkforceTopCardPoolUtiData,
  getLeaverTopCardLOS,
  getLeaverTopCardCounts,
  getActivityBottomCardShiftUti,
  getActivityBottomCardHeadCount,
  getActivityBottomCardSpend,
  getActivityBottomCardAverageHours,
  getWorkforceShiftUti,
  getWorkforceLOS,
  getLeaverNSR,
  getLeaverCount,
  getLeaverLOS,
  getSiteRatingData,
  getAgencyRatingData,
  getClientRatingData,
  getHeaderData,
  getLeaverAnalysisData
} from '../../redux/action/siteDashboard.action';
import {
  deepClone,
  validateForm,
  isDashboardBottomCardVisible,
  allowOnlyAlphabate,
  validateAlphaNumericPostCodeForWorker
} from '../../utils/helper';
import StyledInputWrapper from '../../components/Forms/Forms.styled';
import Card from '../../components/Card';
import StyledSitesContainer from './Sites.styled';
import Loader from '../../components/Loader';
import { getModulePermissions } from '../../utils/hooks';
import DashboardTopCard from '../../components/DashboardTopCard';
import DashboardBottomCard from '../../components/DashboardBottomCard';
import NationalityDropdown from '../../components/Forms/NationalityDropdown';
import CountryDropDownData from '../../utils/countryData.json';
import Label from '../../components/Label';
import DashboardRatingCard from '../../components/DashboardRatingCard';
import AddIcon from '../../assets/images/add.png';
import DeleteBlueIcon from '../../assets/images/delete-blue-icon.png';
import AddWtr from '../../components/Forms/AddWtr';
import AddRC from '../../components/Forms/AddRC';
import SiteDropdownHeader from '../../components/Forms/SiteDropdownHeader';
import { selectPerformanceSite } from '../../redux/actions/performanceDashboard.action';

const Site = function () {
  useEffect(() => {
    document.title = PAGE_TITLE.SITES;
  }, []);

  const toast = useToast();
  const permission = getModulePermissions(PATH.SITES.CODE);
  const [siteData, setSiteData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [siteIdForDashboard, setSiteIdForDashboard] = useState(null);

  const { userData, user_details } = useSelector((state) => state.authentication);
  const {
    selected_client_id,
    selected_start_date,
    selected_end_date,
    is_restricted,
    selected_performance_region_id,
    selected_performance_site_id,
    selected_performance_site_name
  } = useSelector((state) => state.agency);

  const getClientId = () => {
    switch (user_details.user_type_id) {
      case USER_TYPE.CLIENT_ADMIN:
        return userData.client_id;
      case USER_TYPE.AGENCY_ADMIN:
      case USER_TYPE.AGENCY_REGION_ADMIN:
      case USER_TYPE.AGENCY_SITE_ADMIN:
        return selected_client_id || userData.client_id;
      default:
        return selected_client_id || userData.client_id;
    }
  };

  const [refresh, setRefresh] = useState(false);
  const [inModalOpan, setModalOpen] = useState(false);
  const [wtrModelOpen, setWtrModelOpen] = useState(false);
  const [rcModelOpen, setRcModelOpen] = useState(false);
  const [rateCards, setRateCards] = useState([]);
  const [reload, setReload] = useState(false);
  const [validations, setValidations] = useState(deepClone(ADD_SITE_VALIDATIONS));
  const [rulevalidations, setRuleValidations] = useState(deepClone(ADD_RULE_VALIDATIONS));
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState({});
  const [isPageLoader, setIsPageLoader] = useState(false);
  const [loader, setloader] = useState(false);
  const [country, setCountry] = useState({});
  const [selectedDate, setSelectedDate] = useState(CurrentYear);
  const [taxYear, settaxYear] = useState(CurrentYear);
  const [isValid, setIsValid] = useState(validations?.region_id?.isvalid || true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [count, setCount] = useState(1);
  const [ruleData, setruleData] = useState();
  const [editWtrModel, seteditWtrModel] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    name: '',
    address_line_1: '',
    address_line_2: '',
    address_line_3: '',
    city: '',
    // country: '',
    post_code: '',
    region_id: '',
    wtr: []
  });
  useEffect(() => {
    if (validations?.region_id && !validations?.region_id?.isvalid) {
      setErrorMsg(validations?.region_id?.errorMessage);
      setIsValid(validations?.region_id?.isvalid);
    }
  }, [refresh]);

  const setCountryData = (e) => {
    setCountry(e);
    setData({ ...data, country: e.value });
  };

  const getSitesData = async () => {
    if (getClientId()) {
      setIsPageLoader(true);

      const clientId = getClientId();
      const regionId =
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
        user_details.user_type_id === USER_TYPE.REGION_ADMIN
          ? user_details.region_id
          : selected_performance_region_id;

      const sites = await getSites(clientId, regionId || null);

      setIsPageLoader(false);
      if (sites && sites.ok) {
        setSiteData(sites.data.sites);

        // Auto-select first site if needed
        if (sites.data.sites.length > 0) {
          // const firstSite = sites.data.sites[0];
          setSiteIdForDashboard(firstSite.id);
          getChartData(selected_performance_site_id, selected_start_date, selected_end_date);
          //  dispatch(selectPerformanceSite(firstSite.id, firstSite.name));
        }
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: sites.error
        });
      }
    }
  };

  useEffect(() => {
    getSitesData();
  }, [selected_client_id, selected_performance_region_id]);

  useEffect(async () => {
    if (user_details.user_type_id !== USER_TYPE.AGENCY_ADMIN) getSitesData();
    if (permission && permission.includes('create')) {
      const regionsData = await getRegions(getClientId());
      if (regionsData && regionsData.ok) {
        setRegionData(regionsData.data?.region_details);
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: regionsData.error
        });
      }
    }
    setReload(!reload);
  }, []);

  const onSiteModalClose = () => {
    setSelectedDate(CurrentYear);
    settaxYear(CurrentYear);
    setModalOpen(false);
    setRateCards([]);
    setEdit(false);
    setSelectedRegion({});
    setRefresh(!refresh);
    setData({
      name: '',
      address_line_1: '',
      address_line_2: '',
      address_line_3: '',
      city: '',
      // country: '',
      post_code: '',
      region_id: '',
      wtr: []
    });
    setCountry({});
    setCount(1);
    setValidations(deepClone(ADD_SITE_VALIDATIONS));
  };

  const onWtrModalClose = () => {
    if (editWtrModel) {
      seteditWtrModel(false);
      setWtrModelOpen(false);
    } else {
      setruleData({
        rule_name: '',
        role_type: '',
        start_tax_year: '',
        pay_type: '',
        pre_twelve_week: '',
        post_twelve_week: ''
      });
      setWtrModelOpen(false);
    }
    setRuleValidations(deepClone(ADD_RULE_VALIDATIONS));
  };
  const openWtrModel = () => {
    let rules;
    const filterRuleName = [...data.wtr];
    const ruleNames = filterRuleName.map((item) => item.rule_name);
    const ruleNumbers = ruleNames.map((name) => parseInt(name.match(/\d+/)[0]));
    const biggestNumber = Math.max(...ruleNumbers);
    if (biggestNumber > 0) {
      rules = `RULE${biggestNumber + 1}`;
    } else {
      rules = `RULE${count}`;
    }
    const AddNewRole = {
      rule_name: rules,
      role_type: '',
      start_tax_year: '',
      pay_type: '',
      pre_twelve_week: '',
      post_twelve_week: ''
    };
    setruleData(AddNewRole);
    setWtrModelOpen(true);
  };

  const deleteWtrData = (index) => {
    const wtrData = data.wtr;
    wtrData.splice(index, 1);
    setData({ ...data, wtr: wtrData });
  };

  const EditWtrData = (index) => {
    seteditWtrModel(true);
    setruleData(data.wtr[index]);
    setRuleValidations(deepClone(ADD_RULE_VALIDATIONS));
    setWtrModelOpen(true);
  };

  const saveWtrData = async () => {
    if (editWtrModel) {
      const [validationObject, isFormValid] = validateForm(ruleData, rulevalidations);
      setRuleValidations(validationObject);
      if (isFormValid) {
        const filterData = [...data.wtr];
        const updatedData = filterData.map((item) => {
          if (item.rule_name === ruleData.rule_name) {
            return ruleData;
          }
          return item;
        });
        setData({ ...data, wtr: [...updatedData] });
        seteditWtrModel(false);
        setWtrModelOpen(false);
      } else {
        setRefresh(!refresh);
      }
    } else {
      const [validationObject, isFormValid] = validateForm(ruleData, rulevalidations);
      setRuleValidations(validationObject);
      if (isFormValid) {
        setData({ ...data, wtr: [...data.wtr, ruleData] });
        setWtrModelOpen(false);
      } else {
        setRefresh(!refresh);
      }
    }
  };
  const prepareWtrData = (finalData) => {
    const wtrFormatted = finalData.wtr.map((item) => {
      const formattedItem = { ...item };

      formattedItem.pre_twelve_week = Number(item.pre_twelve_week);
      formattedItem.post_twelve_week = Number(item.post_twelve_week);
      formattedItem.role_type = RoleTypeList.find(
        (option) => option.label === item.role_type
      )?.value;
      if (item?.los?.length > 0) {
        formattedItem.los = {};
        item.los.forEach((losItem) => {
          const yearValue = losItem.year.value;
          const value = Number(losItem.holiday.value);
          formattedItem.los[yearValue] = {
            // days,
            value,
            // holiday: losItem.holiday.label,
            year_value: losItem.year.label
          };
        });
      } else {
        formattedItem.los = null;
      }

      return formattedItem;
    });
    return wtrFormatted;
  };

  const openRcModel = () => {
    setRcModelOpen(true);
  };
  const onRcModalClose = () => {
    setRcModelOpen(false);
  };

  const saveSite = async () => {
    let [validationObject, isFormValid] = validateForm(data, validations);
    const isValidPostCode = validateAlphaNumericPostCodeForWorker(data.post_code);
    if (!isValidPostCode) {
      validationObject.post_code.isvalid = false;
      isFormValid = false;
    }
    setValidations(validationObject);
    if (isFormValid) {
      let result = null;
      if (edit) {
        if (data.wtr.length > 0) {
          setloader(true);
          const finalData = { ...data };
          const filteredWtrData = prepareWtrData(finalData);
          finalData.wtr = filteredWtrData;

          result = await updateSite(id, { ...finalData, client_id: userData.client_id });
          if (result) {
            setloader(false);
          }
          if (result && result.ok) {
            // eslint-disable-next-line eqeqeq
            if (id == selected_performance_site_id) {
              setRefresh(!refresh);
              dispatch(selectPerformanceSite(id, data.name));
            }
            getSitesData();
            toast({
              ...TOAST_SETTINGS.SETTINGS,
              duration: CUSTOM_TOAST_DURATION.duration,
              status: TOAST_SETTINGS.SUCCESS,
              title: result.message
            });
            setEdit(false);
            onSiteModalClose();
          } else {
            toast({
              ...TOAST_SETTINGS.SETTINGS,
              status: TOAST_SETTINGS.ERROR,
              title: result.error
            });
          }
        } else {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            status: TOAST_SETTINGS.ERROR,
            title: constants.SITE.ERROR_MESSAGE
          });
        }
      } else {
        if (data.wtr.length > 0) {
          setloader(true);
          const finalData = { ...data };
          const filteredWtrData = prepareWtrData(finalData);
          finalData.wtr = filteredWtrData;
          result = await addSite({ ...finalData, client_id: userData.client_id });
          if (result) {
            setloader(false);
          }
          if (result && result.ok) {
            getSitesData();
            setId(result.data?.site_id);
            toast({
              ...TOAST_SETTINGS.SETTINGS,
              duration: CUSTOM_TOAST_DURATION.duration,
              status: TOAST_SETTINGS.SUCCESS,
              title: result.message
            });
            onSiteModalClose();
            setEdit(false);
          } else {
            toast({
              ...TOAST_SETTINGS.SETTINGS,
              status: TOAST_SETTINGS.ERROR,
              title: result.error
            });
          }
        } else {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            status: TOAST_SETTINGS.ERROR,
            title: constants.SITE.ERROR_MESSAGE
          });
        }
      }
      setReload(!reload);
    } else {
      setRefresh(!refresh);
      setReload(!reload);
    }
  };

  const onEditFilterWtrData = (wtr) => {
    const newwtr = wtr.map((item) => {
      const newItem = { ...item };

      newItem.pre_twelve_week = item.pre_twelve_week.toFixed(2);

      newItem.post_twelve_week = item.post_twelve_week.toFixed(2);
      newItem.role_type = RoleTypeList.find((option) => option.value === item.role_type)?.label;
      if (item.los) {
        newItem.los = Object.keys(item.los).reduce((acc, key) => {
          const losItem = item.los[key];

          acc.push({
            year: {
              label: losItem.year_value,
              value: key.toString()
            },
            holiday: {
              // label: losItem.holiday,
              value: losItem.value
            }
          });

          return acc;
        }, []);
      }

      return newItem;
    });
    const customSort = (a, b) => {
      const aValue = parseInt(a.rule_name.match(/\d+/)[0]);
      const bValue = parseInt(b.rule_name.match(/\d+/)[0]);
      return aValue - bValue;
    };

    const sortedData = newwtr.slice().sort(customSort);
    return sortedData;
  };

  const getRCData = async (siteId) => {
    const result = await getSiteRateCards(siteId);
    if (result && result.ok) {
      setRateCards(result.data?.rate_card_list);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const editData = (rowId, row) => {
    const WTRData = row?.wtr?.length > 0 ? onEditFilterWtrData(row.wtr) : [];
    setEdit(true);
    setSelectedRegion({ label: row.region_name, value: row.region_id });
    setCountry({ label: row.country, value: row.country });
    setSelectedDate('2022-2023');
    settaxYear('2022-2023');
    setData({
      name: row.name,
      address_line_1: row.address.address_line_1,
      address_line_2: row.address.address_line_2,
      address_line_3: row.address.address_line_3,
      city: row.city,
      country: row.country,
      post_code: row.post_code,
      region_id: row.region_id,
      wtr: WTRData
    });
    setModalOpen(true);
    setId(rowId);
    getRCData(rowId);
  };

  const [column, setColumn] = useState([
    {
      label: 'Name',
      field: 'name',
      sort: false
    },
    {
      label: 'Region',
      field: 'region_name',
      sort: false
    },
    {
      label: 'Address',
      field: 'address',
      customRow: (col) => {
        let str = col['address_line_1'];
        str += col['address_line_2'] ? `, ${col['address_line_2']}` : '';
        str += col['address_line_3'] ? `, ${col['address_line_3']}` : '';
        return str;
        // return `${col['address_line_1']}, ${col['address_line_2']}, ${col['address_line_3']}`;
      }
    }
  ]);

  useEffect(() => {
    if (permission && permission.includes('edit')) {
      setColumn([
        ...column,
        {
          label: 'Action',
          field: 'id',
          sort: false,
          customRow: (cell, row) => {
            return (
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={() => editData(cell, row)}>
                {constants.SITE.EDIT_BUTTON_TEXT}
              </Button>
            );
          }
        }
      ]);
    }
  }, [permission]);

  const [isCurrentWeek, setIsCurrentWeek] = useState(true);
  useEffect(() => {
    setIsCurrentWeek(() => isDashboardBottomCardVisible(selected_start_date, selected_end_date));
    if (siteData && siteData.length > 0 && selected_performance_site_id) {
      resetData();
      setRegionIdForDashboard(siteData[currentIndex].id);
      getChartData(selected_performance_site_id, selected_start_date, selected_end_date);
    }
  }, [selected_start_date, selected_end_date]);

  const [activityTopCardData, setActivityTopCardData] = useState(null);
  const [workforceTopCardLOS, setWorkforceTopCardLOS] = useState(null);
  const [workforceTopCardShiftUti, setWorkforceTopCardShiftUti] = useState(null);
  const [workforceTopCardPoolUti, setWorkforceTopCardPoolUti] = useState(null);
  const [leaverTopCardLos, setLeaverTopCardLos] = useState(null);
  const [leaverTopCardCounts, setLeaverTopCardCounts] = useState(null);
  const [leaversTopCardPoolUti, setLeaversTopCardPoolUti] = useState(null);

  const [activityBottomCardShiftUtil, setActivityBottomCardShiftUtil] = useState(null);
  const [activityBottomCardHeadCount, setActivityBottomCardHeadCount] = useState(null);
  const [activityBottomCardSpend, setActivityBottomCardSpend] = useState(null);
  const [activityBottomCardAverageHours, setActivityBottomCardAverageHours] = useState(null);

  const [workforceShiftUti, setWorkforceShiftUti] = useState(null);
  const [workforceLOS, setWorkforceLOS] = useState(null);

  const [leaverNSR, setLeaverNSR] = useState(null);
  const [leaverCount, setLeaverCount] = useState(null);
  const [leaverLOS, setLeaverLOS] = useState(null);
  const [siteRatingData, setSiteRatingData] = useState(null);
  const [agencyRatingData, setAgencyRatingData] = useState(null);
  const [clientRatingData, setClientRatingData] = useState(null);
  const [headerData, setHeaderData] = useState(null);
  const [ratingData, setratingData] = useState(null);
  const [leaversData, setLeaversData] = useState(null);
  const [regionIdForDashboard, setRegionIdForDashboard] = useState(null);

  const resetData = () => {
    setActivityTopCardData(null);
    setWorkforceTopCardLOS(null);
    setWorkforceTopCardShiftUti(null);
    setWorkforceTopCardPoolUti(null);
    setLeaverTopCardLos(null);
    setLeaverTopCardCounts(null);
    setLeaversTopCardPoolUti(null);
    setActivityBottomCardShiftUtil(null);
    setActivityBottomCardHeadCount(null);
    setActivityBottomCardSpend(null);
    setActivityBottomCardAverageHours(null);
    setWorkforceShiftUti(null);
    setWorkforceLOS(null);
    setLeaverNSR(null);
    setLeaverCount(null);
    setLeaverLOS(null);
    setSiteRatingData(null);
    setAgencyRatingData(null);
    setClientRatingData(null);
  };

  const getActivityTopCardDataFunction = async (regionId, startDate, endDate) => {
    const activityTopCard = await getActivityTopCardData(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? userData.agency_id : null
    );
    if (activityTopCard && activityTopCard.ok) {
      setActivityTopCardData(activityTopCard.data);
    }
  };

  const getWorkforceTopCardLOSDataFunction = async (regionId, startDate, endDate) => {
    const WorkforceTopCard = await getWorkforceTopCardLOSData(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? userData.agency_id : null
    );
    if (WorkforceTopCard && WorkforceTopCard.ok) {
      setWorkforceTopCardLOS(WorkforceTopCard.data);
    }
  };
  const getSiteRatingDataFunction = async (regionId) => {
    const rating = await getSiteRatingData(userData.client_id || selected_client_id, regionId);
    if (rating && rating.ok) {
      setSiteRatingData(rating.data);
    }
  };
  const getAgencyRatingDataFunction = async () => {
    const rating = await getAgencyRatingData(
      userData.client_id || selected_client_id,
      user_details.agency_id
    );
    if (rating && rating.ok) {
      setAgencyRatingData(rating.data);
    }
  };
  const getClientRatingDataFunction = async () => {
    const rating = await getClientRatingData(userData.client_id || selected_client_id);
    if (rating && rating.ok) {
      setClientRatingData(rating.data);
    }
  };
  const getHeaderDataFunction = async (regionId, startDate, endDate) => {
    const header = await getHeaderData(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? userData.agency_id : null
    );
    if (header && header.ok) {
      setHeaderData(header.data);
    }
  };
  const getLeaverAnalysisDataFunction = async (regionId, startDate, endDate) => {
    const leaversAnalysis = await getLeaverAnalysisData(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? userData.agency_id : null
    );
    if (leaversAnalysis && leaversAnalysis.ok) {
      setLeaversData(leaversAnalysis.data);
    }
  };

  const getWorkforceTopCardShiftUtiDataFunction = async (regionId, startDate, endDate) => {
    const WorkforceTopCard = await getWorkforceTopCardShiftUtiData(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? userData.agency_id : null
    );
    if (WorkforceTopCard && WorkforceTopCard.ok) {
      setWorkforceTopCardShiftUti(WorkforceTopCard.data);
    }
  };

  const getWorkforceTopCardPoolUtiDataFunction = async (regionId, startDate, endDate) => {
    const WorkforceTopCard = await getWorkforceTopCardPoolUtiData(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? userData.agency_id : null
    );
    if (WorkforceTopCard && WorkforceTopCard.ok) {
      setWorkforceTopCardPoolUti(WorkforceTopCard.data);
    }
  };

  const getLeaverTopCardLOSFunction = async (regionId, startDate, endDate) => {
    const leaverTopCardData = await getLeaverTopCardLOS(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? userData.agency_id : null
    );
    if (leaverTopCardData && leaverTopCardData.ok) {
      setLeaverTopCardLos(leaverTopCardData.data);
    }
  };

  const getLeaverTopCardCountsFunction = async (regionId, startDate, endDate) => {
    const leaverTopCardData = await getLeaverTopCardCounts(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? userData.agency_id : null
    );
    if (leaverTopCardData && leaverTopCardData.ok) {
      setLeaverTopCardCounts(leaverTopCardData.data);
    }
  };

  const getActivityBottomCardShiftUtiFunction = async (regionId, startDate, endDate) => {
    const bottomDeckData = await getActivityBottomCardShiftUti(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardShiftUtil(bottomDeckData?.data);
    }
  };

  const getActivityBottomCardHeadCountFunction = async (regionId, startDate, endDate) => {
    const bottomDeckData = await getActivityBottomCardHeadCount(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardHeadCount(bottomDeckData?.data);
    }
  };

  const getActivityBottomCardSpendFunction = async (regionId, startDate, endDate) => {
    const bottomDeckData = await getActivityBottomCardSpend(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardSpend(bottomDeckData?.data);
    }
  };

  const getActivityBottomCardAverageHoursFunction = async (regionId, startDate, endDate) => {
    const bottomDeckData = await getActivityBottomCardAverageHours(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardAverageHours(bottomDeckData?.data);
    }
  };

  const getWorkforceShiftUtiFunction = async (regionId, startDate, endDate) => {
    const bottomDeckData = await getWorkforceShiftUti(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setWorkforceShiftUti(bottomDeckData?.data);
    }
  };

  const getWorkforceLOSFunction = async (regionId, startDate, endDate) => {
    const bottomDeckData = await getWorkforceLOS(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setWorkforceLOS(bottomDeckData?.data);
    }
  };

  const getLeaverNSRFunction = async (regionId, startDate, endDate) => {
    const bottomDeckData = await getLeaverNSR(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setLeaverNSR(bottomDeckData?.data);
    }
  };

  const getLeaverCountFunction = async (regionId, startDate, endDate) => {
    const bottomDeckData = await getLeaverCount(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setLeaverCount(bottomDeckData?.data);
    }
  };

  const getLeaverLOSFunction = async (regionId, startDate, endDate) => {
    const bottomDeckData = await getLeaverLOS(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setLeaverLOS(bottomDeckData?.data);
    }
  };

  const getChartData = (siteId, startDate, endDate) => {
    if (isDashboardBottomCardVisible(selected_start_date, selected_end_date)) {
      if (is_restricted && user_details.user_type_id === USER_TYPE.AGENCY_ADMIN) {
        Promise.all([
          getHeaderDataFunction(siteId, startDate, endDate),
          getSiteRatingDataFunction(siteId, startDate, endDate),
          getAgencyRatingDataFunction(startDate, endDate),
          getClientRatingDataFunction(startDate, endDate),
          getLeaverAnalysisDataFunction(siteId, startDate, endDate)
        ]);
      } else {
        Promise.all([
          getActivityTopCardDataFunction(siteId, startDate, endDate),
          getWorkforceTopCardLOSDataFunction(siteId, startDate, endDate),
          getWorkforceTopCardShiftUtiDataFunction(siteId, startDate, endDate),
          getWorkforceTopCardPoolUtiDataFunction(siteId, startDate, endDate),
          getLeaverTopCardLOSFunction(siteId, startDate, endDate),
          getLeaverTopCardCountsFunction(siteId, startDate, endDate),
          getActivityBottomCardShiftUtiFunction(siteId, startDate, endDate),
          getActivityBottomCardHeadCountFunction(siteId, startDate, endDate),
          getActivityBottomCardSpendFunction(siteId, startDate, endDate),
          getActivityBottomCardAverageHoursFunction(siteId, startDate, endDate),
          getWorkforceShiftUtiFunction(siteId, startDate, endDate),
          getWorkforceLOSFunction(siteId, startDate, endDate),
          getLeaverNSRFunction(siteId, startDate, endDate),
          getLeaverCountFunction(siteId, startDate, endDate),
          getLeaverLOSFunction(siteId, startDate, endDate),
          getSiteRatingDataFunction(siteId, startDate, endDate),
          getAgencyRatingDataFunction(startDate, endDate),
          getClientRatingDataFunction(startDate, endDate),
          getHeaderDataFunction(siteId, startDate, endDate),
          getLeaverAnalysisDataFunction(siteId, startDate, endDate)
        ]);
      }
    } else {
      Promise.all([
        getActivityTopCardDataFunction(siteId, startDate, endDate),
        getWorkforceTopCardLOSDataFunction(siteId, startDate, endDate),
        getWorkforceTopCardShiftUtiDataFunction(siteId, startDate, endDate),
        getWorkforceTopCardPoolUtiDataFunction(siteId, startDate, endDate),
        getLeaverTopCardLOSFunction(siteId, startDate, endDate),
        getLeaverTopCardCountsFunction(siteId, startDate, endDate),
        getSiteRatingDataFunction(siteId, startDate, endDate),
        getAgencyRatingDataFunction(startDate, endDate),
        getClientRatingDataFunction(startDate, endDate)
      ]);
    }
  };

  useEffect(() => {
    if (selected_performance_site_id) {
      resetData();
      setSiteIdForDashboard(selected_performance_site_id);
      getChartData(selected_performance_site_id, selected_start_date, selected_end_date);
    }
  }, [
    selected_performance_site_id,
    selected_performance_region_id,
    selected_start_date,
    selected_end_date
  ]);

  return (
    <>
      <>
        <Box mb={5}>
          <Card>
            <Box width="250px" ml="15px" mr="15px">
              <SiteDropdownHeader refresh={refresh} />
            </Box>
          </Card>
          {is_restricted && user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? (
            <DashboardRatingCard
              siteRatingData={siteRatingData}
              agencyRatingData={agencyRatingData}
              clientRatingData={clientRatingData}
              headerData={headerData}
              ratingData={ratingData}
              leaversData={leaversData}
              regionId={regionIdForDashboard}
            />
          ) : (
            <DashboardRatingCard
              siteRatingData={siteRatingData}
              agencyRatingData={agencyRatingData}
              clientRatingData={clientRatingData}
              headerData={headerData}
              ratingData={ratingData}
              leaversData={leaversData}
              regionId={regionIdForDashboard}
            />
          )}
          {is_restricted && user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? (
            <Box>
              <Box h="600px" display="flex" justifyContent="center">
                <Center fontSize={35}>Client Has Restricted View</Center>
              </Box>
            </Box>
          ) : (
            <Box mt={10}>
              <>
                <Box>
                  <DashboardTopCard
                    activityTopCardData={activityTopCardData}
                    workforceTopCardPoolUti={workforceTopCardPoolUti}
                    workforceTopCardShiftUti={workforceTopCardShiftUti}
                    workforceTopCardLOS={workforceTopCardLOS}
                    leaverTopCardLos={leaverTopCardLos}
                    leaversTopCardPoolUti={leaversTopCardPoolUti}
                    leaverTopCardCounts={leaverTopCardCounts}
                    isDataAvailable={!!(siteData && siteData.length > 0)}
                  />
                </Box>

                {isCurrentWeek && (
                  <Box>
                    <DashboardBottomCard
                      activityBottomCardShiftUtil={activityBottomCardShiftUtil}
                      activityBottomCardSpend={activityBottomCardSpend}
                      activityBottomCardAverageHours={activityBottomCardAverageHours}
                      workforceLOS={workforceLOS}
                      leaverLOS={leaverLOS}
                      leaverCount={leaverCount}
                      leaverNSR={leaverNSR}
                      activityBottomCardHeadCount={activityBottomCardHeadCount}
                      workforceShiftUti={workforceShiftUti}
                      isDataAvailable={!!(siteData && siteData.length > 0)}
                    />
                  </Box>
                )}
              </>
            </Box>
          )}
        </Box>
        {is_restricted && user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? (
          <Box></Box>
        ) : (
          <StyledSitesContainer>
            {isPageLoader ? <Loader /> : null}
            <Card>
              {permission && permission.includes('create') && (
                <>
                  <Button
                    bg="main.semiPrimary"
                    color="main.white"
                    _hover={{ bg: 'main.primary' }}
                    _active={{
                      bg: 'main.semiPrimary',
                      borderColor: 'main.primary'
                    }}
                    onClick={() => {
                      setModalOpen(true);
                    }}>
                    Add Site
                  </Button>
                  <ModalComponent
                    isOpen={inModalOpan}
                    onClose={() => onSiteModalClose()}
                    modalTitle={edit ? constants.SITE.EDIT_HEADER_TEXT : constants.SITE.HEADER_TEXT}
                    modalContent={
                      <Box p="10px">
                        <Flex>
                          <StyledInputWrapper>
                            <InputComponent
                              isRequired
                              lable={constants.NAME}
                              placeholder={constants.NAME}
                              onChange={(e) => setData({ ...data, name: e })}
                              value={data.name}
                              validationObj={validations.name}
                              refresh={refresh}
                              maxLength="50"
                            />
                          </StyledInputWrapper>
                        </Flex>
                        <Flex>
                          <StyledInputWrapper>
                            <Dropdown
                              label="Region*"
                              onSelect={(e) => {
                                setSelectedRegion(e);
                                setData({ ...data, region_id: e.value });
                                setErrorMsg(null);
                                setIsValid(true);
                              }}
                              options={regionData.map((reg) => {
                                return { label: reg.name, value: reg.id };
                              })}
                              value={selectedRegion}
                              placeholder=""
                              isError={!isValid}
                            />
                            <Text
                              visibility={isValid ? 'hidden' : 'visible'}
                              color="main.error"
                              mb="2px"
                              fontSize="11px">
                              {errorMsg || 'Error!'}
                            </Text>
                          </StyledInputWrapper>
                        </Flex>
                        <Flex>
                          <StyledInputWrapper>
                            <InputComponent
                              lable={constants.ADDRESS1}
                              placeholder={constants.ADDRESS1}
                              validationObj={validations.address_line_1}
                              onChange={(e) => setData({ ...data, address_line_1: e })}
                              refresh={refresh}
                              value={data.address_line_1}
                              maxLength="50"
                            />
                          </StyledInputWrapper>
                        </Flex>
                        <Flex>
                          <StyledInputWrapper style={{ marginRight: '8px' }}>
                            <InputComponent
                              lable={constants.ADDRESS2}
                              placeholder={constants.ADDRESS2}
                              validationObj={validations.address_line_2}
                              onChange={(e) => setData({ ...data, address_line_2: e })}
                              refresh={refresh}
                              value={data.address_line_2}
                              maxLength="50"
                            />
                          </StyledInputWrapper>
                          <StyledInputWrapper>
                            <InputComponent
                              lable={constants.ADDRESS3}
                              placeholder={constants.ADDRESS3}
                              validationObj={validations.address_line_3}
                              onChange={(e) => setData({ ...data, address_line_3: e })}
                              refresh={refresh}
                              value={data.address_line_3}
                              maxLength="50"
                            />
                          </StyledInputWrapper>
                        </Flex>
                        <Flex>
                          <StyledInputWrapper style={{ marginRight: '8px' }}>
                            <InputComponent
                              lable={constants.POST_CODE}
                              placeholder={constants.POST_CODE}
                              validationObj={validations.post_code}
                              onChange={(e) => setData({ ...data, post_code: e })}
                              refresh={refresh}
                              value={data.post_code}
                              maxLength="15"
                            />
                          </StyledInputWrapper>
                          <StyledInputWrapper>
                            <InputComponent
                              lable={constants.CITY}
                              placeholder={constants.CITY}
                              validationObj={validations.city}
                              onChange={(e) => setData({ ...data, city: allowOnlyAlphabate(e) })}
                              refresh={refresh}
                              value={data.city}
                              maxLength="20"
                            />
                          </StyledInputWrapper>
                        </Flex>
                        <Flex>
                          <StyledInputWrapper>
                            <NationalityDropdown
                              label="Country"
                              country={country}
                              setCountry={setCountryData}
                              validationObj={validations.country}
                              refresh={refresh}
                              dropdownData={CountryDropDownData}
                            />
                          </StyledInputWrapper>
                        </Flex>
                        <Flex justifyContent="space-between" mb="10px">
                          <Flex>
                            <Image
                              mr="10px"
                              cursor="pointer"
                              src={AddIcon}
                              height={6}
                              onClick={openWtrModel}
                            />
                            <Label label="Holiday (WTR) Ruling" color="main.primary" />
                          </Flex>
                          <Flex>
                            <Image
                              opacity={edit ? 1 : 0.5}
                              cursor={edit ? 'pointer' : 'not-allowed'}
                              mr="10px"
                              src={AddIcon}
                              height={6}
                              onClick={edit ? openRcModel : undefined}
                            />
                            <Text
                              fontSize="lg"
                              color={edit ? 'main.semiPrimary' : 'gray.500'}
                              opacity={edit ? 1 : 0.6}
                              cursor={edit ? 'text' : 'not-allowed'}>
                              Rate Card Ruling
                            </Text>
                          </Flex>
                        </Flex>
                        {data?.wtr?.length > 0 &&
                          data.wtr.map((item, index) => {
                            return (
                              <div
                                key={item}
                                style={{
                                  justifyContent: 'space-between',
                                  display: 'flex',
                                  marginBottom: '15px',
                                  alignItems: 'center'
                                }}>
                                <Text
                                  color="main.semiPrimary"
                                  cursor="pointer"
                                  onClick={() => EditWtrData(index)}>
                                  {item.rule_name}
                                </Text>
                                <Text color="main.semiPrimary">{item.role_type}</Text>
                                <Text color="main.semiPrimary">{item.pre_twelve_week}</Text>
                                <Text color="main.semiPrimary">{item.post_twelve_week}</Text>
                                <Image
                                  cursor="pointer"
                                  src={DeleteBlueIcon}
                                  width="26px"
                                  height="26px"
                                  onClick={() => deleteWtrData(index)}
                                />
                              </div>
                            );
                          })}
                      </Box>
                    }
                    negative={
                      <Button
                        onClick={() => onSiteModalClose()}
                        bg="main.secondary"
                        mr={3}
                        _hover={{ bg: 'main.secondary' }}
                        color="main.black">
                        {constants.SITE.CANCEL_BUTTON_TEXT}
                      </Button>
                    }
                    positive={
                      <Button
                        onClick={() => saveSite()}
                        bg="main.semiPrimary"
                        color="main.white"
                        _hover={{ bg: 'main.primary' }}
                        _active={{
                          bg: 'main.semiPrimary',
                          borderColor: 'main.primary'
                        }}
                        disabled={loader}>
                        {edit
                          ? constants.SITE.EDIT_UPDATE_BUTTON_TEXT
                          : constants.SITE.SAVE_BUTTON_TEXT}
                      </Button>
                    }
                  />
                </>
              )}

              <ModalComponent
                isOpen={wtrModelOpen}
                onClose={() => onWtrModalClose()}
                modalTitle={
                  editWtrModel ? constants.SITE.EDIT_WTR_RULLING : constants.SITE.ADD_WTR_RULLING
                }
                modalContent={
                  <AddWtr
                    ruleData={ruleData}
                    refresh={refresh}
                    validations={rulevalidations}
                    setruleData={setruleData}
                    editWtrModel={editWtrModel}
                    WtrData={data.wtr}
                  />
                }
                negative={
                  <Button
                    onClick={() => onWtrModalClose()}
                    bg="main.secondary"
                    mr={3}
                    _hover={{ bg: 'main.secondary' }}
                    color="main.black">
                    {constants.SITE.CANCEL_BUTTON_TEXT}
                  </Button>
                }
                positive={
                  <Button
                    onClick={() => saveWtrData()}
                    bg="main.semiPrimary"
                    color="main.white"
                    _hover={{ bg: 'main.primary' }}
                    _active={{
                      bg: 'main.semiPrimary',
                      borderColor: 'main.primary'
                    }}>
                    {editWtrModel
                      ? constants.SITE.EDIT_UPDATE_BUTTON_TEXT
                      : constants.SITE.SAVE_BUTTON_TEXT}
                  </Button>
                }
              />

              <ModalComponent
                isOpen={rcModelOpen}
                onClose={() => onRcModalClose()}
                modalTitle={constants.SITE.ADD_RC_RULLING}
                modalContent={
                  <AddRC
                    rateCards={rateCards}
                    setRateCards={setRateCards}
                    siteId={id}
                    getRCData={getRCData}
                  />
                }
                specifyDivider
              />

              <ClientSideDatatable
                pageSize={10}
                initialSortKey="id"
                column={column}
                row={siteData}
                pagination
                pages={[2, 5, 10, 15]}
                refresh={reload}
              />
              {/* )} */}
            </Card>
          </StyledSitesContainer>
        )}
      </>
      {/* {!chartLoading ? ( */}
    </>
  );
};

Site.propTypes = {};

export default Site;
