/* eslint-disable react/function-component-definition */
/* eslint-disable no-use-before-define */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import SiteListTable from '../SiteListTable/SiteListTable';
import SiteMarginModal from '../SiteMarginModal/SiteMarginModal';
import LOSMarginModal from '../LOSMarginModal/LOSMarginModal';
import { getSiteRestrictions } from '../../redux/action/association.action';

const SiteMargins = ({ associationId }) => {
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [isMarginModalOpen, setIsMarginModalOpen] = useState(false);
  const [isLOSModalOpen, setIsLOSModalOpen] = useState(false);
  const [selectedLOS, setSelectedLOS] = useState(null);

  useEffect(() => {
    const fetchSites = async () => {
      const result = await getSiteRestrictions(associationId);
      if (result.ok) {
        const selectedSites = result.data.sites.filter((site) => site.is_selected);
        setSites(selectedSites);
      }
    };

    fetchSites();
  }, [associationId]);

  const handleEditSite = (site) => {
    setSelectedSite(site);
    setIsMarginModalOpen(true);
  };

  const handleSaveMargins = (data) => {
    const updatedSites = sites.map((site) =>
      site.site_id === data.siteId ? { ...site, margins: data.margins } : site
    );
    setSites(updatedSites);
  };

  const handleSaveLOSMargins = (data) => {
    const updatedSites = sites.map((site) =>
      site.site_id === selectedSite.site_id
        ? {
            ...site,
            lengthOfService: [...(site.lengthOfService || []), data]
          }
        : site
    );
    setSites(updatedSites);
  };

  return (
    <Box>
      <SiteListTable associationId={associationId} sites={sites} onEditSite={handleEditSite} />

      {selectedSite && (
        <SiteMarginModal
          isOpen={isMarginModalOpen}
          onClose={() => setIsMarginModalOpen(false)}
          site={selectedSite}
          onSave={handleSaveMargins}
        />
      )}

      {selectedLOS && (
        <LOSMarginModal
          isOpen={isLOSModalOpen}
          onClose={() => setIsLOSModalOpen(false)}
          selectedLOS={selectedLOS}
          onSave={handleSaveLOSMargins}
        />
      )}
    </Box>
  );
};

export default SiteMargins;
