/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import constants from '../../constants';
import InputComponent from '../Input';
import StyledInputWrapper from './Forms.styled';
import { allowAlphaNumeric, allowTwoDecimalNumbers } from '../../utils/helper';
import CurrencyDropdown from './CurrencyDropdown';

const AddRateCard = function ({ data, setData, refresh, validations, isStatic, currencyValue }) {
  const [currency, setCurrency] = useState(currencyValue);

  const setCurrencyData = (e) => {
    setCurrency(e);
    setData({ ...data, currency: e.value });
  };

  return (
    <Box p="10px">
      {isStatic ? (
        <Flex>
          <StyledInputWrapper>
            <InputComponent
              lable={constants.NAME}
              placeholder={constants.NAME}
              onChange={(e) => setData({ ...data, name: allowAlphaNumeric(e) })}
              value={data.name}
              validationObj={validations.name}
              refresh={refresh}
              disabled={!isStatic}
            />
          </StyledInputWrapper>
        </Flex>
      ) : null}

      <Flex>
        {isStatic ? (
          <StyledInputWrapper style={{ marginRight: '8px' }}>
            <CurrencyDropdown
              currency={currency}
              validationObj={validations.currency}
              refresh={refresh}
              setCurrency={setCurrencyData}
              disabled={false}
            />
          </StyledInputWrapper>
        ) : null}

        <StyledInputWrapper>
          <InputComponent
            lable={constants.PAY_PER_HOUR}
            placeholder={constants.PAY_PER_HOUR}
            onChange={(e) => setData({ ...data, pay_per_hour: allowTwoDecimalNumbers(e) })}
            value={data.pay_per_hour}
            validationObj={validations.pay_per_hour}
            refresh={refresh}
            type="number"
          />
        </StyledInputWrapper>
      </Flex>

      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.NATIONAL_INSURANCE_RATE}
            placeholder={constants.NATIONAL_INSURANCE_RATE}
            onChange={(e) => setData({ ...data, insurance_rate: allowTwoDecimalNumbers(e) })}
            value={data.insurance_rate}
            validationObj={validations.insurance_rate}
            refresh={refresh}
            type="number"
            min={0}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.HOLIDAY_PAY_RATE}
            placeholder={constants.HOLIDAY_PAY_RATE}
            onChange={(e) => setData({ ...data, holiday_pay_rate: allowTwoDecimalNumbers(e) })}
            value={data.holiday_pay_rate}
            validationObj={validations.holiday_pay_rate}
            refresh={refresh}
            type="number"
            min={0}
          />
        </StyledInputWrapper>
      </Flex>

      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.APPRENTICESHIP_LEVY_RATE}
            placeholder={constants.APPRENTICESHIP_LEVY_RATE}
            onChange={(e) => setData({ ...data, apprenticeship_rate: allowTwoDecimalNumbers(e) })}
            value={data.apprenticeship_rate}
            validationObj={validations.apprenticeship_rate}
            refresh={refresh}
            type="number"
            min={0}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.PENSION_RATE}
            placeholder={constants.PENSION_RATE}
            onChange={(e) => setData({ ...data, pension_rate: allowTwoDecimalNumbers(e) })}
            value={data.pension_rate}
            validationObj={validations.pension_rate}
            refresh={refresh}
            type="number"
            min={0}
          />
        </StyledInputWrapper>
      </Flex>

      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.FULL_TIME_HOURS_VALUE}
            placeholder={constants.FULL_TIME_HOURS_VALUE}
            onChange={(e) => setData({ ...data, full_time_hours: allowTwoDecimalNumbers(e) })}
            value={data.full_time_hours}
            validationObj={validations.full_time_hours}
            refresh={refresh}
            type="number"
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.OVER_TIME_PAY}
            placeholder={constants.OVER_TIME_PAY}
            onChange={(e) => setData({ ...data, overtime_pay: allowTwoDecimalNumbers(e) })}
            value={data.overtime_pay}
            validationObj={validations.overtime_pay}
            refresh={refresh}
            type="number"
            disabled={!isStatic}
          />
        </StyledInputWrapper>
      </Flex>
    </Box>
  );
};

export default AddRateCard;
