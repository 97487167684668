/* eslint-disable react/function-component-definition */
/* eslint-disable no-use-before-define */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Button, Box, useToast, Text } from '@chakra-ui/react';
import ModalComponent from '../Modal';
import MarginInputs from '../MarginInputs/MarginInputs';
import { SITE_MARGIN_VALIDATIONS, TOAST_SETTINGS, CUSTOM_TOAST_DURATION } from '../../constants';
import { validateForm, deepClone } from '../../utils/helper';
import {
  createSiteMargin,
  getMargin,
  updateLosMargin
} from '../../redux/action/association.action';

const SiteMarginModal = ({ isOpen, onClose, site, onSave, associationId }) => {
  const [marginData, setMarginData] = useState({});
  const [validations, setValidations] = useState(deepClone(SITE_MARGIN_VALIDATIONS));
  const [refresh, setRefresh] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [marginId, setMarginId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const resetState = () => {
    setMarginData({});
    setValidations(deepClone(SITE_MARGIN_VALIDATIONS));
    setRefresh(false);
    setIsEditing(false);
    setMarginId(null);
    setIsLoading(false);
  };

  const fetchMarginData = async () => {
    try {
      setIsLoading(true);
      const result = await getMargin(associationId, site.site_id);
      if (!result.ok) {
        throw new Error(result.message || 'Failed to fetch margin data');
      }

      if (result.data) {
        const siteMargin = result.data.margins_list.find((margin) => margin.los === null);

        if (siteMargin) {
          setMarginId(siteMargin.id);
          setMarginData({
            margin: siteMargin.margin || 0,
            overtime_margin: siteMargin.overtimeMargin || 0,
            transport_fee: siteMargin.transportFee || 0,
            ssp: siteMargin.ssp || 0,
            training_margin: siteMargin.trainingMargin || 0,
            bh_margin: siteMargin.bhMargin || 0,
            nsp_margin: siteMargin.nspMargin || 0,
            supervisor_standard_margin: siteMargin.supervisorStandardMargin || 0,
            supervisor_overtime_margin: siteMargin.supervisorOvertimeMargin || 0,
            supervisor_permanent_margin: siteMargin.supervisorPermanentMargin || 0,
            suspension_margin: siteMargin.suspensionMargin || 0,
            induction_training_margin: siteMargin.inductionTrainingMargin || 0
          });
          setIsEditing(true);
        }
      }
    } catch (error) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.ERROR,
        title: error.message || 'Failed to fetch margin data'
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && site?.site_id) {
      fetchMarginData();
    } else if (!isOpen) {
      resetState();
    }
  }, [isOpen, site]);

  const handleClose = () => {
    resetState();
    onClose();
  };

  const handleSave = async () => {
    try {
      const marginFields = [
        { field: 'margin', label: 'Standard Margin' },
        { field: 'overtime_margin', label: 'Overtime Margin' },
        { field: 'transport_fee', label: 'Transport Fee' },
        { field: 'ssp', label: 'Miscellaneous' },
        { field: 'training_margin', label: 'Training Margin' },
        { field: 'bh_margin', label: 'Bh Margin' },
        { field: 'nsp_margin', label: 'SP Margin' },
        { field: 'supervisor_standard_margin', label: 'Supervisor Standard Margin' },
        { field: 'supervisor_overtime_margin', label: 'Supervisor Overtime Margin' },
        { field: 'supervisor_permanent_margin', label: 'Supervisor Permanent Margin' },
        { field: 'suspension_margin', label: 'Suspension Margin' },
        { field: 'induction_training_margin', label: 'Induction Training Margin' }
      ];

      const invalidField = marginFields.find(
        ({ field }) => marginData[field] > 9.99 || marginData[field] < 0
      );

      if (invalidField) {
        const errorMessage =
          marginData[invalidField.field] > 9.99
            ? `"${invalidField.label}" must be less than or equal to 9.99`
            : `"${invalidField.label}" must be greater than or equal to 0`;

        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.ERROR,
          title: errorMessage
        });
        return;
      }

      const [validationObject, isFormValid] = validateForm(marginData, validations);
      setValidations(validationObject);

      if (!isFormValid) {
        setRefresh(!refresh);
        return;
      }

      setIsLoading(true);
      const payload = {
        margin: parseFloat(marginData.margin) || 0,
        overtime_margin: parseFloat(marginData.overtime_margin) || 0,
        transport_fee: parseFloat(marginData.transport_fee) || 0,
        ssp: parseFloat(marginData.ssp) || 0,
        training_margin: parseFloat(marginData.training_margin) || 0,
        bh_margin: parseFloat(marginData.bh_margin) || 0,
        nsp_margin: parseFloat(marginData.nsp_margin) || 0,
        supervisor_standard_margin: parseFloat(marginData.supervisor_standard_margin) || 0,
        supervisor_overtime_margin: parseFloat(marginData.supervisor_overtime_margin) || 0,
        supervisor_permanent_margin: parseFloat(marginData.supervisor_permanent_margin) || 0,
        suspension_margin: parseFloat(marginData.suspension_margin) || 0,
        induction_training_margin: parseFloat(marginData.induction_training_margin) || 0
      };

      const result = isEditing
        ? await updateLosMargin(marginId, payload)
        : await createSiteMargin(associationId, site.site_id, payload);

      if (!result.ok) {
        throw new Error(result.message || 'Failed to update site margins');
      }

      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message || `Site margins ${isEditing ? 'updated' : 'created'} successfully`
      });

      onSave({
        siteId: site.site_id,
        margins: marginData
      });
      handleClose();
    } catch (error) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.ERROR,
        title: error.message || 'Failed to update site margins'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={handleClose}
      modalTitle={
        <Box>
          {isEditing ? 'Edit' : 'Add'} Site Margin - {site?.site_name.length > 20 && <br />}
          <Text as="span" display="inline-block" wordBreak="break-word">
            {site?.site_name}
          </Text>
        </Box>
      }
      modalContent={
        <MarginInputs
          data={marginData}
          setData={setMarginData}
          validations={validations}
          refresh={refresh}
        />
      }
      negative={
        <Button
          bg="main.secondary"
          mr={3}
          _hover={{ bg: 'main.secondary' }}
          color="main.black"
          onClick={handleClose}
          isDisabled={isLoading}>
          Cancel
        </Button>
      }
      positive={
        <Button
          bg="main.semiPrimary"
          color="main.white"
          _hover={{ bg: 'main.primary' }}
          onClick={handleSave}
          isLoading={isLoading}>
          {isEditing ? 'Update' : 'Save'} Changes
        </Button>
      }
      specifySize="xl"
    />
  );
};

export default SiteMarginModal;
