/* eslint-disable default-param-last */
import { API_ENDPOINT } from '../../constants';

import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';

const getAgencyAssociations = async (limit = 1, page = 1, sortKey, sortType, id) => {
  const result = await axiosInstance.get(
    `${
      API_ENDPOINT.GET_ASSOCIATIONS
    }?page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}${
      id ? `&agency_id=${id}` : ''
    }`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getClientAssociations = async (limit = 1, page = 1, sortKey, sortType, id) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_ASSOCIATIONS}?page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}&client_id=${id}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getAssociations = async (limit = 1, page = 1, sortKey, sortType) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_ASSOCIATIONS}?page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const addAssociation = async (payload) => {
  const result = await axiosInstance.post(API_ENDPOINT.ADD_ASSOCIATIONS, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};
const updateSwitchDataInAssociation = async (id, payload) => {
  const result = await axiosInstance.put(
    `${API_ENDPOINT.ADD_ASSOCIATIONS}${API_ENDPOINT.RESRICT}/${id}`,
    payload
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const updateTapDataInAssociation = async (id, payload) => {
  const result = await axiosInstance.put(
    `${API_ENDPOINT.ADD_ASSOCIATIONS}${API_ENDPOINT.TAP}/${id}`,
    payload
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const updateRestrictCommentSwitch = async (id, payload) => {
  const result = await axiosInstance.put(
    `${API_ENDPOINT.ADD_ASSOCIATIONS}${API_ENDPOINT.RESRICT_COMMENTS}/${id}`,
    payload
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const updateAssociation = async (id, payload) => {
  const result = await axiosInstance.put(`${API_ENDPOINT.UPDATE_ASSOCIATIONS}/${id}`, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

// Create Margin
const createLosMargin = async (agencyClientAssociationId, siteId, los, payload) => {
  const result = await axiosInstance.post(
    `${API_ENDPOINT.MARGINS}?agency_client_association_id=${agencyClientAssociationId}&site_id=${siteId}&los=${los}`,
    payload
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};
// get los Margin
const getLosMargin = async (agencyClientAssociationId, siteId, los) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.MARGINS}?agency_client_association_id=${agencyClientAssociationId}&site_id=${siteId}&los=${los}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

// delete los Margin
const deleteLosMargin = async (agencyClientAssociationId, siteId, los) => {
  const result = await axiosInstance.delete(
    `${API_ENDPOINT.MARGINS}?agency_client_association_id=${agencyClientAssociationId}&site_id=${siteId}&los=${los}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const deleteSiteWithMargin = async (agencyClientAssociationId, siteId) => {
  const result = await axiosInstance.delete(
    `${API_ENDPOINT.MARGINS}?agency_client_association_id=${agencyClientAssociationId}&site_id=${siteId}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

// Update Margin
const updateLosMargin = async (marginId, payload) => {
  const result = await axiosInstance.put(`${API_ENDPOINT.MARGINS}/${marginId}`, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

// Get Margin
const getMargin = async (agencyClientAssociationId, siteId) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.MARGINS}?agency_client_association_id=${agencyClientAssociationId}&site_id=${siteId}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

// Update Margin
const updateMargin = async (agencyClientAssociationId, siteId, payload) => {
  const result = await axiosInstance.put(
    `${API_ENDPOINT.MARGINS}?agency_client_association_id=${agencyClientAssociationId}&site_id=${siteId}`,
    payload
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

// Delete Margin
const deleteMargin = async (agencyClientAssociationId, siteId, los) => {
  const result = await axiosInstance.delete(
    `${API_ENDPOINT.MARGINS}?agency_client_association_id=${agencyClientAssociationId}&site_id=${siteId}&los=${los}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

// Create Margin
const createSiteMargin = async (agencyClientAssociationId, siteId, payload) => {
  const result = await axiosInstance.post(
    `${API_ENDPOINT.MARGINS}?agency_client_association_id=${agencyClientAssociationId}&site_id=${siteId}`,
    payload
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getSiteRestrictions = async (agencyClientAssociationId) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.SITE_RESTRICTIONS}?agency_client_association_id=${agencyClientAssociationId}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const createSiteRestrictions = async (payload) => {
  const result = await axiosInstance.post(API_ENDPOINT.SITE_RESTRICTIONS, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export {
  createLosMargin,
  getLosMargin,
  deleteLosMargin,
  updateLosMargin,
  deleteMargin,
  updateMargin,
  getMargin,
  getSiteRestrictions,
  getAssociations,
  addAssociation,
  updateAssociation,
  getAgencyAssociations,
  getClientAssociations,
  updateSwitchDataInAssociation,
  updateRestrictCommentSwitch,
  updateTapDataInAssociation,
  createSiteRestrictions,
  createSiteMargin,
  deleteSiteWithMargin
};
