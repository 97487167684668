/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
import { Text } from '@chakra-ui/react';
// eslint-disable-next-line import/named
// import { TOAST_SETTINGS } from '../../constants';
import Dropdown from '../Dropdown';
import { getSurveyCategories } from '../../redux/action/survey.action';

const SurveysDropdown = function ({
  setSurvey,
  survey,
  validationObj,
  refresh,
  multiple = false,
  label = 'Survey',
  setDefaultValue = false
}) {
  // const toast = useToast();
  const [surveys, setSurveys] = useState([]);
  // const { userData } = useSelector((state) => state.authentication);
  // const { selected_client_id } = useSelector((state) => state.agency);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj?.isvalid || true);

  useEffect(async () => {
    const result = await getSurveyCategories();
    if (result && result.ok) {
      setSurveys([...result.data.surveys]);
    }
  }, []);

  useEffect(async () => {
    if (setDefaultValue && surveys[0]) {
      setSurvey({ label: surveys[0].name, value: surveys[0].id });
    }
  }, [surveys]);

  useEffect(() => {
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage);
      setValid(validationObj.isvalid);
    }
  }, [refresh]);

  const setSurveyData = (e) => {
    setErrorMsg(null);
    setValid(true);
    setSurvey(e);
  };

  return (
    <>
      <Dropdown
        label={label.length > 0 ? `${label}${validationObj?.required ? '*' : ''}` : ''}
        onSelect={(e) => {
          setSurveyData(e);
        }}
        options={surveys.map((sec) => {
          return { label: sec.name, value: sec.id };
        })}
        value={survey}
        placeholder=""
        multiple={multiple}
        isError={!isValid}
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error !'}
      </Text>
    </>
  );
};

export default SurveysDropdown;
