import React from 'react';

const DepartmentIcon = function () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 22 25">
      <path
        fill="currentColor"
        d="M21.29 23.438h-.977V1.172C20.313.525 19.788 0 19.14 0H2.734c-.647 0-1.171.525-1.171 1.172v22.265H.586a.586.586 0 00-.586.586V25h21.875v-.977a.586.586 0 00-.586-.585zM6.25 3.71c0-.324.262-.586.586-.586h1.953c.324 0 .586.262.586.586v1.953a.586.586 0 01-.586.586H6.836a.586.586 0 01-.586-.586V3.711zm0 4.687c0-.323.262-.585.586-.585h1.953c.324 0 .586.262.586.585v1.954a.586.586 0 01-.586.585H6.836a.586.586 0 01-.586-.585V8.398zm2.54 7.227H6.835a.586.586 0 01-.586-.586v-1.953c0-.324.262-.586.586-.586h1.953c.324 0 .586.262.586.586v1.953a.586.586 0 01-.586.586zm3.71 7.813H9.375v-4.102c0-.324.262-.586.586-.586h1.953c.324 0 .586.262.586.586v4.102zm3.125-8.399a.586.586 0 01-.586.586h-1.953a.586.586 0 01-.586-.586v-1.953c0-.324.262-.586.586-.586h1.953c.324 0 .586.262.586.586v1.953zm0-4.687a.586.586 0 01-.586.585h-1.953a.586.586 0 01-.586-.585V8.398c0-.323.262-.585.586-.585h1.953c.324 0 .586.262.586.585v1.954zm0-4.688a.586.586 0 01-.586.586h-1.953a.586.586 0 01-.586-.586V3.711c0-.324.262-.586.586-.586h1.953c.324 0 .586.262.586.586v1.953z"
      />
    </svg>
  );
};

export default DepartmentIcon;
