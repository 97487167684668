/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Center, Flex, SkeletonCircle, Image, Skeleton } from '@chakra-ui/react';
import DashboardCard from '../DashboardCard';
import DashboardContainer from '../DashboardContainer';
import NoData from '../NoData';
import greenArrowUp from '../../assets/images/greenArrowUp.png';
import redArrowDown from '../../assets/images/redArrowDown.png';
import CircularChart from '../Charts/CircularChart';
import { colors } from '../../theme';
import ColumnChart from '../Charts/ColumnChart';
import ChartCardTitle from '../ChartCardTitle';
import { calculatePercentage, calculateTrendPercentage, numberFormatter } from '../../utils/helper';
import { DASHBOARD_TEXT_COLOR, HEADER_COLORS, NEW_CHART_COLOR } from '../../constants';
import DonuteChart from '../Charts/DonuteChart';
import DonutChartImageContainer from '../DonutChartImageContainer';
import CircularProgressTile from '../CircularProgressTile';

const DashboardTopCard = function ({
  activityTopCardData,
  workforceTopCardPoolUti,
  workforceTopCardShiftUti,
  workforceTopCardLOS,
  leaverTopCardLos,
  leaverTopCardCounts
}) {
  const total = workforceTopCardShiftUti?.result?.reduce((prev, curr) => prev + curr, 0);
  let total1 = 0;
  workforceTopCardLOS?.result?.rows?.forEach((element) => {
    total1 += element.data[0];
  });
  let total2 = 0;
  leaverTopCardLos?.result?.rows?.forEach((element) => {
    total2 += element.data[0];
  });
  return (
    <Flex mt={25}>
      <DashboardContainer minW="550px" header="Activity" color={HEADER_COLORS.ACTIVITY_COLOR}>
        <Flex>
          <DashboardCard
            minW="300px"
            m="5px"
            borderRadius="10px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px">
            <Box textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
              <Center display="flex" flexDirection="column" pos="absolute" right="51.5%">
                {activityTopCardData?.result?.is_past_range_value_required &&
                  (activityTopCardData?.result?.shift_fullfilment?.current_overall_range
                    ?.fulfilled >=
                  activityTopCardData?.result?.shift_fullfilment?.past_overall_range?.fulfilled ? (
                    <Box mt={2}>
                      <Image src={greenArrowUp} height={6} />
                    </Box>
                  ) : (
                    <Box mt={2}>
                      <Image src={redArrowDown} height={6} />
                    </Box>
                  ))}
                {activityTopCardData?.result?.is_past_range_value_required && (
                  <Box
                    mt={1}
                    fontSize="md"
                    color={
                      activityTopCardData?.result?.shift_fullfilment?.current_overall_range
                        ?.fulfilled >=
                      activityTopCardData?.result?.shift_fullfilment?.past_overall_range?.fulfilled
                        ? colors.main.positiveGreen
                        : colors.main.negativeRed
                    }>
                    {activityTopCardData?.result?.shift_fullfilment?.current_overall_range
                      ?.fulfilled === 0 ||
                    activityTopCardData?.result?.shift_fullfilment?.past_overall_range
                      ?.fulfilled === 0
                      ? 100
                      : Math.abs(
                          calculatePercentage(
                            activityTopCardData?.result?.shift_fullfilment?.current_overall_range
                              ?.fulfilled,
                            activityTopCardData?.result?.shift_fullfilment?.current_overall_range
                              ?.lost
                          ) -
                            calculatePercentage(
                              activityTopCardData?.result?.shift_fullfilment?.past_overall_range
                                ?.fulfilled,
                              activityTopCardData?.result?.shift_fullfilment?.past_overall_range
                                ?.lost
                            )
                        )}
                    %
                  </Box>
                )}
              </Center>
              <ChartCardTitle title="Shift Fulfilment" label="Requested vs Fulfilled %" />
              <Box>
                {activityTopCardData?.is_data_available ? (
                  <>
                    {activityTopCardData &&
                    activityTopCardData?.result?.shift_fullfilment?.current_overall_range ? (
                      <>
                        <Center marginTop={8}>
                          <CircularProgressTile
                            HoursRatio={
                              activityTopCardData?.result?.shift_fullfilment?.current_hours?.output
                            }
                            workerHours={
                              activityTopCardData?.result?.shift_fullfilment?.current_hours
                                ?.totalNonSupervisorsHours
                            }
                            supervisorHours={
                              activityTopCardData?.result?.shift_fullfilment?.current_hours
                                ?.totalSupervisorsHours
                            }
                            shiftFulfillmentPer={
                              activityTopCardData?.result?.shift_fullfilment?.current_overall_range
                                ?.percentage
                            }
                            requested={
                              activityTopCardData?.result?.shift_fullfilment?.current_overall_range
                                ?.requested
                            }
                            fulfilled={
                              activityTopCardData?.result?.shift_fullfilment?.current_overall_range
                                ?.fulfilled
                            }
                            supervisroFullfilled={
                              activityTopCardData?.result?.shift_fullfilment
                                ?.current_supervisors_range?.fulfilled
                            }
                            supervisroRequested={
                              activityTopCardData?.result?.shift_fullfilment
                                ?.current_supervisors_range?.requested
                            }
                            supervisorsPer={
                              activityTopCardData?.result?.shift_fullfilment
                                ?.current_supervisors_range?.percentage
                            }
                          />
                        </Center>
                      </>
                    ) : (
                      <SkeletonCircle size="40" margin="auto" />
                    )}
                  </>
                ) : (
                  <NoData height="130px" width="130px" />
                )}
              </Box>
            </Box>
          </DashboardCard>
          <DashboardCard
            m="5px"
            borderRadius="10px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px">
            <Box>
              {activityTopCardData?.result?.is_past_range_value_required && (
                <>
                  <Center display="flex" flexDirection="column" pos="absolute" right={3}>
                    <Center>
                      {activityTopCardData?.result?.shift_lost.current_range.count >=
                      activityTopCardData?.result.shift_lost.past_range.count ? (
                        <Image src={greenArrowUp} height={6} />
                      ) : (
                        <Image src={redArrowDown} height={6} />
                      )}
                    </Center>
                    <Center
                      mt={1}
                      fontSize="md"
                      color={
                        activityTopCardData?.result?.shift_lost.current_range?.count >=
                        activityTopCardData?.result?.shift_lost.past_range?.count
                          ? colors.main.positiveGreen
                          : colors.main.negativeRed
                      }>
                      {calculateTrendPercentage(
                        activityTopCardData?.result?.shift_lost?.current_range?.count,
                        activityTopCardData?.result?.shift_lost.past_range?.count
                      )}
                      %
                    </Center>{' '}
                  </Center>
                </>
              )}
              <ChartCardTitle
                title="Unfulfilled Shifts"
                label="Total Requested minus Total Fulfilled"
              />

              <Box margin="auto">
                {activityTopCardData?.is_data_available ? (
                  <>
                    {activityTopCardData.is_data_available ? (
                      <>
                        <Center fontSize="60px" h="227px" color={DASHBOARD_TEXT_COLOR.TEXT_COLOR}>
                          {
                            activityTopCardData?.result?.shift_fullfilment?.current_overall_range
                              ?.lost
                          }
                        </Center>
                      </>
                    ) : (
                      <Skeleton h="200px" w="100%" />
                    )}
                  </>
                ) : (
                  <NoData height="130px" width="130px" />
                )}
              </Box>
            </Box>
          </DashboardCard>
        </Flex>
        <Flex>
          <DashboardCard
            m="5px"
            borderRadius="10px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
            position="relative">
            <Box textOverflow="ellipsis" whiteSpace="nowrap">
              <ChartCardTitle title="Spend" label="Breakdown Of Standard And Overtime Spend" />
              <Box>
                {activityTopCardData?.is_data_available ? (
                  activityTopCardData ? (
                    <DonutChartImageContainer
                      difference={calculateTrendPercentage(
                        activityTopCardData?.result?.total_spent?.current_range?.standardTotal +
                          activityTopCardData?.result?.total_spent?.current_range?.overtimeTotal +
                          activityTopCardData?.result?.total_spent?.current_range?.bhTotal +
                          activityTopCardData?.result?.total_spent?.current_range?.suspensionTotal +
                          activityTopCardData?.result?.total_spent?.current_range?.nspTotal +
                          activityTopCardData?.result?.total_spent?.current_range?.trainingTotal +
                          activityTopCardData?.result?.total_spent?.current_range
                            ?.standardBonusTotal +
                          activityTopCardData?.result?.total_spent?.current_range
                            ?.specialBonusTotal +
                          activityTopCardData?.result?.total_spent?.current_range?.weekendTotal +
                          activityTopCardData?.result?.total_spent?.current_range
                            ?.supervisorStandardTotal +
                          activityTopCardData?.result?.total_spent?.current_range
                            ?.supervisorOvertimeTotal +
                          activityTopCardData?.result?.total_spent?.current_range
                            ?.supervisorPermanentTotal +
                          activityTopCardData?.result?.total_spent?.current_range
                            ?.inductionTrainingTotal +
                          activityTopCardData?.result?.total_spent?.current_range.wtrCosts,

                        activityTopCardData?.result?.total_spent?.past_range?.standardTotal +
                          activityTopCardData?.result?.total_spent?.past_range?.overtimeTotal +
                          activityTopCardData?.result?.total_spent?.past_range?.bhTotal +
                          activityTopCardData?.result?.total_spent?.past_range?.suspensionTotal +
                          activityTopCardData?.result?.total_spent?.past_range?.nspTotal +
                          activityTopCardData?.result?.total_spent?.past_range?.trainingTotal +
                          activityTopCardData?.result?.total_spent?.past_range?.standardBonusTotal +
                          activityTopCardData?.result?.total_spent?.past_range?.specialBonusTotal +
                          activityTopCardData?.result?.total_spent?.past_range?.weekendTotal +
                          activityTopCardData?.result?.total_spent?.past_range
                            ?.supervisorStandardTotal +
                          activityTopCardData?.result?.total_spent?.past_range
                            ?.supervisorOvertimeTotal +
                          activityTopCardData?.result?.total_spent?.past_range
                            ?.supervisorPermanentTotal +
                          activityTopCardData?.result?.total_spent?.past_range
                            ?.inductionTrainingTotal +
                          activityTopCardData?.result?.total_spent?.past_range?.wtrCosts
                      )}
                      isIndicatorsVisible={
                        activityTopCardData?.result?.is_past_range_value_required
                      }
                      positive={
                        activityTopCardData?.result?.total_spent?.current_range?.standardTotal >=
                        activityTopCardData?.result?.total_spent?.past_range?.standardTotal
                          ? true
                          : false
                      }
                      children={
                        <DonuteChart
                          series={[
                            activityTopCardData?.result?.total_spent?.current_range?.standardTotal,
                            activityTopCardData?.result?.total_spent?.current_range?.overtimeTotal,
                            activityTopCardData?.result?.total_spent?.current_range?.bhTotal,
                            activityTopCardData?.result?.total_spent?.current_range
                              ?.suspensionTotal,
                            activityTopCardData?.result?.total_spent?.current_range?.nspTotal,
                            activityTopCardData?.result?.total_spent?.current_range?.trainingTotal,
                            activityTopCardData?.result?.total_spent?.current_range
                              ?.standardBonusTotal,
                            activityTopCardData?.result?.total_spent?.current_range
                              ?.specialBonusTotal,
                            activityTopCardData?.result?.total_spent?.current_range?.weekendTotal,
                            activityTopCardData?.result?.total_spent?.current_range
                              ?.supervisorStandardTotal,
                            activityTopCardData?.result?.total_spent?.current_range
                              ?.supervisorOvertimeTotal,
                            activityTopCardData?.result?.total_spent?.current_range
                              ?.supervisorPermanentTotal,
                            activityTopCardData?.result?.total_spent?.current_range
                              ?.inductionTrainingTotal,
                            activityTopCardData?.result?.total_spent?.current_range?.wtrCosts
                          ]}
                          valueType="£"
                          labels={[
                            'Standard',
                            'Overtime',
                            'Bh',
                            'Suspension',
                            'SP',
                            'Training',
                            'Standard Bonus',
                            'Special Bonus',
                            'Weekend',
                            'Supervisor Standard',
                            'Supervisor Overtime',
                            'Supervisor Permanent',
                            'Induction Training',
                            'WTR Costs'
                          ]}
                        />
                      }
                    />
                  ) : (
                    <SkeletonCircle size="40" margin="auto" />
                  )
                ) : (
                  <NoData height="130px" width="130px" />
                )}
              </Box>
            </Box>
          </DashboardCard>
          <DashboardCard
            m="5px"
            borderRadius="10px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
            position="relative">
            <Box textOverflow="ellipsis" whiteSpace="nowrap">
              <ChartCardTitle title="Hours" label="Breakdown Of Standard And Overtime Hours" />
              <Box>
                {activityTopCardData?.is_data_available ? (
                  <>
                    {activityTopCardData ? (
                      <DonutChartImageContainer
                        positive={
                          activityTopCardData?.result?.total_hours?.current_range?.standardHour >=
                          activityTopCardData?.result?.total_hours?.past_range?.standardHour
                            ? true
                            : false
                        }
                        difference={calculateTrendPercentage(
                          activityTopCardData?.result?.total_hours?.current_range?.standardHour +
                            activityTopCardData?.result?.total_hours?.current_range?.overtimeHour +
                            activityTopCardData?.result?.total_hours?.current_range?.bhHour +
                            activityTopCardData?.result?.total_hours?.current_range?.trainingHour +
                            activityTopCardData?.result?.total_hours?.current_range
                              ?.suspensionHour +
                            activityTopCardData?.result?.total_hours?.current_range?.weekendHour +
                            activityTopCardData?.result?.total_hours?.current_range
                              ?.supervisorStandardHour +
                            activityTopCardData?.result?.total_hours?.current_range
                              ?.supervisorOvertimeHour +
                            activityTopCardData?.result?.total_hours?.current_range
                              ?.supervisorPermanentHour +
                            activityTopCardData?.result?.total_hours?.current_range
                              ?.inductionTrainingHour,

                          activityTopCardData?.result?.total_hours?.past_range?.standardHour +
                            activityTopCardData?.result?.total_hours?.past_range?.overtimeHour +
                            activityTopCardData?.result?.total_hours?.past_range?.bhHour +
                            activityTopCardData?.result?.total_hours?.past_range?.trainingHour +
                            activityTopCardData?.result?.total_hours?.past_range?.suspensionHour +
                            activityTopCardData?.result?.total_hours?.past_range?.weekendHour +
                            activityTopCardData?.result?.total_hours?.past_range
                              ?.supervisorStandardHour +
                            activityTopCardData?.result?.total_hours?.past_range
                              ?.supervisorOvertimeHour +
                            activityTopCardData?.result?.total_hours?.past_range
                              ?.supervisorPermanentHour +
                            activityTopCardData?.result?.total_hours?.past_range
                              ?.inductionTrainingHour
                        )}
                        isIndicatorsVisible={
                          activityTopCardData?.result?.is_past_range_value_required
                        }
                        children={
                          <DonuteChart
                            series={[
                              activityTopCardData?.result?.total_hours?.current_range?.standardHour,
                              activityTopCardData?.result?.total_hours?.current_range?.overtimeHour,
                              activityTopCardData?.result?.total_hours?.current_range?.bhHour,
                              activityTopCardData?.result?.total_hours?.current_range?.trainingHour,
                              activityTopCardData?.result?.total_hours?.current_range
                                ?.suspensionHour,
                              activityTopCardData?.result?.total_hours?.current_range?.weekendHour,
                              activityTopCardData?.result?.total_hours?.current_range
                                ?.supervisorStandardHour,
                              activityTopCardData?.result?.total_hours?.current_range
                                ?.supervisorOvertimeHour,
                              activityTopCardData?.result?.total_hours?.current_range
                                ?.supervisorPermanentHour,
                              activityTopCardData?.result?.total_hours?.current_range
                                ?.inductionTrainingHour
                            ]}
                            labels={[
                              'Standard',
                              'Overtime',
                              'Bh',
                              'Training',
                              'Suspension',
                              'Weekend',
                              'Supervisor Standard',
                              'Supervisor Overtime',
                              'Supervisor Permanent',
                              'Induction Training'
                            ]}
                          />
                        }
                      />
                    ) : (
                      <SkeletonCircle size="40" margin="auto" />
                    )}
                  </>
                ) : (
                  <NoData height="130px" width="130px" />
                )}
              </Box>
            </Box>
          </DashboardCard>
        </Flex>
      </DashboardContainer>
      <DashboardContainer header="Workforce" color={HEADER_COLORS.ACTIVITY_COLOR}>
        <Flex>
          <DashboardCard
            m="5px"
            borderRadius="10px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px">
            <ChartCardTitle title="Shift Utilisation" label="Total Number Of Shifts Per Worker" />
            {workforceTopCardShiftUti?.is_data_available ? (
              <>
                {workforceTopCardShiftUti && workforceTopCardShiftUti?.result?.length > 0 ? (
                  <ColumnChart
                    colors={[colors.main.primary]}
                    series={[
                      {
                        data: workforceTopCardShiftUti?.result
                      }
                    ]}
                    categories={[1, 2, 3, 4, 5, 6, 7]}
                    header=""
                    customColor={false}
                    height="80%"
                    categoriesVisible
                    percentage={
                      total > 0
                        ? workforceTopCardShiftUti?.result?.map((d) => {
                            return numberFormatter((d / total) * 100);
                          })
                        : Array(workforceTopCardShiftUti?.result?.length).fill(0)
                    }
                  />
                ) : (
                  <Skeleton h="200px" w="100%" />
                )}
              </>
            ) : (
              <NoData height="130px" width="130px" />
            )}
          </DashboardCard>
          <DashboardCard
            m="5px"
            borderRadius="10px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px">
            <Box textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
              <Center display="flex" flexDir="column" pos="absolute" right={3} mt={2}>
                {workforceTopCardPoolUti?.result?.is_past_range_value_required &&
                  (workforceTopCardPoolUti?.result?.current_range?.total_count >=
                  workforceTopCardPoolUti?.result?.current_range?.last_range?.total_count ? (
                    <Center>
                      <Image src={greenArrowUp} height={6} />
                    </Center>
                  ) : (
                    <Center>
                      <Image src={redArrowDown} height={6} />
                    </Center>
                  ))}
                {workforceTopCardPoolUti?.result?.is_past_range_value_required && (
                  <Center
                    mt={1}
                    fontSize="md"
                    color={
                      workforceTopCardPoolUti?.result?.current_range?.total_count >=
                      workforceTopCardPoolUti?.result?.current_range?.last_range?.total_count
                        ? colors.main.positiveGreen
                        : colors.main.negativeRed
                    }>
                    {workforceTopCardPoolUti?.result?.current_range?.active_count === 0 ||
                    workforceTopCardPoolUti?.result?.last_range?.active_count === 0
                      ? 100
                      : Math.abs(
                          calculatePercentage(
                            workforceTopCardPoolUti?.result?.current_range?.active_count,
                            workforceTopCardPoolUti?.result?.current_range?.total_count -
                              workforceTopCardPoolUti?.result?.current_range?.active_count
                          ) -
                            calculatePercentage(
                              workforceTopCardPoolUti?.result?.last_range?.active_count,
                              workforceTopCardPoolUti?.result?.last_range?.total_count -
                                workforceTopCardPoolUti?.result?.last_range?.active_count
                            )
                        )}
                    %
                  </Center>
                )}
              </Center>
              <ChartCardTitle title="Pool Utilisation" label="Active Workers vs Declared Pool" />
              <Box>
                {workforceTopCardPoolUti?.is_data_available ? (
                  <>
                    {workforceTopCardPoolUti?.result?.current_range?.active_count > -1 ? (
                      <CircularChart
                        value={calculatePercentage(
                          workforceTopCardPoolUti?.result?.current_range?.active_count,
                          workforceTopCardPoolUti?.result?.current_range?.total_count -
                            workforceTopCardPoolUti?.result?.current_range?.active_count
                        )}
                        displayValue={calculatePercentage(
                          workforceTopCardPoolUti?.result?.current_range?.active_count,
                          workforceTopCardPoolUti?.result?.current_range?.total_count -
                            workforceTopCardPoolUti?.result?.current_range?.active_count
                        )}
                        max={100}
                        circleRatio={0.5}
                        styles={{
                          pathColor: NEW_CHART_COLOR.BLUE,
                          trailColor: colors.main.blueBackground,
                          rotation: -1 / 4
                        }}
                        maxW="220px"
                        valueType="%"
                        label="Active"
                        extraLabel="Total"
                        count={workforceTopCardPoolUti?.result?.current_range?.active_count}
                        extraCount={workforceTopCardPoolUti?.result?.current_range?.total_count}
                      />
                    ) : (
                      <SkeletonCircle size="40" margin="auto" />
                    )}
                  </>
                ) : (
                  <NoData height="130px" width="130px" />
                )}
              </Box>
            </Box>
          </DashboardCard>
        </Flex>
        <Flex>
          <DashboardCard
            fullWidth
            m="5px"
            borderRadius="10px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px">
            <ChartCardTitle title="Length of Service" label="Breakdown Of Tenure Per Worker" />
            <Box className="stack-chart">
              {workforceTopCardLOS?.is_data_available ? (
                <>
                  {workforceTopCardLOS && workforceTopCardLOS?.result?.rows?.length > 0 ? (
                    <ColumnChart
                      header=""
                      height="165px"
                      width="100%"
                      series={[{ data: workforceTopCardLOS?.result.rows.map((d) => d.data[0]) }]}
                      categories={workforceTopCardLOS?.result.rows.map((d) => d.name)}
                      categoriesVisible
                      percentage={
                        total1 > 0
                          ? workforceTopCardLOS?.result?.rows.map((d) => {
                              return numberFormatter((d.data[0] / total1) * 100);
                            })
                          : Array(workforceTopCardLOS?.result?.rows.length).fill(0)
                      }
                    />
                  ) : (
                    <Skeleton h="100px" w="100%" />
                  )}
                </>
              ) : (
                <NoData height="130px" width="130px" />
              )}
              {workforceTopCardLOS?.is_data_available && <Center fontSize="xl"># of weeks</Center>}
            </Box>
          </DashboardCard>
        </Flex>
      </DashboardContainer>
      <DashboardContainer header="Leavers" color={HEADER_COLORS.ACTIVITY_COLOR}>
        <Flex>
          <DashboardCard
            m="5px"
            borderRadius="10px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px">
            <Box>
              <Center display="flex" flexDir="column" pos="absolute" right="51.3%">
                {leaverTopCardCounts?.result?.is_past_range_value_required && (
                  <>
                    <Center>
                      {leaverTopCardCounts?.result?.leavers_count.current_value >=
                      leaverTopCardCounts?.result?.leavers_count.past_value ? (
                        <Image src={greenArrowUp} height={6} />
                      ) : (
                        <Image src={redArrowDown} height={6} />
                      )}
                    </Center>
                    <Center
                      fontSize="md"
                      mt={1}
                      color={
                        leaverTopCardCounts?.result.leavers_count.current_value >=
                        leaverTopCardCounts?.result.leavers_count.past_value
                          ? colors.main.positiveGreen
                          : colors.main.negativeRed
                      }>
                      {calculateTrendPercentage(
                        leaverTopCardCounts?.result.leavers_count.current_value,
                        leaverTopCardCounts?.result.leavers_count.past_value
                      )}
                      %
                    </Center>
                  </>
                )}
              </Center>
              <ChartCardTitle title="Leavers" label="Number Of Confirmed Leavers" />
              <Box margin="auto">
                {leaverTopCardCounts?.is_data_available ? (
                  <>
                    {leaverTopCardCounts ? (
                      <>
                        <Center fontSize="60px" color={DASHBOARD_TEXT_COLOR.TEXT_COLOR} h="227px">
                          {Math.round(
                            leaverTopCardCounts?.result?.leavers_count.current_value
                          ).toString().length > 6
                            ? numberFormatter(
                                leaverTopCardCounts?.result?.leavers_count.current_value
                              )
                            : leaverTopCardCounts?.result?.leavers_count.current_value}
                        </Center>
                      </>
                    ) : (
                      <Skeleton h="200px" w="100%" />
                    )}
                  </>
                ) : (
                  <NoData height="130px" width="130px" />
                )}
              </Box>
            </Box>
          </DashboardCard>
          <DashboardCard
            m="5px"
            borderRadius="10px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px">
            <Box textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
              <ChartCardTitle
                title="New Starter Retention"
                label="Percentage Of New Starters Remaining After 2 Weeks"
              />
              <Box>
                {leaverTopCardCounts?.is_data_available ? (
                  <>
                    {leaverTopCardCounts?.result?.new_starter_retention?.inactive > -1 ? (
                      <CircularChart
                        value={calculatePercentage(
                          leaverTopCardCounts?.result?.new_starter_retention?.active,
                          leaverTopCardCounts?.result?.new_starter_retention?.inactive
                        )}
                        displayValue={calculatePercentage(
                          leaverTopCardCounts?.result?.new_starter_retention?.active,
                          leaverTopCardCounts?.result?.new_starter_retention?.inactive
                        )}
                        max={100}
                        circleRatio={0.5}
                        styles={{
                          pathColor: NEW_CHART_COLOR.BLUE,
                          trailColor: colors.main.blueBackground,
                          rotation: -1 / 4
                        }}
                        maxW="220px"
                        valueType="%"
                        isIndicatorsVisible={false}
                        label="New Starters"
                        count={
                          leaverTopCardCounts?.result?.new_starter_retention?.active +
                          leaverTopCardCounts?.result?.new_starter_retention?.inactive
                        }
                        extraLabel="Leavers"
                        extraCount={leaverTopCardCounts?.result?.new_starter_retention?.inactive}
                      />
                    ) : (
                      <SkeletonCircle size="40" margin="auto" />
                    )}
                  </>
                ) : (
                  <NoData height="130px" width="130px" />
                )}
              </Box>
            </Box>
          </DashboardCard>
        </Flex>
        <Flex>
          <DashboardCard
            fullWidth
            m="5px"
            borderRadius="10px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px">
            <ChartCardTitle title="Length of Service" label="Breakdown Of Tenure Per Worker" />
            <Box className="stack-chart">
              {leaverTopCardLos?.is_data_available ? (
                <>
                  {leaverTopCardLos &&
                  leaverTopCardLos?.result?.rows &&
                  leaverTopCardLos?.result.rows.length > 0 ? (
                    <ColumnChart
                      header=""
                      height="165px"
                      width="100%"
                      series={[{ data: leaverTopCardLos?.result.rows.map((d) => d.data[0]) }]}
                      categories={leaverTopCardLos?.result.rows.map((d) => d.name)}
                      percentage={
                        total2 > 0
                          ? leaverTopCardLos?.result.rows.map((d) => {
                              return numberFormatter((d.data[0] / total2) * 100);
                            })
                          : Array(leaverTopCardLos?.result.rows.length).fill(0)
                      }
                      categoriesVisible
                    />
                  ) : (
                    <Skeleton h="100px" w="100%" />
                  )}
                </>
              ) : (
                <NoData height="130px" width="130px" />
              )}
              {leaverTopCardLos?.is_data_available && <Center fontSize="xl"># of weeks</Center>}
            </Box>
          </DashboardCard>
        </Flex>
      </DashboardContainer>
    </Flex>
  );
};

export default DashboardTopCard;
