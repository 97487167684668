import { Flex } from '@chakra-ui/react';
import styled from 'styled-components';

export const StyledSurveysContainer = styled.div``;

export const StyledSurveysCardHeaderContainer = styled(Flex)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
