import React from 'react';

const ShiftIcon = function () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 22 27">
      <path
        fill="currentColor"
        d="M21.838 21.014L11.39 26.909a.89.89 0 01-.78 0L.162 21.014c-.213-.12-.213-.319 0-.44l2.502-1.409a.893.893 0 01.78 0l7.166 4.038a.886.886 0 00.78 0l7.166-4.038a.892.892 0 01.78 0l2.503 1.412c.215.118.215.316 0 .437zm0-8.229l-2.502-1.412a.892.892 0 00-.78 0l-7.166 4.043a.895.895 0 01-.78 0l-7.165-4.043a.893.893 0 00-.781 0L.162 12.785c-.213.12-.213.32 0 .44L10.61 19.12a.889.889 0 00.78 0l10.448-5.894c.216-.122.216-.32 0-.44zM.163 5.932l10.448 5.441c.25.112.529.112.78 0l10.448-5.441c.213-.112.213-.295 0-.406L11.39.085a.942.942 0 00-.78 0L.162 5.525c-.216.112-.216.295 0 .407z"
      />
    </svg>
  );
};

export default ShiftIcon;
