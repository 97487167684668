import styled from 'styled-components';
import { colors } from '../../theme';

// eslint-disable-next-line import/prefer-default-export
export const StyledCard = styled.div`
  position: relative;
  padding: 10px;
  border-radius: 10px;
  background-color: ${colors.main.white};
  // overflow: hidden;
`;
