/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import { Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Dropdown from '../Dropdown';
import { YesNoDropdownList } from '../../constants';

const SelectionDropdown = function ({
  Dropdownvalue,
  validationObj,
  setDropdownvalue,
  refresh,
  lable
}) {
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setIsValid] = useState(validationObj?.isvalid || true);

  useEffect(() => {
    if (validationObj && !validationObj?.isvalid) {
      setErrorMsg(validationObj?.errorMessage);
      setIsValid(validationObj?.isvalid);
    }
  }, [refresh]);

  const setDropdownData = (e) => {
    setErrorMsg(null);
    setDropdownvalue(e);
    setIsValid(true);
  };
  return (
    <>
      <Dropdown
        label={lable}
        placeholder="Select"
        options={YesNoDropdownList.map((item) => {
          return { label: item, value: item };
        })}
        onSelect={(e) => {
          setDropdownData(e);
        }}
        value={Dropdownvalue}
        isError={!isValid}
        disabled={false}
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error!'}
      </Text>
    </>
  );
};

export default SelectionDropdown;
