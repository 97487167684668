/* eslint-disable no-underscore-dangle */
/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Text, Flex, Box, Tooltip, useMediaQuery } from '@chakra-ui/react';
import moment from 'moment';
import Card from '../../components/Card';
import { StyledContainer } from './ClientDashboard.stgyled';
import { colors } from '../../theme';
import {
  getHeaderData,
  getLeaverAnalysisData,
  getRatingData,
  getActivityTopCardData,
  // getWorkforceTopCardDemographicsData,
  getWorkforceTopCardLOSData,
  getWorkforceTopCardShiftUtiData,
  getWorkforceTopCardPoolUtiData,
  getLeaverTopCardLOS,
  getLeaverTopCardCounts,
  // getLeaverTopCardShiftUti,
  getActivityBottomCardShiftUti,
  // getActivityBottomCardLostShift,
  getActivityBottomCardHeadCount,
  getActivityBottomCardSpend,
  getActivityBottomCardAverageHours,
  // getWorkforceDemo,
  getWorkforceShiftUti,
  getWorkforceLOS,
  getLeaverNSR,
  getLeaverCount,
  // getLeaverShiftUtl,
  getLeaverLOS,
  getLeaverTopCardPoolUti,
  getSiteRatingData,
  getAgencyRatingData,
  getClientRatingData
} from '../../redux/action/regionDashboard.action';
import DashboardRatingCard from '../../components/DashboardRatingCard';
import DashboardTopCard from '../../components/DashboardTopCard';
import DashboardBottomCard from '../../components/DashboardBottomCard';
import { PAGE_TITLE } from '../../constants';
import {
  currancyFormatter,
  currencySymbol,
  isDashboardBottomCardVisible
} from '../../utils/helper';

const RegionDashboard = function () {
  useEffect(() => {
    document.title = PAGE_TITLE.DASHBOARD;
  }, []);

  const { userData, user_details } = useSelector((state) => state.authentication);
  const { selected_start_date, selected_end_date } = useSelector((state) => state.agency);
  const [ratingData, setRatingData] = useState(null);
  const [siteRatingData, setSiteRatingData] = useState(null);
  const [agencyRatingData, setAgencyRatingData] = useState(null);
  const [clientRatingData, setClientRatingData] = useState(null);
  const [headerData, setHeaderData] = useState(null);
  const [leaversData, setLeaversData] = useState(null);
  const [activityTopCardData, setActivityTopCardData] = useState(null);
  // const [workforceTopCardDemographics, setWorkforceTopCardDemographics] = useState(null);
  const [workforceTopCardLOS, setWorkforceTopCardLOS] = useState(null);
  const [workforceTopCardShiftUti, setWorkforceTopCardShiftUti] = useState(null);
  const [workforceTopCardPoolUti, setWorkforceTopCardPoolUti] = useState(null);
  const [leaverTopCardLos, setLeaverTopCardLos] = useState(null);
  const [leaverTopCardCounts, setLeaverTopCardCounts] = useState(null);
  const [leaversTopCardPoolUti, setLeaversTopCardPoolUti] = useState(null);

  const [activityBottomCardShiftUtil, setActivityBottomCardShiftUtil] = useState(null);
  // const [activityBottomCardLostShift, setActivityBottomCardLostShift] = useState(null);
  const [activityBottomCardHeadCount, setActivityBottomCardHeadCount] = useState(null);
  const [activityBottomCardSpend, setActivityBottomCardSpend] = useState(null);
  const [activityBottomCardAverageHours, setActivityBottomCardAverageHours] = useState(null);

  // const [workforceDemo, setWorkforceDemo] = useState(null);
  const [workforceShiftUti, setWorkforceShiftUti] = useState(null);
  const [workforceLOS, setWorkforceLOS] = useState(null);

  const [leaverNSR, setLeaverNSR] = useState(null);
  const [leaverCount, setLeaverCount] = useState(null);
  // const [leaverShiftUtl, setLeaverShiftUtl] = useState(null);
  const [leaverLOS, setLeaverLOS] = useState(null);
  // const [isSmallerThan1440] = useMediaQuery('(max-width: 1440px)');

  const { selected_client_id } = useSelector((state) => state.agency);

  const [isCurrentWeek, setIsCurrentWeek] = useState(true);
  useEffect(async () => {
    setIsCurrentWeek(() => isDashboardBottomCardVisible(selected_start_date, selected_end_date));
    resetData();
    getChartData(selected_start_date, selected_end_date);
    // }
  }, [selected_start_date, selected_end_date]);

  const resetData = () => {
    setAgencyRatingData(null);
    setClientRatingData(null);
    setSiteRatingData(null);
    setActivityTopCardData(null);
    setWorkforceTopCardLOS(null);
    setWorkforceTopCardShiftUti(null);
    setWorkforceTopCardPoolUti(null);
    setLeaverTopCardCounts(null);
    setLeaverTopCardLos(null);
    setLeaversTopCardPoolUti(null);
    setActivityBottomCardShiftUtil(null);
    setActivityBottomCardHeadCount(null);
    setActivityBottomCardSpend(null);
    setActivityBottomCardAverageHours(null);
    setWorkforceShiftUti(null);
    setWorkforceLOS(null);
    setLeaverNSR(null);
    setLeaverCount(null);
    setLeaverLOS(null);
  };

  const getChartData = (startDate, endDate) => {
    if (
      (userData.client_id || user_details.client_id) &&
      isDashboardBottomCardVisible(selected_start_date, selected_end_date)
    ) {
      Promise.all([
        // getRatingDataFunction(startDate, endDate),
        getSiteRatingDataFunction(startDate, endDate),
        getAgencyRatingDataFunction(startDate, endDate),
        getClientRatingDataFunction(startDate, endDate),
        getHeaderDataFunction(startDate, endDate),
        getLeaverAnalysisDataFunction(startDate, endDate),
        getActivityTopCardDataFunction(startDate, endDate),
        getWorkforceTopCardLOSDataFunction(startDate, endDate),
        getWorkforceTopCardShiftUtiDataFunction(startDate, endDate),
        getWorkforceTopCardPoolUtiDataFunction(startDate, endDate),
        getLeaverTopCardLOSFunction(startDate, endDate),
        getLeaverTopCardCountsFunction(startDate, endDate),
        getActivityBottomCardShiftUtiFunction(startDate, endDate),
        getActivityBottomCardHeadCountFunction(startDate, endDate),
        getActivityBottomCardSpendFunction(startDate, endDate),
        getActivityBottomCardAverageHoursFunction(startDate, endDate),
        getWorkforceShiftUtiFunction(startDate, endDate),
        getWorkforceLOSFunction(startDate, endDate),
        getLeaverNSRFunction(startDate, endDate),
        getLeaverCountFunction(startDate, endDate),
        getLeaverLOSFunction(startDate, endDate)
        // getLeaverTopCardPoolUtiFunction(startDate, endDate)
      ]);
    } else if (userData.client_id || user_details.client_id) {
      Promise.all([
        // getRatingDataFunction(startDate, endDate),
        getSiteRatingDataFunction(startDate, endDate),
        getAgencyRatingDataFunction(startDate, endDate),
        getClientRatingDataFunction(startDate, endDate),
        getHeaderDataFunction(startDate, endDate),
        getLeaverAnalysisDataFunction(startDate, endDate),
        getActivityTopCardDataFunction(startDate, endDate),
        getWorkforceTopCardLOSDataFunction(startDate, endDate),
        getWorkforceTopCardShiftUtiDataFunction(startDate, endDate),
        getWorkforceTopCardPoolUtiDataFunction(startDate, endDate),
        getLeaverTopCardLOSFunction(startDate, endDate),
        getLeaverTopCardCountsFunction(startDate, endDate)
        // getLeaverTopCardPoolUtiFunction(startDate, endDate)
      ]);
    }
  };

  const getRatingDataFunction = async () => {
    const rating = await getRatingData(
      userData.client_id,
      user_details.region_id,
      selected_start_date,
      selected_end_date,
      userData.agency_id
    );
    if (rating && rating.ok) {
      setRatingData(rating.data.result);
    }
  };

  const getSiteRatingDataFunction = async () => {
    const rating = await getSiteRatingData(
      userData.client_id || user_details.client_id,
      user_details.region_id
    );
    if (rating && rating.ok) {
      setSiteRatingData(rating.data);
    }
  };

  const getAgencyRatingDataFunction = async () => {
    const rating = await getAgencyRatingData(
      userData.client_id || user_details.client_id,
      userData.agency_id || user_details.agency_id
    );
    if (rating && rating.ok) {
      setAgencyRatingData(rating.data);
    }
  };

  const getClientRatingDataFunction = async () => {
    const rating = await getClientRatingData(userData.client_id || user_details.client_id);
    if (rating && rating.ok) {
      setClientRatingData(rating.data);
    }
  };

  const getHeaderDataFunction = async () => {
    const header = await getHeaderData(
      userData.client_id || user_details.client_id,
      user_details.region_id,
      selected_start_date,
      selected_end_date,
      userData.agency_id
    );
    if (header && header.ok) {
      setHeaderData(header.data);
    }
  };

  const getLeaverAnalysisDataFunction = async () => {
    const leaverAnalysis = await getLeaverAnalysisData(
      userData.client_id || user_details.client_id,
      user_details.region_id,
      selected_start_date,
      selected_end_date,
      userData.agency_id
    );
    if (leaverAnalysis && leaverAnalysis.ok) {
      setLeaversData(leaverAnalysis.data);
    }
  };

  const getActivityTopCardDataFunction = async () => {
    const activityTopCard = await getActivityTopCardData(
      userData.client_id || user_details.client_id,
      user_details.region_id,
      selected_start_date,
      selected_end_date,
      userData.agency_id
    );
    if (activityTopCard && activityTopCard.ok) {
      setActivityTopCardData(activityTopCard.data);
    }
  };

  const getWorkforceTopCardLOSDataFunction = async () => {
    const WorkforceTopCard = await getWorkforceTopCardLOSData(
      userData.client_id || user_details.client_id,
      user_details.region_id,
      selected_start_date,
      selected_end_date,
      userData.agency_id
    );
    if (WorkforceTopCard && WorkforceTopCard.ok) {
      setWorkforceTopCardLOS(WorkforceTopCard.data);
    }
  };

  const getWorkforceTopCardShiftUtiDataFunction = async () => {
    const WorkforceTopCard = await getWorkforceTopCardShiftUtiData(
      userData.client_id || user_details.client_id,
      user_details.region_id,
      selected_start_date,
      selected_end_date,
      userData.agency_id
    );
    if (WorkforceTopCard && WorkforceTopCard.ok) {
      setWorkforceTopCardShiftUti(WorkforceTopCard.data);
    }
  };

  const getWorkforceTopCardPoolUtiDataFunction = async () => {
    const WorkforceTopCard = await getWorkforceTopCardPoolUtiData(
      userData.client_id || user_details.client_id,
      user_details.region_id,
      selected_start_date,
      selected_end_date,
      userData.agency_id
    );
    if (WorkforceTopCard && WorkforceTopCard.ok) {
      setWorkforceTopCardPoolUti(WorkforceTopCard.data);
    }
  };

  const getLeaverTopCardLOSFunction = async () => {
    const leaverTopCardData = await getLeaverTopCardLOS(
      userData.client_id || user_details.client_id,
      user_details.region_id,
      selected_start_date,
      selected_end_date,
      userData.agency_id
    );
    if (leaverTopCardData && leaverTopCardData.ok) {
      setLeaverTopCardLos(leaverTopCardData.data);
    }
  };

  const getLeaverTopCardPoolUtiFunction = async () => {
    const leaverTopCardData = await getLeaverTopCardPoolUti(
      userData.client_id || user_details.client_id,
      user_details.region_id,
      selected_start_date,
      selected_end_date,
      userData.agency_id
    );
    if (leaverTopCardData && leaverTopCardData.ok) {
      setLeaversTopCardPoolUti(leaverTopCardData.data);
    }
  };

  const getLeaverTopCardCountsFunction = async () => {
    const leaverTopCardData = await getLeaverTopCardCounts(
      userData.client_id || user_details.client_id,
      user_details.region_id,
      selected_start_date,
      selected_end_date,
      userData.agency_id
    );
    if (leaverTopCardData && leaverTopCardData.ok) {
      setLeaverTopCardCounts(leaverTopCardData.data);
    }
  };

  const getActivityBottomCardShiftUtiFunction = async () => {
    const bottomDeckData = await getActivityBottomCardShiftUti(
      userData.client_id || user_details.client_id,
      user_details.region_id,
      selected_start_date,
      selected_end_date
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardShiftUtil(bottomDeckData?.data);
    }
  };

  const getActivityBottomCardHeadCountFunction = async () => {
    const bottomDeckData = await getActivityBottomCardHeadCount(
      userData.client_id || user_details.client_id,
      user_details.region_id,
      selected_start_date,
      selected_end_date
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardHeadCount(bottomDeckData?.data);
    }
  };

  const getActivityBottomCardSpendFunction = async () => {
    const bottomDeckData = await getActivityBottomCardSpend(
      userData.client_id || user_details.client_id,
      user_details.region_id,
      selected_start_date,
      selected_end_date
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardSpend(bottomDeckData?.data);
    }
  };

  const getActivityBottomCardAverageHoursFunction = async () => {
    const bottomDeckData = await getActivityBottomCardAverageHours(
      userData.client_id || user_details.client_id,
      user_details.region_id,
      selected_start_date,
      selected_end_date
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardAverageHours(bottomDeckData?.data);
    }
  };

  const getWorkforceShiftUtiFunction = async () => {
    const bottomDeckData = await getWorkforceShiftUti(
      userData.client_id || user_details.client_id,
      user_details.region_id,
      selected_start_date,
      selected_end_date
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setWorkforceShiftUti(bottomDeckData?.data);
    }
  };

  const getWorkforceLOSFunction = async () => {
    const bottomDeckData = await getWorkforceLOS(
      userData.client_id || user_details.client_id,
      user_details.region_id,
      selected_start_date,
      selected_end_date
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setWorkforceLOS(bottomDeckData?.data);
    }
  };

  const getLeaverNSRFunction = async () => {
    const bottomDeckData = await getLeaverNSR(
      userData.client_id || user_details.client_id,
      user_details.region_id,
      selected_start_date,
      selected_end_date
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setLeaverNSR(bottomDeckData?.data);
    }
  };

  const getLeaverCountFunction = async () => {
    const bottomDeckData = await getLeaverCount(
      userData.client_id || user_details.client_id,
      user_details.region_id,
      selected_start_date,
      selected_end_date
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setLeaverCount(bottomDeckData?.data);
    }
  };

  const getLeaverLOSFunction = async () => {
    const bottomDeckData = await getLeaverLOS(
      userData.client_id || user_details.client_id,
      user_details.region_id,
      selected_start_date,
      selected_end_date
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setLeaverLOS(bottomDeckData?.data);
    }
  };

  const from = moment(headerData?.result?.clearvue_savings?.last_week).format('DD/MM/YYYY');
  const obj = moment(from, 'DD/MM/YYYY').add(6, 'days')._d;
  const to = moment(obj).format('DD/MM/YYYY');

  return (
    <Box>
      <Card margin="5px">
        <Flex justifyContent="space-around" fontSize={25}>
          <>
            <Text>
              Identified Savings Last Week:{' '}
              <Tooltip
                borderRadius="7px"
                label={
                  from && from !== '' && from !== 'Invalid date' ? `From: ${from} To: ${to}` : ''
                }>
                <Text as="span" color={colors.main.primary} fontWeight="semibold">
                  {Math.round(headerData?.result?.clearvue_savings?.value)?.toString()?.length > 6
                    ? currancyFormatter(headerData?.result?.clearvue_savings?.value || 0)
                    : currencySymbol(headerData?.result?.clearvue_savings?.value || 0)}
                </Text>
              </Tooltip>
            </Text>
            <Text>
              Cumulative Savings:{' '}
              <Text as="span" color={colors.main.primary} fontWeight="semibold">
                {Math.round(headerData?.result?.cumulative_clearvue_savings)?.toString()?.length > 6
                  ? currancyFormatter(headerData?.result?.cumulative_clearvue_savings || 0)
                  : currencySymbol(headerData?.result?.cumulative_clearvue_savings || 0)}
              </Text>
            </Text>
          </>
        </Flex>
      </Card>

      <DashboardRatingCard
        siteRatingData={siteRatingData}
        agencyRatingData={agencyRatingData}
        clientRatingData={clientRatingData}
        ratingData={ratingData}
        headerData={headerData}
        leaversData={leaversData}
      />
      <Box>
        <DashboardTopCard
          activityTopCardData={activityTopCardData}
          workforceTopCardPoolUti={workforceTopCardPoolUti}
          workforceTopCardShiftUti={workforceTopCardShiftUti}
          workforceTopCardLOS={workforceTopCardLOS}
          leaverTopCardLos={leaverTopCardLos}
          leaversTopCardPoolUti={leaversTopCardPoolUti}
          leaverTopCardCounts={leaverTopCardCounts}
          isDataAvailable={userData.client_id || false}
        />
      </Box>

      {isCurrentWeek && (
        <Box>
          <DashboardBottomCard
            activityBottomCardShiftUtil={activityBottomCardShiftUtil}
            activityBottomCardSpend={activityBottomCardSpend}
            activityBottomCardAverageHours={activityBottomCardAverageHours}
            workforceLOS={workforceLOS}
            leaverLOS={leaverLOS}
            leaverCount={leaverCount}
            leaverNSR={leaverNSR}
            activityBottomCardHeadCount={activityBottomCardHeadCount}
            workforceShiftUti={workforceShiftUti}
            isDataAvailable={userData.client_id || false}
          />
        </Box>
      )}
    </Box>

    // {/* <ChartCard
    //   activityTopCardData={activityTopCardData}
    //   workforceTopCardDemographics={workforceTopCardDemographics}
    //   workforceTopCardLOS={workforceTopCardLOS}
    //   workforceTopCardShiftUti={workforceTopCardShiftUti}
    //   workforceTopCardPoolUti={workforceTopCardPoolUti}
    //   leaverTopCardLos={leaverTopCardLos}
    //   leaverTopCardCounts={leaverTopCardCounts}
    //   leaverTopCardShiftUti={leaverTopCardShiftUti}
    //   activityBottomCardShiftUtil={activityBottomCardShiftUtil}
    //   activityBottomCardLostShift={activityBottomCardLostShift}
    //   activityBottomCardHeadCount={activityBottomCardHeadCount}
    //   activityBottomCardSpend={activityBottomCardSpend}
    //   activityBottomCardAverageHours={activityBottomCardAverageHours}
    //   workforceDemo={workforceDemo}
    //   workforceShiftUti={workforceShiftUti}
    //   workforceLOS={workforceLOS}
    //   leaverNSR={leaverNSR}
    //   leaverCount={leaverCount}
    //   leaverShiftUtl={leaverShiftUtl}
    //   leaverLOS={leaverLOS}
    // /> */}
  );
};

export default RegionDashboard;
