/* eslint-disable default-param-last */
import { API_ENDPOINT } from '../../constants';
import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';

export const getSupervisorsData = async (
  clientId = '',
  agencyId = '',
  startDate = '',
  endDate = '',
  limit = 10,
  page = 1,
  sortKey = 'site_name',
  sortType = 'ASC'
) => {
  try {
    let queryString = '';

    if (clientId) {
      queryString += `client_id=${clientId}&`;
    }
    if (agencyId) {
      queryString += `agency_id=${agencyId}&`;
    }
    if (startDate) {
      queryString += `start_date=${startDate}&`;
    }
    if (endDate) {
      queryString += `end_date=${endDate}&`;
    }
    queryString += `page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}`;

    const result = await axiosInstance.get(
      `${API_ENDPOINT.AGENCY_MANAGEMENT_SUPERVISORS_DATA}?${queryString}`
    );

    if (result && result.ok) {
      return prepareSuccessReponse(result);
    }
    return prepareErrorResponse(result);
  } catch (error) {
    return prepareErrorResponse(error);
  }
};

export const getSupervisorsDataByPayrollMeta = async (
  payrollMetaId,
  page = 1,
  limit = 10,
  sortKey = 'total_charge',
  sortType = 'ASC'
) => {
  try {
    const queryString = `page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}`;

    const result = await axiosInstance.get(
      `${API_ENDPOINT.AGENCY_MANAGEMENT_SUPERVISORS_DATA}/${payrollMetaId}?${queryString}`
    );

    if (result && result.ok) {
      return prepareSuccessReponse(result);
    }
    return prepareErrorResponse(result);
  } catch (error) {
    return prepareErrorResponse(error);
  }
};
