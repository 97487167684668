import React from 'react';

const UserIcon = function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      version="1"
      viewBox="0 0 219 230">
      <path
        fill="currentColor"
        d="M975 2190c-137-21-231-67-321-155-112-111-161-255-150-447 5-93 3-113-14-150-38-83-23-183 41-266 21-29 45-77 55-112 9-33 34-85 55-116 26-36 35-56 26-59-6-2-46-16-87-31-190-67-357-181-430-294-67-105-92-269-50-337 40-66 50-68 395-68h310l3 72 3 73H217l7 47c3 27 20 76 36 110 55 111 168 189 402 273 64 23 125 51 134 61 12 13 19 43 22 93 4 72 4 74-30 112-46 51-64 89-73 149-6 40-17 60-51 97-65 70-71 133-15 165l28 16-14 56c-23 90-20 245 5 314 38 105 118 187 224 230 43 17 75 21 163 22 103 0 114-2 177-32 88-42 151-101 190-180 27-55 32-77 36-163s-6-188-24-234c-3-7 6-18 20-24 16-7 29-25 37-52l12-40h140l-5 44c-3 25-14 62-25 83-18 34-19 46-10 112 32 254-92 490-308 586-95 42-226 60-320 45z"
        transform="matrix(.1 0 0 -.1 0 230)"
      />
      <path
        fill="currentColor"
        d="M945 810V450h515V300h-221l3-72 3-73 288-3 287-2v150h-220v150h510v720H945V810zm1025 0V590h-880v440h880V810z"
        transform="matrix(.1 0 0 -.1 0 230)"
      />
    </svg>
  );
};

export default UserIcon;
