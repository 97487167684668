/* eslint-disable no-use-before-define */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import { Button, Stack, Flex, Box, useToast } from '@chakra-ui/react';
import InputComponent from '../Input';
import ModalComponent from '../Modal';
import { ADD_TRAINING_RULE, CUSTOM_TOAST_DURATION, TOAST_SETTINGS } from '../../constants';
import {
  allowTwoDecimalNumbersInRange,
  allowWeekNumber,
  deepClone,
  validateForm
} from '../../utils/helper';
import SiteDropdownForSuperAdmin from '../Forms/SiteDropdownForSuperAdmin';

const TrainingRuleAddEditForm = function ({
  isOpen,
  onClose,
  isEdit,
  existingData,
  onSave,
  clientId,
  tableRefreash
}) {
  const [formData, setFormData] = useState({
    site: null,
    shift: null,
    department: null,
    performanceNumber: '',
    weekNumber: '',
    checkWeek: '',
    maxTrainingHours: '',
    credit: '',
    regionId: ''
  });
  const toast = useToast();
  const [validationObject, setValidationObject] = useState(deepClone(ADD_TRAINING_RULE));
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (isOpen) {
      if (isEdit && existingData) {
        setFormData({
          ...existingData,
          site: existingData.site,
          shift: existingData.shift,
          department: existingData.department,
          performanceNumber: existingData.performanceNumber,
          weekNumber: existingData.weekNumber,
          checkWeek: existingData.checkWeek,
          maxTrainingHours: existingData.maxTrainingHours,
          credit: existingData.credit,
          regionId: existingData.regionId
        });
      } else {
        resetForm();
      }
    }
  }, [isOpen, isEdit, existingData]);

  const resetForm = () => {
    setFormData({
      site: null,
      shift: null,
      department: null,
      performanceNumber: '',
      weekNumber: '',
      checkWeek: '',
      maxTrainingHours: '',
      credit: '',
      regionId: ''
    });
    setValidationObject(deepClone(ADD_TRAINING_RULE));
  };

  const handleSave = () => {
    // eslint-disable-next-line prefer-const
    let [validatedObject, isFormValid] = validateForm(formData, validationObject);
    setValidationObject(validatedObject);

    if (Number(formData.checkWeek) < Number(formData.weekNumber)) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.ERROR,
        title: 'Week Number Cannot Be Greater Than Check Week'
      });
      return;
    }

    setRefresh(!refresh);

    if (isFormValid) {
      tableRefreash();
      onSave(formData);
    }
  };

  const handleModalClose = () => {
    setValidationObject(deepClone(ADD_TRAINING_RULE));
    onClose();
  };

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSiteChange = async (value) => {
    await setFormData((prev) => ({
      ...prev,
      site: value,
      shift: null,
      department: null
    }));
  };

  return (
    <ModalComponent
      specifySize="xl"
      isOpen={isOpen}
      onClose={handleModalClose}
      modalTitle={isEdit ? 'Edit Training Rule' : 'Add Training Rule'}
      modalContent={
        <Stack spacing={4}>
          <Flex direction="row" wrap="wrap" justify="space-between">
            <Box width={['100%']}>
              <SiteDropdownForSuperAdmin
                setRegionId={(value) => handleInputChange('regionId', value)}
                clientId={clientId}
                site={formData.site}
                setSite={handleSiteChange}
                validationObj={validationObject.site}
                refresh={refresh}
              />
            </Box>
            <Box width={['100%', '48%']}>
              <InputComponent
                lable="Check Week"
                placeholder="Check Week"
                value={formData.checkWeek}
                onChange={(value) => handleInputChange('checkWeek', allowWeekNumber(value))}
                validationObj={validationObject.checkWeek}
                refresh={refresh}
              />
            </Box>
            <Box width={['100%', '48%']}>
              <InputComponent
                lable="Week Number"
                placeholder="Week Number"
                value={formData.weekNumber}
                onChange={(value) => handleInputChange('weekNumber', allowWeekNumber(value))}
                validationObj={validationObject.weekNumber}
                refresh={refresh}
              />
            </Box>
            <Box width={['100%', '48%']}>
              <InputComponent
                lable="Performance Number"
                placeholder="Performance Number"
                value={formData.performanceNumber}
                onChange={(value) =>
                  handleInputChange(
                    'performanceNumber',
                    allowTwoDecimalNumbersInRange(value, 0, 999)
                  )
                }
                validationObj={validationObject.performanceNumber}
                refresh={refresh}
              />
            </Box>
            <Box width={['100%', '48%']}>
              <InputComponent
                lable="Max Training Hours"
                placeholder="Max Training Hours"
                value={formData.maxTrainingHours}
                onChange={(value) =>
                  handleInputChange(
                    'maxTrainingHours',
                    allowTwoDecimalNumbersInRange(value, 0, 999)
                  )
                }
                validationObj={validationObject.maxTrainingHours}
                refresh={refresh}
              />
            </Box>
            <Box width={['100%', '48%']}>
              <InputComponent
                lable="Credit(%)"
                type="text"
                placeholder="Credit(%)"
                value={formData.credit}
                onChange={(value) =>
                  handleInputChange('credit', allowTwoDecimalNumbersInRange(value))
                }
                validationObj={validationObject.credit}
                refresh={refresh}
              />
            </Box>
          </Flex>
        </Stack>
      }
      negative={
        <Button onClick={handleModalClose} bg="main.secondary" mr={3} color="main.black">
          Cancel
        </Button>
      }
      positive={
        <Button
          onClick={handleSave}
          bg="main.semiPrimary"
          color="main.white"
          _hover={{ bg: 'main.primary' }}
          mr={3}
          _active={{
            bg: 'main.semiPrimary',
            borderColor: 'main.primary'
          }}>
          Save
        </Button>
      }
    />
  );
};

export default TrainingRuleAddEditForm;
