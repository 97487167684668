/* eslint-disable react/function-component-definition */
/* eslint-disable no-use-before-define */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Box, Button, Table, Thead, Tbody, Tr, Th, Td, Text } from '@chakra-ui/react';
import LOSMarginModal from '../LOSMarginModal/LOSMarginModal';
import SiteMarginModal from '../SiteMarginModal/SiteMarginModal';

const SiteListTable = ({ sites, associationId }) => {
  const [selectedSite, setSelectedSite] = useState(null);
  const [isLOSModalOpen, setIsLOSModalOpen] = useState(false);
  const [isSiteMarginModalOpen, setIsSiteMarginModalOpen] = useState(false);

  const handleOpenLOSModal = (site) => {
    setSelectedSite(site);
    setIsLOSModalOpen(true);
  };

  const handleOpenSiteMarginModal = (site) => {
    setSelectedSite(site);
    setIsSiteMarginModalOpen(true);
  };

  const handleLOSSave = () => {
    setIsLOSModalOpen(false);
  };

  const handleSiteMarginSave = () => {
    setIsSiteMarginModalOpen(false);
  };

  return (
    <Box overflowX="auto" borderWidth="3px" borderRadius="lg" borderColor="main.primary" p={4}>
      <Text fontSize="xl" fontWeight="bold" textAlign="left" mb={4} color="blue.700" ali>
        Site Rules
      </Text>
      {sites.length > 0 ? (
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th textAlign="left">Site Name</Th>
              <Th textAlign="center">Site Margin</Th>
              <Th textAlign="center">LOS Margin</Th>
            </Tr>
          </Thead>
          <Tbody>
            {sites.map((site) => (
              <Tr key={site.site_id}>
                <Td textAlign="left">{site.site_name}</Td>
                <Td textAlign="center" pr={0.25}>
                  <Button
                    size="md"
                    bg="main.semiPrimary"
                    color="main.white"
                    _hover={{ bg: 'main.primary' }}
                    onClick={() => handleOpenSiteMarginModal(site)}>
                    Margin
                  </Button>
                </Td>
                <Td textAlign="center" pl={0.25}>
                  <Button
                    size="md"
                    bg="main.semiPrimary"
                    color="main.white"
                    _hover={{ bg: 'main.primary' }}
                    onClick={() => handleOpenLOSModal(site)}>
                    LOS
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Text textAlign="center" color="gray.500">
          No sites available.
        </Text>
      )}

      {selectedSite && (
        <LOSMarginModal
          associationId={associationId}
          isOpen={isLOSModalOpen}
          onClose={() => setIsLOSModalOpen(false)}
          site={selectedSite}
          onSave={handleLOSSave}
        />
      )}

      {selectedSite && (
        <SiteMarginModal
          isOpen={isSiteMarginModalOpen}
          onClose={() => setIsSiteMarginModalOpen(false)}
          site={selectedSite}
          onSave={handleSiteMarginSave}
          associationId={associationId}
        />
      )}
    </Box>
  );
};

export default SiteListTable;
