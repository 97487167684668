import styled from 'styled-components';
import { Box } from '@chakra-ui/react';

export const StyledBox = styled(Box)`
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: white;
  height: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: #e2e8f0;
  overflow: hidden;
`;

export const StyledContainer = styled.div`
  padding: 24px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;
