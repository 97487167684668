import { API_ENDPOINT } from '../../constants';

import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';

const getRegions = async (clientId) => {
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_REGION}?client_id=${clientId}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const addRegion = async (payload) => {
  const result = await axiosInstance.post(API_ENDPOINT.ADD_REGION, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const updateRegion = async (id, payload) => {
  const result = await axiosInstance.put(`${API_ENDPOINT.UPDATE_REGION}/${id}`, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getRegionDropDownData = async (clientId) => {
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_REGION}?client_id=${clientId}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export { getRegions, addRegion, updateRegion, getRegionDropDownData };
