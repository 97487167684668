/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import { Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { TaxYeraSelectionList } from '../../constants';
import Dropdown from '../Dropdown';

const TaxYearDropDown = function ({ setTaxYearData, taxYearData, validationObj, refresh, label }) {
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setIsValid] = useState(validationObj?.isvalid || true);

  useEffect(() => {
    if (validationObj && !validationObj?.isvalid) {
      setErrorMsg(validationObj?.errorMessage);
      setIsValid(validationObj?.isvalid);
    }
  }, [refresh]);
  const setDropdownData = (e) => {
    setErrorMsg(null);
    setTaxYearData(e);
    setIsValid(true);
  };
  return (
    <>
      <Dropdown
        label={label}
        placeholder="Select Tax Year"
        options={TaxYeraSelectionList}
        onSelect={(e) => {
          setDropdownData(e);
        }}
        value={taxYearData}
        isError={!isValid}
        disabled={false}
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error!'}
      </Text>
    </>
  );
};

export default TaxYearDropDown;
