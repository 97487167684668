/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';
import Dropdown from '../Dropdown';
import { RoleTypeList } from '../../constants';

const RoleTypeDropdown = function ({
  setRoleType,
  roleType,
  validationObj,
  refresh,
  multiple,
  disabled
}) {
  const [roleTypes, setRoleTypes] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj?.isvalid || true);

  useEffect(async () => {
    setRoleTypes(RoleTypeList);
  }, []);

  useEffect(() => {
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage);
      setValid(validationObj.isvalid);
    }
  }, [refresh]);

  const setRoleTypeData = (e) => {
    setErrorMsg(null);
    setValid(true);
    setRoleType(e);
  };

  return (
    <>
      <Dropdown
        label={`Role Type${validationObj?.required ? '*' : ''}`}
        onSelect={setRoleTypeData}
        options={roleTypes.map((item) => {
          return { label: item.label, value: item.value };
        })}
        value={roleType}
        placeholder=""
        isError={!isValid}
        disabled={disabled}
        multiple={multiple}
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error !'}
      </Text>
    </>
  );
};

export default RoleTypeDropdown;
