import React from 'react';

const NoDataIcon = function () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 512 512">
      <path
        fill="currentColor"
        d="M2176 4469c-242-26-514-133-716-285-299-224-505-548-577-911-9-48-18-89-19-91 0-1-36-11-80-23-170-44-377-168-493-295-172-188-273-425-288-675-17-294 88-576 295-791 136-141 292-235 487-295 89-27 94-27 427-31 322-3 338-3 376 17 147 75 158 277 21 368l-42 28-306 6c-236 5-319 10-361 22-172 50-323 174-398 327-54 109-72 184-72 290s20 193 67 290c28 58 55 94 122 161 131 130 269 189 447 189 139 0 232-31 398-133 51-32 68-37 119-37 71 0 115 19 161 70 59 65 70 152 30 235-35 75-216 187-387 240l-88 28 7 31c73 345 324 643 647 770 147 58 209 69 392 70 156 1 178-1 270-27 401-110 694-418 774-814 12-56 21-130 21-165 0-97 22-158 74-206 118-109 297-61 346 92 9 28 11 73 6 158l-7 118h34c65 0 209-30 287-61 246-94 437-306 512-566 30-103 33-341 5-443-81-295-318-531-612-610-64-18-121-23-291-28-207-7-211-7-252-34-139-92-125-296 25-372 45-23 390-23 524 0 257 44 492 166 679 354 624 623 453 1659-338 2055-172 85-352 125-567 125h-141l-28 57c-64 130-213 324-324 420-234 204-506 328-809 368-117 16-233 17-357 4z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
      <path
        fill="currentColor"
        d="M2081 1908c-61-14-133-86-150-151-26-105 2-157 183-337 75-74 136-139 136-145s-68-78-150-160c-135-134-152-156-166-203-52-177 133-329 300-246 17 9 97 82 178 162l148 146 147-146c82-80 164-154 183-164 22-11 57-18 95-19 121 0 210 89 210 210-1 39-7 73-19 95-11 19-84 102-165 183l-145 148 146 147c153 153 187 203 188 273 0 86-47 160-128 196-60 28-125 28-179-1-15-8-95-82-178-165l-149-150-156 154c-140 137-163 155-210 169-60 18-58 18-119 4z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
    </svg>
  );
};

export default NoDataIcon;
