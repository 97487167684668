const initialState = {};
/* eslint-disable default-param-last */
const translate = (state = initialState, action) => {
  switch (action.type) {
    case 'TRANSLATE_DATA':
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
};

export default translate;
