/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable object-shorthand */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */

import {
  Box,
  Button,
  Flex,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Switch,
  Text,
  useToast
} from '@chakra-ui/react';
import { React, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Card from '../../components/Card';
import DepartmentDropdown from '../../components/Forms/DepartmentDropdown';
import StyledInputWrapper from '../../components/Forms/Forms.styled';
import JobDropdown from '../../components/Forms/JobDropdown';
import MessageTypeDropdown from '../../components/Forms/MessageTypeDropDown';
import ShiftDropdown from '../../components/Forms/ShiftDropdown';
import TemplateDropdown from '../../components/Forms/TemplateDropdown';
import WorkerDropdown from '../../components/Forms/WorkerDropdown';
import WorkerNationalityDropdown from '../../components/Forms/WorkerNationalityDropdown';
import InputComponent from '../../components/Input';
import Label from '../../components/Label';
import {
  StyledMessagingBodyContainer,
  StyledMessagingFormContainer
} from '../../components/MessagingView/MessagingView.styled';
import constants, {
  CUSTOM_TOAST_DURATION,
  LANGUAGE_OPTION,
  MESSAGE_CREATE_VALIDATIONS,
  TOAST_SETTINGS
} from '../../constants';
import { checkLanguage, deepClone, validateForm, validateUrl } from '../../utils/helper';
import LanguageDropdown from '../../components/Forms/LanguageDropdown';
import {
  createTemplate,
  getTemplate,
  sendMessage,
  TranslateMessage,
  updateTemplate
} from '../../redux/action/messaging.action';
import AlertModalOverlay from '../../components/AlertModalComponent';

const MessagingTranslate = function () {
  //   const user = useContext(UserContext);
  const { data } = useSelector((state) => state.translate);
  const [, setIsNewTemplate] = useState(true);
  const [templateData, setTemplateData] = useState({
    ...data.templateData
  });
  const [, setSelectedTemplate] = useState(null);
  const [language, setlanguage] = useState(data?.templateData?.languages);
  const [Prevlanguage, setPrevlanguage] = useState(data?.templateData?.languages);
  const [commentToggle, setcommentToggle] = useState(data.commentToggle);
  const [validationObject, setValidationObject] = useState({
    ...deepClone(MESSAGE_CREATE_VALIDATIONS)
  });
  const [refresh, setRefresh] = useState(false);
  const toast = useToast();
  const shift = [...data.shift];
  const worker = [...data.worker];
  const department = [...data.department];
  const role = [...data.role];
  const nationality = [...data.nationality];
  const { user_details } = useSelector((state) => state.authentication);
  const [isSaveUpdateTranslateAlert, setIsSaveUpdateTranslateAlert] = useState(false);
  const [loader, setLoader] = useState(false);
  const INITIAL_MESSAGE_DATA = {
    name: '',
    title: '',
    type: { value: 'GENERAL', label: 'General' },
    from: '',
    body: [
      {
        text: '',
        media: '',
        link: '',
        isValid: true,
        isValidUrl: true
      }
    ]
  };
  useEffect(() => {
    const bodyText = templateData.body.map((data) => {
      return data.text;
    });
    setLoader(true);
    const payload = {
      message: [templateData.from, templateData.title, ...bodyText],
      from: Prevlanguage.value,
      to: language.value
    };
    const fetchdata = async () => {
      const res = await TranslateMessage(payload);
      const translatedBodyText = res.translatedMessage.splice(2);
      const newdata = { ...templateData };
      const data = newdata.name;
      const matches = checkLanguage(data);
      let replacedData;
      if (matches) {
        const extractedLanguages = matches?.map((match) => match.slice(1, -1));
        const matchedValue = extractedLanguages.find((item) =>
          LANGUAGE_OPTION.some((option) => option.name === item)
        );
        if (matchedValue && language.label !== 'English') {
          replacedData = data.replace(`(${matchedValue})`, `(${language.label})`);
        } else if (language.label === 'English') {
          replacedData = data.replace(`(${matchedValue})`, '');
        } else {
          replacedData = `${data}(${language.label})`;
        }
      } else {
        replacedData = `${data}(${language.label})`;
      }
      newdata.name = replacedData;
      if (newdata.name.includes('(English)')) {
        const modifiedString = newdata.name.replace(/\(English\)/, '');
        newdata.name = modifiedString;
      }
      newdata.from = res.translatedMessage[0];
      newdata.title = res.translatedMessage[1];
      newdata.body.map((item, index) => {
        item.text = translatedBodyText[index];
      });
      setTemplateData({ ...newdata });
      setLoader(false);
    };
    fetchdata();
  }, [language]);

  const onInputChange = (field, e) => {
    const newdata = { ...templateData };
    newdata[field] = e;
    setTemplateData({ ...newdata });
    setIsNewTemplate(false);
  };
  const removeBodyItem = (index) => {
    const newdata = data.templateData;
    newdata.body.splice(index, 1);
    setTemplateData({ ...newdata });
  };
  const onAddNewBody = () => {
    const data = { ...templateData };
    data.body.push({
      text: '',
      media: '',
      link: '',
      isValid: true,
      isValidUrl: true
    });
    setTemplateData({ ...data });
  };
  const onBodyInputChange = (field, index, e) => {
    const newdata = { ...templateData };
    const bodyData = { ...templateData.body[index] };
    bodyData[field] = e;
    if (field === 'text') {
      bodyData.isValid = true;
    }
    if (field === 'link') {
      bodyData.isValidUrl = true;
    }
    newdata.body[index] = bodyData;
    setTemplateData({ ...newdata });
    setIsNewTemplate(false);
  };
  const onMessageTypeChange = (e) => {
    const data = { ...INITIAL_MESSAGE_DATA, body: [...INITIAL_MESSAGE_DATA.body] };
    data.type = { ...e };
    setTemplateData({ ...data });
    setSelectedTemplate(null);
    setIsNewTemplate(false);
  };
  const generatePayloadForMessageTemplate = (template, addTranslation) => {
    const bodyItem = [];

    templateData.body.forEach((body) => {
      const item = [];
      if (body.text && body.text !== '') {
        item.push({
          type: 'text',
          data: body.text
        });
      }
      if (body.link && body.link !== '') {
        item.push({
          type: 'link',
          data: body.link
        });
      }
      if (body.media && body.media !== '') {
        item.push({
          type: 'media',
          data: body.media
        });
      }
      if (body.translate && body.translate !== '' && addTranslation === 'ADD_TRANSLATION') {
        item.push({
          type: 'translate',
          data: body.translate
        });
      }
      if (item.length > 0) {
        bodyItem.push(item);
      }
    });
    const payload = template
      ? {
          ...templateData,
          type: templateData.type.value,
          languages: language,
          body: [...bodyItem]
        }
      : {
          ...templateData,
          type: templateData.type.value,
          body: [...bodyItem]
        };
    if (!template) {
      delete payload.languages;
    }
    return payload;
  };
  const updatetemplate = () => {
    setIsSaveUpdateTranslateAlert(true);
  };

  const onTranslateClicked = async (state, sendToType) => {
    const [validationResult, isFormValid] = validateForm(templateData, validationObject);
    setValidationObject({ ...validationResult });
    let isValidBody = true;
    let isValidLink = true;
    const body = [...templateData.body];

    body.forEach((item, index) => {
      if (isValidBody) {
        if (item.text.length < 1) {
          isValidBody = false;
        }
      }
      if (isValidLink) {
        if (item.link && item.link.length > 0) {
          isValidLink = validateUrl(item.link);
        }
      }
      body[index].isValid = item.text.length > 0;
      body[index].isValidUrl = item.link && item.link.length > 0 ? validateUrl(item.link) : true;
    });
    setTemplateData({ ...templateData, body: [...body] });

    if (isFormValid && isValidBody && isValidLink) {
      switch (state) {
        case 'create': {
          const payload = { ...generatePayloadForMessageTemplate(true) };
          if (payload.id) {
            delete payload.id;
          }
          delete payload.title_translations;
          delete payload.is_master_template;
          const result = await createTemplate(payload);
          if (result && result.ok) {
            toast({
              ...TOAST_SETTINGS.SETTINGS,
              duration: CUSTOM_TOAST_DURATION.duration,
              status: TOAST_SETTINGS.SUCCESS,
              title: result.message
            });
            // eslint-disable-next-line prefer-destructuring
            const id = result?.data?.id;
            // eslint-disable-next-line no-use-before-define
            const newTemplateData = await getTemplate(id);
            setSelectedTemplate({
              label: newTemplateData?.data?.template?.name,
              value: newTemplateData?.data?.template?.id
            });
            setTimeout(() => {
              window.close();
            }, 1000);
          } else {
            toast({
              ...TOAST_SETTINGS.SETTINGS,
              status: TOAST_SETTINGS.ERROR,
              title: result.error
            });
          }
          break;
        }
        case 'update': {
          setIsSaveUpdateTranslateAlert(true);
          const payload = { ...generatePayloadForMessageTemplate(true, 'ADD_TRANSLATION') };
          const { id } = payload;
          delete payload.id;
          delete payload.is_master_template;
          const result = await updateTemplate(id, payload);
          if (result && result.ok) {
            toast({
              ...TOAST_SETTINGS.SETTINGS,
              duration: CUSTOM_TOAST_DURATION.duration,
              status: TOAST_SETTINGS.SUCCESS,
              title: result.message
            });
            setSelectedTemplate({ label: payload.name, value: id });
            setTimeout(() => {
              window.close();
            }, 1000);
          } else {
            toast({
              ...TOAST_SETTINGS.SETTINGS,
              status: TOAST_SETTINGS.ERROR,
              title: result.error
            });
          }
          break;
        }
        case 'send': {
          // sendToType is 1 check for worker if 2 check for others
          const send_to = [];
          if (sendToType === '1' && worker.length > 0) {
            const data = {
              type: 'workers'
            };
            data.data = worker.map((item) => item.value);
            send_to.push({ ...data });
          } else if (
            sendToType === '2' &&
            (role.length > 0 || department.length > 0 || shift.length > 0 || nationality.length > 0)
          ) {
            if (department.length > 0) {
              const data = {
                type: 'department'
              };
              data.data = department.map((item) => item.value);
              send_to.push({ ...data });
            }
            if (shift.length > 0) {
              const data = {
                type: 'shift'
              };
              data.data = shift.map((item) => item.value);
              send_to.push({ ...data });
            }
            if (role.length > 0) {
              const data = {
                type: 'job'
              };
              data.data = role.map((item) => item.value);
              send_to.push({ ...data });
            }
            if (nationality.length > 0) {
              const data = {
                type: 'nationality'
              };
              data.data = nationality.map((item) => item.value);
              send_to.push({ ...data });
            }
          } else if (sendToType === '3') {
            const data = {
              type: 'workers'
            };
            data.data = [];
            send_to.push({ ...data });
          } else {
            window.alert('Please select to whom you want to send.');
            return;
          }
          const payload = {
            ...generatePayloadForMessageTemplate(false, 'ADD_TRANSLATION'),
            send_to,
            is_comment_allowed: commentToggle
          };
          if (payload.id) {
            delete payload.id;
          }
          delete payload.is_master_template;
          const result = await sendMessage(
            (data.selectedClient && data.selectedClient.value) || user_details.client_id,
            (data.selectedSite && data.selectedSite.value) || user_details.site_id || null,
            user_details.agency_id,
            data.WorkerType,
            payload
          );
          if (result && result.ok) {
            toast({
              ...TOAST_SETTINGS.SETTINGS,
              duration: CUSTOM_TOAST_DURATION.duration,
              status: TOAST_SETTINGS.SUCCESS,
              title: result.message
            });
            setTimeout(() => {
              window.close();
            }, 1000);
          } else {
            toast({
              ...TOAST_SETTINGS.SETTINGS,
              status: TOAST_SETTINGS.ERROR,
              title: result.error
            });
          }
          break;
        }

        default:
          break;
      }
    } else {
      setRefresh(!refresh);
    }
  };
  return (
    <StyledMessagingBodyContainer style={{ width: '505px' }}>
      <AlertModalOverlay
        isOpen={isSaveUpdateTranslateAlert}
        onClose={() => setIsSaveUpdateTranslateAlert(false)}
        title="Choose Action"
        body={
          templateData.is_master_template
            ? `If you are updating this master template, be sure you hit "translate all" to update all translations as well.`
            : ''
        }
        negative={
          <>
            {templateData.is_master_template && (
              <Button
                onClick={() => {
                  setIsSaveUpdateTranslateAlert(false);
                }}
                bg="main.secondary"
                mr={3}
                _hover={{ bg: 'main.secondary' }}
                color="main.black">
                Cancel
              </Button>
            )}
            <Button
              onClick={() => {
                onTranslateClicked('update');
                setIsSaveUpdateTranslateAlert(false);
              }}
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              mr={3}>
              Update Existing
            </Button>
          </>
        }
        positive={
          <Button
            bg="main.semiPrimary"
            color="main.white"
            _hover={{ bg: 'main.primary' }}
            _active={{
              bg: 'main.semiPrimary',
              borderColor: 'main.primary'
            }}
            onClick={() => {
              onTranslateClicked('create');
              setIsSaveUpdateTranslateAlert(false);
            }}>
            Create New
          </Button>
        }
      />
      <StyledMessagingFormContainer style={{ width: '450px' }}>
        <Card messagingView style={{ width: '450px' }}>
          <Box style={{ width: '450px' }} p="10px">
            <Flex>
              <StyledInputWrapper style={{ marginRight: '8px' }}>
                <LanguageDropdown
                  language={language}
                  refresh
                  setLanguage={setlanguage}
                  setPrevlanguage={setPrevlanguage}
                  label="Choose Message Type"
                  labelFontSize="sm"
                  disabled
                />
              </StyledInputWrapper>
            </Flex>
            <Flex>
              <StyledInputWrapper style={{ marginRight: '8px' }}>
                <MessageTypeDropdown
                  messageType={templateData.type}
                  refresh
                  setMessageType={(e) => {
                    onMessageTypeChange({ ...e });
                  }}
                  label="Choose Message Type"
                  labelFontSize="sm"
                  disabled
                />
              </StyledInputWrapper>
              <StyledInputWrapper>
                <TemplateDropdown
                  messageType={templateData.type.value}
                  refresh
                  label="Choose Template"
                  labelFontSize="sm"
                  template={data.selectedTemplate}
                  disabled
                />
              </StyledInputWrapper>
            </Flex>
            <Flex>
              <StyledInputWrapper>
                <InputComponent
                  lable="Template Name"
                  placeholder="Template Name"
                  validationObj={validationObject.name}
                  value={templateData.name}
                  onChange={(e) => onInputChange('name', e)}
                  refresh={refresh}
                  isRequired
                  maxLength="150"
                  labelFontSize="sm"
                />
              </StyledInputWrapper>
            </Flex>
            <Flex>
              <StyledInputWrapper>
                <InputComponent
                  lable="From"
                  placeholder="From"
                  validationObj={validationObject.from}
                  isRequired
                  maxLength="150"
                  value={templateData.from}
                  refresh={refresh}
                  onChange={(e) => onInputChange('from', e)}
                  labelFontSize="sm"
                />
              </StyledInputWrapper>
            </Flex>
            <Flex justifyContent="center" position="relative">
              {loader && (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                  position="absolute"
                />
              )}
            </Flex>
            <Flex>
              <StyledInputWrapper>
                <InputComponent
                  lable="Message Title"
                  placeholder="Message Title"
                  validationObj={validationObject.title}
                  isRequired
                  value={templateData.title}
                  refresh={refresh}
                  onChange={(e) => onInputChange('title', e)}
                  maxLength="150"
                  labelFontSize="sm"
                />
              </StyledInputWrapper>
            </Flex>
            {data.isComposeType ? (
              <>
                <Box>
                  <Label label="Send to" fontSize="sm" color="main.primary" />
                </Box>
                <Flex style={{ alignItems: 'center' }}>
                  <StyledInputWrapper>
                    <Flex>
                      <RadioGroup value={data.sendTo} ml={2}>
                        <Stack direction="row">
                          {templateData.type.value !== 'GENERAL' && (
                            <Radio value="1" isDisabled>
                              Individual(s)
                            </Radio>
                          )}
                          <Radio value="2" isDisabled>
                            Group(s)
                          </Radio>
                          <Radio value="3" isDisabled>
                            All Workers
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </Flex>
                  </StyledInputWrapper>
                </Flex>
                {data.sendTo === '1' ? (
                  <Flex>
                    <StyledInputWrapper>
                      <WorkerDropdown
                        worker={data.worker}
                        multiple
                        validationObj={{ isvalid: true }}
                        workerType={data.workerType.value}
                        disabled
                      />
                    </StyledInputWrapper>
                  </Flex>
                ) : data.sendTo === '2' ? (
                  <Flex flexDirection="column">
                    <StyledInputWrapper style={{ marginRight: '8px' }}>
                      <JobDropdown
                        roleType={data.role}
                        multiple
                        validationObj={{ isvalid: true }}
                        type="messaging"
                        disabled
                      />
                    </StyledInputWrapper>
                    <StyledInputWrapper style={{ marginRight: '8px' }}>
                      <DepartmentDropdown
                        department={data.department}
                        // setDepartment={setDepartment}
                        multiple
                        validationObj={{ isvalid: true }}
                        disabled
                      />
                    </StyledInputWrapper>
                    <StyledInputWrapper>
                      <ShiftDropdown
                        shift={data.shift}
                        multiple
                        validationObj={{ isvalid: true }}
                        disabled
                      />
                    </StyledInputWrapper>
                    <StyledInputWrapper>
                      <WorkerNationalityDropdown
                        nationality={data.nationality}
                        multiple
                        validationObj={{ isvalid: true }}
                        disabled
                      />
                    </StyledInputWrapper>
                  </Flex>
                ) : null}
              </>
            ) : null}
            {templateData.body.map((message, index) => {
              return (
                <fieldset className="custom-fieldset" key={index}>
                  <legend className="custom-legend">Message Body</legend>
                  {index ? (
                    <Flex flexDirection="row-reverse">
                      <Button
                        bg="main.semiPrimary"
                        color="main.white"
                        _hover={{ bg: 'main.primary' }}
                        _active={{
                          bg: 'main.semiPrimary',
                          borderColor: 'main.primary'
                        }}
                        onClick={() => removeBodyItem(index)}>
                        Remove
                      </Button>
                    </Flex>
                  ) : null}
                  <Flex style={{ alignItems: 'center' }}>
                    <StyledInputWrapper>
                      <p
                        label="Body Text*"
                        fontSize="sm"
                        style={{ fontSize: '0.875rem' }}
                        className={`input-label ${!message.isValid ? 'input-label-error' : ''}`}>
                        Body Text*
                      </p>
                      <textarea
                        className={message.isValid ? 'custom-input' : 'custom-input input-error'}
                        rows="4"
                        value={message.text}
                        onChange={(e) => onBodyInputChange('text', index, e.target.value)}
                      />
                      <p
                        className="input-error-msg"
                        style={{ visibility: message.isValid ? 'hidden' : 'visible' }}>
                        Body Text is required
                      </p>
                    </StyledInputWrapper>
                  </Flex>
                  <Flex>
                    <StyledInputWrapper>
                      <InputComponent
                        lable="Add Media"
                        placeholder="Add Media"
                        validationObj={{ isvalid: true }}
                        labelFontSize="sm"
                        value={message.media}
                      />
                    </StyledInputWrapper>
                  </Flex>
                  <Flex>
                    <StyledInputWrapper>
                      <InputComponent
                        lable="Add Link"
                        placeholder="Add Link"
                        validationObj={{
                          required: false,
                          isvalid: message.isValidUrl,
                          errorMessage: '*Please Enter Valid URL'
                        }}
                        labelFontSize="sm"
                        value={message.link}
                        refresh={data.refresh}
                        onChange={(e) => onBodyInputChange('link', index, e)}
                      />
                    </StyledInputWrapper>
                  </Flex>
                </fieldset>
              );
            })}

            <Flex justifyContent="flex-end">
              {templateData.type && templateData.type.value === 'TRAINING' && (
                <Button
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  onClick={onAddNewBody}
                  mr="auto">
                  {constants.MESSAGING.NEW_PAGE_BUTTON_TEXT}
                </Button>
              )}
              {data.isComposeType ? (
                <Flex
                  style={{
                    height: '50px',
                    position: 'fixed',
                    bottom: '0%',
                    width: '100%',
                    opacity: '1',
                    backgroundColor: '#ffffff',
                    justifyContent: 'flex-end'
                  }}>
                  {!data?.comment_restricted && (
                    <Flex justifyContent="center" alignItems="center" mr="10px">
                      <Text color="main.primary" mr="10px" fontSize="18px">
                        {' '}
                        Is Comment Allowed?
                      </Text>
                      <Switch
                        isChecked={commentToggle}
                        onChange={() => {
                          setcommentToggle(!commentToggle);
                        }}
                      />
                    </Flex>
                  )}
                  <Button
                    bg="main.semiPrimary"
                    color="main.white"
                    _hover={{ bg: 'main.primary' }}
                    _active={{
                      bg: 'main.semiPrimary',
                      borderColor: 'main.primary'
                    }}
                    onClick={() => onTranslateClicked(data.state, data.sendTo)}>
                    {constants.MESSAGING.SEND_BTN_LABEL}
                  </Button>
                </Flex>
              ) : (
                <Flex
                  style={{
                    height: '50px',
                    position: 'fixed',
                    bottom: '0%',
                    width: '100%',
                    opacity: '1',
                    backgroundColor: '#ffffff',
                    justifyContent: 'flex-end'
                  }}>
                  <Button
                    bg="main.semiPrimary"
                    color="main.white"
                    _hover={{ bg: 'main.primary' }}
                    _active={{
                      bg: 'main.semiPrimary',
                      borderColor: 'main.primary'
                    }}
                    onClick={
                      () =>
                        data.selectedTemplate ? updatetemplate() : onTranslateClicked(data.state)
                      // data.state === 'create' ? onTranslateClicked(data.state) : updatetemplate()
                    }>
                    {constants.MESSAGING.SAVE_BTN_LABEL}
                  </Button>
                </Flex>
              )}
            </Flex>
          </Box>
        </Card>
      </StyledMessagingFormContainer>
    </StyledMessagingBodyContainer>
  );
};

export default MessagingTranslate;
