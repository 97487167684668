/* eslint-disable default-param-last */
import { API_ENDPOINT } from '../../constants';

import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';

export const getRateCards = async (limit = 1, page = 1, sortKey, sortType, clientId) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_RATE_CARD}?page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}&client_id=${clientId}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const getRateCardsDropdownList = async (clientId) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_RATE_CARD_DROP_DOWN}?client_id=${clientId}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const addRateCard = async (payload) => {
  const result = await axiosInstance.post(API_ENDPOINT.ADD_RATE_CARD, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const updateRateCard = async (id, payload) => {
  const result = await axiosInstance.put(`${API_ENDPOINT.UPDTAE_RATE_CARD}/${id}`, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};
