import React from 'react';

const AttendanceIcon = function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      version="1"
      viewBox="0 0 225 225">
      <path
        fill="currentColor"
        d="M975 2239c-429-54-807-380-929-803-151-519 99-1081 586-1319 422-207 922-131 1262 193 472 448 472 1182 0 1630-248 236-575 342-919 299zm409-128c98-26 249-97 330-155 78-56 186-164 242-242 57-80 129-231 156-330 19-68 22-105 22-259 0-209-13-273-89-432-105-223-271-387-499-494-150-70-215-83-421-83-154 0-191 3-258 22-168 46-334 142-456 263-94 94-150 173-206 292-76 159-89 223-89 432s13 273 89 432c137 290 394 495 705 564 118 26 359 21 474-10z"
        transform="matrix(.1 0 0 -.1 0 225)"
      />
      <path
        fill="currentColor"
        d="M788 1781c-29-26-33-35-36-99-3-51 0-79 12-104 30-63 96-76 148-29 32 29 33 31 33 116s-1 87-33 116c-42 38-82 38-124 0zM1338 1781c-32-29-33-31-33-116s1-87 33-116c42-38 82-38 124 0 29 26 33 35 36 99 3 51 0 79-12 104-30 63-96 76-148 29z"
        transform="matrix(.1 0 0 -.1 0 225)"
      />
      <path
        fill="currentColor"
        d="M510 1055V440h1230v1230h-200v-42c-1-59-32-123-69-142-38-20-104-20-142 0-37 19-68 83-69 142v42H990v-42c-1-59-32-123-69-142-16-9-48-16-71-16s-55 7-71 16c-37 19-68 83-69 142v42H510v-615zm1168-100l-3-445H575l-3 445-2 445h1110l-2-445z"
        transform="matrix(.1 0 0 -.1 0 225)"
      />
      <path
        fill="currentColor"
        d="M826 1258c-48-56-57-62-73-50-33 22-43 24-59 12-13-12-10-18 23-52 21-21 42-37 48-36 6 2 44 38 84 80 62 65 71 80 61 93-7 8-16 15-20 15-5 0-33-28-64-62zM1172 1260c-29-33-59-60-66-60s-21 9-31 20c-14 15-21 17-32 9-22-19-15-34 28-69l41-33 77 77c63 63 74 79 65 93-18 29-27 24-82-37zM1498 1256c-50-54-60-59-75-48-32 22-44 25-56 13-9-9-1-22 31-54l42-42 82 82c70 70 80 84 69 97-20 24-31 19-93-48zM823 1013l-61-68-26 24c-25 24-27 24-44 7-16-16-15-19 26-58 50-47 42-50 143 63 56 63 58 67 40 83-17 15-21 12-78-51zM1502 1014l-62-66-30 22c-30 21-50 20-50-4 0-6 17-29 38-49l39-38 74 76c80 82 88 95 66 113-11 10-26-1-75-54zM1158 1006l-57-61-26 25c-23 22-27 22-41 8s-11-19 28-56l43-42 79 79c55 55 77 84 73 95-11 29-41 14-99-48zM819 764l-63-64-23 21c-23 22-53 20-53-4 0-8 18-28 41-47l41-33 78 78c43 43 76 84 73 91-11 29-34 19-94-42zM1060 710v-70h130v140h-130v-70zM1400 710v-71l68 3 67 3 3 68 3 67h-141v-70z"
        transform="matrix(.1 0 0 -.1 0 225)"
      />
    </svg>
  );
};

export default AttendanceIcon;
