/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import Dropdown from '../Dropdown';
import contaries from '../../utils/nationalityData.json';
import InputComponent from '../Input';
import StyledInputWrapper from '../Forms/Forms.styled';
import { allowOnlyNumber } from '../../utils/helper';

const MobileInput = function ({ setData, data, validationObj, refresh, disabled }) {
  const [country, setCountry] = useState({
    mobile: '',
    id: '',
    value: '+44',
    label: '+44',
    country_code: '+44'
  });
  const [minLength, setMinLength] = useState(10);
  const [maxLength, setMaxLength] = useState(10);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(true);

  const setCountryData = (e) => {
    setCountry({ ...country, ...{ label: e.value, value: e.value, country_code: e.value } });
    setData({ ...data, ...{ label: e.value, country_code: e.value, mobile: country.mobile } });
    setMinLength(e.min);
    setMaxLength(e.max ? e.max : e.min);
  };

  useEffect(() => {
    setValid(validationObj.country_code.isvalid);
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.country_code.errorMessage);
    }
  }, [refresh, validationObj]);

  const selectCountryCode = (e) => {
    setErrorMsg(null);
    setValid(true);
    setCountryData({ ...e, country_code: e.value });
  };

  const setMobileData = (e) => {
    setCountry({ ...country, mobile: allowOnlyNumber(e) });
    setData({ ...data, mobile: e });
  };

  useEffect(() => {
    if (data.mobile && data.mobile.length && data.country_code && data.country_code.length) {
      setCountry({
        ...country,
        mobile: allowOnlyNumber(data.mobile),
        value: data.country_code,
        label: data.country_code,
        country_code: data.country_code
      });
    }
  }, [data]);

  return (
    <Flex>
      <Box w="30%">
        <Dropdown
          options={contaries}
          onSelect={selectCountryCode}
          value={country}
          isError={!isValid}
          label={`Country Code${validationObj?.mobile.required ? '*' : ''}`}
          placeholder="Country Code"
          disabled={disabled}
        />
        <Text
          visibility={isValid ? 'hidden' : 'visible'}
          color="main.error"
          mb="2px"
          fontSize="11px">
          {errorMsg || 'Error !'}
        </Text>
      </Box>
      <Box w="70%" ml={5}>
        <StyledInputWrapper>
          <InputComponent
            lable="Mobile"
            placeholder="Mobile"
            onChange={setMobileData}
            value={country.mobile}
            minLength={minLength}
            maxLength={maxLength}
            validationObj={validationObj?.mobile}
            refresh={refresh}
            disabled={disabled}
          />
        </StyledInputWrapper>
      </Box>
    </Flex>
  );
};

export default MobileInput;
