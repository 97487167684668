import { Flex } from '@chakra-ui/react';
import styled from 'styled-components';

import { colors } from '../../theme';

const mixin = {
  fullWidth: `
    width: 100%;
    padding: 10px 0;
    justify-content: flex-start;
    `
};

export const StyledHeader = styled(Flex)`
  flex-direction: column;
  margin-bottom: -50px;
  @media (max-width: 1440px) {
    min-width: 1190px;
  }
`;

export const StyledHeaderNav = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  min-height: 40px;
  padding: 10px 0;

  @media (max-width: 1066px) {
    flex-direction: row;
  }
`;

export const StyledHeaderLogo = styled(Flex)`
  width: 170px;
  height: 120px;
  border-radius: 20px;
  align-self: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  // margin-top: -24px;
  z-index: 4;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const StyledHeaderLeftSideMenu = styled(Flex)`
  width: 45%;
  @media (max-width: 1066px) {
    ${mixin.fullWidth}
  }
`;

export const StyledHeaderRightSideMenu = styled(Flex)`
  flex-direction: row-reverse;
  width: 45%;
  @media (max-width: 1066px) {
    ${mixin.fullWidth}
    padding-bottom: 0;
  }
`;

export const StyledHeaderMenuItem = styled.div`
  margin: 0 5px;
`;

export const StyledUserInfo = styled(Flex)`
  width: 160px;
  align-items: center;
`;

export const StyledUserLogo = styled(Flex)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  margin: 0 8px;
`;

export const StyledSearchWrapper = styled(Flex)`
  align-items: center;
`;

export const StyledSearchInput = styled.input`
  display: inline-block;
  flex: 1;
  padding: 8px 16px;
  margin-right: -24px;
  border-radius: 24px;

  &:focus {
    outline: none;
  }
`;

export const StyledUserName = styled.div`
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 700;
`;

export const StyledUserRole = styled.div`
  margin-top: -4px;
  font-size: 12px;
  font-weight: 400;
`;

export const StyledDropDownMenu = styled.ul`
  list-style: none;
  & li {
    padding: 8px 16px;
    cursor: pointer;
    &:hover {
      background-color: ${colors.main.lightPrimary};
    }
  }
`;

export const StyledProfileImageContainer = styled(Flex)`
  width: 100%;
  margin-bottom: 16px;
  justify-content: center;
`;
