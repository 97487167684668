import { Flex } from '@chakra-ui/react';
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const StyledShiftBookingContainer = styled.div``;

export const StyledAgency = styled.div`
  border: 1.2px solid #9f7aea;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 16px;
`;

export const StyledAgencyHeader = styled(Flex)`
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 18px;
}
`;
