/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable import/named */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Flex
} from '@chakra-ui/react';
import ServerSideDatatable from '../../components/ServerSideDatatable';
import { getSupervisorsDataByPayrollMeta } from '../../redux/action/agencyManagement.action';

const INITIAL_COLUMNS = [
  { label: 'Employee ID', field: 'employee_id', sort: true },
  { label: 'First Name', field: 'first_name', sort: true },
  { label: 'Site', field: 'site_name', sort: true },
  { label: 'Shift', field: 'shift_name' },
  { label: 'Department', field: 'department_name', sort: true },
  { label: 'Role', field: 'role', sort: true },
  { label: 'Supervisor Status', field: 'workers_supervisor_status', sort: true },
  { label: 'Supervisor Hours', field: 'supervisor_hours', sort: true },
  {
    label: 'Supervisor Charges',
    field: 'supervisor_charges',
    sort: true,
    customRow: (cell) => `£${cell}`
  },
  { label: 'Non Supervisor Hours', field: 'non_supervisor_hours', sort: true },
  {
    label: 'Non Supervisor Charges',
    field: 'non_supervisor_charges',
    sort: true,
    customRow: (cell) => `£${cell}`
  },
  { label: 'Total Hours', field: 'total_hours', sort: true },
  { label: 'Total Pay', field: 'total_pay', sort: true, customRow: (cell) => `£${cell}` },
  { label: 'Total Charge', field: 'total_charge', sort: true, customRow: (cell) => `£${cell}` }
];

const SupervisorDataModal = function ({ isOpen, onClose, payrollMetaId }) {
  const [reload, setReload] = useState(false);
  const [supervisorsData, setSupervisorsData] = useState([]);
  const [count, setCount] = useState(0);

  const retrieveSupervisorsData = async (size, pageNumber, sortKey, sorting) => {
    const result = await getSupervisorsDataByPayrollMeta(
      payrollMetaId,
      pageNumber,
      size,
      sortKey,
      sorting
    );
    if (result.ok && result.data && result.data.ok) {
      setSupervisorsData(result.data.supervisors_list);
      setCount(result.data.total_count);
      return {
        data: {
          supervisors_data: result.data.supervisors_list,
          count: result.data.total_count
        }
      };
    }
    setSupervisorsData([]);
    setCount(0);
    return {
      data: {
        supervisors_data: [],
        count: 0
      }
    };
  };

  const downloadSupervisorDataClickHandler = async () => {
    const result = await getSupervisorsDataByPayrollMeta(
      payrollMetaId,
      1,
      count,
      'total_charge',
      'ASC'
    );

    if (result.ok && result.data && result.data.ok) {
      const supervisorsList = result.data.supervisors_list;
      let csvContent =
        'Employee ID,First Name,Site,Shift,Department,Role,Supervisor Hours,Supervisor Charges,Non Supervisor Hours,Non Supervisor Charges,Total Hours,Total Pay,Total Charge\r\n';
      supervisorsList.forEach((row) => {
        csvContent += `${row.employee_id},${row.first_name},${row.site_name},${row.shift_name},${
          row.department_name ? row.department_name : ''
        },${row.role},${row.supervisor_hours},${row.supervisor_charges},${
          row.non_supervisor_hours
        },${row.non_supervisor_charges},${row.total_hours},${row.total_pay},${
          row.total_charge
        }\r\n`;
      });
      const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'Supervisors Data.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent width="80%" bg="blue.50">
        <ModalCloseButton />
        <ModalBody>
          <Box bg="white" p={4} marginTop={10} borderRadius="md">
            <Flex justifyContent="space-between" mb={4}>
              <Button
                colorScheme="blue"
                onClick={downloadSupervisorDataClickHandler}
                disabled={count === 0}>
                Export
              </Button>
            </Flex>
            <ServerSideDatatable
              size={10}
              initialSortKey="total_charge"
              column={INITIAL_COLUMNS}
              onPaginate={retrieveSupervisorsData}
              afterPaginateData={() => setReload(false)}
              pagination
              pages={[5, 10, 15]}
              dataKey="supervisors_data"
              refresh={reload}
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SupervisorDataModal;
