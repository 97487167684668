/* eslint-disable camelcase */
import { API_ENDPOINT } from '../../constants';

import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';

const getHeaderData = async (agencyId, clientId, siteId, regionId, startDate, endDate) => {
  let query = '?';
  if (agencyId) {
    query += `agency_id=${agencyId}`;
  }
  if (agencyId && clientId) {
    query += `&client_id=${clientId}`;
  } else if (clientId) {
    query += `client_id=${clientId}`;
  }
  if (regionId && (agencyId || clientId)) {
    query += `&region_id=${regionId}`;
  } else if (regionId) {
    query += `region_id=${regionId}`;
  }
  if (siteId && (agencyId || clientId)) {
    query += `&site_id=${siteId}`;
  } else if (siteId) {
    query += `site_id=${siteId}`;
  }
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_HEADER_DATA}${query}&start_date=${startDate}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getProximityData = async (agencyId, clientId, siteId, regionId, isActive, workerType) => {
  let query = '?';
  if (agencyId) {
    query += `agency_id=${agencyId}`;
  }
  if (agencyId && clientId) {
    query += `&client_id=${clientId}`;
  } else if (clientId) {
    query += `client_id=${clientId}`;
  }
  if (regionId && (agencyId || clientId)) {
    query += `&region_id=${regionId}`;
  } else if (regionId) {
    query += `region_id=${regionId}`;
  }
  if (siteId && (agencyId || clientId)) {
    query += `&site_id=${siteId}`;
  } else if (siteId) {
    query += `site_id=${siteId}`;
  }
  query += `&is_active=${isActive === true ? 'true' : 'false'}`;
  if (workerType) {
    query += `&type=${workerType}`;
  }
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_DEMOGRAPHICS_PROXIMITY}${query}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getGenderData = async (agencyId, clientId, siteId, regionId, isActive, workerType) => {
  let query = '?';
  if (agencyId) {
    query += `agency_id=${agencyId}`;
  }
  if (agencyId && clientId) {
    query += `&client_id=${clientId}`;
  } else if (clientId) {
    query += `client_id=${clientId}`;
  }
  if (regionId && (agencyId || clientId)) {
    query += `&region_id=${regionId}`;
  } else if (regionId) {
    query += `region_id=${regionId}`;
  }
  if (siteId && (agencyId || clientId)) {
    query += `&site_id=${siteId}`;
  } else if (siteId) {
    query += `site_id=${siteId}`;
  }
  query += `&is_active=${isActive === true ? 'true' : 'false'}`;
  if (workerType) {
    query += `&type=${workerType}`;
  }
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_DEMOGRAPHICS_GENDER}${query}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getAgeData = async (agencyId, clientId, siteId, regionId, isActive, workerType) => {
  let query = '?';
  if (agencyId) {
    query += `agency_id=${agencyId}`;
  }
  if (agencyId && clientId) {
    query += `&client_id=${clientId}`;
  } else if (clientId) {
    query += `client_id=${clientId}`;
  }
  if (regionId && (agencyId || clientId)) {
    query += `&region_id=${regionId}`;
  } else if (regionId) {
    query += `region_id=${regionId}`;
  }
  if (siteId && (agencyId || clientId)) {
    query += `&site_id=${siteId}`;
  } else if (siteId) {
    query += `site_id=${siteId}`;
  }
  query += `&is_active=${isActive === true ? 'true' : 'false'}`;
  if (workerType) {
    query += `&type=${workerType}`;
  }
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_DEMOGRAPHICS_AGE}${query}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getWorkforceTopCardDemographicsData = async (
  agencyId,
  clientId,
  siteId,
  regionId,
  isActive,
  workerType
) => {
  let query = '?';
  if (agencyId) {
    query += `agency_id=${agencyId}`;
  }
  if (agencyId && clientId) {
    query += `&client_id=${clientId}`;
  } else if (clientId) {
    query += `client_id=${clientId}`;
  }
  if (regionId && (agencyId || clientId)) {
    query += `&region_id=${regionId}`;
  } else if (regionId) {
    query += `region_id=${regionId}`;
  }
  if (siteId && (agencyId || clientId)) {
    query += `&site_id=${siteId}`;
  } else if (siteId) {
    query += `site_id=${siteId}`;
  }
  query += `&is_active=${isActive === true ? 'true' : 'false'}`;
  if (workerType) {
    query += `&type=${workerType}`;
  }
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_WORKFORCE_TOP_CARD_DEMOGRAPHICS}${query}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export {
  getGenderData,
  getProximityData,
  getHeaderData,
  getWorkforceTopCardDemographicsData,
  getAgeData
};
