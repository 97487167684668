/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import styled from 'styled-components';
import { colors } from '../../theme';

export const StyledUploadFileButton = styled.label`
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: auto;
  line-height: 1.2;
  border-radius: var(--chakra-radii-md);
  font-weight: var(--chakra-fontWeights-semibold);
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  height: var(--chakra-sizes-10);
  min-width: var(--chakra-sizes-10);
  font-size: var(--chakra-fontSizes-md);
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  color: ${colors.main.white};
  background: ${colors.main.semiPrimary};
  cursor: pointer;
  &:hover {
    background: ${(props) => (props.disabled ? colors.main.semiPrimary : colors.main.primary)};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;
