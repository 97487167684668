/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Text, useToast } from '@chakra-ui/react';
import Dropdown from '../Dropdown';

const CreditPercentageDropdown = function ({
  creditPercentage,
  setCreditPercentage,
  validationObj,
  refresh,
  disabled = false,
  multiple = false,
  placeholder,
  removeErrors
}) {
  const toast = useToast();
  const [percentages, setPercentages] = useState([
    { label: '100%', value: '100' },
    { label: '75%', value: '75' },
    { label: '50%', value: '50' },
    { label: '0%', value: '0' }
  ]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj?.isvalid || true);

  useEffect(() => {
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage);
      setValid(validationObj.isvalid);
    }
  }, [refresh]);

  const setPercentageData = (e) => {
    setErrorMsg(null);
    setValid(true);
    setCreditPercentage(e);
  };

  return (
    <>
      <Dropdown
        label={`Credit % Applied${validationObj?.required ? '*' : ''}`}
        onSelect={setPercentageData}
        options={percentages}
        value={creditPercentage}
        placeholder={placeholder || 'Credit %'}
        isError={!isValid}
        disabled={disabled}
        multiple={multiple}
      />
      {!removeErrors && (
        <Text
          visibility={isValid ? 'hidden' : 'visible'}
          color="main.error"
          mb="2px"
          fontSize="11px">
          {errorMsg || 'Error !'}
        </Text>
      )}
    </>
  );
};

export default CreditPercentageDropdown;
