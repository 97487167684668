/* eslint-disable react/function-component-definition */
/* eslint-disable no-use-before-define */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Box, Button, Text, VStack, HStack, Select, useToast, Flex, Image } from '@chakra-ui/react';
import ModalComponent from '../Modal';
import AlertModalComponent from '../AlertModalComponent';
import MarginInputs from '../MarginInputs/MarginInputs';
import { CUSTOM_TOAST_DURATION, SITE_MARGIN_VALIDATIONS, TOAST_SETTINGS } from '../../constants';
import { validateForm, deepClone } from '../../utils/helper';
import {
  createLosMargin,
  updateLosMargin,
  deleteLosMargin,
  getMargin,
  deleteSiteWithMargin
} from '../../redux/action/association.action';
import DeleteIcon from '../../assets/images/delete-icon.png';

const LOS_OPTIONS = [
  { value: '0.5', label: '6 Months' },
  { value: '1', label: '1 Year' },
  { value: '2', label: '2 Years' },
  { value: '3', label: '3 Years' },
  { value: '4', label: '4 Years' },
  { value: '5', label: '5 Years' },
  { value: '6', label: '6 Years' },
  { value: '7', label: '7 Years' },
  { value: '8', label: '8 Years' },
  { value: '9', label: '9 Years' },
  { value: '10', label: '10 Years' },
  { value: '11', label: '11 Years' },
  { value: '12', label: '12 Years' },
  { value: '13', label: '13 Years' },
  { value: '14', label: '14 Years' },
  { value: '15', label: '15 Years' },
  { value: '16', label: '16 Years' },
  { value: '17', label: '17 Years' },
  { value: '18', label: '18 Years' },
  { value: '19', label: '19 Years' },
  { value: '20', label: '20 Years' }
];

const LOSMarginModal = ({ isOpen, onClose, site, onSave, associationId }) => {
  const [selectedLOS, setSelectedLOS] = useState('');
  const [losMargins, setLOSMargins] = useState({});
  const [currentMarginData, setCurrentMarginData] = useState({});
  const [isMarginModalOpen, setIsMarginModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [validations, setValidations] = useState(deepClone(SITE_MARGIN_VALIDATIONS));
  const [refresh, setRefresh] = useState(false);
  const toast = useToast();
  const [availableLOSOptions, setAvailableLOSOptions] = useState(LOS_OPTIONS);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);

  const resetState = () => {
    setSelectedLOS('');
    setCurrentMarginData({});
    setIsEditing(false);
    setValidations(deepClone(SITE_MARGIN_VALIDATIONS));
    setRefresh(false);
  };

  const fetchMargins = async () => {
    if (!site) return;

    try {
      const result = await getMargin(associationId, site.site_id);
      if (result.ok) {
        const formattedMargins = result.data.margins_list.reduce((acc, margin) => {
          if (margin.los === null) return acc;
          acc[margin.los] = {
            id: margin.id,
            los: margin.los,
            margin: margin.margin,
            overtime_margin: margin.overtimeMargin,
            transport_fee: margin.transportFee,
            ssp: margin.ssp,
            training_margin: margin.trainingMargin,
            bh_margin: margin.bhMargin,
            nsp_margin: margin.nspMargin,
            supervisor_standard_margin: margin.supervisorStandardMargin,
            supervisor_overtime_margin: margin.supervisorOvertimeMargin,
            supervisor_permanent_margin: margin.supervisorPermanentMargin,
            suspension_margin: margin.suspensionMargin,
            induction_training_margin: margin.inductionTrainingMargin
          };
          return acc;
        }, {});
        setLOSMargins(formattedMargins);

        const usedLOSValues = Object.keys(formattedMargins);
        const availableOptions = LOS_OPTIONS.filter((option) => {
          const formattedOptionValue = parseFloat(option.value).toFixed(2);
          return !usedLOSValues.includes(formattedOptionValue);
        });

        setAvailableLOSOptions(availableOptions);
      } else {
        throw new Error('Failed to fetch margins');
      }
    } catch (error) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.ERROR,
        title: error.message
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchMargins();
      resetState();
    }
  }, [isOpen]);

  const handleAddMargin = (losValue) => {
    if (losValue) {
      setIsEditing(true);
      setSelectedLOS(losValue);
      const marginData = losMargins[losValue];
      setCurrentMarginData({
        id: marginData.id,
        margin: marginData.margin,
        overtime_margin: marginData.overtime_margin,
        transport_fee: marginData.transport_fee,
        ssp: marginData.ssp,
        training_margin: marginData.training_margin,
        bh_margin: marginData.bh_margin,
        nsp_margin: marginData.nsp_margin,
        supervisor_standard_margin: marginData.supervisor_standard_margin,
        supervisor_overtime_margin: marginData.supervisor_overtime_margin,
        supervisor_permanent_margin: marginData.supervisor_permanent_margin,
        suspension_margin: marginData.suspension_margin,
        induction_training_margin: marginData.induction_training_margin
      });
    } else {
      setIsEditing(false);
      setCurrentMarginData({});
    }
    setIsMarginModalOpen(true);
  };

  const handleSaveMargin = async () => {
    const [validationObject, isFormValid] = validateForm(currentMarginData, validations);
    setValidations(validationObject);

    const marginFields = [
      { field: 'margin', label: 'Standard Margin' },
      { field: 'overtime_margin', label: 'Overtime Margin' },
      { field: 'transport_fee', label: 'Transport Fee' },
      { field: 'ssp', label: 'Miscellaneous' },
      { field: 'training_margin', label: 'Training Margin' },
      { field: 'bh_margin', label: 'Bh Margin' },
      { field: 'nsp_margin', label: 'SP Margin' },
      { field: 'supervisor_standard_margin', label: 'Supervisor Standard Margin' },
      { field: 'supervisor_overtime_margin', label: 'Supervisor Overtime Margin' },
      { field: 'supervisor_permanent_margin', label: 'Supervisor Permanent Margin' },
      { field: 'suspension_margin', label: 'Suspension Margin' },
      { field: 'induction_training_margin', label: 'Induction Training Margin' }
    ];

    const invalidField = marginFields.find(
      ({ field }) => currentMarginData[field] > 9.99 || currentMarginData[field] < 0
    );

    if (invalidField) {
      const errorMessage =
        currentMarginData[invalidField.field] > 9.99
          ? `"${invalidField.label}" must be less than or equal to 9.99`
          : `"${invalidField.label}" must be greater than or equal to 0`;

      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.ERROR,
        title: errorMessage
      });
      return;
    }

    if (!isFormValid) {
      setRefresh(!refresh);
      return;
    }

    const payload = {
      margin: currentMarginData.margin || 0,
      overtime_margin: currentMarginData.overtime_margin || 0,
      transport_fee: currentMarginData.transport_fee || 0,
      ssp: currentMarginData.ssp || 0,
      training_margin: currentMarginData.training_margin || 0,
      bh_margin: currentMarginData.bh_margin || 0,
      nsp_margin: currentMarginData.nsp_margin || 0,
      supervisor_standard_margin: currentMarginData.supervisor_standard_margin || 0,
      supervisor_overtime_margin: currentMarginData.supervisor_overtime_margin || 0,
      supervisor_permanent_margin: currentMarginData.supervisor_permanent_margin || 0,
      suspension_margin: currentMarginData.suspension_margin || 0,
      induction_training_margin: currentMarginData.induction_training_margin || 0
    };

    try {
      const result = isEditing
        ? await updateLosMargin(currentMarginData.id, payload)
        : await createLosMargin(associationId, site.site_id, selectedLOS, payload);

      if (result.ok) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.SUCCESS,
          title: result.message
        });

        await fetchMargins();
        handleCloseMarginModal();
      } else {
        throw new Error(result.error || 'Operation failed');
      }
    } catch (error) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.ERROR,
        title: error.message || 'Failed to save margin'
      });
    }
  };

  const handleCloseMarginModal = () => {
    setIsMarginModalOpen(false);
    resetState();
  };

  const handleRemoveMargin = async (los) => {
    try {
      const result = await deleteLosMargin(associationId, site.site_id, los);
      if (result.ok) {
        // Remove from losMargins
        const updatedMargins = { ...losMargins };
        delete updatedMargins[los];
        setLOSMargins(updatedMargins);

        // Add the option back to availableLOSOptions
        const deletedOption = LOS_OPTIONS.find(
          (opt) => parseFloat(opt.value).toFixed(2) === parseFloat(los).toFixed(2)
        );

        if (deletedOption) {
          setAvailableLOSOptions((prev) =>
            [...prev, deletedOption].sort((a, b) => parseFloat(a.value) - parseFloat(b.value))
          );
        }

        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.SUCCESS,
          title: result.message
        });
      } else {
        throw new Error(result.message || 'Failed to delete margin');
      }
    } catch (error) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: error.message || 'Failed to delete margin'
      });
    }
  };

  const handleDeleteAllClick = () => {
    setIsDeleteAlertOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const result = await deleteSiteWithMargin(associationId, site.site_id);
      if (result.ok) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.SUCCESS,
          title: 'All margins deleted successfully'
        });
        setLOSMargins({});
        setAvailableLOSOptions(LOS_OPTIONS);
      } else {
        throw new Error(result.message || 'Failed to delete all margins');
      }
    } catch (error) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.ERROR,
        title: error.message || 'Failed to delete all margins'
      });
    } finally {
      setIsDeleteAlertOpen(false);
    }
  };

  const selectedLOSLabel =
    LOS_OPTIONS.find(
      (opt) => parseFloat(opt.value).toFixed(2) === parseFloat(selectedLOS).toFixed(2)
    )?.label || 'Unknown LOS';

  return (
    <>
      <ModalComponent
        isOpen={isOpen}
        onClose={onClose}
        modalTitle={
          <Box>
            Length of Service Margins -{' '}
            <Text as="span" display="inline-block" wordBreak="break-word">
              {site?.site_name}
            </Text>
          </Box>
        }
        modalContent={
          <Box>
            <Text mb={4} fontWeight="bold">
              Set LOS Margins for {site.site_name}
            </Text>
            <VStack spacing={4} align="stretch">
              <Flex>
                <Select
                  flex={1}
                  mr={2}
                  placeholder="Select LOS Duration"
                  value={selectedLOS}
                  onChange={(e) => setSelectedLOS(e.target.value)}>
                  {availableLOSOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
                <Button
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  isDisabled={!selectedLOS}
                  onClick={() => handleAddMargin()}
                  mr={2}>
                  Add Margin
                </Button>
                <Button
                  bg="red.500"
                  color="white"
                  _hover={{ bg: 'red.600' }}
                  onClick={handleDeleteAllClick}
                  isDisabled={Object.keys(losMargins).length === 0}>
                  Delete All
                </Button>
              </Flex>

              <VStack spacing={3} align="stretch">
                {Object.entries(losMargins).map(([los, margins]) => (
                  <HStack
                    key={los}
                    justifyContent="space-between"
                    bg="gray.50"
                    p={2}
                    borderRadius="md">
                    <Text fontWeight="medium" color="gray.700">
                      {`LOS: ${los % 1 === 0.5 ? `6 month(s)` : `${parseInt(los, 10)} year(s)`}`}
                    </Text>
                    <HStack>
                      <Button
                        size="sm"
                        bg="main.semiPrimary"
                        color="main.white"
                        _hover={{ bg: 'main.primary' }}
                        onClick={() => handleAddMargin(los)}>
                        Edit
                      </Button>
                      <Button
                        size="sm"
                        bg="main.semiPrimary"
                        color="main.white"
                        _hover={{ bg: 'main.primary' }}
                        _active={{
                          bg: 'main.semiPrimary',
                          borderColor: 'main.primary'
                        }}
                        onClick={() => handleRemoveMargin(los)}>
                        <Image src={DeleteIcon} height={4} />
                      </Button>
                    </HStack>
                  </HStack>
                ))}
              </VStack>
            </VStack>

            <ModalComponent
              isOpen={isMarginModalOpen}
              onClose={handleCloseMarginModal}
              modalTitle={`Set Margin for ${selectedLOSLabel}`}
              modalContent={
                <MarginInputs
                  data={currentMarginData}
                  setData={setCurrentMarginData}
                  validations={validations}
                  refresh={refresh}
                />
              }
              negative={
                <Button bg="main.secondary" mr={3} onClick={handleCloseMarginModal}>
                  Cancel
                </Button>
              }
              positive={
                <Button
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  onClick={handleSaveMargin}>
                  Save Margin
                </Button>
              }
            />
          </Box>
        }
        specifySize="xl"
      />

      <AlertModalComponent
        isOpen={isDeleteAlertOpen}
        onClose={() => setIsDeleteAlertOpen(false)}
        title="Delete All Margins"
        body="Are you sure you want to delete all LOS margins?"
        negative={
          <Button bg="main.secondary" mr={3} onClick={() => setIsDeleteAlertOpen(false)}>
            Cancel
          </Button>
        }
        positive={
          <Button
            bg="main.semiPrimary"
            color="main.white"
            _hover={{ bg: 'main.primary' }}
            _active={{
              bg: 'main.semiPrimary',
              borderColor: 'main.primary'
            }}
            onClick={handleDeleteConfirm}>
            Delete
          </Button>
        }
      />
    </>
  );
};

export default LOSMarginModal;
