/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable import/named */
/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */

import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box, Text, Heading, Flex, Tooltip } from '@chakra-ui/react';
import { StyledBox } from '../PerformanceDashboard.styled';

export default function AssemblyPerformance({ title, data }) {
  const getMaxValue = () => {
    const allValues = [
      ...(data.fulfilled || []),
      ...(data.requested || []),
      ...(data.amended || [])
    ];
    // Filter for positive numbers and convert to integers
    const validValues = allValues
      .filter((val) => val !== null && val !== undefined && !Number.isNaN(val))
      .map((val) => Math.max(0, Math.round(Number(val))));

    const maxValue = validValues.length > 0 ? Math.max(...validValues) : 1;
    // Round up to the nearest hundred
    const roundedMax = Math.ceil(maxValue / 100) * 100;
    return Math.max(100, roundedMax);
  };

  const chartOptions = {
    chart: {
      type: 'line',
      stacked: false,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false // Disable graph zoom
      },
      height: 350
    },
    colors: ['#3B82F6', '#134685', '#22D3EE'], // Bar, Requested line, Amended line
    stroke: {
      width: [0, 2, 2], // First is bar (0 width), others are lines
      curve: 'smooth'
    },
    plotOptions: {
      bar: {
        columnWidth: '50%'
      }
    },
    fill: {
      opacity: [0.85, 1, 1],
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: 'vertical',
        opacityFrom: 0.85,
        opacityTo: 0.55
      }
    },
    labels: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    markers: {
      size: 0
    },
    xaxis: {
      type: 'category'
    },
    yaxis: {
      min: 0,
      max: getMaxValue()
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter(y) {
          if (y === null || typeof y === 'undefined') {
            return 'N/A';
          }
          return `${y.toFixed(0)}`;
        }
      }
    }
  };

  const series = [
    {
      name: 'Actual Fulfillment',
      type: 'column',
      data: data.fulfilled || []
    },
    {
      name: 'Final Booking',
      type: 'line',
      data: data.amended || []
    },
    {
      name: 'Original Booking',
      type: 'line',
      data: data.requested || []
    }
  ];

  return (
    <StyledBox mb={4}>
      <Flex justifyContent="center" mb={2}>
        <Heading as="h2" size="md" color="blue.700">
          {title}
        </Heading>
      </Flex>
      <Flex justifyContent="center" mb={4} gap={20}>
        <Tooltip
          backgroundColor="#e9f4ff"
          w="170px"
          color="black"
          border="none"
          fontWeight="500"
          p="8px"
          borderRadius="7px"
          placement="bottom"
          label="Requested Fulfillment">
          <Text fontSize="3xl" fontWeight="bold" color="blue.600" cursor="pointer">
            {data.percentages?.requested}%
          </Text>
        </Tooltip>
        <Tooltip
          backgroundColor="#e9f4ff"
          w="170px"
          color="black"
          border="none"
          fontWeight="500"
          p="8px"
          borderRadius="7px"
          placement="bottom"
          label="Amended Fulfillment">
          <Text fontSize="3xl" fontWeight="bold" color="cyan.400" cursor="pointer">
            {data.percentages?.amended}%
          </Text>
        </Tooltip>
      </Flex>
      <Box height="350px">
        <ReactApexChart options={chartOptions} series={series} type="line" height="100%" />
      </Box>
    </StyledBox>
  );
}
