/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Center, useMediaQuery } from '@chakra-ui/react';

const HeaderCard = React.forwardRef(({ children, ...props }, ref) => {
  const [isSmallerThan1400] = useMediaQuery('(max-width: 1400px)');

  return (
    <Box
      ref={ref}
      justifyContent="center"
      p={isSmallerThan1400 ? '2' : '3'}
      bg="white"
      borderWidth="1px"
      borderRadius="8px"
      // overflow="hidden"
      style={{
        display: 'flex',
        justifyContent: Center,
        flexDirection: 'column',
        alignContent: 'center'
      }}
      {...props}>
      {children}
    </Box>
  );
});

export default HeaderCard;
