import styled from 'styled-components';

export const Flex = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const StyledInputWrapper = styled.div`
  flex: 1;
`;
