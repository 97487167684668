import React from 'react';

const SiteIcon = function () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 25 25">
      <path
        fill="currentColor"
        d="M22.656 0A2.344 2.344 0 0125 2.344v14.062a2.344 2.344 0 01-2.344 2.344H8.594a2.344 2.344 0 01-2.344-2.344V2.344A2.344 2.344 0 018.594 0h14.062zM8.594 20.313a3.91 3.91 0 01-3.906-3.907V6.25H2.344A2.344 2.344 0 000 8.594v14.062A2.344 2.344 0 002.344 25h14.062a2.344 2.344 0 002.344-2.344v-2.343H8.594z"
      />
    </svg>
  );
};

export default SiteIcon;
