/* eslint-disable react/prop-types */
import Dropdown from '../Dropdown';
import { LANGUAGE_OPTION } from '../../constants';

const LanguageDropdown = function ({ language, setLanguage, setPrevlanguage }) {
  return (
    <Dropdown
      label="Language"
      placeholder="Select"
      options={LANGUAGE_OPTION.map((item) => {
        return { label: item.name, value: item.code };
      })}
      value={language}
      onSelect={(e) => {
        setPrevlanguage(language);
        setLanguage(e);
      }}
      disabled={false}
      // isError={!isValid}
    />
  );
};

export default LanguageDropdown;
