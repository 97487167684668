/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable react/function-component-definition */
import React, { useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import NewStarterPerformance from './components/NewStarterPerformance';
import ShiftPerformanceList from './components/ShiftPerformanceList';
import DashboardCard from '../../components/DashboardCard';
import SiteViewPerformance from './components/SiteViewPerformance';
import { PAGE_TITLE } from '../../constants';

const PerformanceDashboard = () => {
  const { selected_performance_dashboard_type } = useSelector((state) => state.agency);
  useEffect(() => {
    document.title = PAGE_TITLE.REPORTING;
  }, []);
  const renderPerformanceContent = () => {
    switch (selected_performance_dashboard_type) {
      case 'Performance Dashboard':
        return <NewStarterPerformance />;
      case 'Shift Report':
        return <ShiftPerformanceList />;
      case 'Site View':
        return <SiteViewPerformance />;
      default:
        return (
          <DashboardCard m="5px">
            <Text textAlign="center" color="gray.500">
              Please select a dashboard type
            </Text>
          </DashboardCard>
        );
    }
  };

  return <Box>{renderPerformanceContent()}</Box>;
};

export default PerformanceDashboard;
