import moment from 'moment';
import { DATE_SAVE_TO_DB } from '../../constants';

/* eslint-disable default-param-last */
const initialState = {
  selected_client_id: null,
  selected_client_name: null,
  selected_site_id: null,
  selected_site_name: null,
  selected_start_date: DATE_SAVE_TO_DB(
    moment(new Date(), 'DD/MM/YYYY').startOf('week').format('DD/MM/YYYY')
  ),
  selected_end_date: DATE_SAVE_TO_DB(
    moment(new Date(), 'DD/MM/YYYY').endOf('week').format('DD/MM/YYYY')
  ),
  selected_performance_dashboard_type: 'Performance Dashboard', // Set default dashboard type
  selected_performance_region_id: null,
  selected_performance_region_name: null,
  selected_performance_site_id: null,
  selected_performance_site_name: null,
  selected_performance_shift_id: null,
  selected_performance_shift_name: null,
  selected_performance_department_id: null,
  selected_performance_department_name: null
};

const agency = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_LOGIN':
      return {
        ...initialState,
        selected_start_date: state.selected_start_date,
        selected_end_date: state.selected_end_date,
        selected_client_id: state.selected_client_id,
        selected_client_name: state.selected_client_name,
        selected_site_id: state.selected_site_id,
        selected_site_name: state.selected_site_name
      };
    case 'SELECT_CLIENT':
      return {
        ...state,
        selected_client_id: action.payload.selected_client_id,
        selected_client_name: action.payload.selected_client_name,
        is_restricted: action.payload.is_restricted,
        weekday_start: action.payload.weekday_start,
        total_assignment_pay: action.payload.total_assignment_pay
      };
    case 'SELECT_SITE':
      return {
        ...state,
        selected_site_id: action.payload.selected_site_id,
        selected_site_name: action.payload.selected_site_name
      };
    case 'SELECT_DATE_RANGE':
      return {
        ...state,
        selected_start_date: action.payload.start_date,
        selected_end_date: action.payload.end_date
      };
    case 'SELECT_PERFORMANCE_DASHBOARD_TYPE':
      return {
        ...state,
        selected_performance_dashboard_type: action.payload.dashboard_type
      };
    case 'SELECT_PERFORMANCE_REGION':
      return {
        ...state,
        selected_performance_region_id: action.payload.region_id,
        selected_performance_region_name: action.payload.region_name
      };
    case 'SELECT_PERFORMANCE_SITE':
      return {
        ...state,
        selected_performance_site_id: action.payload.site_id,
        selected_performance_site_name: action.payload.site_name
      };
    case 'SELECT_PERFORMANCE_SHIFT':
      return {
        ...state,
        selected_performance_shift_id: action.payload.shift_id,
        selected_performance_shift_name: action.payload.shift_name
      };
    case 'SELECT_PERFORMANCE_DEPARTMENT':
      return {
        ...state,
        selected_performance_department_id: action.payload.department_id,
        selected_performance_department_name: action.payload.department_name
      };
    default:
      return state;
  }
};

export default agency;
