import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { resetPassword } from '../../redux/action/user.action';
import LoginBox from '../../components/LoginBox';
import constants, {
  PATH,
  RESET_PASSWORD_VALIDATIONS,
  TOAST_SETTINGS,
  PAGE_TITLE,
  CUSTOM_TOAST_DURATION
} from '../../constants';
import { useQuery } from '../../utils/hooks';
import { deepClone, validateForm, validatePassword } from '../../utils/helper';

const ResetPassword = function () {
  const navigate = useNavigate();
  const toast = useToast();
  const query = useQuery();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validations, setValidations] = useState(deepClone(RESET_PASSWORD_VALIDATIONS));
  const [refresh, setRefresh] = useState(false);

  // useEffect(() => {
  //   window.document.title = `${window.document.title} | Reset Password`;
  // }, []);

  useEffect(() => {
    document.title = PAGE_TITLE.RESET_PASSWORD;
  }, []);

  const handleClick = async () => {
    const [validationObject, isFormValid] = validateForm(
      {
        password,
        confirmPassword
      },
      validations
    );
    const checkPassword = validatePassword(password);
    if (checkPassword !== true) {
      validationObject.password = {
        required: true,
        errorMessage: 'Please Enter Valid Password',
        isvalid: false
      };
      validationObject.confirmPassword = {
        required: true,
        errorMessage: 'Please Enter Valid Password',
        isvalid: false
      };
      setValidations(validationObject);
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.ERROR,
        title:
          'Password must have at least 8 characters including lowercase, uppercase, special, and numeric characters.'
      });
    }
    if (checkPassword && password !== confirmPassword) {
      validationObject.password = {
        required: true,
        errorMessage: 'Password and confirm password must be same',
        isvalid: false
      };
      validationObject.confirmPassword = {
        required: true,
        errorMessage: 'Password and confirm password must be same',
        isvalid: false
      };
    }
    setValidations(validationObject);
    if (checkPassword && password === confirmPassword && isFormValid) {
      const result = await resetPassword({
        code: query.get('code'),
        password
      });
      if (result && result.ok) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.SUCCESS,
          title: result.message
        });
        navigate(PATH.LOGIN.TO);
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
    } else {
      setRefresh(!refresh);
    }
  };
  return (
    <LoginBox
      leftButtonText={constants.UPDATE_PASSWORD}
      confirmPassword={confirmPassword}
      setConfirmPassword={setConfirmPassword}
      password={password}
      setPassword={setPassword}
      rightLinkTo={PATH.FORGOT_PASSWORD.TO}
      type="RESET-PASSWORD"
      leftButtonClick={handleClick}
      refresh={refresh}
      validations={validations}
    />
  );
};

export default ResetPassword;
