import React from 'react';
import ReactDOM from 'react-dom';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import storeConfig from './config/store.config';
// import './assets/fonts/NimbusSanL-Bol.ttf';
// import './assets/fonts/NimbusSanL-Reg.ttf';

Bugsnag.start({
  apiKey: 'c81b66444aac444ede8906773d378609',
  plugins: [new BugsnagPluginReact()]
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <Provider store={storeConfig.storeConfig}>
    <PersistGate loading={null} persistor={storeConfig.persistor}>
      <React.StrictMode>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
