/* eslint-disable object-shorthand */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { HEAD_LEAVERS_COLORS } from '../../constants';

const AreaChart = function ({ height = '100%', series, categories, colors = HEAD_LEAVERS_COLORS }) {
  const [options] = useState({
    chart: {
      type: 'area',
      height,
      toolbar: {
        show: true,
        tools: {
          download: false
        }
      }
    },
    zoom: {
      enabled: true,
      type: 'x',
      resetIcon: {
        offsetX: -10,
        offsetY: 0,
        fillColor: '#fff',
        strokeColor: '#37474F'
      },
      selection: {
        background: '#90CAF9',
        border: '#0D47A1'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: '',
      align: 'left'
    },
    colors: colors,
    xaxis: {
      categories,
      labels: {
        show: false,
        rotate: -90,
        rotateAlways: true,
        hideOverlappingLabels: true
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      labels: {
        show: true,
        formatter: function (value) {
          return value.toFixed(2);
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    grid: {
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    }
  });

  return (
    <ReactApexChart height={height} width="100%" options={options} series={series} type="area" />
  );
};

export default AreaChart;
