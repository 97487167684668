import axiosInstance from '../../config/axios.config';
import { API_ENDPOINT } from '../../constants';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';

const getSpendData = async (
  clientId,
  siteId,
  agencyId,
  regionId,
  workerType,
  startDate,
  endDate
) => {
  let query = '?';
  if (agencyId) {
    query += `agency_id=${agencyId}`;
  }
  if (agencyId && clientId) {
    query += `&client_id=${clientId}`;
  } else if (clientId) {
    query += `client_id=${clientId}`;
  }
  if (regionId && (agencyId || clientId)) {
    query += `&region_id=${regionId}`;
  } else if (regionId) {
    query += `region_id=${regionId}`;
  }
  if (siteId && (agencyId || clientId)) {
    query += `&site_id=${siteId}`;
  } else if (siteId) {
    query += `site_id=${siteId}`;
  }
  if (workerType) {
    query += `&type=${workerType}`;
  }
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_TRENDS_SPEND}${query}&start_date=${startDate}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getHoursData = async (
  clientId,
  siteId,
  agencyId,
  regionId,
  workerType,
  startDate,
  endDate
) => {
  let query = '?';
  if (agencyId) {
    query += `agency_id=${agencyId}`;
  }
  if (agencyId && clientId) {
    query += `&client_id=${clientId}`;
  } else if (clientId) {
    query += `client_id=${clientId}`;
  }
  if (regionId && (agencyId || clientId)) {
    query += `&region_id=${regionId}`;
  } else if (regionId) {
    query += `region_id=${regionId}`;
  }
  if (siteId && (agencyId || clientId)) {
    query += `&site_id=${siteId}`;
  } else if (siteId) {
    query += `site_id=${siteId}`;
  }
  if (workerType) {
    query += `&type=${workerType}`;
  }
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_TRENDS_HOURS}${query}&start_date=${startDate}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getHeadsData = async (
  clientId,
  siteId,
  agencyId,
  regionId,
  workerType,
  startDate,
  endDate
) => {
  let query = '?';
  if (agencyId) {
    query += `agency_id=${agencyId}`;
  }
  if (agencyId && clientId) {
    query += `&client_id=${clientId}`;
  } else if (clientId) {
    query += `client_id=${clientId}`;
  }
  if (regionId && (agencyId || clientId)) {
    query += `&region_id=${regionId}`;
  } else if (regionId) {
    query += `region_id=${regionId}`;
  }
  if (siteId && (agencyId || clientId)) {
    query += `&site_id=${siteId}`;
  } else if (siteId) {
    query += `site_id=${siteId}`;
  }
  if (workerType) {
    query += `&type=${workerType}`;
  }
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_TRENDS_HEADS}${query}&start_date=${startDate}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getLeaversData = async (
  clientId,
  siteId,
  agencyId,
  regionId,
  workerType,
  startDate,
  endDate
) => {
  let query = '?';
  if (agencyId) {
    query += `agency_id=${agencyId}`;
  }
  if (agencyId && clientId) {
    query += `&client_id=${clientId}`;
  } else if (clientId) {
    query += `client_id=${clientId}`;
  }
  if (regionId && (agencyId || clientId)) {
    query += `&region_id=${regionId}`;
  } else if (regionId) {
    query += `region_id=${regionId}`;
  }
  if (siteId && (agencyId || clientId)) {
    query += `&site_id=${siteId}`;
  } else if (siteId) {
    query += `site_id=${siteId}`;
  }
  if (workerType) {
    query += `&type=${workerType}`;
  }
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_TRENDS_LEAVERS}${query}&start_date=${startDate}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getSiteRatingData = async (
  clientId,
  siteId,
  agencyId,
  regionId,
  workerType,
  startDate,
  endDate
) => {
  let query = '?';
  if (agencyId) {
    query += `agency_id=${agencyId}`;
  }
  if (agencyId && clientId) {
    query += `&client_id=${clientId}`;
  } else if (clientId) {
    query += `client_id=${clientId}`;
  }
  if (regionId && (agencyId || clientId)) {
    query += `&region_id=${regionId}`;
  } else if (regionId) {
    query += `region_id=${regionId}`;
  }
  if (siteId && (agencyId || clientId)) {
    query += `&site_id=${siteId}`;
  } else if (siteId) {
    query += `site_id=${siteId}`;
  }
  if (workerType) {
    query += `&type=${workerType}`;
  }
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_TRENDS_SITE_RATING}${query}&start_date=${startDate}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getAgencyRatingData = async (
  clientId,
  siteId,
  agencyId,
  regionId,
  workerType,
  startDate,
  endDate
) => {
  let query = '?';
  if (agencyId) {
    query += `agency_id=${agencyId}`;
  }
  if (agencyId && clientId) {
    query += `&client_id=${clientId}`;
  } else if (clientId) {
    query += `client_id=${clientId}`;
  }
  if (regionId && (agencyId || clientId)) {
    query += `&region_id=${regionId}`;
  } else if (regionId) {
    query += `region_id=${regionId}`;
  }
  if (siteId && (agencyId || clientId)) {
    query += `&site_id=${siteId}`;
  } else if (siteId) {
    query += `site_id=${siteId}`;
  }
  if (workerType) {
    query += `&type=${workerType}`;
  }
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_TRENDS_AGENCY_RATING}${query}&start_date=${startDate}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getClientRatingData = async (
  clientId,
  siteId,
  agencyId,
  regionId,
  workerType,
  startDate,
  endDate
) => {
  let query = '?';
  if (agencyId) {
    query += `agency_id=${agencyId}`;
  }
  if (agencyId && clientId) {
    query += `&client_id=${clientId}`;
  } else if (clientId) {
    query += `client_id=${clientId}`;
  }
  if (regionId && (agencyId || clientId)) {
    query += `&region_id=${regionId}`;
  } else if (regionId) {
    query += `region_id=${regionId}`;
  }
  if (siteId && (agencyId || clientId)) {
    query += `&site_id=${siteId}`;
  } else if (siteId) {
    query += `site_id=${siteId}`;
  }
  if (workerType) {
    query += `&type=${workerType}`;
  }
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_TRENDS_COMPANY_RATING}${query}&start_date=${startDate}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export {
  getSpendData,
  getHoursData,
  getHeadsData,
  getLeaversData,
  getSiteRatingData,
  getAgencyRatingData,
  getClientRatingData
};
