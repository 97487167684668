/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-shadow */
/* eslint-disable prefer-const */

import React, { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { StyledDivider } from '../Modal/Modal.styled';
import AddUser from './AddUser';
import { ADD_USER_VALIDATIONS, CUSTOM_TOAST_DURATION, TOAST_SETTINGS } from '../../constants';
import { deepClone, validateForm } from '../../utils/helper';
import { addUser } from '../../redux/action/user.action';

const MultiUser = function ({
  adminData,
  setAdminData,
  refresh,
  addClick,
  setAddClick,
  setRefresh,
  saveClick,
  setSaveClick,
  saveAdminData,
  Id,
  ADMIN
}) {
  const defaultAdminData = {
    name: '',
    email: '',
    label: '',
    country_code: '+44',
    mobile: ''
  };
  const [userValidations, setUserValidations] = useState(deepClone(ADD_USER_VALIDATIONS));
  const [newAdminData, setNewAdminData] = useState(defaultAdminData);
  const [filledData, setFilledData] = useState([]);
  const toast = useToast();

  const addAdminData = async (data) => {
    const payloadUsers = data.map((item) => {
      return {
        id: Id,
        name: item.name,
        email: item.email,
        phone: item.mobile,
        country_code: item.country_code
      };
    });
    const result = await addUser({
      user_type: ADMIN,
      users: payloadUsers
    });
    if (result && result.ok) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
      setUserValidations(deepClone(ADD_USER_VALIDATIONS));
      const data = [...adminData];
      data.pop();
      setFilledData((prev) => [...prev, newAdminData]);
      setAdminData([...data, newAdminData, defaultAdminData]);
      setNewAdminData(null);
      setTimeout(() => setNewAdminData(defaultAdminData), 0);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };
  useEffect(() => {
    if (addClick) {
      let [validationObject, isFormValid] = validateForm(newAdminData, userValidations);
      if (!(newAdminData.mobile.length === 10)) {
        validationObject.mobile.isvalid = false;
        isFormValid = false;
      }
      if (isFormValid) {
        addAdminData([newAdminData]);
      } else {
        setRefresh(!refresh);
        setUserValidations(validationObject);
      }
      setAddClick(false);
    }
    if (saveClick) {
      let [validationObject, isFormValid] = validateForm(newAdminData, userValidations);
      if (!(newAdminData.mobile.length === 10)) {
        validationObject.mobile.isvalid = false;
        isFormValid = false;
      }
      if (isFormValid) {
        saveAdminData([newAdminData]);
        setUserValidations(deepClone(ADD_USER_VALIDATIONS));
      } else {
        setRefresh(!refresh);
        setUserValidations(validationObject);
      }
      setSaveClick(false);
    }
  }, [addClick, saveClick, newAdminData, userValidations, refresh]);

  return (
    <div>
      {filledData && filledData?.length > 0 ? (
        <>
          {filledData.map((data) => (
            <AddUser
              data={data}
              setData={() => {}}
              validations={ADD_USER_VALIDATIONS}
              refresh
              key={data}
              disabled
            />
          ))}
          {newAdminData && Object.keys(newAdminData).length !== 0 && (
            <>
              <StyledDivider />
              <AddUser
                data={newAdminData}
                setData={setNewAdminData}
                validations={userValidations}
                refresh={refresh}
              />
            </>
          )}
        </>
      ) : (
        newAdminData &&
        Object.keys(newAdminData).length !== 0 && (
          <AddUser
            data={newAdminData}
            setData={setNewAdminData}
            validations={userValidations}
            refresh={refresh}
          />
        )
      )}
    </div>
  );
};

export default MultiUser;
